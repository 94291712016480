import { makeStyles } from "@material-ui/core";
import colors from "../../../styles/colors";
import {
    ADDRESS_FUNC,
    APPLICATION_TYPE_FUNCTION,
    ARRIVED_INFO_FUNC,
    CONSUMPTION_PLACE_TYPE,
    CUSTOMER_NAME_FUNC,
    LEGAL_BASIS_TYPE,
    MOD_REASON_FUNC,
} from "../../../utils/AppConst";
import StatusLog from "./StatusLog";
import { Link } from "react-router-dom";

const barCodeStatusLogBtn = (id) => <StatusLog applicantId={id} />;

//IGÉNYLŐLAP alap adatai:
export function basicDetails(data, downloadEdms) {
    function getColorCode(type) {
        switch (type) {
            case "application":
                return colors.green;
            case "modification":
                return colors.edit;
            case "termination":
                return colors.red;
            default:
                break;
        }
    }

    const useStyles = makeStyles((theme) => ({
        colorCode: {
            background: "#FFDB64",
        },
        box: {
            height: "20px",
            width: "20px",
            displace: "table-cell",
            background: getColorCode(data?.type),
            margin: theme.spacing(0.5),
            marginLeft: theme.spacing(1),
            transform: "translateY(-2px)",
        },
        link: {
            textDecoration: "none",
            cursor: "pointer",
            "&:link, &:visited": {
                color: "#000000DE",
                textDecoration: "none",
                cursor: "pointer",
            },
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer",
                color: colors.primary,
            }
        },
    }));

    const classes = useStyles();

    const colorCode = <div className={classes.box}></div>;

    let APPLICATION_BASIC_DETAILS = [];

    const barCodeContent = (
        <>
            {data?.barCode}
            {barCodeStatusLogBtn(data?.id)}
        </>
    );

    APPLICATION_BASIC_DETAILS.push(
        { title: "Hivatkozási szám", value: data?.id },
        { title: "Vonalkód", value: barCodeContent },
        { title: "Érkeztetve", value: ARRIVED_INFO_FUNC(data, downloadEdms) || "" },
        { title: "Igénylőlap típusa", value: APPLICATION_TYPE_FUNCTION(data?.type) || "", colorCode: colorCode },
        {
            title: "MAVIR-ID",
            value: data?.mavirId ? (
                <Link to={`/clients/${data?.mavirId}`} className={classes.link}>
                    {data?.mavirId}
                </Link>
            ) : (
                "Nincs"
            ),
        }
    );

    if (data?.deletedAt) {
        APPLICATION_BASIC_DETAILS.push({
            title: "Igénylőlap sztornózva",
            value: data?.deletedAt?.split("T")[0] + " " + data?.deletedAt?.split("T")[1].split("+")[0],
        });
    }

    return APPLICATION_BASIC_DETAILS;
}

//1. IGÉNYLŐ SZEMÉLYES ADATAI:
export function personalDetails(data) {
    let APPLICATION_PERSONAL_DETAILS = [];
    if (data?.applicant) {
        let applicant = data?.applicant;
        let motherName,
            birthName = undefined;
        if (applicant.motherLastName !== undefined && applicant.motherFirstName !== undefined) {
            motherName = applicant.motherLastName + " " + applicant.motherFirstName;
        }
        if (applicant.birthLastName !== undefined && applicant.birthLastName !== undefined) {
            birthName = applicant.birthLastName + " " + applicant.birthFirstName;
        }
        if (applicant?.firstName) {
            APPLICATION_PERSONAL_DETAILS = [
                { title: "Név", value: CUSTOMER_NAME_FUNC(applicant) },
                {
                    title: "Anyja születési neve",
                    value: motherName !== undefined ? motherName : "",
                },
                {
                    title: "Születési Név",
                    value: birthName !== undefined ? birthName : "",
                },
                { title: "Születési hely", value: applicant.birthPlace || "" },
                { title: "Születési idő", value: applicant.birthDate || "" },
                { title: "Lakcím", value: ADDRESS_FUNC(applicant.address) },
                {
                    title: "Helyrajzi szám",
                    value: applicant.address.parcelNumber || "",
                },
                { title: "Telefonszám", value: applicant.phoneNumber || "" },
                { title: "E-mail cím", value: applicant.email || "" },
            ];
        }
    }

    return APPLICATION_PERSONAL_DETAILS;
}

//2. JOGOSULTSÁGRA VONATKOZÓ ADATOK
export function permissionDetails(data, employers) {
    let APPLICATION_PERMISSION_DETAILS = [];

    if (employers) {
        let legalBasisType = data?.legalBasis?.type;
        if (legalBasisType) {
            APPLICATION_PERMISSION_DETAILS.push({
                title: "A jogosultság alapja",
                value: LEGAL_BASIS_TYPE(legalBasisType) || "",
            });
        }
        //ha alkalmazott vagy szakszervezeti alkalmazott:
        if (legalBasisType === "employee" || legalBasisType === "trade_union_employee") {
            let employer = employers.find((x) => x.id === data?.legalBasis.employerId);
            APPLICATION_PERMISSION_DETAILS.push({
                title: "A jelenlegi munkáltató megnevezése",
                value: employer?.name || "",
            });
        }
        //nyugdíjas vagy szakszervezeti nyugdíjas:
        if (legalBasisType === "pensioner" || legalBasisType === "trade_union_pensioner") {
            let employer = employers.find((x) => x.id === data?.legalBasis?.lastEmployerId);
            if (data?.legalBasis?.pensionerRegistrationNumber === "in_progress") {
                APPLICATION_PERMISSION_DETAILS.push(
                    {
                        title: "Nyugdíjas törzsszáma",
                        value: "Folyamatban",
                    },
                    {
                        title: "Utolsó munkáltató megnevezése",
                        value: employer?.name || data?.legalBasis?.legacyLastEmployerName || "",
                    }
                );
            } else {
                APPLICATION_PERMISSION_DETAILS.push(
                    {
                        title: "Nyugdíjas törzsszáma",
                        value: data?.legalBasis?.pensionerRegistrationNumber || "",
                    },
                    {
                        title: "Utolsó munkáltató megnevezése",
                        value: employer?.name || data?.legalBasis?.legacyLastEmployerName || "",
                    }
                );
            }
        }
        //özvegy:
        if (
            legalBasisType === "employees_widow" ||
            legalBasisType === "pensioners_widow" ||
            legalBasisType === "trade_union_employees_widow" ||
            legalBasisType === "trade_union_pensioner_widow"
        ) {
            let deceased = data?.legalBasis.deceased;
            const employer = employers.find((emp) => emp.id === data?.legalBasis?.deceased.lastEmployerId);
            APPLICATION_PERMISSION_DETAILS.push(
                {
                    title: "Az elhunyt házastárs neve",
                    value: deceased.lastName + " " + deceased.firstName || "",
                },
                {
                    title: "Az elhunyt születési neve",
                    value: deceased.birthLastName + " " + deceased.birthFirstName || "",
                },
                {
                    title: "Az elhunyt anyjának születési neve",
                    value: deceased.motherLastName + " " + deceased.motherFirstName || "",
                },
                {
                    title: "Az elhunyt születési helye, ideje",
                    value: deceased.birthPlace + ", " + deceased.birthDate || "",
                },
                { title: "Az elhalálozás időpontja", value: deceased.deathDate || "" },
                {
                    title: "Halotti anyakönyvi kivonat száma",
                    value: deceased.deathCertificateNumber || "",
                },
                { title: "Utolsó munkáltató megnevezése", value: employer?.name || data?.legalBasis?.deceased?.legacyLastEmployerName || "" },
                {
                    title: "Az elhunyttal kötött házasságra vonatkozó házassági anyakönyvi kivonat száma",
                    value: deceased.marriageCertificateNumber || "",
                },
                {
                    title: "Az igénylő öregségi nyugdíjra való jogosultságának (várható) kezdete",
                    value: data?.legalBasis.retirementDate || "",
                }
            );
        }
    }

    return APPLICATION_PERMISSION_DETAILS;
}

//3. A KEDVEZMÉNYES VILLAMOS-ENERGIA-VÉTELEZÉSRE VONATKOZÓ ADATOK
export function discountDetails(data, providers) {
    let APPLICATION_DISCOUNT_DETAILS = [];

    if (data?.primaryConsumptionPlace) {
        let primaryConsumptionPlace = data?.primaryConsumptionPlace;
        let provider = providers?.find((x) => x.id === primaryConsumptionPlace.serviceProvider);

        APPLICATION_DISCOUNT_DETAILS.push(
            { title: "Első fogyasztási hely", value: "title" },
            {
                title: "Cím",
                value: ADDRESS_FUNC(primaryConsumptionPlace.address) || "",
            },
            {
                title: "A kedvezményes felhasználási hely azonosítója",
                value: primaryConsumptionPlace.reference || "",
            },
            {
                title: "A használat jogcíme",
                value: CONSUMPTION_PLACE_TYPE(primaryConsumptionPlace.type) || "",
            },
            {
                title: "Fogyasztásmérő órák mennyisége",
                value: primaryConsumptionPlace.consumptionMeters.length || "",
            }
        );

        for (let i = 0; i < primaryConsumptionPlace.consumptionMeters.length; i++) {
            APPLICATION_DISCOUNT_DETAILS.push(
                {
                    title: `${i + 1}. mérőóra azonosítója`,
                    value: primaryConsumptionPlace?.consumptionMeters[i]?.reference,
                },
                {
                    title: `${i + 1}. mérőóra állása`,
                    value: primaryConsumptionPlace?.consumptionMeters[i]?.reading,
                }
            );
        }

        APPLICATION_DISCOUNT_DETAILS.push(
            {
                title: "Az egyetemes szolgáltató megnevezése",
                value: provider?.name || "",
            },
            {
                title: "Az igénylő egyetemes szolgáltató szerinti ügyfélszáma",
                value: primaryConsumptionPlace.consumerNumber || "",
            },
            {
                title: "Szerződésszám",
                value: primaryConsumptionPlace.contractNumber || "",
            },
            {
                title: "A tárgyévben kedvezményesen vételezni kívánt villamosenergia-mennyiség",
                value: primaryConsumptionPlace.quantity + " kWh" || "",
            }
        );
    }
    if (data?.secondaryConsumptionPlace) {
        let secondaryConsumptionPlace = data?.secondaryConsumptionPlace;
        let provider = providers.find((x) => x.id === secondaryConsumptionPlace.serviceProvider);
        APPLICATION_DISCOUNT_DETAILS.push(
            { title: "Második fogyasztási hely", value: "title" },
            {
                title: "Cím",
                value: ADDRESS_FUNC(secondaryConsumptionPlace.address) || "",
            },
            {
                title: "A kedvezményes felhasználási hely azonosítója",
                value: secondaryConsumptionPlace.reference || "",
            },
            {
                title: "A használat jogcíme",
                value: CONSUMPTION_PLACE_TYPE(secondaryConsumptionPlace.type) || "",
            },
            {
                title: "Fogyasztásmérő órák mennyisége",
                value: secondaryConsumptionPlace.consumptionMeters.length || "",
            }
        );

        for (let i = 0; i < secondaryConsumptionPlace.consumptionMeters.length; i++) {
            APPLICATION_DISCOUNT_DETAILS.push(
                {
                    title: `${i + 1}. mérőóra azonosítója`,
                    value: secondaryConsumptionPlace?.consumptionMeters[i]?.reference,
                },
                {
                    title: `${i + 1}. mérőóra állása`,
                    value: secondaryConsumptionPlace?.consumptionMeters[i]?.reading,
                }
            );
        }

        APPLICATION_DISCOUNT_DETAILS.push(
            {
                title: "Az egyetemes szolgáltató megnevezése",
                value: provider?.name || "",
            },
            {
                title: "Az igénylő egyetemes szolgáltató szerinti vevőkódja/ügyfélszáma/partnerszáma",
                value: secondaryConsumptionPlace.consumerNumber || "",
            },
            {
                title: "Szerződésszám",
                value: secondaryConsumptionPlace.contractNumber || "",
            },
            {
                title: "A tárgyévben kedvezményesen vételezni kívánt villamosenergia-mennyiség",
                value: secondaryConsumptionPlace.quantity + " kWh" || "",
            }
        );
    }

    return APPLICATION_DISCOUNT_DETAILS;
}

//4. Kedvezményes vételezés módosításához/megszüntetéséhez kapcsolódó adatok
export function modificationDetails(data, providers) {
    let APPLICATION_MODIFICATION_DETAILS = [];

    if (data?.primaryConsumptionPlaceTermination) {
        let primaryConsumptionPlace = data?.primaryConsumptionPlaceTermination;
        let provider = providers?.find((x) => x.id === primaryConsumptionPlace.serviceProvider);

        APPLICATION_MODIFICATION_DETAILS.push(
            {
                title: "I. Kedvezményes vételezés befejezése vagy megszüntetése az első kedvezményes felhasználási helyen",
                value: "title",
            },
            {
                title: "A kedvezményes vételezés befejezésének vagy megszüntetésének oka",
                value: MOD_REASON_FUNC(data?.primaryConsumptionPlaceTermination?.type) || "",
            },
            {
                title: "Cím",
                value: ADDRESS_FUNC(primaryConsumptionPlace?.address) || "",
            },
            {
                title: "A kedvezményes felhasználási hely azonosítója",
                value: primaryConsumptionPlace?.reference || "",
            },
            {
                title: "Fogyasztásmérő órák mennyisége",
                value: primaryConsumptionPlace?.consumptionMeters?.length || "",
            }
        );

        for (let i = 0; i < primaryConsumptionPlace.consumptionMeters.length; i++) {
            APPLICATION_MODIFICATION_DETAILS.push(
                {
                    title: `${i + 1}. mérőóra azonosítója`,
                    value: primaryConsumptionPlace?.consumptionMeters[i]?.reference,
                },
                {
                    title: `${i + 1}. mérőóra állása`,
                    value: primaryConsumptionPlace?.consumptionMeters[i]?.reading,
                }
            );
        }

        APPLICATION_MODIFICATION_DETAILS.push({
            title: "Az egyetemes szolgáltató megnevezése",
            value: provider?.name || "",
        });

        if (primaryConsumptionPlace.employmentEndDate !== "" && primaryConsumptionPlace.employmentEndDate) {
            APPLICATION_MODIFICATION_DETAILS.push({
                title: "Munkaviszony vége",
                value: primaryConsumptionPlace.employmentEndDate?.split(" ")[0],
            });
        }
    }
    if (data?.secondaryConsumptionPlaceTermination) {
        let secondaryConsumptionPlace = data?.secondaryConsumptionPlaceTermination;
        let provider = providers.find((x) => x.id === secondaryConsumptionPlace.serviceProvider);

        APPLICATION_MODIFICATION_DETAILS.push(
            {
                title: "II. Kedvezményes vételezés befejezése vagy megszüntetése a második kedvezményes felhasználási helyen",
                value: "title",
            },
            {
                title: "A kedvezményes vételezés befejezésének vagy megszüntetésének oka",
                value: MOD_REASON_FUNC(data?.secondaryConsumptionPlaceTermination.type) || "",
            },
            {
                title: "Cím",
                value: ADDRESS_FUNC(secondaryConsumptionPlace.address) || "",
            },
            {
                title: "A kedvezményes felhasználási hely azonosítója",
                value: secondaryConsumptionPlace.reference || "",
            },
            {
                title: "Fogyasztásmérő órák mennyisége",
                value: secondaryConsumptionPlace.consumptionMeters.length || "",
            }
        );

        for (let i = 0; i < secondaryConsumptionPlace.consumptionMeters.length; i++) {
            APPLICATION_MODIFICATION_DETAILS.push(
                {
                    title: `${i + 1}. mérőóra azonosítója`,
                    value: secondaryConsumptionPlace?.consumptionMeters[i]?.reference,
                },
                {
                    title: `${i + 1}. mérőóra állása`,
                    value: secondaryConsumptionPlace?.consumptionMeters[i]?.reading,
                }
            );
        }

        APPLICATION_MODIFICATION_DETAILS.push({
            title: "Az egyetemes szolgáltató megnevezése",
            value: provider?.name || "",
        });

        if (secondaryConsumptionPlace.employmentEndDate !== "" && secondaryConsumptionPlace.employmentEndDate) {
            APPLICATION_MODIFICATION_DETAILS.push({
                title: "Munkaviszony vége",
                value: secondaryConsumptionPlace.employmentEndDate,
            });
        }
    }

    return APPLICATION_MODIFICATION_DETAILS;
}

//5. NYILATKOZAT
export function statementDetails(data) {
    let APPLICATION_STATEMENT_DETAILS = [];

    if (data?.statement) {
        let statement = data?.statement;
        if (statement.type) {
            APPLICATION_STATEMENT_DETAILS.push(
                {
                    title: "Mellékelt igazolás",
                    value: statement.type ? `${statement.type}. számú igazolás` : "",
                },
                {
                    title: "Az igénylőlap aláírásának időpontja",
                    value: statement.signatureDate,
                }
            );
        }
        if (statement.type === "3") {
            APPLICATION_STATEMENT_DETAILS.push(
                { title: "Nyugdíjazás időpontja", value: statement.retirementDate },
                {
                    title: "Munkaviszony időtartama",
                    value: `${statement.workYears} év, ${statement.workMonths} hónap`,
                } //év + hónap
            );
            if (!statement.durationYears) {
                APPLICATION_STATEMENT_DETAILS.push(
                    { title: "Jogosultság iőtartama", value: "Időben nem meghatározott" } //év + hónap
                );
            } else {
                APPLICATION_STATEMENT_DETAILS.push(
                    {
                        title: "Jogosultság iőtartama",
                        value: `${statement.durationYears} év, ${statement.durationMonths} hónap`,
                    } //év + hónap
                );
            }
        }
    }

    if (data?.type === "termination" && data?.statement) {
        let terminationStatement = data?.statement;
        APPLICATION_STATEMENT_DETAILS.push({
            title: " Az igénylőlap aláírásának időpontja",
            value: terminationStatement.signatureDate,
        });
    }

    return APPLICATION_STATEMENT_DETAILS;
}

//6. Adómentességre vonatkozó adatok
export function taxExemptionDetails(data, employers) {
    let APPLICATION_TAX_EXEMPTION_DETAILS = [];

    if (data?.taxExemption) {
        let taxExemption = data?.taxExemption;
        if (data?.statement.type === "2" || data?.statement.type === "6") {
            APPLICATION_TAX_EXEMPTION_DETAILS.push({
                title: "Jogosult",
                value: "Az adómentesség elbírálása nyugdíjasok esetében nem tartozik az Alkalmazotti Tarifa Rendszer hatáskörébe.",
            });
        }
        if (
            data?.statement.type === "3" ||
            data?.statement.type === "4" ||
            data?.statement.type === "7" ||
            data?.statement.type === "8"
        ) {
            APPLICATION_TAX_EXEMPTION_DETAILS.push({
                title: "Jogosult",
                value: "Az adómentesség elbírálása özvegyek esetében nem tartozik az Alkalmazotti Tarifa Rendszer hatáskörébe.",
            });
        }
        if (data?.statement.type === "1" || data?.statement.type === "5") {
            let currentEmployer = employers.find((x) => x.id === taxExemption?.employments[0]?.employerId);
            if (currentEmployer) {
                APPLICATION_TAX_EXEMPTION_DETAILS.push(
                    { title: "Jogosult", value: taxExemption.taxFree ? "Igen" : "Nem" },
                    {
                        title: "Aktuális munkaviszony kezdete",
                        value: taxExemption?.employments[0]?.from.split("T")[0],
                    },
                    { title: "Munkáltató", value: currentEmployer?.name },
                    {
                        title: "Folytonos munkaviszony",
                        value: taxExemption.continuousEmployment ? "Igen" : "Nem",
                    }
                );
            } else {
                APPLICATION_TAX_EXEMPTION_DETAILS.push(
                    { title: "Jogosult", value: "Jogosultság nincs megadva" }
                );
            }
            if (taxExemption.employments.length > 1) {
                for (let i = 1; i < taxExemption.employments.length; i++) {
                    let formerEmployer = employers.find((x) => x.id === taxExemption?.employments[i]?.employerId);
                    APPLICATION_TAX_EXEMPTION_DETAILS.push(
                        {
                            title: `${i}. Korábbi munkaviszony kezdete`,
                            value: taxExemption?.employments[i]?.from.split("T")[0],
                        },
                        {
                            title: "Munkaviszony vége",
                            value: taxExemption?.employments[i].to.split("T")[0],
                        },
                        { title: "Munkáltató", value: formerEmployer?.name }
                    );
                }
            }
        }
    }
    return APPLICATION_TAX_EXEMPTION_DETAILS;
}
