import API from "../../utils/API";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "../index";
import SearchForm from "../../utils/SearchForm";

function search(form) {
    const searchForm = new SearchForm(form);

    return API.get("/consumption-data/invoice-check", {
        params: searchForm.getSend(),
    })
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error);
        });
}

function details(form) {
    const searchForm = new SearchForm(form);

    return API.get("/consumption-data/invoice-check/detailed", {
        params: searchForm.getSend(),
    })
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error);
        });
}

const service = {
    search,
    details,
    exportInitiate: templateExportInitiate("consumption-data/invoice-check-detailed"),
    exportCheck: templateExportCheck("consumption-data/invoice-check-detailed"),
    exportDownload: templateExportDownload("consumption-data/invoice-check-detailed"),
};

export default service;
