import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import consumptionDataActions from "../../../store/consumptionData/actions";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import Autocomplete from "../../Common/Autocomplete";
import Select from "../../Common/Select";
import { INVOICE_OPTIONS, FILTER_MONTH_OPTIONS, YEAR_OPTIONS } from "../../../utils/AppConst";
import { setInfo } from "../../../utils/FilterHelper";
import InvoiceTypeCellRenderer from "../../../utils/cellRenderer/InvoiceTypeCellRenderer";
import { hasRole, hasServiceProvider } from "../../../utils/User";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function ConsumptionData(props) {
    const { filter, data, tableLoading, employers, providers, token, tableInfo, pageLoading, filterStorageKey } = props;

    const table = {
        columnDefs: [
            { headerName: "Év", field: "year" },
            { headerName: "Hónap", field: "month" },
            { headerName: "Előnév", field: "title" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            { headerName: "Számla kiálltása", field: "invoiceIssueDate" },
            {
                headerName: "Számla típusa",
                field: "invoiceType",
                cellRenderer: "invoiceTypeCellRender",
            },
            { headerName: "Számla sorszáma", field: "invoiceSerialNumber" },
            { headerName: "Finanszírozó", field: "financier.name" },
            { headerName: "Bruttó összeg", field: "financierGrossPrice" },
        ],
        loading: tableLoading,
        autoSize: "true",
        rowData: data,
        frameworkComponents: {
            invoiceTypeCellRender: InvoiceTypeCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setInfo({...props, search: props.firstFetch}, "filter", storageFilter);
        props.getEmployers();
        props.getProviders();
    }, []);

    const employerOptions = employers.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
        };
    });

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    return (
        <ListPageTemplate
            header={{
                title: "Fogyasztási adatok",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "consumption-data": "Fogyasztási adatok",
                },
            }}
            filter={{
                onSubmit: () => {
                    filterStorageSave(filterStorageKey, filter);
                    setInfo(props, "filter", filter)
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    props.search({});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
        >
            <Autocomplete
                value={filter.year || []}
                options={YEAR_OPTIONS}
                id="year"
                name="year"
                label="Év"
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <Autocomplete
                value={filter.month || []}
                options={FILTER_MONTH_OPTIONS}
                id="month"
                name="month"
                label="Hónap"
                format={{ xs: 12, md: 6, lg: 3 }}
            />
            <TextField label="Előnév" name="title" value={filter.title || ""} format={{ xs: 12, md: 4, lg: 2 }} />
            <TextField
                label="Vezetéknév"
                name="lastName"
                value={filter.lastName || ""}
                format={{ xs: 12, md: 4, lg: 2 }}
            />
            <TextField
                label="Keresztnév"
                name="firstName"
                value={filter.firstName || ""}
                format={{ xs: 12, md: 4, lg: 2 }}
            />
            <TextField
                label="Számla kiállítása (-tól)"
                name="invoiceIssueDateStart"
                value={filter.invoiceIssueDateStart || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                type="date"
            />
            <TextField
                label="Számla kiállítása (-ig)"
                name="invoiceIssueDateEnd"
                value={filter.invoiceIssueDateEnd || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                type="date"
            />
            <Autocomplete
                value={filter.invoiceType || []}
                options={INVOICE_OPTIONS}
                id="invoiceType"
                name="invoiceType"
                label="Számla típusa"
                format={{ xs: 12, md: 4, lg: 2 }}
            />
            <TextField
                label="Számla sorszáma"
                name="invoiceSerialNumber"
                value={filter.invoiceSerialNumber || ""}
                format={{ xs: 12, md: 4, lg: 2 }}
            />
            <Autocomplete
                value={filter.financier || []}
                options={employerOptions}
                id="financier"
                name="financier"
                label="Finanszírozó"
                format={{ xs: 12, md: 4, lg: 2 }}
            />
            {(!hasRole("ROLE_ADMIN") && hasServiceProvider()) && (
                <TextField label="Szolgáltató" disabled value={hasServiceProvider().name} format={{ xs: 12 }} />
            )}
            {hasRole("ROLE_ADMIN") && 
                <Select
                    value={filter.serviceProvider || ""}
                    optionList={providerOptions}
                    id="serviceProvider"
                    name="serviceProvider"
                    selectLabel="Szolgáltató"
                    format={{ xs: 12 }}
                />
            }
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, tableLoading, pageLoading, token, tableInfo, filterStorageKey } = state.consumptionData;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return {
        filter,
        data,
        tableLoading,
        employers,
        providers,
        token,
        tableInfo,
        pageLoading,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: consumptionDataActions.changeForm,
    search: consumptionDataActions.filter,
    firstFetch: consumptionDataActions.firstFetch,
    defaultForm: consumptionDataActions.defaultForm,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    exportInitiate: consumptionDataActions.exportInitiate,
    exportCheck: consumptionDataActions.exportCheck,
    exportDownload: consumptionDataActions.exportDownload,
    setInfo: consumptionDataActions.setInfo,
};

export default connect(mapState, actionCreators)(ConsumptionData);
