import React, { Children } from "react";
import Card from "./Card";
import { Grid, makeStyles, Table, TableBody, TableCell, TableRow, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    title: {
        fontSize: "x-large",
        marginBottom: theme.spacing(2),
        fontWeight: "bolder",
        color: "rgba(0,0,0,0.87)",
        width: "100%",
    },
    typography: {
        display: "flex",
        alignItems: "center",
    },
}));

export default function DataCard(props) {
    const { title, data } = props;
    const classes = useStyle();

    return (
        <Card title={title} fullWidth>
            <Table style={{ width: "auto", tableLayout: "auto" }}>
                <TableBody>
                    {Children.toArray(
                        data.map((content) =>
                            content.value === "title" ? (
                                <>
                                    <TableRow>
                                        <TableCell colSpan={2}>
                                            <Typography className={classes.title}>{`${content.title}:`}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <TableRow>
                                    <TableCell>
                                        <Typography style={{ fontWeight: "bold" }}>{`${content.title}:`}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction="row">
                                            <Grid item container alignContent="center" alignItems="center">
                                                <Typography component="span" className={classes.typography}>
                                                    {content.value}
                                                </Typography>
                                                {content.colorCode ? content.colorCode : ""}
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        )
                    )}
                </TableBody>
            </Table>
            {props.children}
        </Card>
    );
}
