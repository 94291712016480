/**
 *
 * @param {Response} data
 *          Szervertől kapott válasz
 * @returns {Array | Object | Promise} Amennyiben nem jött vissza hiba, visszaadja a kapott adatokat, különben pedig Promise
 */
export const responseSave = (data) => {
    const viols = data.data.violations || [];
    if (viols.length > 0) {
        return Promise.reject({
            type: "ValidationError",
            validationErrors: viols.map((d) => {
                return {
                    name: d.property,
                    label: d.message,
                };
            }),
        });
    }
    return data;
};
