import { Grid, makeStyles } from "@material-ui/core";
/* import { useState } from "react"; */
import Card from "../../Common/Card";
import Form from "../../Common/Form";
import PageTemplate from "../../Common/PageTemplate";
import Select from "../../Common/Select";
import { history } from "../../../store";
import SubmitButton from "../../Common/SubmitButton";
import serviceProviderSelectorActions from "../../../store/serviceProviderSelector/actions";
import notificationActions from "../../../store/notification/actions";
import providersActions from "../../../store/providers/actions";
import loginActions from "../../../store/login/actions";
import { connect } from "react-redux";
import { useEffect } from "react";
import Loading from "../../Common/Loading";

const useStyle = makeStyles((theme) => ({
    goButton: {
        marginTop: theme.spacing(0.5),
    },
}));


function ServiceProviderSelector(props) {
    const { form, data, loading, assignableServiceProviders } = props;
    const classes = useStyle();

    const formProps = {
        form: form,
        changeform: props.changeForm,
        name: "form",
    };

    useEffect(() => {
        props.getUserProfile();
        props.getProviders();
    }, []);

    const providerOptions = data?.map((elem) => {
        if (assignableServiceProviders?.includes(elem?.id)) {
            return {
                value: elem.id,
                label: elem.name
            };
        }
    });

    const handleSubmit = () => {
        props.changeServiceProvider(form)
        .then(() => props.addNotification("success", "Sikeres mentés!"))
        .then(() => props.getUserProfile())
        .then(() => history.push("/"))
        .catch(() => props.addNotification("error", "Hiba lépett fel mentés közben."));
    }

    if (loading) return <Loading />

    return (
        <PageTemplate
            header={{
                title: "Szolgáltató választó"
            }}
        >
            <Grid item xs={12}>
                <Card fullwidth title="Szolgáltató választó">
                    <Form 
                        {...formProps} 
                    >
                        <Select
                            name="serviceProvider"
                            optionList={providerOptions?.filter(elem => elem)}
                            selectLabel="Szolgáltató"
                            value={form?.serviceProvider || ""}
                            format={{ xs: 12 }}
                        />
                    </Form>
                    <Grid item container>
                        <SubmitButton 
                            className={classes.goButton}
                            onClick={handleSubmit}
                        >
                            Kiválasztás
                        </SubmitButton>
                    </Grid>
                </Card>
            </Grid>
        </PageTemplate>
    )
}

function mapState(state) {
    const { form, loading, assignableServiceProviders } = state.serviceProviderSelector;
    const { data } = state.providers;
    return { form, data, loading, assignableServiceProviders };
}

const actionCreators = {
    getUserProfile: serviceProviderSelectorActions.getUserProfile,
    getProfile: loginActions.getProfile,
    changeForm: serviceProviderSelectorActions.changeForm,
    changeServiceProvider: serviceProviderSelectorActions.changeServiceProvider,
    addNotification: notificationActions.addNotification,
    getProviders: () => providersActions.filter({}),
};

export default connect(mapState, actionCreators)(ServiceProviderSelector);