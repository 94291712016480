import {
    templateChangeForm,
    templateClearForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateSetLoading,
} from "..";
import consumptionDataByConsumptionPlaceWithBlocksService from "../../services/consumptionDataByConsumptionPlaceWithBlocks";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    clearForm: templateClearForm(types, consumptionDataByConsumptionPlaceWithBlocksService),
    filter: templateFilter(types, consumptionDataByConsumptionPlaceWithBlocksService),
    exportInitiate: templateExportInitiate(types, consumptionDataByConsumptionPlaceWithBlocksService),
    exportCheck: templateExportCheck(types, consumptionDataByConsumptionPlaceWithBlocksService),
    exportDownload: templateExportDownload(types, consumptionDataByConsumptionPlaceWithBlocksService),
    setLoading: templateSetLoading(types),
};

export default actions;
