import actions from "../notification/actions";
import { types } from "./types";

const initialState = {
    filterStorageKey: 'financier.consumptionDataSummary',
    filter: {},
    cachedFilter: {},
    form: {},
    dataSummary: [],
    dataDetails: [],
    pageLoading: true,
    summaryTableLoading: false,
    detailsTableLoading: false,
    showSummaryTable: false,
    showDetailsTable: false,
    detailsTableInfo: {
        filter: {},
        sort: {},
        page: 1,
        metadata: {},
    },
};

export function consumptionDataSummary(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                detailsTableInfo: initialState.detailsTableInfo,
                filter: initialState.filter,
                cachedFilter: initialState.cachedFilter,
                showSummaryTable: false,
                showDetailsTable: false,
            };
        case types.FILTER_SUMMARY_REQUEST:
            return {
                ...state,
                pageLoading: !state.showSummaryTable,
                summaryTableLoading: true,
                showDetailsTable: false,
                cachedFilter: state.filter,
                detailsTableInfo: initialState.detailsTableInfo,
            };
        case types.FILTER_SUMMARY_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                summaryTableLoading: false,
                showSummaryTable: action.data.data.data !== undefined,
                dataSummary: action.data.data.data,
            };
        case types.FILTER_SUMMARY_FAILURE:
            return {
                ...state,
                pageLoading: false,
                showSummaryTable: false,
                showDetailsTable: false,
                summaryTableLoading: false,
                dataSummary: [],
                error: action.error,
            };
        case types.FILTER_FIRST_DETAILS_REQUEST:
            return {
                ...state,
                detailsTableLoading: true,
                pageLoading: !state.showDetailsTable,
                showDetailsTable: false,
            };
        case types.FILTER_FIRST_DETAILS_SUCCESS:
            return {
                ...state,
                detailsTableLoading: false,
                pageLoading: false,
                showDetailsTable: true,
                detailsTableInfo: {
                    ...state.detailsTableInfo,
                    metadata: action?.data?.data?.metadata,
                },
                dataDetails: action?.data?.data?.data,
            };
        case types.FILTER_FIRST_DETAILS_FAILURE:
            return {
                ...state,
                detailsTableLoading: false,
                pageLoading: false,
                showDetailsTable: false,
                dataDetails: [],
                error: action.error,
            };
        case types.FILTER_DETAILS_REQUEST:
            return {
                ...state,
                detailsTableLoading: true,
            };
        case types.FILTER_DETAILS_SUCCESS:
            return {
                ...state,
                detailsTableLoading: false,
                detailsTableInfo: {
                    ...state.detailsTableInfo,
                    metadata: action?.data?.data?.metadata,
                },
                dataDetails: action?.data?.data?.data,
            };
        case types.FILTER_DETAILS_FAILURE:
            return {
                ...state,
                detailsTableLoading: false,
                dataDetails: [],
                error: action.error,
            };
        case types.FETCH_SET_DETAILS_INFO:
            return {
                ...state,
                detailsTableInfo: {
                    ...state.detailsTableInfo,
                    [action.key]: action.value,
                },
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: actions.isLoading,
            };
        default:
            return state;
    }
}
