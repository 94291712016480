import { templateChangeForm, templateDefaultForm, templateGetValidationUpdate, templateGet } from "..";
import applicationFilingService from "../../services/applicationFiling";
import { types } from "./types";

function getDrafts() {
    return (dispatch) => {
        dispatch({ type: types.GET_DRAFTS_REQUEST });
        return applicationFilingService.getDrafts().then(
            (data) => {
                dispatch({ type: types.GET_DRAFTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DRAFTS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function finalize(id, form) {
    return (dispatch) => {
        dispatch({ type: types.FINALIZE_REQUEST });
        return applicationFilingService.finalize(id, form).then(
            (data) => {
                dispatch({ type: types.FINALIZE_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FINALIZE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(id, form) {
    //végigmegyek a form-on, az elemek közül az üres tömböket törlöm
    //illetve a hibákat is törlöm
    let formToSend = Object.assign({}, form);

    for (let elem in formToSend) {
        for (let elem2 in formToSend[elem]) {
            if (Array.isArray(formToSend[elem][elem2]) && formToSend[elem][elem2].length === 0) {
                delete formToSend[elem][elem2];
            }

            //adómentesség hákolás:
            if (elem2 === "formerEmployersCount" && formToSend[elem][elem2] === -1) delete formToSend[elem][elem2];
        }
    }

    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST });
        return applicationFilingService.update(id, formToSend).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const create = (form) => (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return applicationFilingService.create(form).then(
        (data) => {
            dispatch({ type: types.CREATE_SUCCESS });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.CREATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    create,
    getValidationUpdate: templateGetValidationUpdate(types, applicationFilingService),
    get: templateGet(types, applicationFilingService),
    getDrafts,
    update,
    finalize,
};

export default actions;
