import React from "react";
import TextField from "../../../Common/TextField";
import { connect } from "react-redux";
import applicationConsumptionPlacesByContactActions from "../../../../store/applicationConsumptionPlacesByContact/actions";
import Autocomplete from "../../../Common/Autocomplete";
import employersActions from "../../../../store/employers/actions";
import providersActions from "../../../../store/providers/actions";
import { useEffect } from "react";
import axios from "axios";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import { GET_STAT_DETAILS } from "../../../../utils/AppConst";
import {filterStorageGet, filterStorageSave} from "../../../../utils/FilterStorage";

const ValueRenderer = (params) => params.value || <i>--</i>;

function CTariff(props) {
    const { filter, data, token, providers, employers, tableVisibility, loading, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"), 
            props.getEmployers(), 
            props.getProviders()
        ]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
        props.changeForm(false, "tableVisibility");
    }, []);

    const employerOptions = employers.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
            type_en: "employer",
            type_hu: "Munkáltatók",
        };
    });

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
            type_en: "serviceProvider",
            type_hu: "Szolgáltatók",
        };
    });

    const optionsArray = [{ value: "", label: "" }].concat(employerOptions?.concat(providerOptions));

    const table = {
        columnDefs: [
            {
                headerName: "Elsődleges szolgáltató",
                field: "primaryConsumptionPlaceServiceProviderName",
                cellRenderer: "valueRenderer",
            },
            {
                headerName: "Elsődleges szolgáltatói kód",
                field: "primaryConsumptionPlaceServiceProviderReference",
                cellRenderer: "valueRenderer",
            },
            {
                headerName: "Másodlagos szolgáltató",
                field: "secondaryConsumptionPlaceServiceProviderName",
                cellRenderer: "valueRenderer",
            },
            {
                headerName: "Másodlagos szolgáltatói kód",
                field: "secondaryConsumptionPlaceServiceProviderReference",
                cellRenderer: "valueRenderer",
            },
            { headerName: "Kapcsolattartó", field: "contact" },
            { headerName: "Új igénylés", field: "newApplications" },
            { headerName: "Módosítás", field: "modifications" },
            { headerName: "Megszüntetés", field: "terminations" },
            { headerName: "Összesen", field: "total" },
        ],
        autoSize: "true",
        rowData: data,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
        frameworkComponents: {
            valueRenderer: ValueRenderer,
        },
    };

    const disableSubmit =
        filter.groupedContactSimple === undefined ||
        filter.groupedContactSimple === null ||
        Object.keys(filter?.groupedContactSimple).length === 0;

    return (
        <ListPageTemplate
            header={{
                title: GET_STAT_DETAILS[1]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/c-tariff": "C-Tarifa kimutatások",
                    "3-2-2": GET_STAT_DETAILS[1]?.title,
                },
            }}
            filter={{
                onSubmit: (filterForm) => {
                    props.changeForm(true, "tableVisibility");
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filterForm);
                },
                onSubmitDisabled: disableSubmit,
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.changeForm(false, "tableVisibility");
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
            hideTable={!tableVisibility}
            statPageDetail={GET_STAT_DETAILS[1]?.body}
        >
            <Autocomplete
                value={filter.groupedContactSimple?.label ? filter.groupedContactSimple : optionsArray[0]}
                options={optionsArray}
                groupBy={(option) => option.type_hu}
                id="groupedContactSimple"
                name="groupedContactSimple"
                format={{ xs: 12, md: 6 }}
                label="Kapcsolattartó"
                multiple={false}
            />
            <TextField
                label="Időszak kezdete"
                name="from"
                value={filter.from || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Időszak vége"
                name="to"
                value={filter.to || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, tableVisibility, filterStorageKey } = state.applicationConsumptionPlacesByContact;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return {
        filter,
        data,
        loading,
        token,
        employers,
        providers,
        tableVisibility,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: applicationConsumptionPlacesByContactActions.changeForm,
    defaultForm: applicationConsumptionPlacesByContactActions.defaultForm,
    search: applicationConsumptionPlacesByContactActions.filter,
    exportInitiate: applicationConsumptionPlacesByContactActions.exportInitiate,
    exportCheck: applicationConsumptionPlacesByContactActions.exportCheck,
    exportDownload: applicationConsumptionPlacesByContactActions.exportDownload,
    setLoading: applicationConsumptionPlacesByContactActions.setLoading,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
};

export default connect(mapState, actionCreators)(CTariff);
