import React from "react";
import Card from "../../Common/Card";
import PageTemplate from "../../Common/PageTemplate";
import ProviderForm from "./ProviderForm";

export default function CreateChildProvider(props) {
    const { id } = props.match.params;

    return (
        <PageTemplate
            header={{
                breadcrumbs: {
                    operators: "Operátorok",
                    "/providers": "Szolgáltatók",
                    "edit-provider": "Szolgáltató jogutódlás",
                },
            }}
        >
            <Card title="Szolgáltató jogutódlás" fullWidth>
                <ProviderForm parent={id} />
            </Card>
        </PageTemplate>
    );
}
