import API from "../utils/API";
import DownloadAPI from "../utils/downloadAPI";
import { responseSave } from "../utils/responseSave";

/**
 * Listaoldalaknál használt lekérés.
 * @param {URL} url - Lekérendő URL
 * @returns {Array} Szervertől kapott adatok
 */
export const templateFetch = (url) => () =>
    API.get(`/${url}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Szerkesztéskor, illetve egy-egy specifikus adat lekérésekor használt függvény.
 * @param {URL} url
 *           Lekérendő URL
 * @param  {number} id
 *           Lekérendő adat azonosítója
 * @returns {Object} Szervertől kapott adat
 */
export const templateGet = (url) => (id) =>
    API.get(`/${url}/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Egy elem módosításakor használt függvény.
 * @param {URL} url
 *          URL, ahová elküldendő a módosítás
 * @param {number} id
 *          Módosítandó elem azonosítója
 * @param {Object} form
 *          Módosítás értéke
 * @returns {Promise} Módosítás sikeressége (201, ha jó)
 */
export const templateUpdate = (url) => (id, form) =>
    API.put(`/${url}/${id}`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Egy elem létrehozásakor használt függvény.
 * @param {URL} url
 *          URL, ahová elküldendő a létrehozás
 * @param {Object} form
 *          Létrehozandó elem értéke
 * @returns {Promise} Létrehozás sikeressége (201, ha jó)
 */
export const templateCreate = (url) => (form) =>
    API.post(`/${url}`, form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Egy elem létrehozásához szükséges validációk lekérésére szolgáló függvény.
 * @param {URL} url
 *          Lekérendő URL
 * @returns {Array} Szervertől kapott validációk
 */
export const templateGetValidationCreate = (url) => () =>
    API.get(`/${url}/validation/create`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Egy elem módosításához szükséges validációk lekérésére szolgáló függvény.
 * @param {URL} url
 *          Lekérendő URL
 * @returns {Array} Szervertől kapott validációk
 */
export const templateGetValidationUpdate = (url) => () =>
    API.get(`/${url}/validation/update`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Export elindításához használt függvény.
 * @param {URL} url
 *          Export URL
 * @param {Object} form
 *          A szűrő objektum formában
 * @param {string} filter
 *          A szűréskor kapott metadatában található szűrőazonosító
 * @returns {Promise} Export kezdés sikeressége
 */
export const templateExportInitiate = (url) => (form, filter) =>
    API.post(`/${url}/initiate-export`, form, { params: filter })
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Készülőben lévő export státuszának vizsgálására használt függvény.
 * @param {URL} url
 *          Vizsgálandó export URL-je
 * @param {number} id
 *          Vizsgálandó export azonosítója
 * @returns {string} Export státusza
 */
export const templateExportCheck = (url) => (id) =>
    API.get(`/${url}/check-export/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Letölti a kész exportot.
 * @param {URL} url Letöltendő export URL-je
 * @param {number} id Letöltendő export azonosítója
 * @param {number} num Szétszedett fájlok közül melyiket töltse le
 * @returns {void}
 */
export const templateExportDownload = (url) => (id, num) =>
    DownloadAPI.get(`/${url}/download-export/${id}${num ? `/${num}` : ''}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));

/**
 * Elem létrehozásakor használt függvény, ami kezeli a visszatérő hibákat is.
 * @param {URL} url
 *          URL, ahová elküldendő a létrehozás
 * @param {Object} form
 *          Létrehozandó elem értéke
 * @returns {Promise} Létrehozás sikeressége (201, ha jó)
 */
export const createSubmit = (url) => (form) =>
    API.post(`/${url}`, form)
        .then(responseSave)
        .catch((error) => Promise.reject(error));

/**
 * Elem módosításakor használt függvény, ami kezeli a visszatérő hibákat is.
 * @param {URL} url
 *          URL, ahová elküldendő a módosítás
 * @param {Object} form
 *          Módosítandó elem értéke
 * @returns {Promise} Módosítás sikeressége (201, ha jó)
 */
export const updateSubmit = (url) => (id, form) =>
    API.put(`/${url}/${id}`, form)
        .then(responseSave)
        .catch((error) => Promise.reject(error));

/**
 * Elem letöltésére használt függvény, ami kezeli a visszatérő hibákat is.
 * @param {URL} url
 *          URL, ahonnan letölteni szeretnénk
 * @param {Object} id
 *          A letöltendő elem azonosítója
 * @returns {Promise} Módosítás sikeressége (201, ha jó)
 */
export const templateDownload = (url) => (id) =>  {
    DownloadAPI
        .get(`/${url}/${id}`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}
