import { types } from "./types";

const initialState = {
    form: {},
    data: {},
    contacts: {
        employers: [],
        providers: [],
    },
    loading: true,
    loadingChangesLog: false,
    changesLog: {},
    validation: [],
};

export function clientDetails(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.CLEAR_FORM:
            return {
                ...state,
                form: initialState.form,
            };
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.isLoading,
            };

        case types.SET_FORM_DATA:
            return {
                ...state,
                form: action.data,
            };

        case types.SET_DATA:
            return {
                ...state,
                data: action.data,
            };

        case types.GET_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                data: initialState.data,
            };
        case types.GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
            };
        case types.GET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                data: initialState.data,
            };

        case types.GET_CONTACTS_REQUEST:
            return {
                ...state,
                contacts: initialState.contacts,
            };
        case types.GET_CONTACTS_SUCCESS:
            return {
                ...state,
                contacts: action.data.data.data,
            };
        case types.GET_CONTACTS_FAILURE:
            return {
                ...state,
                contacts: initialState.contacts,
            };

        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
            };

        case types.GET_CHANGES_LOG_REQUEST:
            return {
                ...state,
                loadingChangesLog: true,
            };

        case types.GET_CHANGES_LOG_SUCCESS:
            return {
                ...state,
                loadingChangesLog: false,
                changesLog: action.data.data.data,
            };

        case types.GET_CHANGES_LOG_FAILURE:
            return {
                ...state,
                loadingChangesLog: false,
                changesLog: [],
            };
        case types.GET_VALIDATION_UPDATE_REQUEST:
            return {
                ...state,
            };
        case types.GET_VALIDATION_UPDATE_SUCCESS:
            return {
                ...state,
                validation: action.data.data.constraints,
            };
        case types.GET_VALIDATION_UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };

        default:
            return state;
    }
}
