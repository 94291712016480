import React, { useEffect } from "react";
import { connect } from "react-redux";
import permissionsActions from "../../../store/permissions/actions";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import notificationActions from "../../../store/notification/actions";
import PermissionsTab from "./PermissionsTab";
import { hasRole } from "../../../utils/User";
import { PERMISSIONS } from "../../../utils/AppConst";
import usersActions from "../../../store/users/actions";
import {filterStorageGet} from "../../../utils/FilterStorage";
import isEmpty from "../../../utils/isEmpty";

function Permissions(props) {
    const {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        currentTab,
        contactFilter,
        providerFilter,
        users,
    } = props;

    useEffect(() => {
        const providerFilterStorageKey = filterStorageGet(props.providerFilterStorageKey);
        const contactFilterStorageKey = filterStorageGet(props.contactFilterStorageKey);
        if (!isEmpty(providerFilterStorageKey)) props.changeForm(providerFilterStorageKey, "providerFilter");
        if (!isEmpty(contactFilterStorageKey)) props.changeForm(contactFilterStorageKey, "contactFilter");
        props.getUsers();
        if (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.EMPLOYER_CONTACT) 
            || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER)) {
                props.searchContact({filter: contactFilter, sort: { createdAt: "desc" }});
        } 
        if (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.SERVICE_PROVIDER)) {
            props.searchProvider({filter: providerFilter, sort: { createdAt: "desc" }});
        }
    }, []);

   

    const tabContents = [
        {
            label: "Szolgáltatói fájlok",
            value: (
                <PermissionsTab
                    name="providerFilter"
                    filter={providerFilter}
                    data={providerData}
                    search={props.searchProvider}
                    changeForm={props.changeForm}
                    filterStorageKey={props.providerFilterStorageKey}
                    token={props.token}
                    defaultForm={props.defaultFormProvider}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateProvider}
                    exportCheck={props.exportCheckProvider}
                    exportDownload={props.exportDownloadProvider}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={provider}
                    setInfo={props.setProviderInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="permission"
                    subType="service_provider"
                />
            ),
        },
        {
            label: "Kapcsolattartói fájlok",
            value: (
                <PermissionsTab
                    name="contactFilter"
                    filter={contactFilter}
                    data={contactData}
                    search={props.searchContact}
                    changeForm={props.changeForm}
                    filterStorageKey={props.contactFilterStorageKey}
                    token={props.token}
                    defaultForm={props.defaultFormContact}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateContact}
                    exportCheck={props.exportCheckContact}
                    exportDownload={props.exportDownloadContact}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={contact}
                    setInfo={props.setContactInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="permission"
                    subType="contact"
                />
            ),
        },
    ];

    function renderTabs() {
        if (
            hasRole(PERMISSIONS.ADMIN) || 
            (
                hasRole(PERMISSIONS.SERVICE_PROVIDER) && 
                (hasRole(PERMISSIONS.EMPLOYER_CONTACT) || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER))
            )
        ) {
            return <TabsCompleted tabContents={tabContents} currentTab={currentTab} />;
        }
        
        if (hasRole(PERMISSIONS.SERVICE_PROVIDER)) {
            return (
                <PermissionsTab
                    name="providerFilter"
                    filter={providerFilter}
                    data={providerData}
                    search={props.searchProvider}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormProvider}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateProvider}
                    exportCheck={props.exportCheckProvider}
                    exportDownload={props.exportDownloadProvider}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={provider}
                    setInfo={props.setProviderInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="permission"
                    subType="service_provider"
                />
            );
        }
        if (hasRole(PERMISSIONS.EMPLOYER_CONTACT) || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER)) {
            return (
                <PermissionsTab
                    name="contactFilter"
                    filter={contactFilter}
                    data={contactData}
                    search={props.searchContact}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormContact}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateContact}
                    exportCheck={props.exportCheckContact}
                    exportDownload={props.exportDownloadContact}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={contact}
                    setInfo={props.setContactInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="permission"
                    subType="contact"
                />
            );
        }
    }

    return (
        <PageTemplate
            header={{
                title: "Jogosultság megállapításon végigment igénylések",
                breadcrumbs: {
                    application: "Igénylések",
                    "provider-validation": "Elbírált igénylések",
                    permissions: "Jogosultságok",
                },
            }}
            loading={pageLoading}
        >
            {renderTabs()}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        token,
        status,
        file,
        currentTab,
        contactFilter,
        providerFilter,
        providerFilterStorageKey,
        contactFilterStorageKey,
    } = state.permissions;
    const users = state.users.dataPublic;
    return {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        token,
        status,
        file,
        currentTab,
        contactFilter,
        providerFilter,
        users,
        providerFilterStorageKey,
        contactFilterStorageKey,
    };
}

const actionCreators = {
    changeForm: permissionsActions.changeForm,
    searchProvider: permissionsActions.filterProvider,
    searchContact: permissionsActions.filterContact,
    download: permissionsActions.download,
    exportInitiateContact: permissionsActions.exportInitiateContact,
    exportCheckContact: permissionsActions.exportCheckContact,
    exportDownloadContact: permissionsActions.exportDownloadContact,
    exportInitiateProvider: permissionsActions.exportInitiateProvider,
    exportCheckProvider: permissionsActions.exportCheckProvider,
    exportDownloadProvider: permissionsActions.exportDownloadProvider,
    defaultFormProvider: permissionsActions.defaultFormProvider,
    defaultFormContact: permissionsActions.defaultFormContact,
    changeStatus: permissionsActions.changeStatus,
    addNotification: notificationActions.addNotification,
    setContactInfo: permissionsActions.setContactInfo,
    setProviderInfo: permissionsActions.setProviderInfo,
    setPageLoading: permissionsActions.setPageLoading,
    getLog: permissionsActions.getLog,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(Permissions);
