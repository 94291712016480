import API from "../../utils/API";
import { responseSave } from "../../utils/responseSave";

function getData(mavirId, permissionId, placeIndex) {
    return API.get(`/customers/${mavirId}/${permissionId}/consumption-place/${placeIndex}`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getMetaData(mavirId, permissionId, placeIndex) {
    return API.get(`/customers/${mavirId}/${permissionId}/consumption-place/${placeIndex}/metadata`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function saveForm(mavirId, permissionId, placeIndex, form) {
    return API.post(`/customers/${mavirId}/${permissionId}/consumption-place/${placeIndex}`, form)
        .then(responseSave)
        .catch((error) => Promise.reject(error));
}

const consumptionPlaceService = {
    getData,
    getMetaData,
    saveForm,
};

export default consumptionPlaceService;
