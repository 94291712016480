import React from "react";
import Card from "../../Common/Card";
import PageTemplate from "../../Common/PageTemplate";
import EmployerForm from "./EmployerForm";

export default function CreateChildEmployer(props) {
    const { id } = props.match.params;

    return (
        <PageTemplate
            header={{
                breadcrumbs: {
                    operators: "Operátorok",
                    "/employers": "Munkáltatók",
                    "edit-employer": "Munkáltató jogutódlás",
                },
            }}
        >
            <Card title="Munkáltató jogutódlás" fullWidth>
                <EmployerForm parent={id} />
            </Card>
        </PageTemplate>
    );
}
