export const types = {
    DEFAULT_FORM: "@@employment-terminations/DEFAULT_FORM",
    CHANGE_FORM: "@@employment-terminations/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@employment-terminations/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@employment-terminations/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@employment-terminations/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@employment-terminations/FETCH_REQUEST",
    FETCH_SUCCESS: "@@employment-terminations/FETCH_SUCCESS",
    FETCH_FAILURE: "@@employment-terminations/FETCH_FAILURE",

    CREATE_REQUEST: "@@employment-terminations/CREATE_REQUEST",
    CREATE_SUCCESS: "@@employment-terminations/CREATE_SUCCESS",
    CREATE_FAILURE: "@@employment-terminations/CREATE_FAILURE",

    CHECK_CUSTOMER_REQUEST: "@@employment-terminations/CHECK_CUSTOMER_REQUEST",
    CHECK_CUSTOMER_SUCCESS: "@@employment-terminations/CHECK_CUSTOMER_SUCCESS",
    CHECK_CUSTOMER_FAILURE: "@@employment-terminations/CHECK_CUSTOMER_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@employment-terminations/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@employment-terminations/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@employment-terminations/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@employment-terminations/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@employment-terminations/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@employment-terminations/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@employment-terminations/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@employment-terminations/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@employment-terminations/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@employment-terminations/CLEAR_FORM",

    FETCH_SET_INFO: "@@employment-terminations/FETCH_SET_INFO",
};
