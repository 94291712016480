import { types } from "./types";

const initialState = {
    loggedIn: true,
    loading: false,
    reCaptcha: "",
    desiredUrl: "",
    form: {},
    validation: [
        { field: "username", type: "not_blank" },
        { field: "password", type: "not_blank" },
    ],
};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.SAVE_DESIRED_URL:
            return {
                ...state,
                desiredUrl: action.desiredUrl,
            };
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                user: action.user,
            };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user,
                loading: false,
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.REFRESH_TOKEN_REQUEST:
            return {
                ...state,
            };
        case types.REFRESH_TOKEN_SUCCESS:
            return {
                ...state,
            };
        case types.REFRESH_TOKEN_FAILURE:
            return {
                ...state,
            };
        case types.LOGOUT:
            return {
                ...state,
            };
        case types.SET_USER:
            return {
                ...state,
                user: action.user,
            };
        case types.CHECK_TOKEN_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECK_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CHECK_TOKEN_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.GET_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.GET_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
}
