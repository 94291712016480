import commandsService from "../../services/commands";
import { types } from "./types";

const fetchPermissionAssignments = () => (dispatch) => {
    dispatch({ type: types.FETCH_PERMISSION_ASSIGNMENTS_REQUEST });
    return commandsService.fetchPermissionAssignments().then(
        (data) => {
            dispatch({ type: types.FETCH_PERMISSION_ASSIGNMENTS_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_PERMISSION_ASSIGNMENTS_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const fetchSortings = () => (dispatch) => {
    dispatch({ type: types.FETCH_SORTINGS_REQUEST });
    return commandsService.fetchSortings().then(
        (data) => {
            dispatch({ type: types.FETCH_SORTINGS_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_SORTINGS_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const sorting = () => (dispatch) => {
    dispatch({ type: types.SORTING_REQUEST });
    return commandsService.sorting().then(
        () => {
            dispatch({ type: types.SORTING_SUCCESS });
            return Promise.resolve();
        },
        (error) => {
            dispatch({ type: types.SORTING_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const permissionAssignment = () => (dispatch) => {
    dispatch({ type: types.PERMISSION_ASSIGNMENT_REQUEST });
    return commandsService.permissionAssignment().then(
        () => {
            dispatch({ type: types.PERMISSION_ASSIGNMENT_SUCCESS });
            return Promise.resolve();
        },
        (error) => {
            dispatch({ type: types.PERMISSION_ASSIGNMENT_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    fetchPermissionAssignments,
    fetchSortings,
    sorting,
    permissionAssignment,
};

export default actions;
