export const types = {
    DEFAULT_FORM_PROVIDER: "@@permissions/DEFAULT_FORM_PROVIDER",
    DEFAULT_FORM_CONTACT: "@@permissions/DEFAULT_FORM_CONTACT",

    CHANGE_FORM: "@@permissions/CHANGE_FORM",

    FETCH_REQUEST_PROVIDER: "@@permissions/FETCH_REQUEST_PROVIDER",
    FETCH_SUCCESS_PROVIDER: "@@permissions/FETCH_SUCCESS_PROVIDER",
    FETCH_FAILURE_PROVIDER: "@@permissions/FETCH_FAILURE_PROVIDER",

    FETCH_REQUEST_CONTACT: "@@permissions/FETCH_REQUEST_CONTACT",
    FETCH_SUCCESS_CONTACT: "@@permissions/FETCH_SUCCESS_CONTACT",
    FETCH_FAILURE_CONTACT: "@@permissions/FETCH_FAILURE_CONTACT",

    DOWNLOAD_REQUEST: "@@permissions/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@permissions/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@permissions/DOWNLOAD_FAILURE",

    EXPORT_INITIATE_REQUEST_PROVIDER: "@@permissions/EXPORT_INITIATE_REQUEST_PROVIDER",
    EXPORT_INITIATE_SUCCESS_PROVIDER: "@@permissions/EXPORT_INITIATE_SUCCESS_PROVIDER",
    EXPORT_INITIATE_FAILURE_PROVIDER: "@@permissions/EXPORT_INITIATE_FAILURE_PROVIDER",

    EXPORT_INITIATE_REQUEST_CONTACT: "@@permissions/EXPORT_INITIATE_REQUEST_CONTACT",
    EXPORT_INITIATE_SUCCESS_CONTACT: "@@permissions/EXPORT_INITIATE_SUCCESS_CONTACT",
    EXPORT_INITIATE_FAILURE_CONTACT: "@@permissions/EXPORT_INITIATE_FAILURE_CONTACT",

    EXPORT_CHECK_REQUEST_PROVIDER: "@@permissions/EXPORT_CHECK_REQUEST_PROVIDER",
    EXPORT_CHECK_SUCCESS_PROVIDER: "@@permissions/EXPORT_CHECK_SUCCESS_PROVIDER",
    EXPORT_CHECK_FAILURE_PROVIDER: "@@permissions/EXPORT_CHECK_FAILURE_PROVIDER",

    EXPORT_CHECK_REQUEST_CONTACT: "@@permissions/EXPORT_CHECK_REQUEST_CONTACT",
    EXPORT_CHECK_SUCCESS_CONTACT: "@@permissions/EXPORT_CHECK_SUCCESS_CONTACT",
    EXPORT_CHECK_FAILURE_CONTACT: "@@permissions/EXPORT_CHECK_FAILURE_CONTACT",

    EXPORT_DOWNLOAD_REQUEST_PROVIDER: "@@permissions/EXPORT_DOWNLOAD_REQUEST_PROVIDER",
    EXPORT_DOWNLOAD_SUCCESS_PROVIDER: "@@permissions/EXPORT_DOWNLOAD_SUCCESS_PROVIDER",
    EXPORT_DOWNLOAD_FAILURE_PROVIDER: "@@permissions/EXPORT_DOWNLOAD_FAILURE_PROVIDER",

    EXPORT_DOWNLOAD_REQUEST_CONTACT: "@@permissions/EXPORT_DOWNLOAD_REQUEST_CONTACT",
    EXPORT_DOWNLOAD_SUCCESS_CONTACT: "@@permissions/EXPORT_DOWNLOAD_SUCCESS_CONTACT",
    EXPORT_DOWNLOAD_FAILURE_CONTACT: "@@permissions/EXPORT_DOWNLOAD_FAILURE_CONTACT",

    CHANGE_STATUS_REQUEST: "@@permissions/CHANGE_STATUS_REQUEST",
    CHANGE_STATUS_SUCCESS: "@@permissions/CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "@@permissions/CHANGE_STATUS_FAILURE",

    FETCH_SET_PROVIDER_INFO: "@@permissions/FETCH_SET_PROVIDER_INFO",
    FETCH_SET_CONTACT_INFO: "@@permissions/FETCH_SET_CONTACT_INFO",
    SET_PAGE_LOADING: "@@permissions/SET_PAGE_LOADING",

    GET_LOG_REQUEST: "@@permissions/GET_LOG_REQUEST",
    GET_LOG_SUCCESS: "@@permissions/GET_LOG_SUCCESS",
    GET_LOG_FAILURE: "@@permissions/GET_LOG_FAILURE",
};
