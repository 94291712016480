import { templateChangeForm, templateSetPageLoading } from "..";
import admissedApplicationsService from "../../services/admissedApplications";
import { types } from "./types";
import downloadBlob from "../../utils/downloadBlob";

function filterProvider(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_PROVIDER, form });
        return admissedApplicationsService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_PROVIDER, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_PROVIDER, error });
                return Promise.reject(error);
            }
        );
    };
}

function filterContact(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_CONTACT, form });
        return admissedApplicationsService.filter(form).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_CONTACT, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_CONTACT, error });
                return Promise.reject(error);
            }
        );
    };
}

function defaultFormProvider(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_PROVIDER, name });
}

function defaultFormContact(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_CONTACT, name });
}

function changeStatus(id) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_STATUS_REQUEST });
        return admissedApplicationsService.changeStatus(id).then(
            (data) => {
                dispatch({ type: types.CHANGE_STATUS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHANGE_STATUS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportInitiateProvider(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_PROVIDER });
        return admissedApplicationsService.exportInitiate(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_PROVIDER, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_PROVIDER, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckProvider(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_PROVIDER });
        return admissedApplicationsService.exportCheck(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_PROVIDER, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_PROVIDER, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadProvider(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_PROVIDER });
        return admissedApplicationsService.exportDownload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_PROVIDER, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_PROVIDER, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportInitiateContact(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_CONTACT });
        return admissedApplicationsService.exportInitiate(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_CONTACT, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_CONTACT, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckContact(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_CONTACT });
        return admissedApplicationsService.exportCheck(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_CONTACT, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_CONTACT, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadContact(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_CONTACT });
        return admissedApplicationsService.exportDownload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_CONTACT, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_CONTACT, error });
                return Promise.reject(error);
            }
        );
    };
}

function download(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });
        return admissedApplicationsService.download(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setProviderInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_PROVIDER_INFO, key, value });
    };
}

function setContactInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_CONTACT_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    filterProvider,
    filterContact,
    download,
    exportInitiateContact,
    exportCheckContact,
    exportDownloadContact,
    exportInitiateProvider,
    exportCheckProvider,
    exportDownloadProvider,
    defaultFormProvider,
    defaultFormContact,
    changeStatus,
    setProviderInfo,
    setContactInfo,
    setPageLoading: templateSetPageLoading(types),
};

export default actions;
