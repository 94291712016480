import { withStyles } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import colors from "../../styles/colors.js";

const DownloadValid = withStyles({
    root: {
        backgroundColor: colors.edit,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.edit,
        },
    },
})(GetAppIcon);

export default DownloadValid;
