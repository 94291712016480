import {
    templateFetch,
    templateCreate,
    templateGet,
    templateGetValidationCreate,
    templateUpdate,
    templateGetValidationUpdate,
    templateExportInitiate,
    templateExportCheck,
    templateExportDownload,
} from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/users", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function filterPublic(form) {
    const searchForm = new SearchForm(form);

    return API.get("/users/filter", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getUserProfile(id) {
    return API.get(`/users/${id}/profile`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const usersService = {
    filter,
    filterPublic,
    getUserProfile,
    fetch: templateFetch("users"),
    get: templateGet("users"),
    update: templateUpdate("users"),
    create: templateCreate("users"),
    getValidationCreate: templateGetValidationCreate("users"),
    getValidationUpdate: templateGetValidationUpdate("users"),
    exportInitiate: templateExportInitiate("users"),
    exportCheck: templateExportCheck("users"),
    exportDownload: templateExportDownload("users"),
};

export default usersService;
