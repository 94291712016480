export const types = {
    DEFAULT_FORM_PROVIDER: "@@cancellations/DEFAULT_FORM_PROVIDER",
    DEFAULT_FORM_CONTACT: "@@cancellations/DEFAULT_FORM_CONTACT",

    CHANGE_FORM: "@@cancellations/CHANGE_FORM",

    FETCH_REQUEST_PROVIDER: "@@cancellations/FETCH_REQUEST_PROVIDER",
    FETCH_SUCCESS_PROVIDER: "@@cancellations/FETCH_SUCCESS_PROVIDER",
    FETCH_FAILURE_PROVIDER: "@@cancellations/FETCH_FAILURE_PROVIDER",

    FETCH_REQUEST_CONTACT: "@@cancellations/FETCH_REQUEST_CONTACT",
    FETCH_SUCCESS_CONTACT: "@@cancellations/FETCH_SUCCESS_CONTACT",
    FETCH_FAILURE_CONTACT: "@@cancellations/FETCH_FAILURE_CONTACT",

    DOWNLOAD_REQUEST: "@@cancellations/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@cancellations/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@cancellations/DOWNLOAD_FAILURE",

    EXPORT_INITIATE_REQUEST_PROVIDER: "@@cancellations/EXPORT_INITIATE_REQUEST_PROVIDER",
    EXPORT_INITIATE_SUCCESS_PROVIDER: "@@cancellations/EXPORT_INITIATE_SUCCESS_PROVIDER",
    EXPORT_INITIATE_FAILURE_PROVIDER: "@@cancellations/EXPORT_INITIATE_FAILURE_PROVIDER",

    EXPORT_INITIATE_REQUEST_CONTACT: "@@cancellations/EXPORT_INITIATE_REQUEST_CONTACT",
    EXPORT_INITIATE_SUCCESS_CONTACT: "@@cancellations/EXPORT_INITIATE_SUCCESS_CONTACT",
    EXPORT_INITIATE_FAILURE_CONTACT: "@@cancellations/EXPORT_INITIATE_FAILURE_CONTACT",

    EXPORT_CHECK_REQUEST_PROVIDER: "@@cancellations/EXPORT_CHECK_REQUEST_PROVIDER",
    EXPORT_CHECK_SUCCESS_PROVIDER: "@@cancellations/EXPORT_CHECK_SUCCESS_PROVIDER",
    EXPORT_CHECK_FAILURE_PROVIDER: "@@cancellations/EXPORT_CHECK_FAILURE_PROVIDER",

    EXPORT_CHECK_REQUEST_CONTACT: "@@cancellations/EXPORT_CHECK_REQUEST_CONTACT",
    EXPORT_CHECK_SUCCESS_CONTACT: "@@cancellations/EXPORT_CHECK_SUCCESS_CONTACT",
    EXPORT_CHECK_FAILURE_CONTACT: "@@cancellations/EXPORT_CHECK_FAILURE_CONTACT",

    EXPORT_DOWNLOAD_REQUEST_PROVIDER: "@@cancellations/EXPORT_DOWNLOAD_REQUEST_PROVIDER",
    EXPORT_DOWNLOAD_SUCCESS_PROVIDER: "@@cancellations/EXPORT_DOWNLOAD_SUCCESS_PROVIDER",
    EXPORT_DOWNLOAD_FAILURE_PROVIDER: "@@cancellations/EXPORT_DOWNLOAD_FAILURE_PROVIDER",

    EXPORT_DOWNLOAD_REQUEST_CONTACT: "@@cancellations/EXPORT_DOWNLOAD_REQUEST_CONTACT",
    EXPORT_DOWNLOAD_SUCCESS_CONTACT: "@@cancellations/EXPORT_DOWNLOAD_SUCCESS_CONTACT",
    EXPORT_DOWNLOAD_FAILURE_CONTACT: "@@cancellations/EXPORT_DOWNLOAD_FAILURE_CONTACT",

    CHANGE_STATUS_REQUEST: "@@cancellations/CHANGE_STATUS_REQUEST",
    CHANGE_STATUS_SUCCESS: "@@cancellations/CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "@@cancellations/CHANGE_STATUS_FAILURE",

    FETCH_SET_PROVIDER_INFO: "@@cancellations/FETCH_SET_PROVIDER_INFO",
    FETCH_SET_CONTACT_INFO: "@@cancellations/FETCH_SET_CONTACT_INFO",
    SET_PAGE_LOADING: "@@cancellations/SET_PAGE_LOADING",
};
