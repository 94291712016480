import { APPLICATION_STATUS_FUNCTION } from "../../../utils/AppConst";
import OperationButton from "../../Common/OperationButton";

export function BtnCellRenderer(params, handleDeleteApplication) {
    return (
        <>
            <OperationButton
                type="delete"
                confirmation={`Biztosan törli a "${params.data.barCode}" vonalkódú igénylést?`}
                submittext="Igen"
                handleClick={() => handleDeleteApplication(params?.data?.id)}
            />
        </>
    );
}

export function IsDeletedCellRenderer(params) {
    return params?.data?.isDeleted ? "Igen" : "Nem";
}

export function PermissionToCellRenderer(params) {
    if (params?.data?.type === "termination" && params?.data?.employmentEndDate) return params?.data?.employmentEndDate;
    if (params?.data?.permissionTo) return params?.data?.permissionTo;
    return "";
}

export function StatusCellRenderer(params) {
    return APPLICATION_STATUS_FUNCTION(params.data.status) || null;
}

export const isDeletedOptions = [
    { value: "true", label: "Igen" },
    { value: "false", label: "Nem" },
];
