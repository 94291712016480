import { templateCreate, templateGetValidationUpdate, templateGet, templateUpdate } from "..";
import API from "../../utils/API";

function getDrafts() {
    return API.get(`/applications/drafts`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function finalize(id, form) {
    return API.post(`/applications/${id}/finalize`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const applicationFilingService = {
    getDrafts,
    finalize,
    create: templateCreate("applications"),
    getValidationUpdate: templateGetValidationUpdate("applications"),
    get: templateGet("applications"),
    update: templateUpdate("applications"),
};

export default applicationFilingService;
