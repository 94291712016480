import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import TxtField from "@material-ui/core/TextField";
import DatePicker from "./DatePicker.js";
import DateTimePicker from "./DateTimePicker.js";

const CssTextField = withStyles({
    root: {
        backgroundColor: colors.white,
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
        "& label.Mui-focused": {
            color: colors.black,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.black,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.black,
            },
            "&:hover fieldset": {
                borderColor: colors.black,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.black,
            },
        },
    },
})(TxtField);

/**
 * @param rule - objektum, amiben meg lehet adni egyes mezőkre vonatkozó szabályokat (number: "true", length: 3, ...stb)
 * Alapértelmezett: {}
 */

const getRules = (name, rule, type) => {
    name = name.toLowerCase();

    const baseObject = rule || { length: 128 };

    if (name.includes("postcode")) {
        baseObject.number = "true";
        baseObject.length = 4;
    }

    if (type === "datetime-local") {
        baseObject.max = "9999-12-31T23:59"; //csak négyjegyű évet enged
    }

    return baseObject;
};

const TextField = (props) => {
    const {type, ...rest} = props;

    switch(type) {
        case "date":
            return (
                <DatePicker
                    {...rest}
                />
            );
        case "datetime-local":
            return (
                <DateTimePicker
                    {...rest}
                />
            );
        default: 
            return (
                <CssTextField
                    margin={props.margin ? props.margin : "dense"}
                    variant={props.variant ? props.variant : "outlined"}
                    fullWidth={Object.prototype.hasOwnProperty.call(props, "fullWidth") ? props.fullWidth : true}
                    size="small"
                    inputProps={props.name && getRules(props.name, props.rule, props.type)}
                    InputLabelProps={props.type === "date" || props.type === "file" ? { shrink: true } : {}}
                    type={type === "date" ? "text" : type}
                    {...rest}
                />
            );
    }
}

export default TextField;
