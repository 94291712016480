import API from "../../utils/API";
import uploadAPI from "../../utils/uploadAPI";
import downloadAPI from "../../utils/downloadAPI";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/consumption-data-import", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

//sztornó
function cancel(id) {
    return API.post(`/consumption-data-import/${id}/cancel`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

//kötegelt sztornó - BatchCancellation komponensben van használva
function cancelPeriod(form) {
    return API.post(`/consumption-data-import/cancel`, form)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

//feltöltés
function upload(form) {
    return uploadAPI
        .post("/consumption-data-import", form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

//letöltés
function download(id) {
    return downloadAPI
        .get(`/consumption-data-import/${id}/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function downloadReply(id) {
    return downloadAPI
        .get(`/consumption-data-import/${id}/reply/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function getLog(id) {
    return API.get(`/consumption-data-import/${id}/log`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

const filesListService = {
    filter,
    cancel,
    upload,
    cancelPeriod,
    download,
    downloadReply,
    getLog,
    exportInitiate: templateExportInitiate("consumption-data-import"),
    exportCheck: templateExportCheck("consumption-data-import"),
    exportDownload: templateExportDownload("consumption-data-import"),
};

export default filesListService;
