import React from "react";
import BasicAutocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors";
import TextField from "./TextField";

const CustomAutocomplete = withStyles({
    tag: {
        backgroundColor: colors.primary,
        height: 24,
        position: "relative",
        zIndex: 0,
        "& .MuiChip-label": {
            color: "#fff",
        },
        "& .MuiChip-deleteIcon": {
            color: colors.red,
        },
    },
})(BasicAutocomplete);

const Autocomplete = (props) => (
    <CustomAutocomplete
        multiple={props.multiple === undefined ? true : props.multiple}
        getOptionLabel={
            props.getOptionLabel !== undefined
                ? props.getOptionLabel
                : (option) => option.label || props.options.find((opt) => opt.value === option)?.label || ""
        }
        getOptionSelected={
            props.getOptionSelected === undefined
                ? (option, value) => option.value === value.value || option == value.value || option.value == value
                : props.getOptionSelected
        }
        ChipProps={{
            name: props.name,
        }}
        id={props.name}
        filterSelectedOptions
        disableClearable
        size="small"
        renderInput={(params) => (
            <TextField
                {...params}
                label={props.label}
                error={props.error}
                helperText={props.error && props.helperText}
            />
        )}
        autoComplete={true}
        autoHighlight={true}
        autoSelect={props.multiple === false}
        {...props}
    />
);

export default Autocomplete;
