import React from "react";
import { DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import DialogBasic from "@material-ui/core/Dialog";
import colors from "../../styles/colors";
import SubmitButton from "./SubmitButton";

const useStyles = makeStyles(() => ({
    title: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    dialog: {
        minWidth: "500px",
        minHeight: "500px",
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
}));

export default function Dialog(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {React.cloneElement(props.opener, {
                onClick: () => {
                    if (props.opener.props.onClick) {
                        props.opener.props.onClick();
                    }
                    handleOpen();
                },
            })}
            <DialogBasic open={open} onClose={handleClose} fullScreen={false} {...props}>
                <DialogTitle className={classes.title}>{props.title}</DialogTitle>
                <DialogContent>{props.children}</DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleClose}>Bezár</SubmitButton>
                </DialogActions>
            </DialogBasic>
        </>
    );
}
