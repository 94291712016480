import { types } from "./types";

const initialState = {
    searchForm: {},
    arrivalForm: {},
    loading: false,
    data: [],
};

export function applicationReceipt(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                searchForm: initialState.searchForm,
                arrivalForm: initialState.arrivalForm,
                data: initialState.data,
            };
        case types.CHECK_ARRIVAL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECK_ARRIVAL_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.CHECK_ARRIVAL_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                form: {
                    ...state.form,
                    error: action.error,
                },
            };
        case types.ARRIVE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.ARRIVE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.ARRIVE_FAILURE:
            return {
                ...state,
                loading: false,
                arrivalForm: {
                    ...state.arrivalForm,
                    error: action.error.response.data.message,
                },
            };
        case types.DELETE_ARRIVAL_REQUEST:
            return {
                ...state,
            };
        case types.DELETE_ARRIVAL_SUCCESS:
            return {
                ...state,
            };
        case types.DELETE_ARRIVAL_FAILURE:
            return {
                ...state,
                form: {
                    ...state.form,
                    error: action.error,
                },
            };
        default:
            return state;
    }
}
