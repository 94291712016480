export const types = {
    DEFAULT_FORM: "@@clients/DEFAULT_FORM",
    CHANGE_FORM: "@@clients/CHANGE_FORM",

    FETCH_REQUEST_BASE: "@@clients/FETCH_REQUEST_BASE",
    FETCH_SUCCESS_BASE: "@@clients/FETCH_SUCCESS_BASE",
    FETCH_FAILURE_BASE: "@@clients/FETCH_FAILURE_BASE",

    FETCH_REQUEST_DETAILED: "@@clients/FETCH_REQUEST_DETAILED",
    FETCH_SUCCESS_DETAILED: "@@clients/FETCH_SUCCESS_DETAILED",
    FETCH_FAILURE_DETAILED: "@@clients/FETCH_FAILURE_DETAILED",

    DEFAULT_FORM_BASE: "@@clients/DEFAULT_FORM_BASE",
    DEFAULT_FORM_DETAILED: "@@clients/DEFAULT_FORM_DETAILED",

    EXPORT_INITIATE_REQUEST_BASE: "@@clients/EXPORT_INITIATE_REQUEST_BASE",
    EXPORT_INITIATE_SUCCESS_BASE: "@@clients/EXPORT_INITIATE_SUCCESS_BASE",
    EXPORT_INITIATE_FAILURE_BASE: "@@clients/EXPORT_INITIATE_FAILURE_BASE",

    EXPORT_INITIATE_REQUEST_DETAILED: "@@clients/EXPORT_INITIATE_REQUEST_DETAILED",
    EXPORT_INITIATE_SUCCESS_DETAILED: "@@clients/EXPORT_INITIATE_SUCCESS_DETAILED",
    EXPORT_INITIATE_FAILURE_DETAILED: "@@clients/EXPORT_INITIATE_FAILURE_DETAILED",

    EXPORT_CHECK_REQUEST_BASE: "@@clients/EXPORT_CHECK_REQUEST_BASE",
    EXPORT_CHECK_SUCCESS_BASE: "@@clients/EXPORT_CHECK_SUCCESS_BASE",
    EXPORT_CHECK_FAILURE_BASE: "@@clients/EXPORT_CHECK_FAILURE_BASE",

    EXPORT_CHECK_REQUEST_DETAILED: "@@clients/EXPORT_CHECK_REQUEST_DETAILED",
    EXPORT_CHECK_SUCCESS_DETAILED: "@@clients/EXPORT_CHECK_SUCCESS_DETAILED",
    EXPORT_CHECK_FAILURE_DETAILED: "@@clients/EXPORT_CHECK_FAILURE_DETAILED",

    EXPORT_DOWNLOAD_REQUEST_BASE: "@@clients/EXPORT_DOWNLOAD_REQUEST_BASE",
    EXPORT_DOWNLOAD_SUCCESS_BASE: "@@clients/EXPORT_DOWNLOAD_SUCCESS_BASE",
    EXPORT_DOWNLOAD_FAILURE_BASE: "@@clients/EXPORT_DOWNLOAD_FAILURE_BASE",

    EXPORT_DOWNLOAD_REQUEST_DETAILED: "@@clients/EXPORT_DOWNLOAD_REQUEST_DETAILED",
    EXPORT_DOWNLOAD_SUCCESS_DETAILED: "@@clients/EXPORT_DOWNLOAD_SUCCESS_DETAILED",
    EXPORT_DOWNLOAD_FAILURE_DETAILED: "@@clients/EXPORT_DOWNLOAD_FAILURE_DETAILED",

    FETCH_FILTER_FINANCIERS_REQUEST: "@@clients/FETCH_FILTER_FINANCIERS_REQUEST",
    FETCH_FILTER_FINANCIERS_SUCCESS: "@@clients/FETCH_FILTER_FINANCIERS_SUCCESS",
    FETCH_FILTER_FINANCIERS_FAILURE: "@@clients/FETCH_FILTER_FINANCIERS_FAILURE",

    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_REQUEST: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_REQUEST",
    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS",
    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_FAILURE: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_FAILURE",

    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_REQUEST: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_REQUEST",
    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS",
    FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_FAILURE: "@@clients/FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_FAILURE",

    FETCH_SET_BASE_INFO: "@@clients/FETCH_SET_BASE_INFO",
    FETCH_SET_DETAILED_INFO: "@@clients/FETCH_SET_DETAILED_INFO",
    SET_PAGE_LOADING: "@@clients/SET_PAGE_LOADING",
};
