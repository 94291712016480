import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import Dialog from "../../../Common/Dialog";
import OperationButton from "../../../Common/OperationButton";
import Table from "../../../Common/Table";
import Loading from "../../../Common/Loading";
import colors from "../../../../styles/colors";
import clientDetailsService from "../../../../services/clientDetails";

const useStyles = makeStyles(() => ({
    userRow: {
        backgroundColor: colors.edit,
        height: "48px",
        padding: "10px",
    },
    userRowReason: {
        display: "block",
    },
    userRowUsername: {
        display: "block",
        fontWeight: "bold",
    },
    noChangesRow: {
        padding: "10px",
        paddingTop: "15px",
        color: colors.lightPrimary,
    },
}));

function mapDatas(datas) {
    if (!Array.isArray(datas)) {
        return [];
    }

    let fields = [];
    let mapped = [];
    let hasChanges = false;

    datas.forEach((element) => {
        mapped.push({
            data: element,
            isFullWidth: true,
        });

        hasChanges = false;
        if (element.new && typeof element.new == typeof {}) {
            Object.keys(element.new).forEach((field) => {
                if (field === "reason") {
                    return;
                }
                if (fields.includes(field)) {
                    return;
                }
                fields.push(field);
            });
        }
        if (element.old && typeof element.old == typeof {}) {
            Object.keys(element.old).forEach((field) => {
                if (field === "reason") {
                    return;
                }
                if (fields.includes(field)) {
                    return;
                }
                fields.push(field);
            });
        }

        fields.forEach((field) => {

            if(element.old[field] === undefined && element.new[field] === undefined) {
                return;
            }

            mapped.push({
                data: field,
                earlier: element.old[field] ? element.old[field] : "-",
                modified: element.new[field] ? element.new[field] : "-",
                date: element.date,
                modifier: element.user.name,
                isFullWidth: false,
            });
            hasChanges = true;
        });

        if (!hasChanges) {
            mapped.push({
                noChanges: true,
                isFullWidth: true,
            });
        }
    });

    return mapped;
}

function FullWidthCellRenderer(params) {
    const classes = useStyles();

    if (params.data.noChanges) {
        return <div className={classes.noChangesRow}>Nincsenek változtatások</div>;
    }

    return (
        <div className={classes.userRow}>
            <div className={classes.userRowUsername}>
                {params.data.data.date} - {params.data.data.user.name}
            </div>
            <div className={classes.userRowReason}>{params.data.data.reason}</div>
        </div>
    );
}

export default function ConsumptionPlaceTrackingChanges(props) {
    const [d, setD] = useState([]);
    const [loading, setLoading] = useState(true);

    const table = {
        columnDefs: [
            {
                headerName: "Adat",
                field: "data",
                sortable: false,
                resizable: true,
                minWidth: 250,
            },
            {
                headerName: "Korábbi",
                field: "earlier",
                sortable: false,
                resizable: true,
            },
            {
                headerName: "Módosított",
                field: "modified",
                sortable: false,
                resizable: true,
            },
            { headerName: "Dátum", field: "date", sortable: false, resizable: true },
            {
                headerName: "Módosító",
                field: "modifier",
                sortable: false,
                resizable: true,
            },
        ],
        rowData: mapDatas(d),
        isFullWidthCell: function (rowNode) {
            return rowNode.data.isFullWidth === true;
        },
        fullWidthCellRenderer: "fullWidthCellRenderer",
        frameworkComponents: {
            fullWidthCellRenderer: FullWidthCellRenderer,
        },
    };

    return (
        <Dialog
            opener={
                <OperationButton
                    type={"track-changes-table"}
                    onClick={() => {
                        clientDetailsService
                            .getConsumptionPlaceChangesLog(props.customerMavirId, props.permissionId, props.placeIndex)
                            .then((response) => {
                                setD(response.data.data);
                                setLoading(false);
                            });
                    }}
                />
            }
            fullWidth
            maxWidth="lg"
        >
            {loading ? (
                <Loading />
            ) : (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Table noExportButton showPagination={false} {...table}></Table>
                    </Grid>
                </Grid>
            )}
        </Dialog>
    );
}
