import { templateCreate, templateFetch } from "..";

const commandsService = {
    fetchPermissionAssignments: templateFetch("permission-assignments"),
    fetchSortings: templateFetch("sorting"),
    permissionAssignment: templateCreate("permission-assignments/initiate"),
    sorting: templateCreate("sorting/initiate"),
};

export default commandsService;
