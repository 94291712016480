import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import failedLoginAttemptsActions from "../../../store/failedLoginAttempts/actions";
import moment from "moment";
import { setInfo } from "../../../utils/FilterHelper";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY.MM.DD. HH:mm:ss");
}

function BrowserNameCellRenderer(params) {
    return params.data.browserName === "unknown" ? "-" : params.data.browserName;
}

function BrowserVersionCellRenderer(params) {
    return params.data.browserVersion === "unknown" ? "-" : params.data.browserVersion;
}

function OperatingSystemNameCellRenderer(params) {
    return params.data.operatingSystemName === "unknown" ? "-" : params.data.operatingSystemName;
}

function OperatingSystemVersionCellRenderer(params) {
    return params.data.operatingSystemVersion === "unknown" ? "-" : params.data.operatingSystemVersion;
}

function FailedLoginAttempts(props) {
    const { filter, data, pageLoading, tableLoading, token, tableInfo, filterStorageKey } = props;

    const table = {
        columnDefs: [
            { headerName: "Felhasználónév", field: "username" },
            {
                headerName: "Böngésző neve",
                field: "browserName",
                cellRenderer: "browserNameCellRenderer",
            },
            {
                headerName: "Böngésző verziója",
                field: "browserVersion",
                cellRenderer: "browserVersionCellRenderer",
            },
            {
                headerName: "Operációs rendszer neve",
                field: "operatingSystemName",
                cellRenderer: "operatingSystemNameCellRenderer",
            },
            {
                headerName: "Operációs rendszer verziója",
                field: "operatingSystemVersion",
                cellRenderer: "operatingSystemVersionCellRenderer",
            },
            {
                headerName: "Idő",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
            },
        ],
        loading: tableLoading,
        rowData: data,
        frameworkComponents: {
            dateCellRenderer: DateCellRenderer,
            browserNameCellRenderer: BrowserNameCellRenderer,
            browserVersionCellRenderer: BrowserVersionCellRenderer,
            operatingSystemNameCellRenderer: OperatingSystemNameCellRenderer,
            operatingSystemVersionCellRenderer: OperatingSystemVersionCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setInfo({...props, search: props.firstFetch}, "sort", { createdAt: "desc" }, "filter", storageFilter);
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Sikertelen bejelentkezések",
                breadcrumbs: {
                    operators: "Operátorok",
                    "failed-login-attempts": "Sikertelen bejelentkezések",
                },
            }}
            filter={{
                onSubmit: () => {
                    filterStorageSave(filterStorageKey, filter);
                    setInfo(props, "filter", filter, "sort", { createdAt: "desc" });
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    setInfo(props, "sort", { createdAt: "desc" });
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
        >
            <TextField
                label="Felhasználónév"
                name="username"
                value={filter.username || ""}
                format={{ xs: 12, md: 4 }}
            />
            <TextField
                label="Idő (-tól)"
                name="from"
                value={filter.from || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
            />
            <TextField
                label="Idő (-ig)"
                name="to"
                value={filter.to || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="datetime-local"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, filterStorageKey } = state.failedLoginAttempts;
    return { filter, data, pageLoading, tableLoading, tableInfo, token, filterStorageKey };
}

const actionCreators = {
    changeForm: failedLoginAttemptsActions.changeForm,
    search: failedLoginAttemptsActions.filter,
    firstFetch: failedLoginAttemptsActions.firstFetch,
    defaultForm: failedLoginAttemptsActions.defaultForm,
    exportInitiate: failedLoginAttemptsActions.exportInitiate,
    exportCheck: failedLoginAttemptsActions.exportCheck,
    exportDownload: failedLoginAttemptsActions.exportDownload,
    setInfo: failedLoginAttemptsActions.setInfo,
};

export default connect(mapState, actionCreators)(FailedLoginAttempts);
