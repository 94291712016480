export const types = {
    DEFAULT_FORM: "@@tax-list/DEFAULT_FORM",
    CHANGE_FORM: "@@tax-list/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@tax-list/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@tax-list/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@tax-list/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@tax-list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@tax-list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@tax-list/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@tax-list/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@tax-list/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@tax-list/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@tax-list/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@tax-list/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@tax-list/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@tax-list/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@tax-list/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@tax-list/EXPORT_DOWNLOAD_FAILURE",

    FETCH_FILTER_FINANCIERS_REQUEST: "@@tax-list/FETCH_FILTER_FINANCIERS_REQUEST",
    FETCH_FILTER_FINANCIERS_SUCCESS: "@@tax-list/FETCH_FILTER_FINANCIERS_SUCCESS",
    FETCH_FILTER_FINANCIERS_FAILURE: "@@tax-list/FETCH_FILTER_FINANCIERS_FAILURE",

    FETCH_SET_INFO: "@@tax-list/FETCH_SET_INFO",
};
