import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import BasicButton from "@material-ui/core/Button";

const StyledButton = withStyles({
    root: {
        background: colors.filter,
        borderRadius: 3,
        border: 0,
        color: colors.white,
        height: 30,
        padding: "0 20px",
        boxShadow: "black",
        "&:hover": {
            background: colors.filterHover,
            color: colors.white,
        },
        "&:disabled": {
            background: colors.lightPrimary,
            color: colors.white,
        },
    },
    label: {
        textTransform: "none",
        fontWeight: "bold",
    },
})(BasicButton);

export default StyledButton;
