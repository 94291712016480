import { Grid, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import colors from "../../styles/colors";
import Accordion from "./Accordion";
import CancelButton from "./CancelButton";
import Form from "./Form";
import SubmitButton from "./SubmitButton";

const useStyles = makeStyles((theme) => ({
    accordionDetails: {
        display: "block",
    },
    filterButton: {
        backgroundColor: colors.filter,
        "&:hover": {
            background: colors.filterHover,
            color: colors.white,
        },
    },
    fixWidth: {
        minWidth: theme.spacing(20),
        marginRight: theme.spacing(1),
    },
    fixWidthMobile: {
        minWidth: theme.spacing(20),
        marginRight: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    defaultButton: {
        borderColor: colors.filter,
        color: colors.filter,
        "&:hover": {
            background: colors.filterHover,
            color: colors.white,
            borderColor: colors.filterHover,
        },
    },
}));

/**
 *
 * @param onSubmit - szűréskor meghívandó függvény
 * @param defaultForm - alapértelmezettre visszaállításkor meghívandó függvény
 */

export default function Filter(props) {
    const matches = useMediaQuery("(min-width: 600px)");
    const classes = useStyles();

    const handleDefault = () => {
        if (props.defaultFormAction) {
            props.defaultFormAction();
        } else {
            props.defaultForm("filter");
            props.onSubmit({});
        }
    };

    return (
        <Grid item xs={12}>
            <Accordion title="Szűrő" expanded>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Form name={props.name || "filter"} onSubmit={() => props.onSubmit(props.form)} {...props}>
                            {props.children}
                            <Grid item format={{ xs: 12 }}>
                                <SubmitButton
                                    type="submit"
                                    classes={{
                                        root: classes.filterButton,
                                    }}
                                    onClick={() => props.onSubmit(props.form)}
                                    disabled={props.onSubmitDisabled === undefined ? false : props.onSubmitDisabled}
                                    className={matches ? classes.fixWidth : classes.fixWidthMobile}
                                >
                                    Szűrés
                                </SubmitButton>
                                <CancelButton
                                    type="button"
                                    onClick={handleDefault}
                                    classes={{
                                        root: classes.defaultButton,
                                    }}
                                    className={classes.fixWidth}
                                >
                                    Alapértelmezett
                                </CancelButton>
                            </Grid>
                        </Form>
                    </Grid>
                </Grid>
            </Accordion>
        </Grid>
    );
}
