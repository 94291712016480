import { withStyles } from "@material-ui/core/styles";
import BasicInputBase from "@material-ui/core/InputBase";

const CssInputBase = withStyles({
    root: {
        padding: "10.5px 14px",
        "& .MuiInputBase-input.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
})(BasicInputBase);

const InputBase = (props) => (
    <CssInputBase
        component="pre"
        fullWidth={Object.prototype.hasOwnProperty.call(props, "fullWidth") ? props.fullWidth : true}
        size="small"
        inputProps={{
            style: {
                fontWeight: "bolder",
            },
        }}
        {...props}
    />
);

export default InputBase;
