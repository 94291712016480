import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(
        form,
        {
            inoviceIssueDateFrom: "inoviceIssueDate",
            inoviceIssueDateUntil: "inoviceIssueDate",
        },
        {
            inoviceIssueDate: "date",
        }
    );

    return API.get("/consumption-data", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getYears() {
    return API.get("/consumption-data/years")
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error);
        });
}

const consumptionDataService = {
    filter,
    exportInitiate: templateExportInitiate("consumption-data"),
    exportCheck: templateExportCheck("consumption-data"),
    exportDownload: templateExportDownload("consumption-data"),
    getYears,
};

export default consumptionDataService;
