export const types = {
    DEFAULT_FORM: "@@cities/DEFAULT_FORM",
    CHANGE_FORM: "@@cities/CHANGE_FORM",

    FETCH_REQUEST: "@@cities/FETCH_REQUEST",
    FETCH_SUCCESS: "@@cities/FETCH_SUCCESS",
    FETCH_FAILURE: "@@cities/FETCH_FAILURE",

    GET_REQUEST: "@@cities/GET_REQUEST",
    GET_SUCCESS: "@@cities/GET_SUCCESS",
    GET_FAILURE: "@@cities/GET_FAILURE",
};
