import { history, templateChangeForm } from "..";
import loginService from "../../services/login";
import { types } from "./types";
import {clearFilterStorage} from "../../utils/FilterStorage";

function login(form, desiredUrl = null) {
    return (dispatch) => {
        dispatch({ type: types.LOGIN_REQUEST, form });
        return loginService.login(form, desiredUrl).then(
            (data) => {
                const user = data.user;
                dispatch({ type: types.LOGIN_SUCCESS, user });
                // TODO: Töröljük belépés után a szűrőkben megjegyzett feltételeket. Szükséges-e?
                clearFilterStorage();
                history.push("/");
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.LOGIN_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function refreshToken() {
    return (dispatch) => {
        dispatch({ type: types.REFRESH_TOKEN_REQUEST });
        return loginService.refreshToken().then(
            (data) => {
                dispatch({ type: types.REFRESH_TOKEN_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.REFRESH_TOKEN_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getProfile() {
    return (dispatch) => {
        dispatch({ type: types.GET_PROFILE_REQUEST });
        return loginService.getUser().then(
            (data) => {
                dispatch({ type: types.GET_PROFILE_SUCCESS });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_PROFILE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function saveDesiredUrl(desiredUrl) {
    return (dispatch) => dispatch({ type: types.SAVE_DESIRED_URL, desiredUrl });
}

const actions = {
    changeForm: templateChangeForm(types),
    login,
    refreshToken,
    getProfile,
    saveDesiredUrl,
};

export default actions;
