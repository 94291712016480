import { types } from "./types";

const initialState = {
    customerFilterStorageKey: 'financier.customer.consumptionDataValidator',
    consumptionPeriodFilterStorageKey: 'financier.consumptionPeriod.consumptionDataValidator',
    filter: {},
    form: {},
    tableLoading: false,
    pageLoading: true,
    showTable: false,
    data: [],
    tableInfo: {
        filter: {},
        sort: {},
        page: 1,
        metadata: {},
    },
};

export function consumptionDataValidator(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                tableInfo: initialState.tableInfo,
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.isLoading,
            };
        case types.FIRST_FETCH_REQUEST:
            return {
                ...state,
                pageLoading: true,
                showTable: false,
            };
        case types.FIRST_FETCH_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                data: action.data.data,
                showTable: true,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.metadata,
                },
            };
        case types.FIRST_FETCH_FAILURE:
            return {
                ...state,
                pageLoading: false,
                data: [],
                error: action.error,
                showTable: false,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                data: action.data.data,
                showTable: true,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.metadata,
                },
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                data: [],
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
