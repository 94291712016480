import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";

const CssKeyboardDatePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.black,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.black,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.black,
            },
            "&:hover fieldset": {
                borderColor: colors.black,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.black,
            },
        },
    },
})(KeyboardDatePicker);

export default function DatePicker(props) {
    const {functionToChild, formNameToChild, formToChild, ...rest} = props;

    const convertToHyphenFormat = date => {
        if (!date) {
            return null;
        }
        return new Date(date).toLocaleDateString("hu-HU").replaceAll(" ", "-").replaceAll(".", "");
    }

    return (
        <CssKeyboardDatePicker
            {...rest}
            autoOk
            value={props.value || null}
            variant="inline"
            inputVariant="outlined"
            format="YYYY-MM-DD"
            invalidDateMessage={`Hibás formátum`}
            maxDateMessage={`Nem lehet nagyobb, mint 2300.01.01.`}
            minDateMessage={`Nem lehet kisebb, mint 1800.01.01.`}
            minDate={moment("1800-01-01")}
            maxDate={moment("2300-01-01")}
            InputLabelProps={{ shrink: true }}
            onChange={props.onChange || (date => functionToChild({
                ...formToChild,
                ...(props.name.includes(".") ? 
                    {
                        [props.name.split(".")[0]]: {
                            ...formToChild[props.name.split(".")[0]],
                            [props.name.split(".")[1]]: convertToHyphenFormat(date)
                        }
                    }
                    : {[props.name]: convertToHyphenFormat(date)} 
                )
            }, formNameToChild))}
            fullWidth
            size="small"
        />
    );
}
