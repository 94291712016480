import React, { Children } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import HelmetTemplate from "./HelmetTemplate";
import { history } from "../../store";

const useStyles = makeStyles(() => ({
    breadcrumbs: {
        color: colors.primary,
        fontSize: "medium",
    },
}));

/**
 *
 * @param breadcrumbs - objektum, pl.:  {nemElérhetőRoute: "Route neve", /elérhetőRoute: "Route neve"}
 * Fontos: csak azon route-ok nevei elé kell rakni perjelet, amire át lehet irányítani!
 */

export default function Header(props) {
    const classes = useStyles();

    let breadcrumbs = null;

    if (props.breadcrumbs) {
        breadcrumbs = Children.toArray(
            Object.entries(props.breadcrumbs).map(([route, title]) => {
                if (route[0] !== "/") {
                    return (
                        <Typography className={classes.breadcrumbs} key={title}>
                            {title}
                        </Typography>
                    );
                } else {
                    return (
                        <Link
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                history.push(route);
                            }}
                            key={title}
                        >
                            <Typography className={classes.breadcrumbs} key={title}>
                                {title}
                            </Typography>
                        </Link>
                    );
                }
            })
        );
    }

    return (
        <>
            <HelmetTemplate title={props.title} />
            { breadcrumbs && 
            <Grid item xs={12}>
                <Breadcrumbs aria-label="breadcrumb">{breadcrumbs}</Breadcrumbs>
            </Grid>
            }
        </>
    );
}
