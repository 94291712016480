import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import Autocomplete from "../../Common/Autocomplete";
import { connect } from "react-redux";
import employmentTerminationActions from "../../../store/employmentTerminations/actions";
import usersActions from "../../../store/users/actions";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { userOptions } from "../../../utils/listOptions/userOptions";
import { setInfo } from "../../../utils/FilterHelper";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function EmploymentTerminations(props) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, users, filterStorageKey } = props;

    function AppliedCellRenderer(params) {
        return params.data.applied ? "Igen" : "Nem";
    }

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Munkaviszony vége", field: "terminatedAt" },
            { headerName: "Bejelentés dátuma", field: "reportedAt" },
            { headerName: "Igénylés határideje", field: "applicationDeadline" },
            {
                headerName: "Igénylés beadva",
                field: "applied",
                cellRenderer: "appliedCellRenderer",
            },
            { headerName: "Bejelentő", field: "createdBy.name" },
            { headerName: "Oka", field: "reason" },
        ],
        rowData: data,
        loading: tableLoading,
        frameworkComponents: {
            appliedCellRenderer: AppliedCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        to: "employment-terminations/add",
        newButtonText: "Új űrlap felvitele",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.getUsers();
        setInfo({...props, search: props.firstFetch}, "filter", storageFilter)
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Űrlapok",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    forms: "Űrlapok",
                },
            }}
            filter={{
                onSubmit: () => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                },
                defaultFormAction: () => {
                    props.defaultForm();
                    setInfo(props, "filter", {});
                    filterStorageSave(filterStorageKey, {});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
        >
            <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 6 }} />
            <Autocomplete
                value={filter.createdBy || []}
                options={userOptions(users)}
                id="createdBy"
                name="createdBy"
                format={{ xs: 12, md: 6 }}
                label="Bejelentő"
            />
            <TextField
                label="Bejelentés dátuma (-tól)"
                name="reportedAtStart"
                value={filter.reportedAtStart || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Bejelentés dátuma (-ig)"
                name="reportedAtEnd"
                value={filter.reportedAtEnd || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, customerForm, filterStorageKey } = state.employmentTerminations;
    const users = state.users.dataPublic;
    return { filter, data, pageLoading, tableLoading, tableInfo, token, customerForm, users, filterStorageKey };
}

const actionCreators = {
    changeForm: employmentTerminationActions.changeForm,
    search: employmentTerminationActions.filter,
    defaultForm: employmentTerminationActions.defaultForm,
    exportInitiate: employmentTerminationActions.exportInitiate,
    exportCheck: employmentTerminationActions.exportCheck,
    exportDownload: employmentTerminationActions.exportDownload,
    firstFetch: employmentTerminationActions.firstFetch,
    setInfo: employmentTerminationActions.setInfo,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(EmploymentTerminations);
