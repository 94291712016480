import API from "../../utils/API";
import downloadAPI from "../../utils/downloadAPI";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/permission-assignment-results", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function changeStatus(id) {
    return API.post(`/permission-assignment-results/${id}/change-downloaded-status`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function download(id) {
    return downloadAPI
        .get(`/permission-assignment-results/${id}/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}
function getLog(id) {
    return API.get(`/permission-assignment-results/${id}/get-log`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

const admissedApplicationsService = {
    filter,
    changeStatus,
    download,
    exportInitiate: templateExportInitiate("permission-assignment-results"),
    exportCheck: templateExportCheck("permission-assignment-results"),
    exportDownload: templateExportDownload("permission-assignment-results"),
    getLog,
};

export default admissedApplicationsService;
