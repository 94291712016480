import { makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { APPLICATION_STATUS_FUNCTION, APPLICATION_TYPE_FUNCTION, PERMISSIONS } from "../../../../utils/AppConst";
import CardTable from "../../../Common/CardTable";
import EditableCard from "../../../Common/EditableCard";
import colors from "../../../../styles/colors";
import "./styles.css";
import { hasOnlyRole } from "../../../../utils/User";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
}));

export default function FurtherApplications(props) {
    const classes = useStyles();
    const { applications } = props;
    let applicationsTemp = applications;

    function ApplicationBarcodeCellRenderer(params) {
        if (hasOnlyRole(PERMISSIONS.SERVICE_PROVIDER)) {
            return params.data.barcode;
        }

        return (
            <Link to={`/applications/${params.data.id}`} className={classes.link}>
                {params.data.barcode}
            </Link>
        );
    }

    function ApplicationTypeCellRenderer(params) {
        return APPLICATION_TYPE_FUNCTION(params.data.type);
    }

    function ApplicationStatusCellRenderer(params) {
        return APPLICATION_STATUS_FUNCTION(params.data.status);
    }

    /**
     * Van egy olyan bug, hogy néhány felhasználónál a kapott adatokat objectként kapjuk meg, és nem tömbként.
     */
    if (applicationsTemp && !Array.isArray(applicationsTemp)) {
        applicationsTemp = Object.values(applicationsTemp);
    }

    const table = {
        columnDefs: [
            {
                headerName: "",
                field: "",
                maxWidth: 10,
                cellStyle: (params) => {
                    if (params?.data?.isDeleted) {
                        return {
                            background: colors.menuGroup, //szürke
                            paddingLeft: 0,
                            paddingRight: 0,
                        };
                    }
                    switch (params?.data?.type) {
                        case "application":
                            return {
                                background: colors.green,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "modification":
                            return {
                                background: colors.edit,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "termination":
                            return {
                                background: colors.red,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        default:
                            break;
                    }
                },
            },
            {
                headerName: "Vonalkód",
                field: "barcode",
                cellRenderer: "applicationBarcodeCellRenderer",
            },
            {
                headerName: "Típus",
                field: "type",
                cellRenderer: "applicationTypeCellRenderer",
            },
            {
                headerName: "Státusz",
                field: "status",
                cellRenderer: "applicationStatusCellRenderer",
            },
            { headerName: "Felöltés dátuma", field: "uploadDate" },
            { headerName: "Kaplcsolattartó", field: "contact" },
        ],
        rowClassRules: {
            "deleted-application": (params) => params?.data?.isDeleted,
        },
        rowData: applicationsTemp?.map((application) => {
            return {
                id: application.id,
                barcode: application.barCode,
                type: application.type,
                status: application.status,
                uploadDate: application.finalizedAt,
                contact: application.contact ? application.contact.name : "",
                isDeleted: application.isDeleted,
            };
        }),
        frameworkComponents: {
            applicationBarcodeCellRenderer: ApplicationBarcodeCellRenderer,
            applicationTypeCellRenderer: ApplicationTypeCellRenderer,
            applicationStatusCellRenderer: ApplicationStatusCellRenderer,
        },
    };

    return (
        <EditableCard title="További igénylőlapok">
            <CardTable noExportButton showPagination={false} {...table}></CardTable>
        </EditableCard>
    );
}
