import API from "../../utils/API";

const changeServiceProvider = (form) =>
    API.post(`/users/change-service-provider`, form)
    .then((data) => data)
    .catch((error) => Promise.reject(error))
;

const serviceProviderSelectorService = {
    changeServiceProvider
};

export default serviceProviderSelectorService;