import {
    templateChangeForm,
    templateCreate,
    templateDefaultForm,
    templateFilter,
    templateGet,
    templateGetValidationCreate,
    templateGetValidationUpdate,
    templateUpdate,
    templateExportInitiate,
    templateExportCheck,
    templateExportDownload,
    templateClearForm,
    templateCreateChild,
    templateUpdateClose,
} from "..";
import providersService from "../../services/providers";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, providersService),
    clearForm: templateClearForm(types, providersService),
    get: templateGet(types, providersService),
    update: templateUpdate(types, providersService),
    updateClose: templateUpdateClose(types, providersService),
    create: templateCreate(types, providersService),
    createChild: templateCreateChild(types, providersService),
    getValidationCreate: templateGetValidationCreate(types, providersService),
    getValidationUpdate: templateGetValidationUpdate(types, providersService),
    exportInitiate: templateExportInitiate(types, providersService),
    exportCheck: templateExportCheck(types, providersService),
    exportDownload: templateExportDownload(types, providersService),
};

export default actions;
