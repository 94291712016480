import Card from "../../../Common/Card";
import TextField from "../../../Common/TextField";
import React, { Children } from "react";
import Title from "../../../Common/Title";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";

export function Permission(props) {
    const { form, permissionKey, contacts } = props;
    const current = form.permissions[permissionKey] ?? {};

    const serial = current.serial ?? permissionKey + 1;
    const formErrors = form.errors ?? [];
    const getHelperText = (name) => {
        const errors = formErrors.filter((v) => v.name === name);
        return errors.length > 0 ? errors[0].label : "";
    };
    const getError = (name) => formErrors.filter((v) => v.name === name).length > 0;
    const formKey = `permissions[${permissionKey}]`;

    const formProps = {
        form: form,
        name: props.name,
        changeform: props.changeForm,
    };

    const handleChange = (element, gotName) => {
        const value = element?.target?.value || 
            new Date(element).toLocaleDateString("hu-HU").replaceAll(" ", "-").replaceAll(".", "")
            || "";
        const name = element?.target?.name || gotName || "";
        let formClone = Object.assign({}, form);
        if (name === "financier") {
            const financierName = contacts?.employers?.filter((d) => d.id === parseInt(value))?.[0];
            current[name] = {id: value, name: financierName?.name};
        } else {
            current[name] = value;
        }
        formClone.permissions[permissionKey] = current;
        props.changeForm(formClone, "form");
    };

    return (
        <Card title={serial + ". jogosultság"} fullWidth>
            <Form {...formProps}>
                <Title
                    format={{ xs: 12 }}
                    title={`Vonalkód: ${
                        current.application && current.application.barCode ? current.application.barCode : "-"
                    }`}
                    noBold
                    {...formProps}
                >
                    <Select
                        name="financier"
                        selectLabel="Finanszírozó"
                        value={current.financier && current.financier.id ? current.financier.id : ""}
                        format={{ xs: 12 }}
                        onChange={handleChange}
                        native
                    >
                        {contacts && contacts.employers
                            ? Children.toArray(
                                  contacts.employers.map((employer) => (
                                      <option value={employer.id}>{employer.name}</option>
                                  ))
                              )
                            : ""}
                    </Select>
                    <TextField
                        label="Kezdete"
                        name="from"
                        error={getError(`${formKey}.from`)}
                        helperText={getHelperText(`${formKey}.from`)}
                        value={current.from || ""}
                        format={{ xs: 12, md: 4 }}
                        onChange={e => handleChange(e, "from")}
                        type="date"
                    />
                    <TextField
                        label="Vége"
                        name="to"
                        error={getError(`${formKey}.to`)}
                        helperText={getHelperText(`${formKey}.to`)}
                        value={current.to || ""}
                        format={{ xs: 12, md: 4 }}
                        onChange={e => handleChange(e, "to")}
                        type="date"
                    />
                    <TextField
                        label="Korlát"
                        name="limit"
                        error={getError(`${formKey}.limit`)}
                        helperText={getHelperText(`${formKey}.limit`)}
                        value={current.limit || ""}
                        format={{ xs: 12, md: 4 }}
                        onChange={handleChange}
                    />
                </Title>
            </Form>
        </Card>
    );
}
