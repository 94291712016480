import { Grid } from "@material-ui/core";
import React from "react";
import Filter from "./Filter";
import PageTemplate from "./PageTemplate";
import Table from "./Table";

/**
 *
 * @param header - objektum, amit a Header vár (lásd Header dok.)
 * @param filter - objektum, amit a Filter vár (lásd Filter dok.)
 * @param table - objektum, amit a Table vár (lásd Table dok.)
 */
export default function ListPageTemplate(props) {
    return (
        <PageTemplate header={props.header} loading={props.loading}>
            {props.statPageDetail && (
                <Grid item xs={12} style={{ padding: "16px" }}>
                    <i>{props.statPageDetail}</i>
                </Grid>
            )}
            <Filter {...props.filter}>{props.children}</Filter>
            {!props.hideTable && (
                <Grid item xs={12}>
                    <Table {...props.table} />
                </Grid>
            )}
        </PageTemplate>
    );
}
