import React, { useEffect } from "react";
import { connect } from "react-redux";
import consumptionDataByConsumptionPlaceWithBlocksActions from "../../../../store/consumptionDataByConsumptionPlaceWithBlocks/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import providersActions from "../../../../store/providers/actions";
import employersActions from "../../../../store/employers/actions";
import axios from "axios";
import { FILTER_MONTH_OPTIONS, GET_STAT_DETAILS, LEGAL_BASIS_TYPE, YEAR_OPTIONS } from "../../../../utils/AppConst";
import BlockTypeCellRenderer from "../../../../utils/cellRenderer/BlockTypeCellRenderer";
import ConsumptionPlaceTypeCellRenderer from "../../../../utils/cellRenderer/ConsumptionPlaceTypeCellRenderer";
import NumberFormatCellRenderer from "../../../../utils/cellRenderer/NumberFormatCellRenderer";
import { makeOptions } from "../../../../utils/listOptions/makeOptions";
import Select from "../../../Common/Select";
import {filterStorageGet, filterStorageSave} from "../../../../utils/FilterStorage";

function LegalBasisCellRenderer(params) {
    return LEGAL_BASIS_TYPE(params.value) || 'Ismeretlen jogalap';
}

//4.1.3
function GKMThree(props) {
    const { filter, data, token, tableVisibility, loading, employers, providers, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"), 
            props.getEmployers(), 
            props.getProviders()])
        .then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
        props.changeForm(false, "tableVisibility");
    }, []);

    const providerOptions = makeOptions(providers);

    const financierOptions = makeOptions(employers);

    const table = {
        columnDefs: [
            {
                headerName: "Jogosultság",
                field: "legalBasisType",
                cellRenderer: "legalBasisCellRenderer",
            },
            { headerName: "Azonosító", field: "legalBasisTypeId" },
            { headerName: "Szolgáltató", field: "serviceProvider", hide: data[0]?.serviceProvider === null },
            { headerName: "Finanszírozó", field: "financier", hide: data[0]?.financier === null },
            {
                headerName: "Fogyasztási hely",
                field: "consumptionPlaceType",
                cellRenderer: "consumptionPlaceTypeCellRenderer",
            },
            { headerName: "Tömb", field: "blockType", cellRenderer: "blockTypeCellRenderer" },
            { headerName: "Fogyasztás (kWh)", field: "quantity", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Ár (Ft)", field: "grossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Fogyasztói ár (Ft)", field: "consumerGrossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Finanszírozói ár (Ft)", field: "financierGrossPrice", cellRenderer: "numberFormatCellRenderer" },
        ],
        autoSize: "true",
        rowData: data,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
        frameworkComponents: {
            legalBasisCellRenderer: LegalBasisCellRenderer,
            consumptionPlaceTypeCellRenderer: ConsumptionPlaceTypeCellRenderer,
            blockTypeCellRenderer: BlockTypeCellRenderer,
            numberFormatCellRenderer: NumberFormatCellRenderer,
        },
    };

    const disableSubmit =
        filter.yearFrom === undefined || filter.yearFrom === null || 
        filter.monthFrom === undefined || filter.monthFrom === null ||
        filter.yearTo === undefined || filter.yearTo === null || 
        filter.monthTo === undefined || filter.monthTo === null
    ;

    return (
        <ListPageTemplate
            header={{
                title: GET_STAT_DETAILS[4]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/gkm": "GKM kimutatások",
                    "4.1.3": GET_STAT_DETAILS[4]?.title,
                },
            }}
            filter={{
                onSubmit: (filterForm) => {
                    props.changeForm(true, "tableVisibility");
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filterForm);
                },
                onSubmitDisabled: disableSubmit,
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.changeForm(false, "tableVisibility");
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
            hideTable={!tableVisibility}
            statPageDetail={GET_STAT_DETAILS[4]?.body}
        >
            <Select
                selectLabel="Időszak kezdete (év)"
                name="yearFrom"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearFrom || ""}
            />
            <Select
                selectLabel="Időszak kezdete (hónap)"
                name="monthFrom"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthFrom || ""}
                dontSort
            />
            <Select
                selectLabel="Időszak vége (év)"
                name="yearTo"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearTo || ""}
            />
            <Select
                selectLabel="Időszak vége (hónap)"
                name="monthTo"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthTo || ""}
                dontSort
            />
            <Select
                selectLabel="Szolgáltató"
                name="serviceProvider"
                optionList={providerOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.serviceProvider || ""}
            />
            <Select
                selectLabel="Finanszírozó"
                name="financier"
                optionList={financierOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.financier || ""}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, tableVisibility, filterStorageKey } = state.consumptionDataByConsumptionPlaceWithBlocks;
    const providers = state.providers.data;
    const employers = state.employers.data;
    return { filter, data, loading, token, tableVisibility, providers, employers, filterStorageKey };
}

const actionCreators = {
    changeForm: consumptionDataByConsumptionPlaceWithBlocksActions.changeForm,
    defaultForm: consumptionDataByConsumptionPlaceWithBlocksActions.defaultForm,
    search: consumptionDataByConsumptionPlaceWithBlocksActions.filter,
    exportInitiate: consumptionDataByConsumptionPlaceWithBlocksActions.exportInitiate,
    exportCheck: consumptionDataByConsumptionPlaceWithBlocksActions.exportCheck,
    exportDownload: consumptionDataByConsumptionPlaceWithBlocksActions.exportDownload,
    setLoading: consumptionDataByConsumptionPlaceWithBlocksActions.setLoading,
    getProviders: () => providersActions.filter({}),
    getEmployers: () => employersActions.filter({}),
};

export default connect(mapState, actionCreators)(GKMThree);
