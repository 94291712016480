import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import colors from "../../styles/colors.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import SubmitButton from "./SubmitButton";
import TextMenuItem from "./TextMenuItem.js";
import notificationActions from "../../store/notification/actions";
import { connect } from "react-redux";
import SearchForm from "../../utils/SearchForm.js";
import { useSelector } from "react-redux";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import StyledCancelButton from "./CancelButton.js";
import { useExportStatus } from "../Providers/ExportStatusProvider.js";

const useStyles = makeStyles(() => ({
    exportButton: {
        backgroundColor: colors.export,
        "&:hover": {
            background: colors.exportHover,
            color: colors.white,
        },
    },
    textMenuItem: {
        backgroundColor: colors.white,
        color: colors.black,
        width: "136.27px",
        "&:hover": {
            background: "#dbdbdb",
            color: colors.black,
        },
        "&.Mui-selected": {
            background: colors.lightPrimary,
        },
        "&.Mui-selected:hover": {
            background: colors.lightPrimary,
            color: colors.black,
        },
    },
    paperClass: {
        background: "#dbdbdb",
    },
    arrowButton: {
        padding: "0px",
    },
    title: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    dialog: {
        minWidth: "500px",
        minHeight: "500px",
    },
    content: {
        lineHeight: "28px"
    }
}));

function SplitButton(props) {
    const { options, exportcontext} = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const exportLimit = useSelector((state) => state.exportSettings.limit);
    const [limitErrorDialogOpen ,setLimitErrorDialogOpen] = React.useState(false);
    const { setExportStatus } = useExportStatus();

    const handleMenuItemClick = (event, index) => {
        if (typeof exportcontext === typeof undefined || !exportcontext.exportInitiate) {
            console.error("Nincs megadva az exportInitiate", exportcontext);
            setOpen(false);
            return;
        }

        const format = index === 0 ? "csv" : "xlsx";
        const toSendForm = {
            format: format,
            token: exportcontext.token,
        };
        const searchForm = new SearchForm(exportcontext.info ? exportcontext.info : exportcontext.filter).getSend();
        exportcontext
            .exportInitiate(toSendForm, searchForm)
            .then((data) => {
                const id = data.data.id;
                setExportStatus((prev) => [...prev, { id, processedRows: 0, rowCount: 0 }]);
                let fileUnit = data?.data?.fileUnit || 0;
                let ready = false;
                let isError = false;
                let interval;
                let status;
                interval = setInterval(() => {
                    exportcontext
                        .exportCheck(id)
                        .then((d) => {
                            status = d.data.status;
                            fileUnit = d.data.fileUnit;
                            ready = status === "finished" || status === "unknown_error" || status === "limit_error";
                            isError = status !== "finished";
                            setExportStatus((prev) => {
                                const index = prev.findIndex((e) => e.id === id);
                                if (index !== -1) {
                                    prev[index].processedRows = d.data.processedRows;
                                    prev[index].rowCount = d.data.rowCount;
                                }
                                return [...prev];
                            });
                        })
                        .catch((error) => console.log(error, "hiba"));
                    if (ready) {
                        clearInterval(interval);
                        setExportStatus((prev) => prev.filter((e) => e.id !== id));
                        if (!isError) {
                            if (fileUnit > 0) {
                                for (let i = 1; i <= fileUnit; i++) {
                                    exportcontext
                                        .exportDownload(id, i)
                                        .then((response) => {
                                            let fileName =
                                                response.headers["content-disposition"]
                                                    ?.split(";")[1]
                                                    ?.split("=")[1]
                                                    ?.split(".")[0] || `export_${i}`;
                                            downloadFile(response.data, fileName, response.data.type);
                                            props.removeNotification();
                                        })
                                        .catch(() => {
                                            props.removeNotification();
                                            props.addNotification("error", `Hiba történt a fájl előállítása során (export_${i})`);
                                        });
                                }
                            } else {
                                exportcontext.exportDownload(id, 0)
                                    .then((response) => {
                                        let fileName = response.headers["content-disposition"]?.split(";")[1]?.split("=")[1]?.split(".")[0] || "export";
                                        downloadFile(response.data, fileName, response.data.type);
                                        props.removeNotification();
                                        props.addNotification("success", "A fájl sikeresen előállítva!");
                                    })
                                    .catch(() => {
                                        props.removeNotification();
                                        props.addNotification("error", "Hiba történt a fájl előállítása során");
                                    });
                            }
                        } else if (status === 'limit_error') {
                            props.removeNotification();
                            setLimitErrorDialogOpen(true);
                        } else {
                            props.removeNotification();
                            props.addNotification("error", "Hiba történt a fájl előállítása során");
                        }
                    }
                }, 2000);
            })
            .catch((error) => console.log(error));
        setOpen(false);
    };

    function downloadFile(data, filename, mime) {
        const blob = new Blob([data], { type: mime || "application/octet-stream" });
        const blobURL = window.URL.createObjectURL(blob);
        const tempLink = document.createElement("a");
        tempLink.style.display = "none";
        tempLink.href = blobURL;
        const filenameTest = mime === "text/csv" ? filename + ".csv" : filename;
        tempLink.setAttribute("download", filenameTest);
        if (typeof tempLink.download === "undefined") {
            tempLink.setAttribute("target", "_blank");
        }
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        setTimeout(() => {
            window.URL.revokeObjectURL(blobURL);
        }, 100);
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
                <ButtonGroup ref={anchorRef}>
                    <SubmitButton className={classes.exportButton} onClick={handleToggle}>
                        Exportálás
                        <ArrowDropDownIcon />
                    </SubmitButton>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal {...props}>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper className={classes.paperClass}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <TextMenuItem
                                                className={classes.textMenuItem}
                                                key={option}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option}
                                            </TextMenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
            <Dialog open={limitErrorDialogOpen} className={classes.dialog}>
                <DialogTitle className={classes.title}>Hiba!</DialogTitle>
                <DialogContent className={classes.content}>{`Az Excel export készítése nem lehetséges, mivel a szűrt rekordok száma túllépi a rögzített limitet (${exportLimit}). A limit fölötti rekordszám esetén csak CSV export lehetséges.`}</DialogContent>
                <DialogActions>
                    <StyledCancelButton onClick={() => setLimitErrorDialogOpen(false)}>Bezárás</StyledCancelButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function mapState(state) {
    const { snackbar, permanentSnackbar } = state.notification;
    return { snackbar, permanentSnackbar };
}

const actionCreators = {
    addNotification: notificationActions.addNotification,
    addNotificationPermanent: notificationActions.addNotificationPermanent,
    removeNotification: notificationActions.removeNotification
};

export default connect(mapState, actionCreators)(SplitButton);
