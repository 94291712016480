export const types = {
    DEFAULT_FORM: "@@audit-of-financing-accounts/DEFAULT_FORM",
    CHANGE_FORM: "@@audit-of-financing-accounts/CHANGE_FORM",

    SET_PAGE_LOADING: "@@audit-of-financing-accounts/SET_PAGE_LOADING",

    GET_SEARCH_REQUEST: "@@audit-of-financing-accounts/GET_SEARCH_REQUEST",
    GET_SEARCH_SUCCESS: "@@audit-of-financing-accounts/GET_SEARCH_SUCCESS",
    GET_SEARCH_FAILURE: "@@audit-of-financing-accounts/GET_SEARCH_FAILURE",

    GET_FIRST_DETAILS_REQUEST: "@@audit-of-financing-accounts/GET_FIRST_DETAILS_REQUEST",
    GET_FIRST_DETAILS_SUCCESS: "@@audit-of-financing-accounts/GET_FIRST_DETAILS_SUCCESS",
    GET_FIRST_DETAILS_FAILURE: "@@audit-of-financing-accounts/GET_FIRST_DETAILS_FAILURE",

    GET_DETAILS_REQUEST: "@@audit-of-financing-accounts/GET_DETAILS_REQUEST",
    GET_DETAILS_SUCCESS: "@@audit-of-financing-accounts/GET_DETAILS_SUCCESS",
    GET_DETAILS_FAILURE: "@@audit-of-financing-accounts/GET_DETAILS_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@audit-of-financing-accounts/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@audit-of-financing-accounts/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@audit-of-financing-accounts/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@audit-of-financing-accounts/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@audit-of-financing-accounts/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@audit-of-financing-accounts/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@audit-of-financing-accounts/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@audit-of-financing-accounts/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@audit-of-financing-accounts/EXPORT_DOWNLOAD_FAILURE",

    GET_YEARS_REQUEST: "@@audit-of-financing-accounts/GET_YEARS_REQUEST",
    GET_YEARS_SUCCESS: "@@audit-of-financing-accounts/GET_YEARS_SUCCESS",
    GET_YEARS_FAILURE: "@@audit-of-financing-accounts/GET_YEARS_FAILURE",

    FETCH_SET_INFO: "@@audit-of-financing-accounts/FETCH_SET_INFO",
    SET_TABLE_VISIBILITY: "@@audit-of-financing-accounts/SET_TABLE_VISIBILITY",
};
