import {
    Checkbox,
    Grid,
    makeStyles,
    Table as TableMU,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, {useState} from "react";
import Autocomplete from "../../Common/Autocomplete";
import CancelButton from "../../Common/CancelButton";
import Filter from "../../Common/Filter";
import OperationButton from "../../Common/OperationButton";
import SubmitButton from "../../Common/SubmitButton";
import Table from "../../Common/Table";
import TextField from "../../Common/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import colors from "../../../styles/colors";
import { hasRole, hasServiceProvider } from "../../../utils/User";
import { setInfo } from "../../../utils/FilterHelper";
import { userOptions } from "../../../utils/listOptions/userOptions";
import { PERMISSIONS } from "../../../utils/AppConst";
import { filterStorageSave} from "../../../utils/FilterStorage";

const useStyles = makeStyles(() => ({
    checkbox: {
        color: colors.primary,
    },
}));

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY-MM-DD H:mm");
}

export default function ProviderValidationTab(props) {
    const { name, filter, data, token, info, tableLoading, showTable, users, filterStorageKey } = props;

    const [fileLog, setFileLog] = useState([]);
    const [showFileLog, setShowFileLog] = useState(false);
    const [fileLogLoading, setFileLogLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const classes = useStyles();
    const option = [true, false];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const setCheckBoxToTrue = (params) => {
        var rowNode = params.api.getRowNode(params.data.id);
        var newNode = rowNode;
        newNode.data.downloaded = true;
        rowNode.setData(newNode.data);
    };

    const checkBoxClickHandler = (params) => {
        props.changeStatus(params.data.id);
        var rowNode = params.api.getRowNode(params.data.id);
        var newNode = rowNode;
        newNode.data.downloaded = !newNode.data.downloaded;
        rowNode.setData(newNode.data);
    };

    const handleDownload = (params) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .download(params.data.id, params.data.fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .then(() => setCheckBoxToTrue(params))
            .catch((error) => {
                const errorMessage = error.response.status === 404 ? "A fájl nem található" : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
    };

    const handleDownloadReply = (params) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .downloadReply(params.data.id, params.data?.replyFileName || params.data.fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .then(() => setCheckBoxToTrue(params))
            .catch((error) => {
                const errorMessage = error?.response?.status === 404 ? "A fájl nem található" : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
    };

    const changeHandler = (event) => {
        if (event?.target?.files[0]?.name.slice(-4) === ".csv") {
            setSelectedFile(event?.target?.files[0]);
        } else {
            props.addNotification("error", "Kérem .csv kiterjesztésű fájlt adjon meg!");
            props.changeForm({ ...filter, file: null }, "filter");
            setSelectedFile(null);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (selectedFile !== null) {
            props.addNotification("info", "A fájl feltöltése folyamatban...");
            formData.append("file", selectedFile);
            props
                .upload(formData)
                .then(() => props.addNotification("success", "Sikeres feltöltés!"))
                .then(() => props.search(filter))
                .catch(() => props.addNotification("error", "Hiba lépett fel feltöltés közben."));
            handleClose();
        }
    };

    function BtnCellRenderer(params) {
        return (
            <>
                {name === "uploadFilter" && (
                    <>
                        <OperationButton type="download" onClick={() => handleDownload(params)} />
                        <OperationButton type="download-response" onClick={() => handleDownloadReply(params)} />
                    </>
                )}
                {name === "downloadFilter" && (
                    <OperationButton type="download" onClick={() => handleDownload(params)} />
                )}
                <OperationButton
                    type="info"
                    onClick={() => {
                        setFileLog([]);
                        setFileLogLoading(true);
                        props.getLog(params.data.id).then((response) => {
                            setFileLog(response.data.data);
                            setFileLogLoading(false);
                            setShowFileLog(true);
                        });
                    }}
                />
            </>
        );
    }

    function CheckBoxCellRenderer(params) {
        return (
            <Checkbox
                color="default"
                className={classes.checkbox}
                option={option}
                defaultChecked={params.data.downloaded}
                onClick={() => checkBoxClickHandler(params)}
            />
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Fájl neve", field: "fileName" },
            {
                headerName: "Feltöltés dátuma",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: "Feltöltő",
                field: "createdBy",
                cellRenderer: "nameRenderer",
            },
            {
                headerName: "Állapot",
                field: "status",
                hide: name === "downloadFilter",
            },
            {
                headerName: "Letöltve",
                field: "downloaded",
                cellRenderer: "checkBoxCellRenderer",
                sortable: false,
                maxWidth: 100,
            },
            {
                headerName: "Műveletek",
                field: "info",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ].filter(colDef => colDef.field !== "createdBy" || name === "uploadFilter"  || hasRole(PERMISSIONS.ADMIN)),
        rowData: data,
        frameworkComponents: {
            dateCellRenderer: DateCellRenderer,
            checkBoxCellRenderer: CheckBoxCellRenderer,
            btnCellRenderer: BtnCellRenderer,
            nameRenderer: (params) => params?.data?.createdBy?.name || "-",
        },
        newButtonText: name === "uploadFilter" && hasServiceProvider() ? "Feltöltés" : null,
        to: name === "uploadFilter" ? "#" : null,
        onClick: handleOpen,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: info,
        },
        filterData: filter,
        searchfunc: props.search,
        info: info,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading || fileLogLoading,
    };


    return (
        <Grid item container xs={12} spacing={2}>
            <Filter
                onSubmit={() => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                }}
                defaultFormAction={() => {
                    props.defaultForm();
                    props.search({});
                    filterStorageSave(filterStorageKey, {});
                }}
                form={filter}
                name={name}
                changeform={props.changeForm}
            >
                <TextField label="Fájl név" name="fileName" value={filter?.fileName || ""} format={{ xs: 12, md: 6 }} />
                <Autocomplete
                    value={filter.createdBy || []}
                    options={userOptions(users)}
                    id="createdBy"
                    name="createdBy"
                    format={{ xs: 12, md: 6 }}
                    label="Feltöltő"
                />
                <TextField
                    label="Feltöltés dátuma (-tól)"
                    name="uploadDateStart"
                    value={filter?.uploadDateStart || ""}
                    type="date"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Feltöltés dátuma (-ig)"
                    name="uploadDateEnd"
                    value={filter?.uploadDateEnd || ""}
                    type="date"
                    format={{ xs: 12, md: 6 }}
                />
            </Filter>
            {showTable && (
                <Grid item xs={12}>
                    <Table {...table} />
                </Grid>
            )}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Fájl feltöltése</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Fájl"
                        type="file"
                        name="file"
                        format={{ xs: 12 }}
                        onChange={changeHandler}
                        accept=".csv"
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit}>Feltöltés</SubmitButton>
                    <CancelButton onClick={handleClose}>Mégsem</CancelButton>
                </DialogActions>
            </Dialog>
            <Dialog open={showFileLog}>
                <DialogTitle>Információk</DialogTitle>
                <DialogContent>{getInfoTable(fileLog)}</DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setShowFileLog(false)}>Bezárás</CancelButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function getInfoTable(data) {
    if (data === null || !Array.isArray(data) || data.length === 0) {
        return <i>Nincs naplózott információ</i>;
    }

    return (
        <TableMU>
            <TableHead>
                <TableRow>
                    <TableCell>Dátum</TableCell>
                    <TableCell>Felhasználó</TableCell>
                    <TableCell>Művelet</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((d) => {
                    return (
                        <TableRow>
                            <TableCell>{d.date}</TableCell>
                            <TableCell>{d.user ? (d.user.email ? (d.user.name + " / " + d.user.email) : d.user.name) : "-"}</TableCell>
                            <TableCell>{d.action}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </TableMU>
    );
}
