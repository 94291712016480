import ParticlesBasic from "react-tsparticles";
import colors from "../../styles/colors";

export default function Particles(props) {
    const { matches } = props;

    if (!matches) {
        return "";
    }

    return (
        <ParticlesBasic
            id="tsparticles"
            options={{
                background: {
                    color: {
                        value: "#fff",
                    },
                },
                fpsLimit: 60,
                interactivity: {
                    detectsOn: "canvas",
                    events: {
                        onClick: {
                            enable: false,
                            mode: "push",
                        },
                        onHover: {
                            enable: false,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        bubble: {
                            distance: 40,
                            duration: 50,
                            opacity: 0.8,
                            size: 40,
                        },
                        push: {
                            quantity: 4,
                        },
                        repulse: {
                            distance: 100,
                            duration: 2.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: colors.primary,
                    },
                    links: {
                        color: colors.primary,
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 0.5,
                    },
                    collisions: {
                        enable: true,
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outMode: "bounce",
                        random: true,
                        speed: 0.5,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: false,
                            value_area: 100,
                        },
                        value: 200,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        random: true,
                        value: 5,
                    },
                },
                detectRetina: false,
            }}
            style={{
                position: "absolute",
                height: "100vh",
                width: "100%",
            }}
        />
    );
}
