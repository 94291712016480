export const types = {
    DEFAULT_FORM: "@@application-filing/DEFAULT_FORM",
    CHANGE_FORM: "@@application-filing/CHANGE_FORM",

    CREATE_REQUEST: "@@application-filing/CREATE_REQUEST",
    CREATE_SUCCESS: "@@application-filing/CREATE_SUCCESS",
    CREATE_FAILURE: "@@application-filing/CREATE_FAILURE",

    GET_VALIDATION_UPDATE_REQUEST: "@@application-filing/GET_VALIDATION_UPDATE_REQUEST",
    GET_VALIDATION_UPDATE_SUCCESS: "@@application-filing/GET_VALIDATION_UPDATE_SUCCESS",
    GET_VALIDATION_UPDATE_FAILURE: "@@application-filing/GET_VALIDATION_UPDATE_FAILURE",

    GET_REQUEST: "@@application-filing/GET_REQUEST",
    GET_SUCCESS: "@@application-filing/GET_SUCCESS",
    GET_FAILURE: "@@application-filing/GET_FAILURE",

    GET_DRAFTS_REQUEST: "@@application-filing/GET_DRAFTS_REQUEST",
    GET_DRAFTS_SUCCESS: "@@application-filing/GET_DRAFTS_SUCCESS",
    GET_DRAFTS_FAILURE: "@@application-filing/GET_DRAFTS_FAILURE",

    UPDATE_REQUEST: "@@application-filing/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@application-filing/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@application-filing/UPDATE_FAILURE",

    FINALIZE_REQUEST: "@@application-filing/FINALIZE_REQUEST",
    FINALIZE_SUCCESS: "@@application-filing/FINALIZE_SUCCESS",
    FINALIZE_FAILURE: "@@application-filing/FINALIZE_FAILURE",
};
