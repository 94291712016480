import { withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import colors from "../../styles/colors.js";

const CardEdit = withStyles({
    root: {
        backgroundColor: colors.edit,
        borderRadius: 3,
        color: colors.white,
        transform: "translateY(6px)",
        "&:hover": {
            backgroundColor: colors.editHover,
        },
    },
})(EditIcon);

export default CardEdit;
