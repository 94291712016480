import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
    AppBar,
    Box,
    Container,
    createMuiTheme,
    Divider,
    Drawer,
    Grid,
    IconButton,
    makeStyles,
    Menu,
    Snackbar,
    ThemeProvider,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Link from "../Common/Link";
import Logo from "../../logo.svg";
import colors from "../../styles/colors.js";
import TextMenuItem from "../Common/TextMenuItem";
import { useHistory, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Copyright from "../Common/Copyright";
import SubmitButton from "../Common/SubmitButton";
import SidebarMenu from "./SidebarMenu";
import Helper from "./Helper";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import notificationActions from "../../store/notification/actions";
import citiesActions from "../../store/cities/actions";
import exportActions from "../../store/export/actions";
import loginActions from "../../store/login/actions";
import API from '../../utils/API';
import loginService from '../../services/login';
import { history as historyStore } from '../../store';
import Loading from '../Common/Loading';
import { ExportStatusProvider } from "../Providers/ExportStatusProvider.js";
import ExportChecker from "./ExportChecker.js";

const theme = createMuiTheme({
    palette: {
        primary: {
            light: colors.primary,
            main: colors.primary,
            dark: colors.primary,
            contrastText: colors.white,
        },
    },
});

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
    "@global": {
        "*::-webkit-scrollbar": {
            width: "0.4em",
        },
        "*::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
        },
        "*::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(0,0,0,.48)",
            borderRadius: "5px",
        },
    },
    root: {
        display: "flex",
        backgroundColor: colors.background,
        height: "100vh",
    },
    toolbar: {
        paddingRight: 24,
        backgroundColor: colors.primary,
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 8px",
        ...theme.mixins.toolbar,
        backgroundColor: colors.white,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
        color: colors.white,
        fontSize: "x-large",
    },
    menuButtonHidden: {
        display: "none",
    },
    drawerPaper: {
        position: "relative",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperMobile: {
        position: "absolute",
        backgroundColor: colors.white,
        whiteSpace: "nowrap",
        width: "100%",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: "auto",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    copyrightLink: {
        display: "inline",
    },
    title: {
        flexGrow: 1,
        position: "absolute",
        right: 30,
    },
    logo: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    link: {
        textDecoration: "none",
        color: colors.white,
        width: "100%",
    },
    userName: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: colors.white,
        fontSize: "large",
        fontWeight: "bolder",
    },
    providerName: {
        paddingLeft: theme.spacing(4),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(1),
        color: colors.white,
        fontSize: "large",
    },
    accountCircle: {
        fontSize: "xx-large",
    },
    leftIcon: {
        color: colors.primary,
        fontSize: "xx-large",
    },
    providerBtn: {
        backgroundColor: colors.edit,
        "&:hover": {
            backgroundColor: colors.editHover,
        }
    }
}));

function Layout(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const history = useHistory();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loadingLogout, setSoadingLogout] = React.useState(false);
    const userMenuOpen = Boolean(anchorEl);
    const th = useTheme();
    const matches = useMediaQuery(th.breakpoints.up("sm"));
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const { snackbar, permanentSnackbar } = props;

    useEffect(() => matches, [location, matches]);

    useEffect(() => {
        props.fetchCities();
        props.getXlsxLimit();
    }, []);

    useEffect(() => {
        if (location.pathname !== "/logout") props.getProfile();
    }, [history, location]);

    const handleClose = () => setAnchorEl(null);

    const handleDrawerOpen = () => setOpen(true);
    const handleDrawerClose = () => setOpen(false);

    const logout = () => {
        setSoadingLogout(true)
        API.post('/logout').then(() => {
            loginService.logout();
            historyStore.push("/login");
        });
    };

    const user = JSON.parse(localStorage.getItem("user")) || {
        name: "Nincs bejelentkezve",
        email: "",
    };

    if (loadingLogout) return <Loading />;

    return (
        <div className={classes.root}>
            <ExportStatusProvider>
                <ThemeProvider theme={theme}>
                    <AppBar className={clsx(classes.appBar, open && classes.appBarShift)}>
                        <Toolbar className={classes.toolbar} variant="dense">
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDrawerOpen}
                                className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Grid container justify="center">
                                {
                                    user?.assignableServiceProviders?.length > 1 ?
                                        <Link to="/provider-selector">
                                            <SubmitButton className={classes.providerBtn}>
                                                {user?.serviceProvider?.name || "Nincs szolgáltató"}
                                            </SubmitButton>
                                        </Link>
                                        :
                                        <div className={classes.campusText}>
                                            {user?.serviceProvider?.name || "Nincs szolgáltató"}
                                        </div>
                                }
                            </Grid>
                            <Typography component="h1" variant="h6" noWrap className={classes.title}>
                                <IconButton onClick={handleMenu} color="inherit">
                                    <AccountCircle className={classes.accountCircle} />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={userMenuOpen}
                                    onClose={handleClose}
                                >
                                    <div className={classes.userName}>{user.name}</div>
                                    <div className={classes.providerName}>{user.email}</div>
                                    <Divider />
                                    {
                                        user?.assignableServiceProviders?.length > 1 &&
                                        <TextMenuItem onClick={() => historyStore.push("/provider-selector")} className={classes.link}>Szolgáltató választó</TextMenuItem>
                                    }
                                    <Helper />
                                    <TextMenuItem onClick={logout} className={classes.link}>Kijelentkezés</TextMenuItem>
                                </Menu>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant={matches ? "permanent" : "temporary"}
                        classes={{
                            paper: clsx(open && classes.drawerPaper, !open && classes.drawerPaperClose),
                        }}
                        open={open}
                    >
                        <div className={classes.toolbarIcon}>
                            <Link to="/">
                                <img className={classes.logo} src={Logo} alt="Logo" />
                            </Link>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon className={classes.leftIcon} />
                            </IconButton>
                        </div>
                        <Divider />
                        <Grid item xs={false}>
                            <SidebarMenu roles={user.roles} />
                        </Grid>
                    </Drawer>
                    <main className={classes.content}>
                        <div className={classes.appBarSpacer} />
                        <Container maxWidth={false}>
                            {props.children}
                            <Box pt={4}>
                                <Copyright />
                            </Box>
                        </Container>
                    </main>
                    {snackbar.type && (
                        <Snackbar
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            open={snackbar.type !== ""}
                            autoHideDuration={4000}
                            onClose={props.removeNotification}
                        >
                            <Alert severity={snackbar.type}>{snackbar.msg}</Alert>
                        </Snackbar>
                    )}
                    {permanentSnackbar.type && (
                        <Snackbar
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            open={permanentSnackbar.type !== ""}
                        >
                            <Alert severity={permanentSnackbar.type}>{permanentSnackbar.msg}</Alert>
                        </Snackbar>
                    )}
                </ThemeProvider>
                <ExportChecker/>
            </ExportStatusProvider>
        </div>
    );
}

function mapState(state) {
    const { snackbar, permanentSnackbar } = state.notification;
    const cities = state.cities.data;
    return { snackbar, permanentSnackbar, cities };
}

const actionCreators = {
    addNotification: notificationActions.addNotification,
    removeNotification: notificationActions.removeNotification,
    fetchCities: citiesActions.fetch,
    refreshToken: loginActions.refreshToken,
    getProfile: loginActions.getProfile,
    getXlsxLimit: exportActions.getXlsxLimit,
};

export default connect(mapState, actionCreators)(Layout);
