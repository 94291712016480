import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateSetInfo,
    templateSetPageLoading,
} from "..";
import { types } from "./types";
import notificationActions from "../notification/actions";
import auditOfFinancingAccountsService from "../../services/auditOfFinancingAccounts";

function search(filter) {
    return (dispatch) => {
        dispatch({ type: types.GET_SEARCH_REQUEST, filter });
        return auditOfFinancingAccountsService.search(filter).then(
            (data) => {
                if (data.length === 0) {
                    dispatch(
                        notificationActions.addNotification(
                            "error",
                            "Nincs megjeleníthető adat a szűrési feltételekhez"
                        )
                    );
                }
                dispatch({ type: types.GET_SEARCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_SEARCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function details(filter) {
    return (dispatch) => {
        dispatch({ type: types.GET_DETAILS_REQUEST, filter });
        return auditOfFinancingAccountsService.details(filter).then(
            (data) => {
                dispatch({ type: types.GET_DETAILS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DETAILS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function firstDetails(filter) {
    return (dispatch) => {
        dispatch({ type: types.GET_FIRST_DETAILS_REQUEST, filter });
        return auditOfFinancingAccountsService.details(filter).then(
            (data) => {
                dispatch({ type: types.GET_FIRST_DETAILS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_FIRST_DETAILS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    search,
    details,
    firstDetails,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setPageLoading: templateSetPageLoading(types),
    exportInitiate: templateExportInitiate(types, auditOfFinancingAccountsService),
    exportCheck: templateExportCheck(types, auditOfFinancingAccountsService),
    exportDownload: templateExportDownload(types, auditOfFinancingAccountsService),
    setInfo: templateSetInfo(types),
};

export default actions;
