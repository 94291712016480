import { templateChangeForm, templateClearForm, templateDefaultForm, templateSetLoading } from "..";
import { types } from "./types";
import consumptionPlaceService from "../../services/consumptionPlace";

function getData(mavirId, permissionId, placeIndex) {
    return (dispatch) => {
        dispatch({
            type: types.GET_DATA_REQUEST,
            mavirId,
            permissionId,
            placeIndex,
        });
        return consumptionPlaceService.getData(mavirId, permissionId, placeIndex).then(
            (data) => {
                dispatch({ type: types.GET_DATA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DATA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getMetaData(mavirId, permissionId, placeIndex) {
    return (dispatch) => {
        dispatch({
            type: types.GET_META_DATA_REQUEST,
            mavirId,
            permissionId,
            placeIndex,
        });
        return consumptionPlaceService.getMetaData(mavirId, permissionId, placeIndex).then(
            (data) => {
                dispatch({ type: types.GET_META_DATA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_META_DATA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function saveForm(mavirId, permissionId, placeIndex, form) {
    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST, mavirId, permissionId, placeIndex });
        return consumptionPlaceService.saveForm(mavirId, permissionId, placeIndex, form).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setData(data) {
    return (dispatch) => {
        dispatch({ type: types.SET_DATA, data });
    };
}

function setFormData(data) {
    return (dispatch) => {
        dispatch({ type: types.SET_FORM_DATA, data });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    clearForm: templateClearForm(types),
    getData: getData,
    setFormData: setFormData,
    setData: setData,
    getMetaData: getMetaData,
    setLoading: templateSetLoading(types),
    saveForm: saveForm,
};

export default actions;
