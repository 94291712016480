import { templateChangeForm, templateDefaultForm } from "..";
import filesListService from "../../services/filesList";
import { types } from "./types";

function cancelPeriod(form) {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_PERIOD_REQUEST, form });
        return filesListService.cancelPeriod(form).then(
            (data) => {
                dispatch({ type: types.CANCEL_PERIOD_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CANCEL_PERIOD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    cancelPeriod,
};

export default actions;
