import React from "react";
import { withStyles } from "@material-ui/core";
import TabsBasic from "@material-ui/core/Tabs";
import colors from "../../styles/colors.js";

const StyledTabs = withStyles({
    indicator: {
        backgroundColor: colors.white,
        height: "5px",
    },
})(TabsBasic);

export default function Tabs(props) {
    return <StyledTabs variant="fullWidth" scrollButtons="auto" {...props} />;
}
