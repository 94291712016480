import React from "react";
import { Route } from "react-router-dom";
import loginService from "../../services/login";

export default function PrivateRoute({ component: Component, layout: Layout, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                loginService.getTokenValidity() ? (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                ) : (
                    ""
                )
            }
        />
    );
}
