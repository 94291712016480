import { templateChangeForm, templateDefaultForm, templateSetPageLoading } from "..";
import { types } from "./types";
import clientsService from "../../services/clients";
import isEmpty from "../../utils/isEmpty";

function filterBase(form) {
    let formToSend = Object.assign({}, form);

    if (formToSend.filter && !isEmpty(formToSend.filter)) {
        for (const [key, value] of Object.entries(formToSend.filter)) {
            if (value === "Invalid-Date") delete formToSend.filter[key];
        }
    }

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_BASE, formToSend });
        return clientsService.filterBase(formToSend).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_BASE, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_BASE, error });
                return Promise.reject(error);
            }
        );
    };
}

function filterDetailed(form) {
    let formToSend = Object.assign({}, form);

    if (formToSend.filter && !isEmpty(formToSend.filter)) {
        for (const [key, value] of Object.entries(formToSend.filter)) {
            if (value === "Invalid-Date") delete formToSend.filter[key];
        }
    }

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_DETAILED, formToSend });
        return clientsService.filterDetailed(formToSend).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_DETAILED, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_DETAILED, error });
                return Promise.reject(error);
            }
        );
    };
}

function defaultFormBase(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_BASE, name });
}

function defaultFormDetailed(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_DETAILED, name });
}

function exportInitiateBase(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_BASE });
        return clientsService.exportInitiateBase(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_BASE, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_BASE, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportInitiateDetailed(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_DETAILED });
        return clientsService.exportInitiateDetailed(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_DETAILED, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_DETAILED, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckBase(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_BASE });
        return clientsService.exportCheckBase(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_BASE, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_BASE, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckDetailed(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_DETAILED });
        return clientsService.exportCheckDetailed(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_DETAILED, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_DETAILED, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadBase(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_BASE });
        return clientsService.exportDownloadBase(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_BASE, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_BASE, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadDetailed(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_DETAILED });
        return clientsService.exportDownloadDetailed(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_DETAILED, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_DETAILED, error });
                return Promise.reject(error);
            }
        );
    };
}

function getFilterFinanciers() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_FINANCIERS_REQUEST });
        return clientsService.getFilterFinanciers().then(
            (data) => {
                dispatch({ type: types.FETCH_FILTER_FINANCIERS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FILTER_FINANCIERS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getConsumptionPlaceServices1() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_REQUEST });
        return clientsService.getConsumptionPlaceServices(1).then(
            (data) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function getConsumptionPlaceServices2() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_REQUEST });
        return clientsService.getConsumptionPlaceServices(2).then(
            (data) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function setBaseInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_BASE_INFO, key, value });
    };
}

function setDetailedInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_DETAILED_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setPageLoading: templateSetPageLoading(types),
    filterBase: filterBase,
    filterDetailed: filterDetailed,
    defaultFormBase: defaultFormBase,
    defaultFormDetailed: defaultFormDetailed,
    exportInitiateBase: exportInitiateBase,
    exportInitiateDetailed: exportInitiateDetailed,
    exportCheckBase: exportCheckBase,
    exportCheckDetailed: exportCheckDetailed,
    exportDownloadBase: exportDownloadBase,
    exportDownloadDetailed: exportDownloadDetailed,
    getFilterFinanciers: getFilterFinanciers,
    getConsumptionPlaceServices1: getConsumptionPlaceServices1,
    getConsumptionPlaceServices2: getConsumptionPlaceServices2,
    setBaseInfo: setBaseInfo,
    setDetailedInfo: setDetailedInfo,
};

export default actions;
