export default function StatusCellRenderer(params) {
    switch (params.data.status) {
        case "import_initiated":
            return "Feltöltés kezdeményezve";
        case "import_processing":
            return "Feltöltés feldolgozás alatt";
        case "import_row_counting":
            return "Sorok számlálása…";
        case "import_row_validation":
            return "Validáció:" + " ("+params.data.process+")";
        case "import_row_validate_invoice_serial_number":
            return "Validáció: számlaszámok ellenőrzése" + " ("+params.data.process+")";
        case "import_row_saving":
            return "Mentés:" + " ("+params.data.process+")";
        case "import_validation_error":
            return "Validálási hiba";
        case "import_finished":
            return "Feltöltve";
        case "import_unknown_error":
            return "Ismeretlen hiba feltöltés közben";
        case "cancellation_initiated":
            return "Sztornózás kezdeményezve";
        case "cancellation_processing":
            return "Sztornózás feldolgozás alatt";
        case "cancellation_finished":
            return "Sztornózva";
        case "cancellation_unknown_error":
            return "Ismeretlen hiba sztornózás közben";
        case "import_invoice_serial_numbers_exist_error":
            return "A számlasorszám már létezik a rendszerben.";
        case "import_invoice_serial_numbers_error_in_file":
            return "A számlasorszám már szerepel a fájlban az adott időszakra";
        case "import_invalid_employer_reference_error":
            return "Hibás szolgáltatói adat";
        case "import_invalid_mavir_id_error":
            return "Nem létező Mavir id";
        case "import_validation_success":
            return "Sikeres validáció";
        case "import_excel_validation":
            return "Excel validáció";
        default:
            return "Ismeretlen hiba lépett fel";
    }
}
