import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { hasOnlyRole } from "../User";
import { PERMISSIONS } from "../AppConst";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
}));

export function ApplicationBarcodeCellRenderer(params) {
    const classes = useStyles();

    /**
     * Here we need to check if the logged in user has ONLY "ROLE_SERVICE_PROVIDER" and nothing else.
     * And if that is true we just return the barcode without the link.
     */
    if (hasOnlyRole(PERMISSIONS.SERVICE_PROVIDER)) {
        return params?.data?.application?.barCode;
    }

    return (
        <Link to={`/applications/${params?.data?.application?.id}`} className={classes.link}>
            {params?.data?.application?.barCode}
        </Link>
    );
}
