export const types = {
    DEFAULT_FORM: "@@application-receipt/DEFAULT_FORM",
    CHANGE_FORM: "@@application-receipt/CHANGE_FORM",

    CHECK_ARRIVAL_REQUEST: "@@application-receipt/CHECK_ARRIVAL_REQUEST",
    CHECK_ARRIVAL_SUCCESS: "@@application-receipt/CHECK_ARRIVAL_SUCCESS",
    CHECK_ARRIVAL_FAILURE: "@@application-receipt/CHECK_ARRIVAL_FAILURE",

    ARRIVE_REQUEST: "@@application-receipt/ARRIVE_REQUEST",
    ARRIVE_SUCCESS: "@@application-receipt/ARRIVE_SUCCESS",
    ARRIVE_FAILURE: "@@application-receipt/ARRIVE_FAILURE",

    DELETE_ARRIVAL_REQUEST: "@@application-receipt/DELETE_ARRIVAL_REQUEST",
    DELETE_ARRIVAL_SUCCESS: "@@application-receipt/DELETE_ARRIVAL_SUCCESS",
    DELETE_ARRIVAL_FAILURE: "@@application-receipt/DELETE_ARRIVAL_FAILURE",
};
