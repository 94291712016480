import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField";
import SubmitButton from "../../../Common/SubmitButton";
import CancelButton from "../../../Common/CancelButton";
import React from "react";

export default function ReasonDialog(props) {
    const { form, backMethod, openDialog, setOpenDialog } = props;

    return (
        <Dialog open={openDialog || props.openDialog} fullScreen={false}>
            <DialogTitle>Ügyfél adatok módosítására szóló engedély kérése</DialogTitle>
            <DialogContent>
                FIGYELEM! A következő funkcióval a jogosultak adatait módosíthatja. A módosításokat csak többszöri
                ellenőrzés után érvényesítse, mert a téves módosításokkal a jogosultságot veszélyezteti!
                <Form form={form} name="form" changeform={props.changeForm}>
                    <TextField
                        multiline={true}
                        rows={4}
                        label="Indoklás"
                        name="reason"
                        format={{ xs: 12 }}
                        value={form?.reason || ""}
                        rule={{
                            length: 2000
                        }}
                    />
                </Form>
            </DialogContent>
            <DialogActions>
                <SubmitButton
                    onClick={() => {
                        if (form?.reason == null || form?.reason?.length < 25) {
                            props.changeForm(
                                {
                                    ...form,
                                    errors: [
                                        {
                                            name: "reason",
                                            label: "Ez az érték túl rövid. Legalább 25 karaktert kell tartalmaznia.",
                                        },
                                    ],
                                },
                                "form"
                            );
                            return false;
                        }
                        props.changeForm({ ...form, errors: [] }, "form");
                        setOpenDialog(false);
                        if (props.saveDialog) {
                            props.saveDialog();
                        }
                    }}
                >
                    Mentés
                </SubmitButton>
                <CancelButton onClick={backMethod ? backMethod : () => setOpenDialog(false)}>Mégse</CancelButton>
            </DialogActions>
        </Dialog>
    );
}
