import React, { Children } from "react";
import { Grid } from "@material-ui/core";
import { TAX_STATUS_OPTIONS } from "../../../../utils/AppConst";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import Title from "../../../Common/Title";
import InputBase from "../../../Common/InputBase";
import Card from "../../../Common/Card";

export default function TaxStatus(props) {
    const { form, name, edit, data } = props;

    const formProps = {
        form: form,
        name: name,
        changeform: props.changeForm,
        noBold: true,
    };

    return (
        <Card title="Adóstátusz" edit={edit} fullWidth>
            {edit ? (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="Adóstátusz" {...formProps}>
                            <Select name="taxStatus" value={form.taxStatus} format={{ xs: 12 }} native>
                                {Children.toArray(
                                    TAX_STATUS_OPTIONS.map((option) => (
                                        <option value={option.value}>{option.label}</option>
                                    ))
                                )}
                            </Select>
                        </Title>
                        <Title title="Adóstátusz kezdete" {...formProps}>
                            <TextField
                                name="taxStatusStartedAt"
                                value={form.taxStatusStartedAt || ""}
                                type="date"
                                format={{ xs: 12 }}
                            />
                        </Title>
                    </Grid>
                </Form>
            ) : (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="Adóstátusz" {...formProps}>
                            <InputBase
                                value={data.taxStatus != "taxfree" ? "Adóköteles" : "Adómentes"}
                                format={{ xs: 12 }}
                                disabled
                            />
                        </Title>
                        <Title title="Adóstátusz kezdete" {...formProps}>
                            <InputBase value={data.taxStatusStartedAt || ""} format={{ xs: 12 }} disabled />
                        </Title>
                        <Title title="Adóstátusz megállapítva" {...formProps}>
                            <InputBase value={data.taxStatusDeterminedAt || ""} format={{ xs: 12 }} disabled />
                        </Title>
                    </Grid>
                </Form>
            )}
        </Card>
    );
}
