import {
    templateChangeForm,
    templateClearForm,
    templateCreate,
    templateCreateChild,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateGet,
    templateGetValidationCreate,
    templateGetValidationUpdate,
    templateUpdate,
    templateUpdateClose,
} from "..";
import employersService from "../../services/employers";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types, employersService),
    filter: templateFilter(types, employersService),
    clearForm: templateClearForm(types, employersService),
    get: templateGet(types, employersService),
    update: templateUpdate(types, employersService),
    create: templateCreate(types, employersService),
    createChild: templateCreateChild(types, employersService),
    updateClose: templateUpdateClose(types, employersService),
    getValidationCreate: templateGetValidationCreate(types, employersService),
    getValidationUpdate: templateGetValidationUpdate(types, employersService),
    exportInitiate: templateExportInitiate(types, employersService),
    exportCheck: templateExportCheck(types, employersService),
    exportDownload: templateExportDownload(types, employersService),
};

export default actions;
