import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Dialog from "../../../Common/Dialog";
import OperationButton from "../../../Common/OperationButton";
import Table from "../../../Common/Table";
import Loading from "../../../Common/Loading";
import colors from "../../../../styles/colors";

const useStyles = makeStyles(() => ({
    userRow: {
        backgroundColor: colors.edit,
        height: "48px",
        padding: "10px",
    },
    userRowReason: {
        display: "block",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    userRowUsername: {
        display: "block",
        fontWeight: "bold",
    },
    noChangesRow: {
        padding: "10px",
        paddingTop: "15px",
        color: colors.lightPrimary,
    },
}));

function mapDatas(datas) {
    if (!Array.isArray(datas)) {
        return [];
    }

    let mapped = [];
    let fields = [];
    let hasChanges = false;

    datas.forEach((element) => {
        mapped.push({
            data: element,
            isFullWidth: true,
        });
        hasChanges = false;

        if (element.new && typeof element.new == typeof {}) {
            Object.keys(element.new).forEach((field) => {
                if (field === "reason") {
                    return;
                }
                if (element?.new?.[field]?.key) {
                    if (fields.includes(element.new[field].key)) {
                        return;
                    }
                    fields.push(element.new[field].key);
                }
            });
        }
        if (element.old && typeof element.old == typeof {}) {
            Object.keys(element.old).forEach((field) => {
                if (field === "reason") {
                    return;
                }
                if (element?.old?.[field]?.key) {
                    if (fields.includes(element.old[field].key)) {
                        return;
                    }
                    fields.push(element.old[field].key);
                }
            });
        }

        fields.forEach((field) => {
            const newData = element.new.filter((d) => d.key === field)?.[0];
            const oldData = element.old.filter((d) => d.key === field)?.[0];

            if(oldData === undefined && newData === undefined) {
                return;
            }

            let data = field;
            if (newData?.label) {
                data = newData.label;
            } else if (oldData?.label) {
                data = oldData.label;
            }

            mapped.push({
                data: data,
                earlier: oldData ? oldData.value : "-",
                modified: newData ? newData.value : "-",
                date: element.date,
                modifier: element?.user?.name,
                isFullWidth: false,
            });
            hasChanges = true;
        });

        if (!hasChanges) {
            mapped.push({
                noChanges: true,
                isFullWidth: true,
            });
        }
    });

    return mapped;
}

function FullWidthCellRenderer(params) {
    const classes = useStyles();

    if (params.data.noChanges) {
        return <div className={classes.noChangesRow}>Nincsenek változtatások</div>;
    }

    let reasonLength = params.data?.data?.reason?.length;
    let numberOfLines = (Math.floor(reasonLength / 132) + 1)
    let reasonHeight = 36 * numberOfLines + 36;

    return (
        <div className={classes.userRow} style={{ height: reasonHeight > 50 ? reasonHeight : 50}}>
            <div className={classes.userRowUsername}>
                {params.data.data.date} - {params.data.data.user.name}
            </div>
            <div className={classes.userRowReason}>{params.data.data.reason}</div>
        </div>
    );
}

export default function TrackingChanges(props) {
    const { type } = props;

    const table = {
        columnDefs: [
            { headerName: "Adat", field: "data", sortable: false },
            { headerName: "Korábbi", field: "earlier", sortable: false },
            { headerName: "Módosított", field: "modified", sortable: false },
            { headerName: "Dátum", field: "date", sortable: false },
            { headerName: "Módosító", field: "modifier", sortable: false },
        ],
        rowData: mapDatas(props.changesLog),
        isFullWidthCell: function (rowNode) {
            return rowNode.data.isFullWidth === true;
        },
        getRowHeight: function(rowNode) {
            let reasonLength = rowNode.data?.data?.reason?.length;
            let reasonHeight = 0;
            if (reasonLength) {   
                let numberOfLines = (Math.floor(reasonLength / 132) + 1)
                reasonHeight = 30 * numberOfLines + 36;
            }
            
            return (rowNode.data.isFullWidth === true && reasonHeight > 50) ? reasonHeight : 50;
        },
        fullWidthCellRenderer: "fullWidthCellRenderer",
        frameworkComponents: {
            fullWidthCellRenderer: FullWidthCellRenderer,
        },
        hidePagination: props.hidePagination === undefined ? false : props.hidePagination,
    };

    return (
        <Dialog
            opener={
                <OperationButton
                    type={type}
                    onClick={() => {
                        props.getChangesLog(props.mavirId);
                    }}
                />
            }
            title="Módosítások nyomonkövetése"
            fullWidth
            maxWidth="md"
        >
            {props.loadingChangesLog ? (
                <Loading />
            ) : (
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                        <Table noExportButton showPagination={false} {...table} />
                    </Grid>
                </Grid>
            )}
        </Dialog>
    );
}
