import axios from "axios";
import loginService from "../services/login";
import { history } from "../store";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    responseType: "blob",
});

instance.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            const AUTH_TOKEN = localStorage.getItem("token");
            if (AUTH_TOKEN) config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response && error.response.status) {
            if (401 === error.response.status) {
                loginService.logout();
                history.push("/login");
                return Promise.reject(error);
            } else if (404 === error.response.status) {
                return Promise.reject(error);
            } else if (403 === error.response.status) {
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
