import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "../index";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/tax", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getFilterFinanciers() {
    return API.get("/tax/filter/financiers")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const taxService = {
    filter,
    exportInitiate: templateExportInitiate("tax"),
    exportCheck: templateExportCheck("tax"),
    exportDownload: templateExportDownload("tax"),
    getFilterFinanciers,
};

export default taxService;
