import {
    templateChangeForm,
    templateClearForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateSetLoading,
} from "..";
import applicationConsumptionPlacesByContactService from "../../services/applicationConsumptionPlacesByContact";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    clearForm: templateClearForm(types, applicationConsumptionPlacesByContactService),
    filter: templateFilter(types, applicationConsumptionPlacesByContactService),
    exportInitiate: templateExportInitiate(types, applicationConsumptionPlacesByContactService),
    exportCheck: templateExportCheck(types, applicationConsumptionPlacesByContactService),
    exportDownload: templateExportDownload(types, applicationConsumptionPlacesByContactService),
    setLoading: templateSetLoading(types),
};

export default actions;
