import { templateChangeForm, templateDefaultForm, templateSetPageLoading } from "..";
import providerValidationService from "../../services/providerValidation";
import downloadBlob from "../../utils/downloadBlob";
import isEmpty from "../../utils/isEmpty";
import { types } from "./types";

function filterDownload(form) {
    let filter = {};

    if (isEmpty(form)) {
        filter = {
            page: 1,
            filter: form,
            [`sort[createdAt]`]: "desc"
        }
    } else {
        Object.assign(filter, form);
    }

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_DOWNLOAD, filter });
        return providerValidationService.filterDownload(filter).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_DOWNLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function filterUpload(form) {
    let filter = {};

    if (isEmpty(form)) {
        filter = {
            page: 1,
            filter: form,
            [`sort[createdAt]`]: "desc"
        }
    } else {
        Object.assign(filter, form);
    }

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST_UPLOAD, filter });
        return providerValidationService.filterUpload(filter).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS_UPLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function upload(form) {
    return (dispatch) => {
        dispatch({ type: types.UPLOAD_REQUEST, form });
        return providerValidationService.upload(form).then(
            (data) => {
                dispatch({ type: types.UPLOAD_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function defaultFormDownload(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_DOWNLOAD, name });
}

function defaultFormUpload(name) {
    return (dispatch) => dispatch({ type: types.DEFAULT_FORM_UPLOAD, name });
}

function changeStatusDownload(id) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_STATUS_REQUEST_DOWNLOAD });
        return providerValidationService.changeStatusDownload(id).then(
            (data) => {
                dispatch({ type: types.CHANGE_STATUS_SUCCESS_DOWNLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHANGE_STATUS_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function changeStatusUpload(id) {
    return (dispatch) => {
        dispatch({ type: types.CHANGE_STATUS_REQUEST_UPLOAD });
        return providerValidationService.changeStatusUpload(id).then(
            (data) => {
                dispatch({ type: types.CHANGE_STATUS_SUCCESS_UPLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHANGE_STATUS_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportInitiateDownload(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_DOWNLOAD });
        return providerValidationService.exportInitiateDownload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_DOWNLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckDownload(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_DOWNLOAD });
        return providerValidationService.exportCheckDownload(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_DOWNLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadDownload(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_DOWNLOAD });
        return providerValidationService.exportDownloadDownload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_DOWNLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportInitiateUpload(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_INITIATE_REQUEST_UPLOAD });
        return providerValidationService.exportInitiateUpload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_INITIATE_SUCCESS_UPLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_INITIATE_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportCheckUpload(id) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_CHECK_REQUEST_UPLOAD });
        return providerValidationService.exportCheckUpload(id).then(
            (data) => {
                dispatch({ type: types.EXPORT_CHECK_SUCCESS_UPLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_CHECK_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function exportDownloadUpload(form, filter) {
    return (dispatch) => {
        dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST_UPLOAD });
        return providerValidationService.exportDownloadUpload(form, filter).then(
            (data) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS_UPLOAD, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadDownload(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST_DOWNLOAD });
        return providerValidationService.downloadDownload(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS_DOWNLOAD, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE_DOWNLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadUpload(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST_UPLOAD });
        return providerValidationService.downloadUpload(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS_UPLOAD, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE_UPLOAD, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadUploadReply(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST_UPLOAD_REPLY });
        return providerValidationService.downloadUploadReply(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS_UPLOAD_REPLY, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE_UPLOAD_REPLY, error });
                return Promise.reject(error);
            }
        );
    };
}

function setDownloadInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_DOWNLOAD_INFO, key, value });
    };
}

function setUploadInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_UPLOAD_INFO, key, value });
    };
}

function getLogServiceProviderDataVerification(id) {
    return (dispatch) => {
        dispatch({
            type: types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_REQUEST,
        });
        return providerValidationService.getLogServiceProviderDataVerification(id).then(
            (data) => {
                dispatch({
                    type: types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function getLogSortingResults(id) {
    return (dispatch) => {
        dispatch({
            type: types.GET_LOG_SORTING_RESULTS_REQUEST,
        });
        return providerValidationService.getLogSortingResults(id).then(
            (data) => {
                dispatch({
                    type: types.GET_LOG_SORTING_RESULTS_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.GET_LOG_SORTING_RESULTS_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    filterDownload,
    filterUpload,
    changeStatusDownload,
    changeStatusUpload,
    defaultFormDownload,
    defaultFormUpload,
    exportInitiateDownload,
    exportCheckDownload,
    exportDownloadDownload,
    exportInitiateUpload,
    exportCheckUpload,
    exportDownloadUpload,
    downloadDownload,
    downloadUpload,
    downloadUploadReply,
    upload,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setDownloadInfo,
    setUploadInfo,
    setPageLoading: templateSetPageLoading(types),
    getLogServiceProviderDataVerification,
    getLogSortingResults,
};

export default actions;
