import API from "../../utils/API";
import { templateGetValidationUpdate, updateSubmit } from "../index";

function getData(mavirId) {
    return API.get("/customers/get/" + mavirId)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getContacts() {
    return API.get("/customers/contacts")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getChangesLog(mavirId) {
    return API.get("/customers/" + mavirId + "/changes-log")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
function getConsumptionPlaceChangesLog(customerMavirId, permissionId, placeIndex) {
    return API.get(`/customers/${customerMavirId}/${permissionId}/consumption-place/${placeIndex}/changes-log`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const clientDetailsService = {
    getData,
    getContacts,
    getChangesLog,
    getConsumptionPlaceChangesLog,
    update: updateSubmit("customers"),
    getValidationUpdate: templateGetValidationUpdate("customers"),
};

export default clientDetailsService;
