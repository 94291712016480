import { types } from "./types";

const initialState = {
    form: {},
    data: [],
    metadata: {
        types: [],
        providers: [],
    },
    loading: true,
    reasonForm: {},
};

export function consumptionPlace(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
            };
        case types.CLEAR_FORM:
            return {
                ...state,
                reasonForm: initialState.reasonForm,
            };
        case types.GET_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                data: initialState.data,
                form: initialState.form,
            };
        case types.GET_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
                form: action.data.data.data,
            };
        case types.GET_DATA_FAILURE:
            return {
                ...state,
                loading: false,
                data: initialState.data,
                form: initialState.form,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.GET_META_DATA_REQUEST:
            return {
                ...state,
                metadata: initialState.metadata,
            };
        case types.GET_META_DATA_SUCCESS:
            return {
                ...state,
                metadata: action.data.data.data,
            };
        case types.GET_META_DATA_FAILURE:
            return {
                ...state,
                metadata: initialState.form,
            };
        case types.SET_FORM_DATA:
            return {
                ...state,
                form: action.data,
            };
        case types.SET_DATA:
            return {
                ...state,
                data: action.data,
            };
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.isLoading,
            };

        default:
            return state;
    }
}
