import React, { useState } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Dialog from "../../Common/Dialog";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import applicationsDetailsService from "../../../services/applicationDetails";

export default function StatusLog(props) {
    const { applicantId } = props;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [printinfo, setPrintInfo] = useState("");

    const NewStatusCellRenderer = (params) => {
        if(params.data.debugInfo && params.data.debugInfo !== null){
            return (
                <div onClick={() => {
                    setPrintInfo(params.data.debugInfo)
                }}>
                    {params.data.newStatus}
                </div>
            );
        }else{
            return (
                <div onClick={() => {
                    setPrintInfo("")
                }}>
                    {params.data.newStatus}
                </div>
            );
        }
    }

    const table = {
        columnDefs: [
            { headerName: "Felhasználó", field: "createdBy", sortable: false },
            { headerName: "Miről", field: "oldStatus", sortable: false },
            { headerName: "Mire", field: "newStatus", sortable: false,
                cellRenderer: "newStatusCellRenderer", },
            { headerName: "Dátum", field: "createdAt", sortable: false },
        ],
        rowData: data,
        hidePagination: true,
        frameworkComponents: {
            newStatusCellRenderer: NewStatusCellRenderer
        },
    };

    const title = (
        <>
            Státuszváltozások
            {loading && <LinearProgress />}
        </>
    );


    return (
        <Dialog
            opener={
                <OperationButton
                    type="track-changes"
                    onClick={() => {
                        setLoading(true);
                        setData([]);
                        applicationsDetailsService
                            .getApplicationStatusLog(applicantId)
                            .then((response) => {
                                setLoading(false);
                                setData(response.data.data);
                            })
                            .catch(() => {
                                setLoading(false);
                                setData([]);
                            });
                    }}
                />
            }
            title={title}
            fullWidth
            maxWidth="md"
        >
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} style={{ height: "400px" }}>
                    {loading ? (
                        <Skeleton variant="rect" style={{ position: "relative", width: "100%", height: "100%" }} />
                    ) : (
                        <Table noExportButton showPagination={false} {...table} />
                    )}
                    {printinfo && <pre>{JSON.stringify(printinfo, null, 2)}</pre>}
                </Grid>
            </Grid>
        </Dialog>
    );
}
