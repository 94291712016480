import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import downloadAPI from "../../utils/downloadAPI";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/applications", {
        params: searchForm.getSend(),
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function downloadEdms(barCode, forceVal = false) {
    return downloadAPI
        .get(`/edms/${barCode}?force=${forceVal}`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function getUsersWhoArrived() {
    return API.get('/applications/list-users-who-arrived')
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const applicationsListService = {
    filter,
    downloadEdms,
    getUsersWhoArrived,
    exportInitiate: templateExportInitiate("applications"),
    exportCheck: templateExportCheck("applications"),
    exportDownload: templateExportDownload("applications"),
};

export default applicationsListService;
