import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Address from "../ClientDetails/cards/Address";
import ContractDetails from "../ClientDetails/cards/ContractDetails";
import PageTemplate from "../../Common/PageTemplate";
import consumptionPlaceActions from "../../../store/consumptionPlace/actions.js";
import { connect } from "react-redux";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import { history } from "../../../store";
import AddConsumptionMeters from "../ClientDetails/cards/AddConsumptionMeters";
import Card from "../../Common/Card";
import Detail from "../../Common/Detail";
import colors from "../../../styles/colors";

const useStyles = makeStyles((theme) => ({
    goButton: {
        marginLeft: theme.spacing(1),
        marginTop: theme.spacing(0.5),
        background: colors.green,
        "&:hover": {
            background: colors.greenHover,
        },
    },
    cancelButton: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(0.5),
        color: colors.green,
        borderColor: colors.green,
        "&:hover": {
            background: colors.greenHover,
            borderColor: colors.greenHover,
        },
    },
    userTypography: {
        paddingBottom: "16px",
    },
}));

function AddConsumptionPlace(props) {
    const classes = useStyles();
    const { address, contractDetails, consumptionMeters } = props;
    const id = props.match.params.id;

    return (
        <PageTemplate
            header={{
                title: "Fogyasztási hely hozzáadása",
                breadcrumbs: {
                    "/clients": "Ügyfelek",
                    ["/clients/" + id]: "Ügyfél adminisztrációja",
                    "add-consumption-place": "Fogyasztási hely hozzáadása",
                },
            }}
        >
            <Card title="Második fogyasztási hely" fullWidth>
                <Grid className={classes.userTypography}>
                    <Detail title="Felhasználó" value={id} />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Address name="address" form={address} addNew changeForm={props.changeForm} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContractDetails
                            name="contractDetails"
                            addNew
                            form={contractDetails}
                            changeForm={props.changeForm}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddConsumptionMeters
                            name="consumptionMeters"
                            addNew
                            form={consumptionMeters}
                            changeForm={props.changeForm}
                        />
                    </Grid>
                    <SubmitButton onClick={() => history.push(`/clients/${id}`)} className={classes.goButton}>
                        Mentés
                    </SubmitButton>
                    <CancelButton onClick={() => history.push(`/clients/${id}`)} className={classes.cancelButton}>
                        Mégse
                    </CancelButton>
                </Grid>
            </Card>
        </PageTemplate>
    );
}

function mapState(state) {
    const { address, contractDetails, consumptionMeters } = state.consumptionPlace;
    return { address, contractDetails, consumptionMeters };
}

const actionCreators = {
    changeForm: consumptionPlaceActions.changeForm,
};

export default connect(mapState, actionCreators)(AddConsumptionPlace);
