import { withStyles } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import colors from "../../styles/colors.js";

const Delete = withStyles({
    root: {
        backgroundColor: colors.red,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.redHover,
        },
    },
})(DeleteIcon);

export default Delete;
