export const types = {
    DEFAULT_FORM: "@@files-list/DEFAULT_FORM",
    CHANGE_FORM: "@@files-list/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@files-list/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@files-list/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@files-list/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@files-list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@files-list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@files-list/FETCH_FAILURE",

    CANCEL_REQUEST: "@@files-list/CANCEL_REQUEST",
    CANCEL_SUCCESS: "@@files-list/CANCEL_SUCCESS",
    CANCEL_FAILURE: "@@files-list/CANCEL_FAILURE",

    UPLOAD_REQUEST: "@@files-list/UPLOAD_REQUEST",
    UPLOAD_SUCCESS: "@@files-list/UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "@@files-list/UPLOAD_FAILURE",

    DOWNLOAD_REQUEST: "@@files-list/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@files-list/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@files-list/DOWNLOAD_FAILURE",

    DOWNLOAD_REPLY_REQUEST: "@@files-list/DOWNLOAD_REPLY_REQUEST",
    DOWNLOAD_REPLY_SUCCESS: "@@files-list/DOWNLOAD_REPLY_SUCCESS",
    DOWNLOAD_REPLY_FAILURE: "@@files-list/DOWNLOAD_REPLY_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@files-list/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@files-list/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@files-list/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@files-list/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@files-list/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@files-list/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@files-list/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@files-list/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@files-list/EXPORT_DOWNLOAD_FAILURE",

    GET_LOG_REQUEST: "@@files-list/GET_LOG_REQUEST",
    GET_LOG_SUCCESS: "@@files-list/GET_LOG_SUCCESS",
    GET_LOG_FAILURE: "@@files-list/GET_LOG_FAILURE",

    FETCH_SET_INFO: "@@files-list/FETCH_SET_INFO",
};
