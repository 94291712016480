import { KeyboardDateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";

const CssKeyboardDateTimePicker = withStyles({
    root: {
        "& label.Mui-focused": {
            color: colors.black,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:before": {
            borderBottomColor: colors.black,
        },
        "& .MuiInput-underline:hover": {
            borderBottomColor: colors.black,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: colors.black,
            },
            "&:hover fieldset": {
                borderColor: colors.black,
            },
            "&.Mui-focused fieldset": {
                borderColor: colors.black,
            },
        },
    },
})(KeyboardDateTimePicker);

export default function DateTimePicker(props) {
    const {functionToChild, formNameToChild, formToChild, ...rest} = props;

    const removeZ = date => {
        if (!date || isNaN(new Date(date).getTime())) {
            return null;
        }
        const diff = (new Date(date)).getTimezoneOffset() * 60000; 
        const isoString = new Date(new Date(date) - diff).toISOString();
        const splittedIsoString = isoString.split(".")[0];

        return splittedIsoString.slice(0, splittedIsoString.length - 3);
    }

    return (
        <CssKeyboardDateTimePicker
            {...rest}
            autoOk
            value={props.value || null}
            ampm={false}
            variant="inline"
            inputVariant="outlined"
            format="YYYY-MM-DD HH:mm"
            invalidDateMessage={`Hibás formátum`}
            minDate={moment("1800-01-01")}
            maxDate={moment("2300-01-01")}
            InputLabelProps={{ shrink: true }}
            onChange={date => functionToChild({
                ...formToChild,
                ...(props.name.includes(".") ? 
                    {
                        [props.name.split(".")[0]]: {
                            ...formToChild[props.name.split(".")[0]],
                            [props.name.split(".")[1]]: removeZ(date)
                        }
                    }
                    : {[props.name]: removeZ(date)} 
                )
            }, formNameToChild)}
            fullWidth
            size="small"
        />
    );
}
