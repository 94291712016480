export const types = {
    DEFAULT_FORM_PROVIDER: "@@denied-applications/DEFAULT_FORM_PROVIDER",
    DEFAULT_FORM_CONTACT: "@@denied-applications/DEFAULT_FORM_CONTACT",

    CHANGE_FORM: "@@denied-applications/CHANGE_FORM",

    FETCH_REQUEST_PROVIDER: "@@denied-applications/FETCH_REQUEST_PROVIDER",
    FETCH_SUCCESS_PROVIDER: "@@denied-applications/FETCH_SUCCESS_PROVIDER",
    FETCH_FAILURE_PROVIDER: "@@denied-applications/FETCH_FAILURE_PROVIDER",

    FETCH_REQUEST_CONTACT: "@@denied-applications/FETCH_REQUEST_CONTACT",
    FETCH_SUCCESS_CONTACT: "@@denied-applications/FETCH_SUCCESS_CONTACT",
    FETCH_FAILURE_CONTACT: "@@denied-applications/FETCH_FAILURE_CONTACT",

    DOWNLOAD_REQUEST: "@@denied-applications/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@denied-applications/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@denied-applications/DOWNLOAD_FAILURE",

    EXPORT_INITIATE_REQUEST_PROVIDER: "@@denied-applications/EXPORT_INITIATE_REQUEST_PROVIDER",
    EXPORT_INITIATE_SUCCESS_PROVIDER: "@@denied-applications/EXPORT_INITIATE_SUCCESS_PROVIDER",
    EXPORT_INITIATE_FAILURE_PROVIDER: "@@denied-applications/EXPORT_INITIATE_FAILURE_PROVIDER",

    EXPORT_INITIATE_REQUEST_CONTACT: "@@denied-applications/EXPORT_INITIATE_REQUEST_CONTACT",
    EXPORT_INITIATE_SUCCESS_CONTACT: "@@denied-applications/EXPORT_INITIATE_SUCCESS_CONTACT",
    EXPORT_INITIATE_FAILURE_CONTACT: "@@denied-applications/EXPORT_INITIATE_FAILURE_CONTACT",

    EXPORT_CHECK_REQUEST_PROVIDER: "@@denied-applications/EXPORT_CHECK_REQUEST_PROVIDER",
    EXPORT_CHECK_SUCCESS_PROVIDER: "@@denied-applications/EXPORT_CHECK_SUCCESS_PROVIDER",
    EXPORT_CHECK_FAILURE_PROVIDER: "@@denied-applications/EXPORT_CHECK_FAILURE_PROVIDER",

    EXPORT_CHECK_REQUEST_CONTACT: "@@denied-applications/EXPORT_CHECK_REQUEST_CONTACT",
    EXPORT_CHECK_SUCCESS_CONTACT: "@@denied-applications/EXPORT_CHECK_SUCCESS_CONTACT",
    EXPORT_CHECK_FAILURE_CONTACT: "@@denied-applications/EXPORT_CHECK_FAILURE_CONTACT",

    EXPORT_DOWNLOAD_REQUEST_PROVIDER: "@@denied-applications/EXPORT_DOWNLOAD_REQUEST_PROVIDER",
    EXPORT_DOWNLOAD_SUCCESS_PROVIDER: "@@denied-applications/EXPORT_DOWNLOAD_SUCCESS_PROVIDER",
    EXPORT_DOWNLOAD_FAILURE_PROVIDER: "@@denied-applications/EXPORT_DOWNLOAD_FAILURE_PROVIDER",

    EXPORT_DOWNLOAD_REQUEST_CONTACT: "@@denied-applications/EXPORT_DOWNLOAD_REQUEST_CONTACT",
    EXPORT_DOWNLOAD_SUCCESS_CONTACT: "@@denied-applications/EXPORT_DOWNLOAD_SUCCESS_CONTACT",
    EXPORT_DOWNLOAD_FAILURE_CONTACT: "@@denied-applications/EXPORT_DOWNLOAD_FAILURE_CONTACT",

    CHANGE_STATUS_REQUEST: "@@denied-applications/CHANGE_STATUS_REQUEST",
    CHANGE_STATUS_SUCCESS: "@@denied-applications/CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "@@denied-applications/CHANGE_STATUS_FAILURE",

    FETCH_SET_PROVIDER_INFO: "@@denied-applications/FETCH_SET_PROVIDER_INFO",
    FETCH_SET_CONTACT_INFO: "@@denied-applications/FETCH_SET_CONTACT_INFO",
    SET_PAGE_LOADING: "@@denied-applications/SET_PAGE_LOADING",
};
