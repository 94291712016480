import React, { useEffect } from "react";
import { connect } from "react-redux";
import consumptionDataByPermissionActions from "../../../../store/consumptionDataByPermission/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import axios from "axios";
import NumberFormatCellRenderer from "../../../../utils/cellRenderer/NumberFormatCellRenderer";
import { FILTER_MONTH_OPTIONS, GET_STAT_DETAILS, YEAR_OPTIONS } from "../../../../utils/AppConst";
import Select from "../../../Common/Select";
import {filterStorageGet, filterStorageSave} from "../../../../utils/FilterStorage";

//4.1.1
function GKMOne(props) {
    const { filter, data, token, tableVisibility, loading, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"), 
        ]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
        props.changeForm(false, "tableVisibility");
    }, []);

    const table = {
        columnDefs: [
            { headerName: "Jogosultság", field: "permission" },
            { headerName: "Azonosító", field: "id" },
            { headerName: "Létszám", field: "numberOfCustomers" },
            { headerName: "Fogyasztás (kWh)", field: "quantity", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Ár (Ft)", field: "grossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Fogyasztói ár (Ft)", field: "consumerGrossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Finanszírozói ár (Ft)", field: "financierGrossPrice", cellRenderer: "numberFormatCellRenderer" },
        ],
        rowData: data,
        frameworkComponents: {
            numberFormatCellRenderer: NumberFormatCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
    };

    const disableSubmit =
        filter.yearFrom === undefined || filter.yearFrom === null || 
        filter.monthFrom === undefined || filter.monthFrom === null ||
        filter.yearTo === undefined || filter.yearTo === null || 
        filter.monthTo === undefined || filter.monthTo === null
    ;
    
    return (
        <ListPageTemplate
            header={{
                title: GET_STAT_DETAILS[2]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/gkm": "GKM kimutatások",
                    "4.1.1": GET_STAT_DETAILS[2]?.title,
                },
            }}
            filter={{
                onSubmit: (filterForm) => {
                    props.changeForm(true, "tableVisibility");
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filterForm);
                },
                onSubmitDisabled: disableSubmit,
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.changeForm(false, "tableVisibility");
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
            hideTable={!tableVisibility}
            statPageDetail={GET_STAT_DETAILS[2]?.body}
        >
            <Select
                selectLabel="Időszak kezdete (év)"
                name="yearFrom"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearFrom || ""}
            />
            <Select
                selectLabel="Időszak kezdete (hónap)"
                name="monthFrom"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthFrom || ""}
                dontSort
            />
            <Select
                selectLabel="Időszak vége (év)"
                name="yearTo"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearTo || ""}
            />
            <Select
                selectLabel="Időszak vége (hónap)"
                name="monthTo"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthTo || ""}
                dontSort
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, tableVisibility, filterStorageKey } = state.consumptionDataByPermission;
    return { filter, data, loading, token, tableVisibility, filterStorageKey };
}

const actionCreators = {
    changeForm: consumptionDataByPermissionActions.changeForm,
    defaultForm: consumptionDataByPermissionActions.defaultForm,
    search: consumptionDataByPermissionActions.filter,
    exportInitiate: consumptionDataByPermissionActions.exportInitiate,
    exportCheck: consumptionDataByPermissionActions.exportCheck,
    exportDownload: consumptionDataByPermissionActions.exportDownload,
    setLoading: consumptionDataByPermissionActions.setLoading,
};

export default connect(mapState, actionCreators)(GKMOne);
