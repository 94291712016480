export const types = {
    FETCH_PERMISSION_ASSIGNMENTS_REQUEST: "@@commands/FETCH_PERMISSION_ASSIGNMENTS_REQUEST",
    FETCH_PERMISSION_ASSIGNMENTS_SUCCESS: "@@commands/FETCH_PERMISSION_ASSIGNMENTS_SUCCESS",
    FETCH_PERMISSION_ASSIGNMENTS_FAILURE: "@@commands/FETCH_PERMISSION_ASSIGNMENTS_FAILURE",

    FETCH_SORTINGS_REQUEST: "@@commands/FETCH_SORTINGS_REQUEST",
    FETCH_SORTINGS_SUCCESS: "@@commands/FETCH_SORTINGS_SUCCESS",
    FETCH_SORTINGS_FAILURE: "@@commands/FETCH_SORTINGS_FAILURE",

    PERMISSION_ASSIGNMENT_REQUEST: "@@commands/PERMISSION_ASSIGNMENT_REQUEST",
    PERMISSION_ASSIGNMENT_SUCCESS: "@@commands/PERMISSION_ASSIGNMENT_SUCCESS",
    PERMISSION_ASSIGNMENT_FAILURE: "@@commands/PERMISSION_ASSIGNMENT_FAILURE",

    SORTING_REQUEST: "@@commands/SORTING_REQUEST",
    SORTING_SUCCESS: "@@commands/SORTING_SUCCESS",
    SORTING_FAILURE: "@@commands/SORTING_FAILURE",
};
