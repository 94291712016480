import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";

function optionOne(formProps) {
    const { form } = formProps;
    return (
        <TextField
            label="Az igénylőlap aláírásának időpontja"
            type="date"
            value={form.signatureDate}
            format={{ xs: 12 }}
            name="signatureDate"
        />
    );
}

function optionThree(formProps) {
    const { form } = formProps;
    const permissionDurationYearsOptions = [{ value: "none", label: "Időben nem meghatározott" }];
    const permissionDurationMonthsOptions = [];

    for (let i = 1; i <= 9; i++) permissionDurationYearsOptions.push({ value: i, label: i });

    for (let i = 0; i <= 11; i++) permissionDurationMonthsOptions.push({ value: i, label: i });

    return (
        <Form {...formProps} childComponent format={{ xs: 12 }}>
            <TextField
                label="Az igénylőlap aláírásának időpontja"
                type="date"
                value={form.signatureDate}
                name="signatureDate"
                format={{ xs: 12 }}
            />
            <TextField
                label="Nyugdíjazás időpontja"
                type="date"
                value={form.retirementDate}
                name="retirementDate"
                format={{ xs: 12 }}
            />
            <Title title="Munkaviszony időtartama" format={{ xs: 12 }} {...formProps}>
                <TextField
                    label="Év"
                    value={form.workYears}
                    name="workYears"
                    margin="none"
                    rule={{
                        number: "true",
                        length: 2,
                    }}
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Hónap"
                    value={form.workMonths}
                    name="workMonths"
                    margin="none"
                    rule={{
                        number: "true",
                        length: 2,
                    }}
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Select
                selectLabel="Jogosultság időtartama (évben)"
                name="durationYears"
                value={form.durationYears || "none"}
                optionList={permissionDurationYearsOptions}
                format={{ xs: 12 }}
            />
            {form.durationYears > 0 && (
                <Select
                    selectLabel="Jogosultság időtartama (hónapban)"
                    name="durationMonths"
                    value={form.durationMonths || "0"}
                    optionList={permissionDurationMonthsOptions}
                    format={{ xs: 12 }}
                />
            )}
        </Form>
    );
}

export default function StatementForm(props) {
    const { form, validation, applicationType } = props;
    const formProps = {
        name: "statement",
        changeform: props.onChange,
        form: form,
        validation: validation,
    };

    const type = useSelector((state) => state.applicationFiling.legalBasis.type);

    const forms = [optionOne, optionOne, optionThree, optionOne, optionOne, optionOne, optionOne];

    const certificateOptions = [
        { value: "1", label: "1. számú igazolás" },
        { value: "2", label: "2. számú igazolás" },
        { value: "3", label: "3. számú igazolás" },
        { value: "4", label: "4. számú igazolás" },
        { value: "5", label: "5. számú igazolás" },
        { value: "6", label: "6. számú igazolás" },
        { value: "7", label: "7. számú igazolás" },
    ];

    const filteredCertificateOptions = () => {
        const result = [];

        switch (type) {
            case "employee":
                result.push(certificateOptions[0]);
                break;
            case "trade_union_employee":
                result.push(certificateOptions[0]);
                break;
            case "pensioner":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[2]);
                break;
            case "employees_widow":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[3]);
                break;
            case "pensioners_widow":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[4]);
                result.push(certificateOptions[5]);
                result.push(certificateOptions[6]);
                break;
            case "trade_union_pensioner":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[2]);
                break;
            case "trade_union_employees_widow":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[3]);
                break;
            case "trade_union_pensioner_widow":
                result.push(certificateOptions[1]);
                result.push(certificateOptions[4]);
                result.push(certificateOptions[5]);
                result.push(certificateOptions[6]);
                break;
            default:
                break;
        }

        return result;
    };

    useEffect(() => {
        if (!form?.signatureDate) {
            props.onChange({ type: form.type, errors: form.errors }, "statement");
        }
    }, [form.type]);

    return applicationType !== "termination" ? (
        <Form {...formProps}>
            <Select
                optionList={filteredCertificateOptions()}
                selectLabel="Mellékelt igazolás"
                name="type"
                format={{ xs: 12 }}
                value={form.type || ""}
            />
            {certificateOptions.findIndex((e) => e.value === form.type) === -1 ? (
                <Typography format={{ xs: 12 }}>Kérem, válasszon igazolást!</Typography>
            ) : (
                forms[certificateOptions.findIndex((e) => e.value === form.type)](formProps)
            )}
        </Form>
    ) : (
        <Form {...formProps}>{optionOne(formProps)}</Form>
    );
}
