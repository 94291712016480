import { CardContent, CardHeader, makeStyles } from "@material-ui/core";
import CardBasic from "@material-ui/core/Card";
import React from "react";
import colors from "../../styles/colors";

const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
        padding: "7px",
    },
    titleRoot: {
        fontWeight: "bold",
    },
    fullWidth: {
        width: "100%",
    },
    editHeader: {
        background: colors.edit,
    },
}));

export default function Card(props) {
    const classes = useStyles();

    return (
        <CardBasic className={props.fullWidth && classes.fullWidth}>
            <CardHeader
                title={props.title}
                className={`${classes.header} ${props.edit ? classes.editHeader : ""}`}
                classes={{
                    title: classes.titleRoot,
                }}
            />
            <CardContent>{props.children}</CardContent>
        </CardBasic>
    );
}
