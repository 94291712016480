import { Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import GDPRActions from "../../../store/GDPR/actions";
import notificationActions from "../../../store/notification/actions";
import colors from "../../../styles/colors";
import { APPLICATION_STATUS_OPTIONS } from "../../../utils/AppConst";
import BarCodeCellRenderer from "../../../utils/cellRenderer/BarCodeCellRenderer";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import Autocomplete from "../../Common/Autocomplete";
import CancelButton from "../../Common/CancelButton";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import "./styles.css";
import {
    BtnCellRenderer,
    IsDeletedCellRenderer,
    isDeletedOptions,
    PermissionToCellRenderer,
    StatusCellRenderer,
} from "./utils";

const useStyles = makeStyles(() => ({
    statusHeader: {
        fontSize: "large",
        fontWeight: "bold",
    },
    greenText: {
        color: colors.green,
    },
    redText: {
        color: colors.red,
    },
}));

const INFO_TEXT = "A GDPR felülvizsgálat oldalon az ügyfelekhez tartozó 10 évnél régebbi igénylőlapok jelennek meg.";

function GDPR(props) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, cachedFilter, filterStorageKey } = props;
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [total, setTotal] = useState(null);
    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(null);
    const [remaining, setRemaining] = useState(null);
    const classes = useStyles();

    const handleDeleteApplication = (id) => {
        props.addNotification("info", "Törlés folyamatban...");
        props
            .deleteApplication(id)
            .then(() => props.addNotification("success", "Sikeres törlés!"))
            .then(() => setInfo(props, "filter", filter))
            .catch((error) => {
                props.addNotification("error", error?.response?.data?.detail || "Hiba lépett fel törlés közben.");
            });
    };

    const handleCloseDialog = () => {
        setStatusDialogOpen(false);
        setErrors(null);
        setTotal(null);
        setSuccess(null);
        setRemaining(null);
        filterData();
    };

    const deleteFilteredApplicationsHandler = () => {
        setStatusDialogOpen(true);
        props
            .deleteFilteredApplications(cachedFilter)
            .then((response) => sendGetLogStatus(response))
            .catch((error) => {
                props.addNotification("error", error?.response?.data?.detail || "Hiba lépett fel törlés közben.");
                setStatusDialogOpen(false);
            });
    };

    const sendGetLogStatus = (response, sessionId = null) => {
        if (sessionId === null) {
            sessionId = response.data?.sessionId;
        }

        if (sessionId) {
            props
                .getLogStatus(sessionId)
                .then((response) => handleGetLogStatus(response, sessionId))
                .catch(() => {
                    props.addNotification("error", "Hiba lépett fel törlés közben.");
                    setStatusDialogOpen(false);
                });
        } else {
            props.addNotification("error", "Hiba lépett fel törlés közben.");
            setStatusDialogOpen(false);
        }
    };

    const handleGetLogStatus = async (response, sessionId) => {
        const info = response.data?.newData;
        const resErrors = info?.numberOfApplicationsNotRemoved?.value;
        const resTotal = info?.numberOfApplicationsToBeRemoved?.value;
        const resSuccess = info?.numberOfApplicationsRemoved?.value;
        const resRemaining = resTotal - (resErrors + resSuccess);

        setErrors(resErrors);
        setTotal(resTotal);
        setSuccess(resSuccess);
        setRemaining(resRemaining);

        if (resRemaining !== 0) {
            await sleep(2000);
            sendGetLogStatus(response, sessionId);
        }
    };

    const sleep = (ms) => {
        return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const filterData = () => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setInfo({ ...props, search: props.firstFetch }, "filter", storageFilter);
    };

    useEffect(() => {
        filterData();
    }, []);

    const table = {
        columnDefs: [
            {
                headerName: "",
                field: "",
                maxWidth: 10,
                cellStyle: (params) => {
                    if (params?.data?.isDeleted) {
                        return {
                            background: colors.menuGroup, //szürke
                            paddingLeft: 0,
                            paddingRight: 0,
                        };
                    }
                    switch (params?.data?.type) {
                        case "application":
                            return {
                                background: colors.green,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "modification":
                            return {
                                background: colors.edit,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "termination":
                            return {
                                background: colors.red,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        default:
                            break;
                    }
                },
            },
            {
                headerName: "Vonalkód",
                field: "barCode",
                cellRenderer: "barCodeCellRenderer",
                minWidth: 130,
                maxWidth: 130,
            },
            {
                headerName: "Érkeztetési vonalkód",
                field: "arrivalBarCode",
                minWidth: 190,
            },
            {
                headerName: "Mavir ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
                minWidth: 140,
                maxWidth: 140,
            },
            {
                headerName: "Státusz",
                field: "status",
                cellRenderer: "statusCellRenderer",
                minWidth: 120,
                maxWidth: 120,
            },
            { headerName: "Ügyfél neve", field: "customerName" },
            {
                headerName: "Jogosultság kezdete",
                field: "permissionFrom",
                minWidth: 190,
                maxWidth: 190,
            },
            {
                headerName: "Jogosultság vége",
                field: "permissionTo",
                cellRenderer: "permissionToCellRenderer",
                minWidth: 180,
                maxWidth: 180,
            },
            {
                headerName: "Létrehozás dátuma",
                field: "createdAt",
                minWidth: 190,
                maxWidth: 190,
            },
            {
                headerName: "Sztornózva",
                field: "isDeleted",
                cellRenderer: "isDeletedCellRenderer",
                minWidth: 140,
                maxWidth: 140,
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 120,
            },
        ],
        loading: tableLoading,
        autoSize: "true",
        rowData: data,
        rowClassRules: {
            "deleted-application": (params) => params?.data?.isDeleted,
        },
        frameworkComponents: {
            btnCellRenderer: (params) => BtnCellRenderer(params, handleDeleteApplication),
            barCodeCellRenderer: BarCodeCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
            isDeletedCellRenderer: IsDeletedCellRenderer,
            permissionToCellRenderer: PermissionToCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
        deleteButtonText: "Találatok törlése",
        deleteButtonCommand: () => deleteFilteredApplicationsHandler(),
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    return (
        <ListPageTemplate
            header={{
                title: "GDPR felülvizsgálat",
                breadcrumbs: {
                    operators: "Operátorok",
                    gdpr: "GDPR felülvizsgálat",
                },
            }}
            filter={{
                onSubmit: () => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    setInfo(props, "filter", {});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
            statPageDetail={INFO_TEXT}
        >
            <TextField label="Vonalkód" name="barCode" value={filter.barCode || ""} format={{ xs: 12, md: 6 }} />
            <TextField
                label="Érkeztetési vonalkód"
                name="arrivalBarCode"
                value={filter.arrivalBarCode || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField label="Mavir ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 6 }} />
            <TextField
                label="Ügyfél neve"
                name="customerName"
                value={filter.customerName || ""}
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                label="Státusz"
                value={filter.status || []}
                options={APPLICATION_STATUS_OPTIONS}
                name="status"
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                label="Sztornózva"
                name="isDeleted"
                value={filter.isDeleted || []}
                format={{ xs: 12, md: 6 }}
                options={isDeletedOptions}
            />
            <TextField
                label="Jogosultság kezdete (-tól)"
                type="date"
                name="permissionFromStart"
                value={filter.permissionFromStart || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Jogosultság kezdete (-ig)"
                type="date"
                name="permissionFromEnd"
                value={filter.permissionFromEnd || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Jogosultság vége (-tól)"
                type="date"
                name="permissionToStart"
                value={filter.permissionToStart || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Jogosultság vége (-ig)"
                type="date"
                name="permissionToEnd"
                value={filter.permissionToEnd || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Létrehozás dátuma (-tól)"
                type="date"
                name="createdAtFrom"
                value={filter.createdAtFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Létrehozás dátuma (-ig)"
                type="date"
                name="createdAtTo"
                value={filter.createdAtTo || ""}
                format={{ xs: 12, md: 6 }}
            />
            <Dialog open={statusDialogOpen}>
                <DialogContent>
                    <DialogContentText className={classes.statusHeader}>
                        {remaining === 0 ? "A törlési folyamat véget ért!" : "Az igénylések törlése folyamatban van…"}
                    </DialogContentText>
                    {total ? (
                        <>
                            <DialogContentText>{`Hátralévő törlendő igénylések: ${remaining} darab`}</DialogContentText>
                            <DialogContentText
                                className={classes.greenText}
                            >{`Sikeresen törölt igénylések: ${success} darab`}</DialogContentText>
                            <DialogContentText
                                className={classes.redText}
                            >{`Sikertelenül törölt igénylések: ${errors} darab`}</DialogContentText>
                            {remaining === 0 ? (
                                <DialogActions>
                                    <CancelButton onClick={handleCloseDialog}>{"Bezárás"}</CancelButton>
                                </DialogActions>
                            ) : (
                                <LinearProgress />
                            )}
                        </>
                    ) : (
                        <LinearProgress />
                    )}
                </DialogContent>
            </Dialog>
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, cachedFilter, filterStorageKey } = state.GDPR;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        tableInfo,
        token,
        cachedFilter,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: GDPRActions.changeForm,
    defaultForm: GDPRActions.defaultForm,
    search: GDPRActions.filter,
    firstFetch: GDPRActions.firstFetch,
    getLogStatus: GDPRActions.getLogStatus,
    exportInitiate: GDPRActions.exportInitiate,
    exportCheck: GDPRActions.exportCheck,
    exportDownload: GDPRActions.exportDownload,
    deleteApplication: GDPRActions.deleteApplication,
    deleteFilteredApplications: GDPRActions.deleteFilteredApplications,
    addNotification: notificationActions.addNotification,
    setInfo: GDPRActions.setInfo,
};

export default connect(mapState, actionCreators)(GDPR);
