import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import colors from "../../../styles/colors";
import Card from "../../Common/Card";
import CancelButton from "../../Common/CancelButton";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { history } from "../../../store";
import { connect } from "react-redux";
import employmentTerminationsActions from "../../../store/employmentTerminations/actions";
import notificationActions from "../../../store/notification/actions";
import PageTemplate from "../../Common/PageTemplate";

const useStyles = makeStyles((theme) => ({
    thead: {
        fontWeight: 550,
        backgroundColor: colors.primary,
        color: colors.white,
    },
    visible: {
        marginTop: theme.spacing(2),
    },
    nextButton: {
        margin: theme.spacing(1),
    },
}));

function AddForm(props) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const { form, loading, checkCustomerData } = props;

    useEffect(() => {
        props.clearForm();
    }, []);

    const handleSearch = () => {
        props
            .checkCustomer(form.mavirId)
            .then(() => setVisible(true))
            .catch(() => {
                setVisible(false);
                props.addNotification("error", "Nincs ilyen ügyfél.");
            });
    };

    const handleSave = () => {
        props
            .create(form)
            .then(() => {
                props.addNotification("success", "Sikeres mentés!", "/employment-terminations");
            })
            .catch((e) => {
                if (e.validationErrors) {
                    props.changeForm({ ...form, errors: e.validationErrors }, "form");
                    props.addNotification("error", "Kérjük ellenőrizze az adatokat");
                } else {
                    props.addNotification("error", "Hiba a mentés során!");
                }
            });
    };

    const customerName = (data) => {
        if (data.title) {
            return data.title + " " + data.lastName + " " + data.firstName;
        }

        return data.lastName + " " + data.firstName;
    };

    const disableSubmit = form?.mavirId === undefined || form?.mavirId === null;

    return (
        <PageTemplate
            header={{
                title: "Új űrlap felvitele",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    "/employment-terminations": "Űrlapok",
                    "add-form": "Új űrlap felvitele",
                },
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card className={classes.cardClass} title="Megszüntetés bejelentése">
                    <Form form={form} onSubmit={handleSearch} changeform={props.changeForm} name="form">
                        <TextField label="MAVIR ID" name="mavirId" value={form.mavirId || ""} format={{ xs: 12 }} />
                        <TextField
                            label="Munkaviszony vége"
                            name="terminatedAt"
                            value={form.terminatedAt || ""}
                            format={{ xs: 12 }}
                            InputLabelProps={{ shrink: true }}
                            type="date"
                        />
                        <TextField
                            label="Megszűntetés oka"
                            name="reason"
                            value={form.reason || ""}
                            format={{ xs: 12 }}
                        />
                        <SubmitButton disabled={disableSubmit} type="submit">
                            Ellenőrzés
                        </SubmitButton>
                        <CancelButton onClick={() => history.push(`/employment-terminations`)}>Vissza</CancelButton>
                    </Form>
                </Card>
            </Grid>
            {visible && (
                <Grid item xs={12}>
                    <Card title="Alapadatok ellenőrzése">
                        <h3>Kérem ellenőrizze, hogy az ügyfél megfelel-e a bejelenteni kívánttal!</h3>
                        <Form
                            form={checkCustomerData || []}
                            onSubmit={handleSave}
                            changeform={props.changeForm}
                            name="form"
                        >
                            <TextField
                                label="MAVIR ID"
                                name="mavirId"
                                value={checkCustomerData ? checkCustomerData.mavirId : ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Ügyfél neve"
                                name="clientsName"
                                value={checkCustomerData ? customerName(checkCustomerData) : ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Anyja neve"
                                name="mothersName"
                                value={
                                    checkCustomerData
                                        ? checkCustomerData.motherLastName + " " + checkCustomerData.motherFirstName
                                        : ""
                                }
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Születési hely"
                                name="birthPlace"
                                value={checkCustomerData ? checkCustomerData.birthPlace : ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Születési idő"
                                name="birthDate"
                                value={checkCustomerData ? checkCustomerData.birthDate : ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <SubmitButton type="submit">Űrlap beadása</SubmitButton>
                            <CancelButton onClick={() => setVisible(false)}>Mégse</CancelButton>
                        </Form>
                    </Card>
                </Grid>
            )}
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading, checkCustomerData } = state.employmentTerminations;
    return { form, loading, checkCustomerData };
}

const actionCreators = {
    changeForm: employmentTerminationsActions.changeForm,
    create: employmentTerminationsActions.create,
    defaultForm: employmentTerminationsActions.defaultForm,
    clearForm: employmentTerminationsActions.clearForm,
    checkCustomer: employmentTerminationsActions.checkCustomer,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(AddForm);
