import React from "react";
import TextMenuItem from "../Common/TextMenuItem";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import colors from "../../styles/colors.js";

/**
 * Főmenücsoport
 * @param text - csoport neve
 * @param children - menüpontok (fontos, hogy egy blokkban kell megadni)
 */

export default function Topic(props) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <React.Fragment key={props.text}>
            <TextMenuItem
                style={{ backgroundColor: colors.menuGroup, color: colors.white }}
                onClick={handleClick}
                {...props}
            >
                <Typography variant="inherit">{props.text}</Typography>
                {open ? <ExpandLess /> : <ExpandMore />}
            </TextMenuItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>
        </React.Fragment>
    );
}
