import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/statistics/application-consumption-places-by-contact", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const applicationConsumptionPlacesByContactService = {
    filter,
    exportInitiate: templateExportInitiate("statistics/application-consumption-places-by-contact"),
    exportCheck: templateExportCheck("statistics/application-consumption-places-by-contact"),
    exportDownload: templateExportDownload("statistics/application-consumption-places-by-contact"),
};

export default applicationConsumptionPlacesByContactService;
