import { CardActions, CardContent, CardHeader, makeStyles } from "@material-ui/core";
import CardBasic from "@material-ui/core/Card";
import React from "react";
import colors from "../../styles/colors";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: colors.primary,
        color: colors.white,
        padding: "7px",
    },
    titleRoot: {
        fontWeight: "bold",
    },
    fullWidth: {
        width: "100%",
        height: "100%",
    },
    saveButton: {
        marginTop: theme.spacing(-2),
        marginLeft: theme.spacing(1),
        background: colors.edit,
        "&:hover": {
            background: colors.editHover,
        },
    },
    cancelButton: {
        marginTop: theme.spacing(-2),
        borderColor: colors.edit,
        color: colors.edit,
        "&:hover": {
            background: colors.editHover,
            borderColor: colors.editHover,
            color: colors.white,
        },
    },
    editHeader: {
        background: colors.edit,
    },
    addNewHeader: {
        background: colors.green,
    },
}));

export default function EditableCard(props) {
    const { edit, addNew } = props;
    const classes = useStyles();

    return (
        <CardBasic className={props.fullWidth && classes.fullWidth}>
            <CardHeader
                title={props.title}
                action={props.action}
                className={`${classes.header} ${edit ? classes.editHeader : ""} ${addNew ? classes.addNewHeader : ""}`}
                classes={{
                    title: classes.titleRoot,
                }}
            />
            <CardContent>{props.children}</CardContent>
            {edit && (
                <CardActions>
                    <SubmitButton onClick={props.onSubmit} className={classes.saveButton}>
                        Mentés
                    </SubmitButton>
                    <CancelButton onClick={props.onCancel} className={classes.cancelButton}>
                        Mégse
                    </CancelButton>
                </CardActions>
            )}
        </CardBasic>
    );
}
