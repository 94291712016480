import React, { useEffect } from "react";
import PageTemplate from "../../Common/PageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import consumptionDataSummaryActions from "../../../store/consumptionDataSummary/actions";
import Autocomplete from "../../Common/Autocomplete";
import Filter from "../../Common/Filter";
import Table from "../../Common/Table";
import SubmitButton from "../../Common/SubmitButton";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import NumberFormatCellRenderer from "../../../utils/cellRenderer/NumberFormatCellRenderer";
import {
    FILTER_MONTH_OPTIONS,
    YEAR_OPTIONS,
    INVOICE_OPTIONS_DATA_SUMMARY,
    numberFormat,
} from "../../../utils/AppConst";
import { setInfo } from "../../../utils/FilterHelper";
import axios from "axios";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function ConsumerPriceCellRenderer(params) {
    return <strong>{numberFormat(params.value)}</strong>;
}

function ConsumptionDataSummary(props) {
    const {
        filter,
        cachedFilter,
        pageLoading,
        summaryTableLoading,
        detailsTableLoading,
        dataSummary,
        dataDetails,
        detailsTableInfo,
        providers,
        employers,
        token,
        showSummaryTable,
        showDetailsTable,
        filterStorageKey,
    } = props;

    const summaryTable = {
        columnDefs: [
            {
                headerName: "Mennyiség (kWh)",
                field: "quantity",
                cellRenderer: "numberFormatCellRenderer",
                sortable: false,
            },
            {
                headerName: "Bruttó alapár (Ft)",
                field: "basePrice",
                cellRenderer: "numberFormatCellRenderer",
                sortable: false,
            },
            {
                headerName: "Bruttó fogyasztói ár (Ft)",
                field: "consumerPrice",
                cellRenderer: "consumerPriceCellRenderer",
                sortable: false,
            },
            {
                headerName: "Bruttó finanszírozói ár (Ft)",
                field: "financierPrice",
                cellRenderer: "numberFormatCellRenderer",
                sortable: false,
            },
        ],
        frameworkComponents: {
            numberFormatCellRenderer: NumberFormatCellRenderer,
            consumerPriceCellRenderer: ConsumerPriceCellRenderer,
        },
        loading: summaryTableLoading,
        rowData: dataSummary,
        title: "Összesítő",
        noExportButton: true,
        hidePagination: true,
    };

    const detailsTable = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Év", field: "year" },
            { headerName: "Hónap", field: "month" },
            {
                headerName: "Mennyiség (kWh)",
                field: "quantity",
                cellRenderer: "numberFormatCellRenderer",
            },
            {
                headerName: "Alapár (Ft)",
                field: "basePrice",
                cellRenderer: "numberFormatCellRenderer",
            },
            {
                headerName: "Fogyasztói ár (Ft)",
                field: "consumerPrice",
                cellRenderer: "numberFormatCellRenderer",
            },
            {
                headerName: "Finanszírozói ár (Ft)",
                field: "financierPrice",
                cellRenderer: "numberFormatCellRenderer",
            },
        ],
        frameworkComponents: {
            numberFormatCellRenderer: NumberFormatCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        loading: detailsTableLoading,
        title: "Részletezés",
        isPaginated: true,
        filterData: filter,
        searchfunc: props.filterDetails,
        info: detailsTableInfo,
        setInfo: props.setDetailsInfo,
        rowData: dataDetails,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            info: detailsTableInfo,
            filter: filter,
            token: token,
        },
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"),
            props.getEmployers(),
            props.getProviders()
        ]).then(
            axios.spread(() => {
                props.setPageLoading(false);
            })
        );
    }, []);

    const employerOptions = employers.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
        };
    });

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    return (
        <PageTemplate
            header={{
                title: "Fogyasztási adatok összesítő",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "consumption-data-summary": "Fogyasztási adatok összesítő",
                },
            }}
            loading={pageLoading}
        >
            <Filter
                onSubmit={() => {
                    filterStorageSave(filterStorageKey, filter);
                    setInfo(
                        {
                            ...props,
                            search: props.filterSummary,
                            setInfo: props.setDetailsInfo,
                        },
                        "filter",
                        filter
                    );
                }}
                defaultFormAction={() => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                }}
                form={filter}
                name="filter"
                changeform={props.changeForm}
            >
                <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 3 }} />
                <Autocomplete
                    value={filter.provider || []}
                    options={providerOptions}
                    id="provider"
                    name="provider"
                    format={{ xs: 12, md: 3 }}
                    label="Szolgáltató"
                />
                <Autocomplete
                    value={filter.year || []}
                    options={YEAR_OPTIONS}
                    id="year"
                    name="year"
                    format={{ xs: 12, md: 2 }}
                    label="Év"
                />
                <Autocomplete
                    value={filter.month || []}
                    options={FILTER_MONTH_OPTIONS}
                    id="month"
                    name="month"
                    label="Hónap"
                    format={{ xs: 12, md: 2 }}
                />
                <Autocomplete
                    value={filter.invoice || []}
                    options={INVOICE_OPTIONS_DATA_SUMMARY}
                    id="invoice"
                    name="invoice"
                    label="Számla típusa"
                    format={{ xs: 12, md: 2 }}
                />
                <TextField
                    label="Ügyfél neve"
                    name="clientsName"
                    value={filter.clientsName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    value={filter.financier || []}
                    options={employerOptions}
                    id="financier"
                    name="financier"
                    label="Finanszírozó"
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Kiállítás dátuma (-tól)"
                    name="issueDateFrom"
                    value={filter.issueDateFrom || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Kiállítás dátuma (-ig)"
                    name="issueDateTo"
                    value={filter.issueDateTo || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Elszámolt időszak kezdete (-tól)"
                    name="accountingStartFrom"
                    value={filter.accountingStartFrom || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Elszámolt időszak kezdete (-ig)"
                    name="accountingStartTo"
                    value={filter.accountingStartTo || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Elszámolt időszak vége (-tól)"
                    name="accountingEndFrom"
                    value={filter.accountingEndFrom || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Elszámolt időszak vége (-ig)"
                    name="accountingEndTo"
                    value={filter.accountingEndTo || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
            </Filter>
            {showSummaryTable && (
                <Table
                    submitButton={
                        <SubmitButton
                            disabled={summaryTableLoading}
                            onClick={() => {
                                setInfo(
                                    {
                                        ...props,
                                        search: showDetailsTable ? props.filterDetails : props.filterFirstDetails,
                                        setInfo: props.setDetailsInfo,
                                    },
                                    "filter",
                                    cachedFilter
                                );
                            }}
                        >
                            Részletezés
                        </SubmitButton>
                    }
                    {...summaryTable}
                />
            )}
            {showDetailsTable && <Table {...detailsTable}></Table>}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        filter,
        cachedFilter,
        pageLoading,
        summaryTableLoading,
        detailsTableLoading,
        dataSummary,
        dataDetails,
        detailsTableInfo,
        token,
        showSummaryTable,
        showDetailsTable,
        filterStorageKey,
    } = state.consumptionDataSummary;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return {
        filter,
        cachedFilter,
        pageLoading,
        summaryTableLoading,
        detailsTableLoading,
        dataSummary,
        dataDetails,
        detailsTableInfo,
        employers,
        providers,
        token,
        showSummaryTable,
        showDetailsTable,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: consumptionDataSummaryActions.changeForm,
    defaultForm: consumptionDataSummaryActions.defaultForm,
    setPageLoading: consumptionDataSummaryActions.setPageLoading,
    filterSummary: consumptionDataSummaryActions.filterSummary,
    filterDetails: consumptionDataSummaryActions.filterDetails,
    filterFirstDetails: consumptionDataSummaryActions.filterFirstDetails,
    setDetailsInfo: consumptionDataSummaryActions.setDetailsInfo,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    exportInitiate: consumptionDataSummaryActions.exportInitiate,
    exportCheck: consumptionDataSummaryActions.exportCheck,
    exportDownload: consumptionDataSummaryActions.exportDownload,
};

export default connect(mapState, actionCreators)(ConsumptionDataSummary);
