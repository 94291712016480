import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateGet,
} from "..";
import systemLogService from "../../services/systemLog";
import { types } from "./types";

function getTypesAndEvents() {
    return (dispatch) => {
        dispatch({ type: types.GET_TYPES_AND_EVENTS_REQUEST });
        return systemLogService.getTypesAndEvents().then(
            (data) => {
                dispatch({ type: types.GET_TYPES_AND_EVENTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_TYPES_AND_EVENTS_FAILURE });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types, systemLogService),
    filter: templateFilter(types, systemLogService),
    firstFetch: templateFirstFetch(types, systemLogService),
    get: templateGet(types, systemLogService),
    exportInitiate: templateExportInitiate(types, systemLogService),
    exportCheck: templateExportCheck(types, systemLogService),
    exportDownload: templateExportDownload(types, systemLogService),
    getTypesAndEvents,
    setInfo,
};

export default actions;
