import React, { useEffect, useState } from "react";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import filesListActions from "../../../store/filesList/actions";
import providersActions from "../../../store/providers/actions"
import ListPageTemplate from "../../Common/ListPageTemplate";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import CancelButton from "../../Common/CancelButton";
import SubmitButton from "../../Common/SubmitButton";
import Select from "../../Common/Select";
import OperationButton from "../../Common/OperationButton";
import notificationActions from "../../../store/notification/actions";
import moment from "moment";
import { hasRole, hasServiceProvider } from "../../../utils/User";
import { setInfo } from "../../../utils/FilterHelper";
import StatusCellRenderer from "../../../utils/cellRenderer/FileStatusCellRenderer";
import colors from "../../../styles/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY.MM.DD. HH:mm");
}

function FilesList(props) {
    const { filter, data, pageLoading, tableLoading, token, fileLog, tableInfo, providers, filterStorageKey } = props;
    const [open, setOpen] = useState(false);
    const [info, showInfo] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleOpen = () => {
        setOpen(true);
        setDisableSubmit(true);
    };

    const handleClose = () => setOpen(false);

    const changeHandler = (event) => {
        if ([...event.target.files].filter((file) => file.name.slice(-4) !== ".csv").length === 0) {
            setSelectedFile([...event.target.files]);
            setDisableSubmit(false);
        } else {
            props.addNotification("error", "Kérem .csv kiterjesztésű fájlt adjon meg!");
            props.changeForm({ ...filter, file: null }, "filter");
            setSelectedFile(null);
            setDisableSubmit(true);
        }
    };

    const handleCancel = (id) => {
        props
            .cancel(id)
            .then(() => props.addNotification("success", "Sikeres sztornózás!"))
            .then(() => props.search(filter))
            .catch(() => props.addNotification("error", "Hiba lépett fel sztornózás közben."));
    };

    const handleDownload = (id, fileName) => {
        props
            .download(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleDownloadReply = (id, fileName) => {
        props
            .downloadReply(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleSubmit = (e) => {
        handleClose();
        e.preventDefault();
        if (selectedFile?.length > 0) {
            selectedFile.map((file) => {
                const formData = new FormData();
                formData.append("file", file);
                props
                    .upload(formData)
                    .then((response) => {
                        const viols = response.data.violations || [];
                        if (viols.length > 0) {
                            let prevErrors = [];
                            viols.map((viol) => {
                                const property = viol.property;
                                const message = viol.message;
                                if (!prevErrors) prevErrors = [];
                                props.changeForm(
                                    {
                                        ...filter,
                                        errors: [
                                            ...prevErrors,
                                            {
                                                name: property,
                                                label: message,
                                            },
                                        ],
                                    },
                                    "filter"
                                );
                                prevErrors.push({
                                    name: property,
                                    label: message,
                                });
                                props.addNotification("error", viol.message);
                            });
                        } else {
                            props.addNotification("success", "Sikeres feltöltés!");
                            handleClose();
                        }
                    })
                    .then(() => props.search(filter))
                    .catch((error) =>
                        props.addNotification(
                            "error",
                            error?.response?.data?.violations[0]?.message || "Hiba lépett fel feltöltés közben."
                        )
                    );
            });
        }
    };

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="download"
                    onClick={() => handleDownload(params.data?.id, params.data?.fileName)}
                />
                {(params.data.status !== "import_finished" || params.data.status !== "import_initiated") && (
                    <OperationButton
                        type="download-response"
                        onClick={() => handleDownloadReply(params.data.id, params.data.replyFileName)}
                    />
                )}
                {params.data.status === "import_finished" && (
                    <OperationButton
                        type="cancel"
                        confirmation={`Biztosan sztornózza a "${params.data.fileName}" nevű fájlt?`}
                        submittext="Igen"
                        handleClick={() => handleCancel(params.data.id)}
                    />
                )}
                <OperationButton
                    type="info"
                    onClick={() => {
                        props.getLog(params.data.id);
                        showInfo(true);
                    }}
                />
            </>
        );
    }

    const getColor = (status) => {
        switch (status) {
            case "import_processing":
            case "import_row_counting":
            case "import_row_validation":
            case "import_row_validate_invoice_serial_number":
            case "import_row_saving":
                return colors.next;
            case "import_finished":
                return colors.green;
            default:
                return colors.primary;
        }
    };

    const colorCell = (params) => {
        const status = params?.data?.status || "error";
        return {
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: status.includes("error") ? colors.error : getColor(params.data.status),
        };
    };

    const table = {
        columnDefs: [
            { headerName: "", field: "", maxWidth: 10, cellStyle: colorCell },
            { headerName: "File név", field: "fileName" },
            {
                headerName: "Állapot",
                field: "status",
                cellRenderer: "statusCellRenderer",
            },
            {
                headerName: "Feltöltve",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
            },
            { headerName: "Feltöltő", field: "createdBy.name" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
        newButtonText: hasServiceProvider() ? "Feltöltés" : null,
        newButtonIcon: <CloudUploadIcon />,
        to: "#",
        onClick: handleOpen,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setInfo({...props, search: props.firstFetch}, "filter", storageFilter);
        props.getProviders();
    }, []);

    const providerOptions = providers?.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    return (
        <ListPageTemplate
            header={{
                title: "Fájlok listája",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "files-list": "Fájlok listája",
                },
            }}
            filter={{
                onSubmit: () => {
                    filterStorageSave(filterStorageKey, filter);
                    setInfo(props, "filter", filter)
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    props.search({});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
        >
            <TextField label="Fájl neve" name="fileName" value={filter.fileName || ""} format={{ xs: 12, md: 4 }} />
            <TextField
                label="Feltöltés dátuma (-tól)"
                name="uploadedAtStart"
                value={filter.uploadedAtStart || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Feltöltés dátuma (-ig)"
                name="uploadedAtEnd"
                value={filter.uploadedAtEnd || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            {(!hasRole("ROLE_ADMIN") && hasServiceProvider()) && (
                <TextField label="Szolgáltató" disabled value={hasServiceProvider().name} format={{ xs: 12 }} />
            )}
            {hasRole("ROLE_ADMIN") && 
                <Select
                    value={filter.serviceProvider || ""}
                    optionList={providerOptions}
                    id="serviceProvider"
                    name="serviceProvider"
                    selectLabel="Szolgáltató"
                    format={{ xs: 12 }}
                />
            }
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Fájl feltöltése</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Fájl"
                        type="file"
                        name="file"
                        format={{ xs: 12 }}
                        onChange={changeHandler}
                        inputProps={{ multiple: true, accept: ".csv" }}
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} disabled={disableSubmit}>
                        Feltöltés
                    </SubmitButton>
                    <CancelButton onClick={handleClose}>Mégsem</CancelButton>
                </DialogActions>
            </Dialog>
            <Dialog open={info && fileLog != null}>
                <DialogTitle>Információk</DialogTitle>
                <DialogContent>{getInfoTable(fileLog)}</DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => showInfo(false)}>Bezárás</CancelButton>
                </DialogActions>
            </Dialog>
        </ListPageTemplate>
    );
}

function getInfoTable(data) {
    if (data === null || !Array.isArray(data) || data.length === 0) {
        return <i>Nincs naplózott információ</i>;
    }

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Dátum</TableCell>
                    <TableCell>Felhasználó</TableCell>
                    <TableCell>Művelet</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((d) => {
                    return (
                        <TableRow>
                            <TableCell>{d.date}</TableCell>
                            <TableCell>{d.user ? d.user.name + " / " + d.user.email : "-"}</TableCell>
                            <TableCell>{d.action}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, form, token, fileLog, tableInfo, filterStorageKey } = state.filesList;
    const providers = state.providers.data;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        form,
        token,
        fileLog,
        tableInfo,
        providers,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: filesListActions.changeForm,
    search: filesListActions.filter,
    firstFetch: filesListActions.firstFetch,
    defaultForm: filesListActions.defaultForm,
    cancel: filesListActions.cancel,
    download: filesListActions.download,
    downloadReply: filesListActions.downloadReply,
    upload: filesListActions.upload,
    addNotification: notificationActions.addNotification,
    exportInitiate: filesListActions.exportInitiate,
    exportCheck: filesListActions.exportCheck,
    exportDownload: filesListActions.exportDownload,
    getProviders: () => providersActions.filter({}),
    getLog: filesListActions.getLog,
    setInfo: filesListActions.setInfo,
};

export default connect(mapState, actionCreators)(FilesList);
