import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import AgGrid from "./AgGrid";
import Tooltip from "./Tooltip";

/**
 * olyan mint a table csak nem accordion alapú, pl. Card komponenssel lehet használni
 */

const useStyles = makeStyles(() => ({
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

function TooltipRenderer(params) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const value = params.value === 0 ? "0" : params.value;

    return (
        <Tooltip title={value ? value : ""}>
            <div className={classes.truncate}>{value ? value : ""}</div>
        </Tooltip>
    );
}

export default function CardTable(props) {
    const paginationPageSize = useState(25);
    const defaultColDef = {
        suppressMovable: true,
        flex: 1,
        sortable: typeof props.sortable !== typeof undefined ? props.sortable : true,
        minWidth: props.minWidth ? props.minWidth : 125,
        cellRenderer: "tooltipRenderer",
    };

    const frameworkComponents = props.frameworkComponents || {};

    frameworkComponents.tooltipRenderer = TooltipRenderer;

    return (
        <Grid item container xs={12}>
            <div className="ag-theme-material agGridTable" style={{ width: "100%" }}>
                <AgGrid
                    pagination={
                        props.showPagination === true || props.showPagination === undefined
                            ? true
                            : props.showPagination
                    }
                    paginationPageSize={
                        props.showPagination === true || props.showPagination === undefined ? paginationPageSize : false
                    }
                    domLayout="autoHeight"
                    defaultColDef={defaultColDef}
                    frameworkComponents={frameworkComponents}
                    enableCellTextSelection={true}
                    {...props}
                ></AgGrid>
            </div>
            {props.children}
        </Grid>
    );
}
