import React, { useEffect, useState } from "react";
import PageTemplate from "../../Common/PageTemplate";
import Address from "./cards/Address";
import BasicData from "./cards/BasicData";
import Contact from "./cards/Contact";
import PersonalDetails from "./cards/PersonalDetails";
import TaxStatus from "./cards/TaxStatus";
import Permissions from "./cards/Permissions";
import FurtherApplications from "./cards/FurtherApplications";
import clientDetailsActions from "../../../store/clientDetails/actions";
import { connect } from "react-redux";
import { isAdmin } from "../../../utils/User";

import { Grid, makeStyles } from "@material-ui/core";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import axios from "axios";
import notificationActions from "../../../store/notification/actions";
import ReasonDialog from "./dialogs/ReasonDialog";

const useStyles = makeStyles(() => ({
    editButton: {
        position: "absolute",
        right: "30px",
        zIndex: 100,
    },
    button: {
        marginRight: "5px",
    },
}));

function cancelSave(setEdit) {
    setEdit(false);
}

function ClientDetails(props) {
    const classes = useStyles();
    const { form, loading, data, contacts, loadingChangesLog, changesLog, validation } = props;
    const id = props.match.params.id;
    const [openDialog, setOpenDialog] = useState(false);
    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (isAdmin()) {
            axios.all([props.getData(id), props.getContacts(), props.getValidationUpdate()]).then(
                axios.spread(() => {
                    props.setLoading(false);
                })
            );
        } else {
            axios.all([props.getData(id), props.getContacts()]).then(
                axios.spread(() => {
                    props.setLoading(false);
                })
            );
        }
    }, [id]);

    const handleSubmit = (id, form) => {
        props
            .saveForm(id, form)
            .then((response) => {
                setEdit(false);
                props.setData(response.data.data);
                props.addNotification("success", "Sikeres mentés!");
            })
            .catch((e) => {
                if (e.validationErrors) {
                    props.changeForm({ ...form, errors: e.validationErrors }, "form");
                    props.addNotification("error", "Kérjük ellenőrizze az adatokat");
                } else {
                    props.addNotification("error", "Hiba a mentés során!");
                }
            });
    };

    return (
        <React.Fragment>
            <PageTemplate
                loading={loading}
                header={{
                    title: "Ügyfél adminisztrációja",
                    breadcrumbs: {
                        "/clients": "Ügyfelek",
                        "client-administration": "Ügyfél adminisztrációja",
                    },
                }}
            >
                {!edit && isAdmin() && (
                    <SubmitButton
                        className={classes.editButton}
                        format={{ xs: 12 }}
                        type="submit"
                        onClick={() => {
                            props.clearForm();
                            props.setFormData(data);
                            setOpenDialog(true);
                        }}
                    >
                        Szerkesztés
                    </SubmitButton>
                )}

                <Grid item xs={12}>
                    <BasicData
                        name="form"
                        form={form}
                        mavirID={id}
                        changeForm={props.changeForm}
                        getChangesLog={props.getChangesLog}
                        loadingChangesLog={loadingChangesLog}
                        changesLog={changesLog}
                        edit={edit}
                        data={data}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PersonalDetails name="form" form={form} changeForm={props.changeForm} edit={edit} data={data} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Address
                        name="form"
                        form={form}
                        changeForm={props.changeForm}
                        edit={edit}
                        data={data}
                        validation={validation}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Contact
                        name="form"
                        form={form}
                        changeForm={props.changeForm}
                        edit={edit}
                        data={data}
                        contacts={contacts}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TaxStatus name="form" form={form} changeForm={props.changeForm} edit={edit} data={data} />
                </Grid>
                <Grid item xs={12}>
                    <Permissions
                        name="form"
                        form={form}
                        changeForm={props.changeForm}
                        mavirID={id}
                        data={data}
                        edit={edit}
                        contacts={contacts}
                    />
                </Grid>
                {edit ? (
                    <Grid item xs={12} md={6}>
                        <SubmitButton className={classes.button} type="submit" onClick={() => handleSubmit(id, form)}>
                            Mentés
                        </SubmitButton>
                        <CancelButton className={classes.button} onClick={() => cancelSave(setEdit)}>
                            Vissza
                        </CancelButton>
                    </Grid>
                ) : (
                    ""
                )}
                <Grid item xs={12}>
                    <FurtherApplications applications={data.applications || []} />
                </Grid>
            </PageTemplate>
            <ReasonDialog
                form={form}
                initState={false}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                saveDialog={() => setEdit(true)}
                changeForm={props.changeForm}
            ></ReasonDialog>
        </React.Fragment>
    );
}

function mapState(state) {
    const { form, loading, data, contacts, loadingChangesLog, changesLog, validation } = state.clientDetails;
    return {
        form,
        loading,
        data,
        contacts,
        loadingChangesLog,
        changesLog,
        validation,
    };
}

const actionCreators = {
    changeForm: clientDetailsActions.changeForm,
    clearForm: clientDetailsActions.clearForm,
    getData: clientDetailsActions.getData,
    getContacts: clientDetailsActions.getContacts,
    setData: clientDetailsActions.setData,
    setFormData: clientDetailsActions.setFormData,
    setLoading: clientDetailsActions.setLoading,
    saveForm: clientDetailsActions.saveForm,
    getChangesLog: clientDetailsActions.getChangesLog,
    addNotification: notificationActions.addNotification,
    getValidationUpdate: clientDetailsActions.getValidationUpdate,
};

export default connect(mapState, actionCreators)(ClientDetails);
