import { Grid, makeStyles, useTheme, TablePagination, IconButton } from "@material-ui/core";
import React from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { setInfo } from "../../utils/FilterHelper";
import FirstPageIcon from "../Icons/FirstPageIcon";
import LastPageIcon from "../Icons/LastPageIcon";
import KeyboardArrowLeft from "../Icons/KeyBoardArrowLeft";
import KeyboardArrowRight from "../Icons/KeyBoardArrowRight";
import "./custom.css";
import { numberFormat } from "../../utils/AppConst";

const useStyles = makeStyles((theme) => ({
    root: {
        height: theme.spacing(7),
        "&.MuiPagination-ul": {
            color: "rgba(0, 0, 0, 0.54)",
        },
    },
    paginationComponent: {
        color: "rgba(0, 0, 0, 0.54)",
    },
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
        color: "#0000008A",
        fontSize: "13px",
    },
    icon: {
        width: "18px",
        height: "18px",
        margin: "0 8px",
        "&:disabled": {
            cursor: "not-allowed",
        },
        "&:before": {
            transition: "none",
        },
        "&:after": {
            transition: "none",
        },
        "&:hover": {
            transition: "none",
            background: "none",
        },
    },
}));

export default function TablePaginator(props) {
    const classes = useStyles();

    const lastPage = props?.info?.metadata?.lastPage || 0;
    const count = props?.info?.metadata?.filteredCount || 0;
    const currentPage = count > 0 ? props?.info?.metadata?.page : 0;

    const handleChange = (event, newPage) => {
        setInfo(
            {
                setInfo: props.setInfo,
                info: props.info,
                search: props.searchfunc,
            },
            "page",
            newPage
        );
    };

    //Paginator actions component
    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, rowsPerPage, onChangePage, page } = props;

        const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 1);
        };

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage)));
        };

        let firstRowOnPage = currentPage < 2 ? currentPage : (currentPage - 1) * 25 + 1;
        let lastRowOnPage = currentPage === lastPage ? count : firstRowOnPage + 24;
        let totalToShow = numberFormat(count);

        return (
            <>
                <Grid item className={classes.root}>
                    {`${firstRowOnPage} / ${lastRowOnPage} Összesen: ${totalToShow}`}
                </Grid>
                <Grid item className={classes.root}>
                    <IconButton
                        className={classes.icon}
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 1}
                        aria-label="first page"
                        disableRipple
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                        className={classes.icon}
                        onClick={handleBackButtonClick}
                        disabled={page === 1}
                        aria-label="previous page"
                        disableRipple
                    >
                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </IconButton>
                    {`Oldal: ${currentPage} Összesen: ${lastPage}`}
                    <IconButton
                        className={classes.icon}
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage)}
                        aria-label="next page"
                        disableRipple
                    >
                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </IconButton>
                    <IconButton
                        className={classes.icon}
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage)}
                        aria-label="last page"
                        disableRipple
                    >
                        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </Grid>
            </>
        );
    }

    return (
        <Grid container direction="row-reverse" alignContent="center" className={classes.root}>
            <TablePagination
                rowsPerPageOptions={[]}
                count={count}
                rowsPerPage={25}
                onChangePage={handleChange}
                page={currentPage}
                ActionsComponent={TablePaginationActions}
                component="div"
            />
        </Grid>
    );
}
