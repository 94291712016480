import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import usersAction from "../../../store/users/actions";
import providersAction from "../../../store/providers/actions";
import employersAction from "../../../store/employers/actions";
import OperationButton from "../../Common/OperationButton";
import Autocomplete from "../../Common/Autocomplete";
import { hasRole } from "../../../utils/User";
import { history } from "../../../store";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function OperationsBtnCellRenderer(params) {
    return (
        <>
            <OperationButton type="edit" to={`/users/edit/${params.data.id}`} />
        </>
    );
}

function ActiveCellRenderer(params) {
    return params.data.enabled ? "Aktív" : "Inaktív";
}

function Users(props) {
    const { filter, data, loading, token, allUsers, providers, employers, filterStorageKey } = props;
    const statusOptions = [
        { value: true, label: "Aktív" },
        { value: false, label: "Inaktív" },
    ];
    const table = {
        columnDefs: [
            { headerName: "Felhasználónév", field: "username" },
            { headerName: "Teljes név", field: "name" },
            { headerName: "E-mail", field: "email" },
            { headerName: "Kapcsolattartók", field: "employers", maxWidth: 300, valueGetter: val => (val.data.employers || []).map(employer => employer.name).join(", ")},
            { headerName: "Szolgáltató", field: "serviceProvider.name" },
            { headerName: "Vezetékes telefonszám", field: "phoneLanding" },
            { headerName: "Mobiltelefonszám", field: "phoneMobile" },
            {
                headerName: "Állapot",
                field: "enabled",
                cellRenderer: "activeCellRenderer",
            },
            { headerName: "Utolsó belépés", field: "lastLoggedInAt" },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "operationsBtnCellRenderer",
                sortable: false,
                minWidth: 200,
            },
        ],
        rowData: data,
        frameworkComponents: {
            operationsBtnCellRenderer: OperationsBtnCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
    };

    useEffect(() =>{
        if (!hasRole("ROLE_ADMIN")) {
            history.push("/403");
        }
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter"), 
        props.getAllUsers();
        props.search(storageFilter);
        props.fetchEmployers();
        props.fetchServiceProviders();
    }, []);

    const userOptions = () => allUsers?.map((user) => {
        return {
            value: user.id,
            label: user.username
        }
    })

    const providerOptions = providers.map((provider) => ({
        value: provider.id,
        label: provider.name, 
    }));
    const employerOptions = employers.map((employer) => ({
        value: employer.id,
        label: employer.name, 
    }));

    return (
        <ListPageTemplate
            header={{
                title: "Felhasználók",
                breadcrumbs: {
                    operators: "Operátorok",
                    users: "Felhasználók",
                },
            }}
            filter={{
                onSubmit: (filter) => {
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filter);
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    props.search({});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
        >
            <Autocomplete
                value={filter.userId || []}
                options={userOptions(allUsers)}
                id="userId"
                name="userId"
                format={{ xs: 12, md: 3 }}
                label="Felhasználónév"
            />
            <TextField label="Teljes név" name="name" value={filter.name || ""} format={{ xs: 12, md: 3 }} />
            <TextField label="E-mail" name="email" value={filter.email || ""} format={{ xs: 12, md: 3 }} />
            <Autocomplete
                multiple
                value={filter.serviceProvider || []}
                options={providerOptions}
                id="serviceProvider"
                name="serviceProvider"
                label="Szolgáltató"
                format={{ xs: 12, md: 3 }}
            />
            <Autocomplete
                value={filter.employers || []}
                options={employerOptions}
                id="employers"  
                name="employers"
                format={{ xs: 12, md: 3 }}
                label="Kapcsolattartó"
            />
            <Autocomplete
                multiple
                value={filter.enabled || []}
                options={statusOptions}
                id="enabled"
                name="enabled"
                label="Állapot"
                format={{ xs: 12, md: 3 }}
            />
            <TextField
                label="Utolsó belépés (-tól)"
                name="lastLoggedInAtStart"
                value={filter.lastLoggedInAtStart || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Utolsó belépés (-ig)"
                name="lastLoggedInAtEnd"
                value={filter.lastLoggedInAtEnd || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, allUsers, filterStorageKey } = state.users;
    const providers = state.providers.data;
    const employers = state.employers.data;
    return { filter, data, loading, token, allUsers, providers, employers, filterStorageKey };
}

const actionCreators = {
    changeForm: usersAction.changeForm,
    search: usersAction.filter,
    getAllUsers: usersAction.getAllUsers,
    defaultForm: usersAction.defaultForm,
    exportInitiate: usersAction.exportInitiate,
    exportCheck: usersAction.exportCheck,
    exportDownload: usersAction.exportDownload,
    fetchServiceProviders: () => providersAction.filter({}),
    fetchEmployers: () => employersAction.filter({}),
};

export default connect(mapState, actionCreators)(Users);
