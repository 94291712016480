import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateGet,
} from "..";
import applicationsListService from "../../services/applicationsList";
import { types } from "./types";
import downloadBlob from "../../utils/downloadBlob";

function downloadEdms(barCode, forceVal = false) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST_EDMS });
        return applicationsListService.downloadEdms(barCode, forceVal).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS_EDMS, data });
                downloadBlob(data.data, `${barCode}.pdf`);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE_EDMS, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

function getUsersWhoArrived() {
    return (dispatch) => {
        dispatch({ type: types.GET_USERS_WHO_ARRIVED_REQUEST });
        return applicationsListService.getUsersWhoArrived().then(
            (data) => {
                dispatch({ type: types.GET_USERS_WHO_ARRIVED_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_USERS_WHO_ARRIVED_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, applicationsListService),
    get: templateGet(types, applicationsListService),
    exportInitiate: templateExportInitiate(types, applicationsListService),
    exportCheck: templateExportCheck(types, applicationsListService),
    exportDownload: templateExportDownload(types, applicationsListService),
    firstFetch: templateFirstFetch(types, applicationsListService),
    setInfo,
    downloadEdms,
    getUsersWhoArrived,
};

export default actions;
