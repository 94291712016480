import { withStyles } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import colors from "../../styles/colors.js";

const DisabledHouse = withStyles({
    root: {
        backgroundColor: colors.menuGroup,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
    },
})(HomeIcon);

export default DisabledHouse;
