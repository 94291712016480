import React, { Children } from "react";
import MenuList from "@material-ui/core/MenuList";
import Topic from "../../Common/Topic";
import NavMenuItem from "../../Common/NavMenuItem";
import RolesRules from "./RolesRules";
import { faHome } from "@fortawesome/free-solid-svg-icons";

/**
 * Tudnivalók:
 * * Logika a RolesRules.js-ben
 * * Menüpontok az AppConsts.js-ben
 * @param {[Jogosultság]} roles - A felhasználó jogosultságai
 * @returns Oldalmenü
 */

export default function SidebarMenu({ roles }) {
    const getMenu = () => new RolesRules(roles).getMenu();

    return (
        <MenuList>
            <NavMenuItem to="/" text="Főoldal" exact icon={faHome} />
            {Children.toArray(
                getMenu().map((topic) => (
                    <div>
                        <Topic text={topic.name}>
                            {Children.toArray(
                                topic.subMenus.map((subMenu) => (
                                    <NavMenuItem to={subMenu.to} icon={subMenu.icon} text={subMenu.text} />
                                ))
                            )}
                        </Topic>
                    </div>
                ))
            )}
        </MenuList>
    );
}
