import API from "../../utils/API";
import uploadAPI from "../../utils/uploadAPI";
import downloadAPI from "../../utils/downloadAPI";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";

function filterDownload(form) {
    const searchForm = new SearchForm(form);

    return API.get("/sorting-results", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function changeStatusDownload(id) {
    return API.post(`/sorting-results/${id}/change-downloaded-status`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function downloadDownload(id) {
    return downloadAPI
        .get(`/sorting-results/${id}/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function filterUpload(form) {
    const searchForm = new SearchForm(form);

    return API.get("/service-provider-data-verification", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function changeStatusUpload(id) {
    return API.post(`/service-provider-data-verification/${id}/change-downloaded-status`)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function downloadUploadReply(id) {
    return downloadAPI
        .get(`/service-provider-data-verification/${id}/download-reply`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function downloadUpload(id) {
    return downloadAPI
        .get(`/service-provider-data-verification/${id}/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}
function getLogServiceProviderDataVerification(id) {
    return API.get(`/service-provider-data-verification/${id}/get-log`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}
function getLogSortingResults(id) {
    return API.get(`/sorting-results/${id}/get-log`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function upload(form) {
    return uploadAPI
        .post("/service-provider-data-verification/upload", form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const providerValidationService = {
    filterDownload,
    changeStatusDownload,
    downloadDownload,
    exportInitiateDownload: templateExportInitiate("sorting-results"),
    exportCheckDownload: templateExportCheck("sorting-results"),
    exportDownloadDownload: templateExportDownload("sorting-results"),
    changeStatusUpload,
    filterUpload,
    downloadUpload,
    downloadUploadReply,
    upload,
    exportInitiateUpload: templateExportInitiate("service-provider-data-verification"),
    exportCheckUpload: templateExportCheck("service-provider-data-verification"),
    exportDownloadUpload: templateExportDownload("service-provider-data-verification"),
    getLogServiceProviderDataVerification,
    getLogSortingResults,
};

export default providerValidationService;
