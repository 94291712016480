export const types = {
    DEFAULT_FORM: "@@client-details/DEFAULT_FORM",
    CHANGE_FORM: "@@client-details/CHANGE_FORM",
    CLEAR_FORM: "@@client-details/CLEAR_FORM",
    SET_FORM_DATA: "@@client-details/SET_FORM_DATA",
    SET_DATA: "@@client-details/SET_DATA",

    GET_DATA_REQUEST: "@@client-details/GET_DATA_REQUEST",
    GET_DATA_SUCCESS: "@@client-details/GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "@@client-details/GET_DATA_FAILURE",

    GET_CONTACTS_REQUEST: "@@client-details/GET_CONTACTS_REQUEST",
    GET_CONTACTS_SUCCESS: "@@client-details/GET_CONTACTS_SUCCESS",
    GET_CONTACTS_FAILURE: "@@client-details/GET_CONTACTS_FAILURE",

    GET_CHANGES_LOG_REQUEST: "@@client-details/GET_CHANGES_LOG_REQUEST",
    GET_CHANGES_LOG_SUCCESS: "@@client-details/GET_CHANGES_LOG_SUCCESS",
    GET_CHANGES_LOG_FAILURE: "@@client-details/GET_CHANGES_LOG_FAILURE",

    SET_LOADING: "@@client-details/SET_LOADING",

    UPDATE_REQUEST: "@@client-details/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@client-details/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@client-details/UPDATE_FAILURE",

    GET_VALIDATION_UPDATE_REQUEST: "@@client-details/GET_VALIDATION_UPDATE_REQUEST",
    GET_VALIDATION_UPDATE_SUCCESS: "@@client-details/GET_VALIDATION_UPDATE_SUCCESS",
    GET_VALIDATION_UPDATE_FAILURE: "@@client-details/GET_VALIDATION_UPDATE_FAILURE",
};
