import { templateFetch } from "..";
import searchAPI from "../../utils/searchAPI";
import API from "../../utils/API";

function filter() {
    return API.get("/consumption-data-import-periods")
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function activate(id) {
    return searchAPI
        .post(`/consumption-data-import-periods/${id}/activate`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function deactivate(id) {
    return searchAPI
        .post(`/consumption-data-import-periods/${id}/deactivate`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const consumptionPeriodsService = {
    filter,
    activate,
    deactivate,
    fetch: templateFetch("consumption-data-import-periods"),
};

export default consumptionPeriodsService;
