import { withStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import colors from "../../styles/colors.js";

const Check = withStyles({
    root: {
        backgroundColor: colors.white,
        borderRadius: 3,
        color: colors.green,
        fontSize: "xx-large",
        marginTop: "0.7vh",
    },
})(CheckIcon);

export default Check;
