import { types } from "./types";

const initialState = {
    filterStorageKey: 'applications.applicationsList',
    filter: {},
    data: [],
    users: [],
    usersWhoArrived: [],
    tableLoading: false,
    pageLoading: false,
    loading: false,
    tableInfo: {
        filter: {},
        sort: {},
        page: 1,
        metadata: {},
    },
};

export function applicationsList(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                tableInfo: initialState.tableInfo,
                filter: initialState.filter,
            };
        case types.FIRST_FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
                pageLoading: true,
            };
        case types.FIRST_FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: action.data.data.data,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.data?.metadata,
                },
            };
        case types.FIRST_FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: [],
                error: action.error,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                data: [],
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action?.data?.data?.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST_EDMS:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS_EDMS:
            return {
                ...state,
                edmsFile: action.data.data,
            };
        case types.DOWNLOAD_FAILURE_EDMS:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_USERS_WHO_ARRIVED_REQUEST:
            return {
                ...state,
                pageLoading: true,
            }
        case types.GET_USERS_WHO_ARRIVED_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                usersWhoArrived: action?.data?.data?.data,
            }
        case types.GET_USERS_WHO_ARRIVED_FAILURE:
            return {
                ...state,
                pageLoading: false,
                usersWhoArrived: initialState.usersWhoArrived,
                error: action?.error,
            }
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        default:
            return state;
    }
}
