import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    title: {
        paddingLeft: theme.spacing(2),
    },
    text: {
        fontWeight: "bold",
        paddingLeft: theme.spacing(2),
    },
    gridRow: {
        alignItems: "center",
        marginBottom: "10px",
    },
}));

/**
 *
 * @param title: megadod a bal oldalon lévő szöveget
 * @param props.children: ennek megadod a jobb oldalon lévő elemeket/komponenseket
 * @param child.props.format: pl. format={{ xs: 12, md:6 }} formátumban
 * @param child: maga egy child komponens
 */
export default function Detail(props) {
    const { title, value } = props;
    const classes = useStyles();

    return (
        <Grid className={classes.gridRow}>
            <Grid container item direction="row" justify="space-between">
                <Typography className={classes.title}>{title ? `${title}:` : ""}</Typography>
                <Grid item>
                    <Typography className={classes.text}>{value}</Typography>
                    {props.children}
                </Grid>
            </Grid>
            <Divider />
        </Grid>
    );
}
