import { templateExportCheck, templateExportDownload, templateExportInitiate, templateGet } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/system-log", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getTypesAndEvents() {
    return API.get("/system-log/types-and-events")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const systemLogService = {
    filter,
    getTypesAndEvents,
    get: templateGet("system-log"),
    exportInitiate: templateExportInitiate("system-log"),
    exportCheck: templateExportCheck("system-log"),
    exportDownload: templateExportDownload("system-log"),
};

export default systemLogService;
