export const types = {
    DEFAULT_FORM: "@@application-details/DEFAULT_FORM",

    GET_REQUEST: "@@application-details/GET_REQUEST",
    GET_SUCCESS: "@@application-details/GET_SUCCESS",
    GET_FAILURE: "@@application-details/GET_FAILURE",

    DELETE_APPLICATION_REQUEST: "@@application-details/DELETE_APPLICATION_REQUEST",
    DELETE_APPLICATION_SUCCESS: "@@application-details/DELETE_APPLICATION_SUCCESS",
    DELETE_APPLICATION_FAILURE: "@@application-details/DELETE_APPLICATION_FAILURE",
};
