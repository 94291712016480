import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import PageTemplate from "../../Common/PageTemplate";
import CommandsTable from "./CommandsTable";
import commandsActions from "../../../store/commands/actions";
import notificationActions from "../../../store/notification/actions";
import { connect } from "react-redux";

function Commands(props) {
    const { sortings, permissionAssignments, loading } = props;

    useEffect(() => {
        props.fetchSortings();
        props.fetchPermissionAssignments();
    }, []);

    const handleSorting = () => {
        props
            .sorting()
            .then(() => props.fetchSortings())
            .then(() => props.addNotification("info", "A leválogatás elindult"))
            .catch(() => props.addNotification("error", "Hiba lépett fel a leválogatás során"));
    };

    const handlePermissionAssingnment = () => {
        props
            .permissionAssignment()
            .then(() => props.fetchPermissionAssignments())
            .then(() => props.addNotification("info", "A jogosultság megállapítás elindult"))
            .catch(() => props.addNotification("error", "Hiba lépett fel jogosultság megállapítás során"));
    };

    return (
        <PageTemplate
            header={{
                title: "Parancsok",
                breadcrumbs: {
                    operators: "Operátorok",
                    commands: "Parancsok",
                },
            }}
            loading={loading}
        >
            <Grid item container xs={12} spacing={2}>
                <CommandsTable
                    data={sortings}
                    title="Igénylések leválogatása"
                    submitText="Leválogatás"
                    onClick={handleSorting}
                />
                <CommandsTable
                    data={permissionAssignments}
                    title="Jogosultság megállapítása"
                    submitText="Megállapítás"
                    onClick={handlePermissionAssingnment}
                />
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { sortings, permissionAssignments, loading } = state.commands;
    return { sortings, permissionAssignments, loading };
}

const actionCreators = {
    fetchSortings: commandsActions.fetchSortings,
    fetchPermissionAssignments: commandsActions.fetchPermissionAssignments,
    sorting: commandsActions.sorting,
    permissionAssignment: commandsActions.permissionAssignment,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Commands);
