import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import employersAction from "../../../store/employers/actions";
import OperationButton from "../../Common/OperationButton";
import Autocomplete from "../../Common/Autocomplete";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function OperationsBtnCellRenderer(params) {
    return (
        <>
            <OperationButton type="edit" to={`/employers/edit/${params.data.id}`} />
            {params.data.canExtend ? (
                <OperationButton type="new-child" to={`/employers/new-child/${params.data.id}`} />
            ) : (
                ""
            )}
            {params.data.contractTo == null || params.data.contractTo == "" ? (
                <OperationButton type="abolition" to={`/employers/close/${params.data.id}`} />
            ) : (
                ""
            )}
        </>
    );
}

function ActiveCellRenderer(params) {
    return params.data.active ? "Aktív" : "Inaktív";
}

function Employers(props) {
    const { filter, data, loading, token, filterStorageKey } = props;

    const statusOptions = [
        { value: true, label: "Aktív" },
        { value: false, label: "Inaktív" },
    ];

    const table = {
        columnDefs: [
            { headerName: "Azonosító", field: "reference" },
            { headerName: "Megnevezés", field: "name" },
            { headerName: "Székhely", field: "headquarters" },
            { headerName: "Érvényesség kezdete", field: "contractFrom" },
            { headerName: "Érvényesség vége", field: "contractTo" },
            {
                headerName: "Állapot",
                field: "active",
                cellRenderer: "activeCellRenderer",
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "operationsBtnCellRenderer",
                sortable: false,
                minWidth: 150,
            },
        ],
        rowData: data,
        autoSize: "true",
        frameworkComponents: {
            operationsBtnCellRenderer: OperationsBtnCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
        to: "employers/new",
        newButtonText: "Munkáltató hozzáadása",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter"), 
        props.search(filter)
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Munkáltatók",
                breadcrumbs: {
                    operators: "Operátorok",
                    employers: "Munkáltatók",
                },
            }}
            filter={{
                onSubmit: (filter) => {
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filter);
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm("filter");
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
        >
            <TextField 
                label="Azonosító" 
                name="reference"
                value={filter.reference || ""} 
                format={{ xs: 12, md: 3 }} 
                rule={{
                    number: "true"
                }}
            />
            <TextField label="Megnevezés" name="name" value={filter.name || ""} format={{ xs: 12, md: 3 }} />
            <TextField
                label="Székhely"
                name="headquarters"
                value={filter.headquarters || ""}
                format={{ xs: 12, md: 3 }}
            />
            <Autocomplete
                value={filter?.active || []}
                options={statusOptions}
                id="active"
                name="active"
                format={{ xs: 12, md: 3 }}
                label="Állapot"
            />
            <TextField
                label="Érvényesség kezdete (-tól)"
                name="contractFromStart"
                value={filter.contractFromStart || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség kezdete (-ig)"
                name="contractFromEnd"
                value={filter.contractFromEnd || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség vége (-tól)"
                name="contractToStart"
                value={filter.contractToStart || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség vége (-ig)"
                name="contractToEnd"
                value={filter.contractToEnd || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, filterStorageKey } = state.employers;
    return { filter, data, loading, token, filterStorageKey };
}

const actionCreators = {
    changeForm: employersAction.changeForm,
    search: employersAction.filter,
    defaultForm: employersAction.defaultForm,
    exportInitiate: employersAction.exportInitiate,
    exportCheck: employersAction.exportCheck,
    exportDownload: employersAction.exportDownload,
};

export default connect(mapState, actionCreators)(Employers);
