import { types } from "./types";

const initialState = {
    contactFilterStorageKey: "denied.contact",
    providerFilterStorageKey: "denied.provider",
    providerData: [],
    contactData: [],
    provider: {
        filter: { type: "rejection", subType: "service_provider" },
        sort: {
            createdAt: "desc",
        },
        page: 1,
        metadata: {},
    },
    contact: {
        filter: { type: "rejection", subType: "contact" },
        sort: {
            createdAt: "desc",
        },
        page: 1,
        metadata: {},
    },
    pageLoading: false,
    tableLoading: false,
    showContactTable: false,
    showProviderTable: false,
    token: "",
    status: null,
    file: null,
    currentTab: 0,
    providerFilter: {
        type: "rejection",
        subType: "service_provider",
    },
    contactFilter: {
        type: "rejection",
        subType: "contact",
    },
};

export function deniedApplications(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.isLoading,
            };
        case types.DEFAULT_FORM_PROVIDER:
            return {
                ...state,
                provider: {
                    ...initialState.provider,
                },
                providerFilter: initialState.providerFilter,
                currentTab: 0,
                showProviderTable: false,
            };
        case types.DEFAULT_FORM_CONTACT:
            return {
                ...state,
                contact: {
                    ...initialState.contact,
                },
                contactFilter: initialState.contactFilter,
                currentTab: 1,
                showContactTable: false,
            };
        case types.FETCH_SET_PROVIDER_INFO:
            return {
                ...state,
                provider: {
                    ...state.provider,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_SET_CONTACT_INFO:
            return {
                ...state,
                contact: {
                    ...state.contact,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_REQUEST_PROVIDER:
            return {
                ...state,
                tableLoading: true,
                currentTab: 0,
                pageLoading: !state.showProviderTable,
            };
        case types.FETCH_SUCCESS_PROVIDER:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                provider: {
                    ...state.provider,
                    metadata: action.data.data.metadata,
                },
                providerData: action?.data?.data?.data,
                showProviderTable: true,
            };
        case types.FETCH_FAILURE_PROVIDER:
            return {
                ...state,
                tableLoading: false,
                providerData: [],
                error: action.error,
            };
        case types.FETCH_REQUEST_CONTACT:
            return {
                ...state,
                tableLoading: true,
                currentTab: 1,
                pageLoading: !state.showContactTable,
            };
        case types.FETCH_SUCCESS_CONTACT:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                contact: {
                    ...state.contact,
                    metadata: action.data.data.metadata,
                },
                contactData: action?.data?.data?.data,
                showContactTable: true,
            };
        case types.FETCH_FAILURE_CONTACT:
            return {
                ...state,
                tableLoading: false,
                contactData: [],
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS:
            return {
                ...state,
            };
        case types.DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_PROVIDER:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_CONTACT:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_PROVIDER:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_CONTACT:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_PROVIDER:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_CONTACT:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.CHANGE_STATUS_REQUEST:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_SUCCESS:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
