import API from "../../utils/API";
import uploadAPI from "../../utils/uploadAPI";
import downloadAPI from "../../utils/downloadAPI";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/customer-modification-import-list", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

//feltöltés
function upload(form) {
    return uploadAPI
        .post("/customer-modification-import-list", form)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

//letöltés
function download(id) {
    return downloadAPI
        .get(`/customer-modification-import-list/${id}/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

//excel valid letöltés
function downloadValid(id) {
    return downloadAPI
        .get(`/customer-modification-import-list/${id}/download-valid`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

//excel invalid letöltés
function downloadInvalid(id) {
    return downloadAPI
        .get(`/customer-modification-import-list/${id}/download-invalid`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

function downloadReply(id) {
    return downloadAPI
        .get(`/customer-modification-import-list/${id}/reply/download`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}


const customerModificationImportService = {
    filter,
    upload,
    download,
    downloadValid,
    downloadInvalid,
    downloadReply,
};

export default customerModificationImportService;
