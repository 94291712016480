import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";
import Button from "@material-ui/core/Button";

const StyledCancelButton = withStyles({
    root: {
        background: colors.white,
        borderRadius: 3,
        border: "2px solid",
        borderColor: colors.filter,
        color: colors.filter,
        height: 30,
        padding: "0 20px",
        boxShadow: colors.black,
        "&:hover": {
            background: colors.filterHover,
            borderColor: colors.filterHover,
            color: colors.white,
        },
    },
    label: {
        textTransform: "capitalize",
        fontWeight: "bold",
    },
})(Button);

export default StyledCancelButton;
