import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextField from "./TextField";
import Title from "./Title";
import Autocomplete from "./Autocomplete";

export default function Address(props) {
    const { form, name, validation, id } = props;
    const cities = useSelector((state) => state.cities.data);
    const streetTypeOptions = validation.find((val) => val.type === "street_type")?.options.types || [];
    const floorOptions = validation.find((val) => val.type === "floor")?.options.types || [];
    const [citiesOptions, setCitiesOptions] = useState([]);

    if (floorOptions[0] !== "") {
        floorOptions.unshift("");
    }

    const resetCitiesOptions = () => {
        let citiesTmp = [];

        for (const prop in Object.values(cities)) {
            citiesTmp.push(cities[prop].name);
        }

        citiesTmp.sort();
        setCitiesOptions(citiesTmp);
    }

    useEffect(() => {
        resetCitiesOptions();
    }, [cities])

    useEffect(() => {
        if (form.address?.postCode?.length === 4) {
            let citiesTmp1 = cities.filter(city => city.postCode == form.address?.postCode);
            let citiesTmp2 = [];

            citiesTmp1.forEach(city => {
                citiesTmp2.push(city.name);
            });

            citiesTmp2.sort();
            setCitiesOptions(citiesTmp2);

            props.changeform(
                {
                    ...form,
                    address: {
                        ...form.address,
                        city: citiesTmp2[0] ? citiesTmp2[0] : '',
                    },
                },
                name
            );
        } else {
            resetCitiesOptions();
        }
    }, [form.address?.postCode]);

    return (
        <Title title="Lakcím" {...props}>
            <TextField
                label="Irányítószám"
                name="address.postCode"
                id={`address.postCode.${id}`}
                value={form.address?.postCode || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                rule={{
                    number: "true",
                    length: 4,
                }}
                margin="none"
            />
            <Autocomplete
                value={form.address?.city || null}
                options={citiesOptions || []}
                name="address.city"
                id={`address.city.${id}`}
                format={{ xs: 12, md: 6, lg: 3 }}
                label="Város"
                multiple={false}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
            />
            <TextField
                label="Közterület neve"
                name="address.street"
                id={`address.street.${id}`}
                value={form.address?.street || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                margin="none"
            />
            <Autocomplete
                value={form.address?.streetType || null}
                options={streetTypeOptions}
                name="address.streetType"
                id={`address.streetType.${id}`}
                format={{ xs: 12, md: 6, lg: 3 }}
                label="Közterület jellege"
                multiple={false}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
            />
            <TextField
                label="Házszám"
                name="address.houseNumber"
                id={`address.houseNumber.${id}`}
                value={form.address?.houseNumber || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                margin="none"
                rule={{
                    length: 16,
                }}
            />
            <Autocomplete
                value={form.address?.floor || null}
                options={floorOptions}
                name="address.floor"
                id={`address.floor.${id}`}
                format={{ xs: 12, md: 6, lg: 3 }}
                label="Emelet"
                multiple={false}
                getOptionLabel={(option) => option}
                getOptionSelected={(option, value) => option === value}
            />
            <TextField
                label="Ajtó"
                name="address.door"
                id={`address.door.${id}`}
                value={form.address?.door || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                margin="none"
                rule={{
                    length: 16,
                }}
            />
            <TextField
                label="Helyrajzi szám"
                name="address.parcelNumber"
                id={`address.parcelNumber.${id}`}
                value={form.address?.parcelNumber || ""}
                format={{ xs: 12, md: 6, lg: 3 }}
                margin="none"
            />
        </Title>
    );
}
