import React, { Children } from "react";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import SelectBasic from "@material-ui/core/Select";
import colors from "../../styles/colors.js";

import FormHelperText from "@material-ui/core/FormHelperText";
import { NativeSelect } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        minWidth: 120,
    },
    labelFocusedRoot: {
        "& label.MuiFormLabel-root.Mui-focused": {
            color: colors.black,
        },
    },
}));
const useOutlinedInputStyles = makeStyles(() => ({
    root: {
        "& $notchedOutline": {
            borderColor: colors.black,
        },
        "&:hover $notchedOutline": {
            borderColor: colors.black,
        },
        "&$focused $notchedOutline": {
            borderColor: colors.black,
        },
    },
    focused: {},
    notchedOutline: {},
}));

export default function Select(props) {
    const classes = useStyles();
    const outlinedInputClasses = useOutlinedInputStyles();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl
            margin={props.margin || "dense"}
            fullWidth
            variant="outlined"
            disabled={props.disabled}
            className={classes.formControl}
            error={props.error || false}
            classes={{ root: classes.labelFocusedRoot }}
            size="small"
        >
            <InputLabel ref={inputLabel} htmlFor={props.labelId} required={props.required}>
                {props.selectLabel}
            </InputLabel>
            {props.native ? (
                <NativeSelect
                    required={props.required}
                    shrink={props.required ? "1" : "0"}
                    input={
                        <OutlinedInput
                            required={props.required}
                            shrink={props.required ? "1" : "0"}
                            value={props.value}
                            notched={props.shrink}
                            defaultValue={props.defaultValue}
                            labelWidth={labelWidth}
                            name={props.name}
                            id={props.id}
                            margin="dense"
                            size="small"
                            classes={outlinedInputClasses}
                        />
                    }
                    label={props.label}
                    multiple={props.multiple}
                    onChange={props.onChange}
                >
                    {props.children}
                </NativeSelect>
            ) : (
                <SelectBasic
                    required={props.required}
                    shrink={props.required ? "1" : "0"}
                    onChange={props.onChange}
                    input={
                        <OutlinedInput
                            required={props.required}
                            shrink={props.required ? "1" : "0"}
                            value={props.value}
                            notched={props.shrink}
                            defaultValue={props.defaultValue}
                            labelWidth={labelWidth}
                            name={props.name}
                            id={props.id}
                            margin="dense"
                            size="small"
                            classes={outlinedInputClasses}
                        />
                    }
                    label={props.label}
                    multiple={props.multiple}
                >
                    { props.dontSort ? 
                        Children.toArray(
                            props.optionList
                            .map((item) => <MenuItem value={item.value}>{item.label}</MenuItem>)
                        )
                    :
                        Children.toArray(
                            props.optionList
                            .sort((a, b) => ("" + (a.label || a.name || a)).localeCompare(b.label || b.name || b))
                            .map((item) => <MenuItem value={item.value}>{item.label}</MenuItem>)
                        )
                    }
                </SelectBasic>
            )}
            {props.error ? <FormHelperText> {props.helperText} </FormHelperText> : ""}
        </FormControl>
    );
}
