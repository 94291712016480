export const types = {
    DEFAULT_FORM: "@@failed-login-attempts/DEFAULT_FORM",
    CHANGE_FORM: "@@failed-login-attempts/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@failed-login-attempts/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@failed-login-attempts/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@failed-login-attempts/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@failed-login-attempts/FETCH_REQUEST",
    FETCH_SUCCESS: "@@failed-login-attempts/FETCH_SUCCESS",
    FETCH_FAILURE: "@@failed-login-attempts/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@failed-login-attempts/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@failed-login-attempts/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@failed-login-attempts//EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@failed-login-attempts/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@failed-login-attempts/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@failed-login-attempts/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@failed-login-attempts/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@failed-login-attempts/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@failed-login-attempts/EXPORT_DOWNLOAD_FAILURE",

    FETCH_SET_INFO: "@@failed-login-attempts/FETCH_SET_INFO",
};
