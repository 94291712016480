import {
    templateGet,
    templateGetValidationCreate,
    templateGetValidationUpdate,
    templateExportInitiate,
    templateExportCheck,
    templateExportDownload,
    updateSubmit,
    createSubmit,
} from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    let formToSend = Object.assign({}, form);

    if (formToSend.reference) formToSend.reference = Number(formToSend.reference);

    const searchForm = new SearchForm(formToSend);

    return API.get("/employers", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const employersService = {
    filter,
    get: templateGet("employers"),
    update: updateSubmit("employers"),
    updateClose: updateSubmit("employers/close"),
    create: createSubmit("employers"),
    createChild: createSubmit("employers/child"),
    getValidationCreate: templateGetValidationCreate("employers"),
    getValidationUpdate: templateGetValidationUpdate("employers"),
    exportInitiate: templateExportInitiate("employers"),
    exportCheck: templateExportCheck("employers"),
    exportDownload: templateExportDownload("employers"),
};

export default employersService;
