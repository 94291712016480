export const types = {
    DEFAULT_FORM: "@@providers/DEFAULT_FORM",
    CHANGE_FORM: "@@providers/CHANGE_FORM",

    FETCH_REQUEST: "@@providers/FETCH_REQUEST",
    FETCH_SUCCESS: "@@providers/FETCH_SUCCESS",
    FETCH_FAILURE: "@@providers/FETCH_FAILURE",

    GET_REQUEST: "@@providers/GET_REQUEST",
    GET_SUCCESS: "@@providers/GET_SUCCESS",
    GET_FAILURE: "@@providers/GET_FAILURE",

    UPDATE_REQUEST: "@@providers/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@providers/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@providers/UPDATE_FAILURE",

    CREATE_REQUEST: "@@providers/CREATE_REQUEST",
    CREATE_SUCCESS: "@@providers/CREATE_SUCCESS",
    CREATE_FAILURE: "@@providers/CREATE_FAILURE",

    GET_VALIDATION_CREATE_REQUEST: "@@providers/GET_VALIDATION_CREATE_REQUEST",
    GET_VALIDATION_CREATE_SUCCESS: "@@providers/GET_VALIDATION_CREATE_SUCCESS",
    GET_VALIDATION_CREATE_FAILURE: "@@providers/GET_VALIDATION_CREATE_FAILURE",

    GET_VALIDATION_UPDATE_REQUEST: "@@providers/GET_VALIDATION_UPDATE_REQUEST",
    GET_VALIDATION_UPDATE_SUCCESS: "@@providers/GET_VALIDATION_UPDATE_SUCCESS",
    GET_VALIDATION_UPDATE_FAILURE: "@@providers/GET_VALIDATION_UPDATE_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@providers/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@providers/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@providers/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@providers/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@providers/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@providers/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@providers/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@providers/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@providers/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@providers/CLEAR_FORM",
};
