import React from "react";
import { CONSUMPTION_PLACE_TYPE } from "../../../../utils/AppConst";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import Title from "../../../Common/Title";
import Select from "../../../Common/Select";
import InputBase from "../../../Common/InputBase";
import Card from "../../../Common/Card";

export default function ContractDetails(props) {
    const { form, name, addNew, edit, metadata, data } = props;

    const formProps = {
        form: form,
        name: name,
        changeform: props.changeForm,
    };

    return (
        <Card title="Szerződés adatai" edit={edit} fullWidth>
            {edit || addNew ? (
                <Form {...formProps}>
                    <Title title="Felhasználási hely azonosítója" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Azonosító"
                            name="reference"
                            value={form?.reference || ""}
                            format={{ xs: 12 }}
                        />
                    </Title>
                    <Title title="Használat jogcíme" format={{ xs: 12 }} {...formProps}>
                        <Select
                            name="type"
                            value={form?.type || ""}
                            format={{ xs: 12 }}
                            optionList={metadata.types.map((option) => ({
                                value: option.id,
                                label: option.label,
                            }))}
                        />
                    </Title>
                    <Title title="Kívánt villamos energia mennyiség" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Kívánt menny. (kWh)"
                            name="quantity"
                            value={form?.quantity || ""}
                            format={{ xs: 12 }}
                        />
                    </Title>
                    <Title title="Egyetemes szolgáltató" format={{ xs: 12 }} {...formProps}>
                        <Select
                            name="serviceProviderId"
                            value={form?.serviceProviderId || ""}
                            format={{ xs: 12 }}
                            optionList={metadata.providers.map((option) => ({
                                value: option.id,
                                label: option.name,
                            }))}
                        />
                    </Title>
                    <Title title="Az igénylő vevőkódja/ügyfélszáma/partnerszáma" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Vevőkód/ügyfélszám/partnerszám"
                            helperText=""
                            name="consumerNumber"
                            value={form?.consumerNumber || ""}
                            format={{ xs: 12 }}
                        />
                    </Title>
                    <Title title="Szerződésszám" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Szerződésszám"
                            name="contractNumber"
                            value={form?.contractNumber || ""}
                            format={{ xs: 12 }}
                        />
                    </Title>
                    <Title title="Szolgáltató szerinti egyedi azonosító" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Egyedi azonosító"
                            name="uniqueIdByProvider"
                            value={form?.uniqueIdByProvider || ""}
                            format={{ xs: 12 }}
                        />
                    </Title>
                </Form>
            ) : (
                <Form {...formProps}>
                    <Title title="Felhasználási hely azonosítója" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            label="Azonosító"
                            name={"data.reference"}
                            value={data?.reference || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Használat jogcíme" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            name={"data.type"}
                            value={CONSUMPTION_PLACE_TYPE(data?.type) || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Kívánt villamos energia mennyiség" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            label="Kívánt menny. (kWh)"
                            name={"data.quantity"}
                            value={data?.quantity ? data?.quantity + " kWh" : ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Egyetemes szolgáltató" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            name={"data.serviceProviderId"}
                            value={metadata?.providers?.find((x) => x.id === data?.serviceProviderId)?.name || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Az igénylő vevőkódja/ügyfélszáma/partnerszáma" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            label="Vevőkód/ügyfélszám/partnerszám"
                            name={"data.consumerNumber"}
                            value={data?.consumerNumber || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Szerződésszám" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            label="Szerződésszám"
                            name={"data.contractNumber"}
                            value={data?.contractNumber || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Szolgáltató szerinti egyedi azonosító" {...formProps} format={{ xs: 12 }}>
                        <InputBase
                            label="Egyedi azonosító"
                            name={"data.uniqueIdByProvider"}
                            value={data?.uniqueIdByProvider || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                </Form>
            )}
        </Card>
    );
}
