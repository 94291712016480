export const types = {
    DEFAULT_FORM: "@@applications-list/DEFAULT_FORM",
    CHANGE_FORM: "@@applications-list/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@applications-list/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@applications-list/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@applications-list/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@applications-list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@applications-list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@applications-list/FETCH_FAILURE",

    GET_REQUEST: "@@applications-list/GET_REQUEST",
    GET_SUCCESS: "@@applications-list/GET_SUCCESS",
    GET_FAILURE: "@@applications-list/GET_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@applications-list/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@applications-list/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@applications-list/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@applications-list/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@applications-list/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@applications-list/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@applications-list/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@applications-list/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@applications-list/EXPORT_DOWNLOAD_FAILURE",

    FETCH_SET_INFO: "@@applications-list/FETCH_SET_INFO",

    DOWNLOAD_REQUEST_EDMS: "@@applications-list/DOWNLOAD_REQUEST_EDMS",
    DOWNLOAD_SUCCESS_EDMS: "@@applications-list/DOWNLOAD_SUCCESS_EDMS",
    DOWNLOAD_FAILURE_EDMS: "@@applications-list/DOWNLOAD_FAILURE_EDMS",

    GET_USERS_WHO_ARRIVED_REQUEST: "@@applications-list/DOWNLOAD_REQUEST_EDMS",
    GET_USERS_WHO_ARRIVED_SUCCESS: "@@applications-list/GET_USERS_WHO_ARRIVED_SUCCESS",
    GET_USERS_WHO_ARRIVED_FAILURE: "@@applications-list/GET_USERS_WHO_ARRIVED_FAILURE",
};
