import { types } from "./types";

const initialState = {
    downloadFilterStorageKey: "providerValidation.download",
    uploadFilterStorageKey: "providerValidation.upload",
    downloadFilter: {},
    uploadFilter: {},
    downloadForm: {},
    uploadForm: {},
    downloadData: [],
    uploadData: [],
    token: "",
    pageLoading: false,
    tableLoading: false,
    file: null,
    currentTab: 0,
    showDownloadTable: false,
    showUploadTable: false,
    downloadInfo: {
        filter: {},
        sort: {
            createdAt: "desc",
        },
        page: 1,
        metadata: {},
    },
    uploadInfo: {
        filter: {},
        sort: {
            createdAt: "desc",
        },
        page: 1,
        metadata: {},
    },
    logServiceProviderDataVerificationLoading: false,
    logServiceProviderDataVerification: [],
    logSortingResultsLoading: false,
    logSortingResults: [],
};

export function providerValidation(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
            };
        case types.DEFAULT_FORM_DOWNLOAD:
            return {
                ...state,
                downloadFilter: initialState.downloadFilter,
                downloadInfo: initialState.downloadInfo,
                currentTab: 0,
            };
        case types.DEFAULT_FORM_UPLOAD:
            return {
                ...state,
                uploadFilter: initialState.uploadFilter,
                uploadInfo: initialState.uploadInfo,
                currentTab: 1,
            };
        case types.FETCH_SET_DOWNLOAD_INFO:
            return {
                ...state,
                downloadInfo: {
                    ...state.downloadInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_SET_UPLOAD_INFO:
            return {
                ...state,
                uploadInfo: {
                    ...state.uploadInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_REQUEST_DOWNLOAD:
            return {
                ...state,
                tableLoading: true,
                currentTab: 0,
                pageLoading: !state.showDownloadTable,
            };
        case types.FETCH_SUCCESS_DOWNLOAD:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                downloadInfo: {
                    ...state.downloadInfo,
                    metadata: action.data.data.metadata,
                },
                downloadData: action.data.data.data,
                showDownloadTable: true,
            };
        case types.FETCH_FAILURE_DOWNLOAD:
            return {
                ...state,
                tableLoading: false,
                downloadData: [],
                error: action.error,
            };
        case types.FETCH_REQUEST_UPLOAD:
            return {
                ...state,
                tableLoading: true,
                currentTab: 1,
                pageLoading: !state.showUploadTable,
            };
        case types.FETCH_SUCCESS_UPLOAD:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                uploadInfo: {
                    ...state.uploadInfo,
                    metadata: action.data.data.metadata,
                },
                uploadData: action.data.data.data,
                showUploadTable: true,
            };
        case types.FETCH_FAILURE_UPLOAD:
            return {
                ...state,
                tableLoading: false,
                uploadData: [],
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST_DOWNLOAD:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS_DOWNLOAD:
            return {
                ...state,
            };
        case types.DOWNLOAD_FAILURE_DOWNLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST_UPLOAD:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS_UPLOAD:
            return {
                ...state,
            };
        case types.DOWNLOAD_FAILURE_UPLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST_UPLOAD_REPLY:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS_UPLOAD_REPLY:
            return {
                ...state,
            };
        case types.DOWNLOAD_FAILURE_UPLOAD_REPLYs:
            return {
                ...state,
                error: action.error,
            };
        case types.UPLOAD_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPLOAD_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPLOAD_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_DOWNLOAD:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_DOWNLOAD:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_DOWNLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_UPLOAD:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_UPLOAD:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_UPLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_DOWNLOAD:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_DOWNLOAD:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_DOWNLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_UPLOAD:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_UPLOAD:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_UPLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_DOWNLOAD:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_DOWNLOAD:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_DOWNLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_UPLOAD:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_UPLOAD:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_UPLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.CHANGE_STATUS_REQUEST_DOWNLOAD:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_SUCCESS_DOWNLOAD:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_FAILURE_DOWNLOAD:
            return {
                ...state,
                error: action.error,
            };
        case types.CHANGE_STATUS_REQUEST_UPLOAD:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_SUCCESS_UPLOAD:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_FAILURE_UPLOAD:
            return {
                ...state,
                error: action.error,
            };

        case types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_REQUEST:
            return {
                ...state,
                logServiceProviderDataVerificationLoading: true,
                logServiceProviderDataVerification: [],
            };
        case types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_SUCCESS:
            return {
                ...state,
                logServiceProviderDataVerificationLoading: false,
                logServiceProviderDataVerification: action.data.data.data,
            };
        case types.GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_FAILURE:
            return {
                ...state,
                logServiceProviderDataVerificationLoading: false,
                logServiceProviderDataVerification: [],
            };
        case types.GET_LOG_SORTING_RESULTS_REQUEST:
            return {
                ...state,
                logSortingResultsLoading: true,
                logSortingResults: [],
            };
        case types.GET_LOG_SORTING_RESULTS_SUCCESS:
            return {
                ...state,
                logSortingResultsLoading: false,
                logSortingResults: action.data.data.data,
            };
        case types.GET_LOG_SORTING_RESULTS_FAILURE:
            return {
                ...state,
                logSortingResultsLoading: false,
                logSortingResults: [],
            };
        default:
            return state;
    }
}
