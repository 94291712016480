import Card from "../../../Common/Card";
import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import Form from "../../../Common/Form";
import SubmitButton from "../../../Common/SubmitButton";
import colors from "../../../../styles/colors";

const useStyles = makeStyles((theme) => ({
    delButton: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        float: "right",
        backgroundColor: colors.red,
        "&:hover": {
            backgroundColor: colors.redHover,
        },
    },
}));

export function ConsumptionMeter(props) {
    const classes = useStyles();
    const { form, meterKey } = props;
    const current = form.consumptionMeters[meterKey] ?? {};

    const serial = current.serial ?? meterKey + 1;
    const formErrors = form.errors ?? [];
    const getHelperText = (name) => {
        const errors = formErrors.filter((v) => v.name === name);
        return errors.length > 0 ? errors[0].label : "";
    };
    const getError = (name) => formErrors.filter((v) => v.name === name).length > 0;
    const formKey = `consumptionMeters[${meterKey}]`;

    const formProps = {
        form: form,
        name: props.name,
        changeform: props.changeForm,
    };

    const handleChange = (element) => {
        let formClone = Object.assign({}, form);
        current[element.target.name] = element.target.value;
        formClone.consumptionMeters[meterKey] = current;
        props.changeForm(formClone, "form");
    };

    return (
        <Card title={serial + ". fogyasztásmérő óra"} fullWidth>
            <Grid container item xs={12} spacing={2}>
                <Form {...formProps}>
                    <Title>
                        <TextField
                            label="Szám"
                            name="reference"
                            error={getError(`${formKey}.reference`)}
                            helperText={getHelperText(`${formKey}.reference`)}
                            value={current.reference || ""}
                            format={{ xs: 12, md: 4 }}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Jelenlegi állás"
                            name="reading"
                            error={getError(`${formKey}.reading`)}
                            helperText={getHelperText(`${formKey}.reading`)}
                            value={current.reading || ""}
                            format={{ xs: 12, md: 4 }}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Dátum"
                            name="readingDate"
                            error={getError(`${formKey}.readingDate`)}
                            helperText={getHelperText(`${formKey}.readingDate`)}
                            value={current.readingDate || ""}
                            type="date"
                            format={{ xs: 12, md: 4 }}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Záró állás"
                            name="closingReading"
                            error={getError(`${formKey}.closingReading`)}
                            helperText={getHelperText(`${formKey}.closingReading`)}
                            value={current.closingReading || ""}
                            format={{ xs: 12, md: 4 }}
                            onChange={handleChange}
                        />
                        <TextField
                            label="Záró dátum"
                            name="closedAt"
                            error={getError(`${formKey}.closedAt`)}
                            helperText={getHelperText(`${formKey}.closedAt`)}
                            value={current.closedAt || ""}
                            type="date"
                            format={{ xs: 12, md: 4 }}
                            onChange={handleChange}
                        />
                    </Title>
                </Form>
            </Grid>

            <SubmitButton
                className={classes.delButton}
                onClick={() => {
                    let formClone = Object.assign({}, form);
                    delete formClone.consumptionMeters[meterKey];
                    formClone.consumptionMeters = formClone.consumptionMeters.filter(function (e) {
                        return e;
                    });
                    //hibák törlése
                    formClone.errors = formClone?.errors?.filter((error) => {
                        !!error.name.includes(`consumptionMeters[${meterKey}]`);
                    });
                    // Újrasorszámozás
                    let srl_ = 1;
                    formClone.consumptionMeters = formClone.consumptionMeters.map((element) => {
                        element.serial = srl_++;
                        return element;
                    });
                    props.changeForm(formClone, "form");
                }}
            >
                Törlés
            </SubmitButton>
        </Card>
    );
}
