export const types = {
    DEFAULT_FORM: "@@application-consumption-places-by-contact/DEFAULT_FORM",
    CHANGE_FORM: "@@application-consumption-places-by-contact/CHANGE_FORM",

    FETCH_REQUEST: "@@application-consumption-places-by-contact/FETCH_REQUEST",
    FETCH_SUCCESS: "@@application-consumption-places-by-contact/FETCH_SUCCESS",
    FETCH_FAILURE: "@@application-consumption-places-by-contact/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@application-consumption-places-by-contact/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@application-consumption-places-by-contact/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@application-consumption-places-by-contact/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@application-consumption-places-by-contact/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@application-consumption-places-by-contact/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@application-consumption-places-by-contact/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@application-consumption-places-by-contact/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@application-consumption-places-by-contact/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@application-consumption-places-by-contact/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@application-consumption-places-by-contact/CLEAR_FORM",

    SET_LOADING: "@@application-consumption-places-by-contact/SET_LOADING",
};
