import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import colors from "../../../styles/colors";
import Card from "../../Common/Card";
import CancelButton from "../../Common/CancelButton";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { history } from "../../../store";
import { connect } from "react-redux";
import blackListActions from "../../../store/blackList/actions";
import notificationActions from "../../../store/notification/actions";
import PageTemplate from "../../Common/PageTemplate";

const useStyles = makeStyles((theme) => ({
    thead: {
        fontWeight: 550,
        backgroundColor: colors.primary,
        color: colors.white,
    },
    visible: {
        marginTop: theme.spacing(2),
    },
    nextButton: {
        marginTop: theme.spacing(2),
    },
}));

function BlackListAdd(props) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const { form, loading } = props;

    useEffect(() => {
        props.clearForm();
    }, []);

    const handleSearch = () => {
        props
            .checkCustomer(form.mavirId)
            .then(() => setVisible(true))
            .catch(() => props.addNotification("error", "Nincs ilyen ügyfél."));
    };

    const customerName = (data) => {
        if (data.title) return data.title + " " + data.lastName + " " + data.firstName;
        return data.lastName + " " + data.firstName;
    };

    return (
        <PageTemplate
            header={{
                title: "Ügyfél hozzáadása a tiltólistához",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    "/blacklist": "Tiltólista",
                    "add-to-blacklist": "Ügyfél hozzadása a tiltólistához",
                },
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card className={classes.cardClass} title="Ügyfél keresése">
                    <Form form={form} changeform={props.changeForm} name="form">
                        <TextField label="MAVIR ID" name="mavirId" value={form.mavirId || ""} format={{ xs: 12 }} />
                        <SubmitButton onClick={handleSearch}>Keresés</SubmitButton>
                        <CancelButton onClick={() => history.push(`/blacklist`)}>Vissza</CancelButton>
                    </Form>
                </Card>
            </Grid>
            {visible && (
                <Grid item xs={12}>
                    <Card title="Alapadatok ellenőrzése">
                        <Form form={form} changeform={props.changeForm} name="form">
                            <TextField
                                label="MAVIR ID"
                                name="mavirId"
                                value={form.mavirId || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Ügyfél neve"
                                name="customerName"
                                value={customerName(form) || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Anyja neve"
                                name="motherName"
                                value={form.motherLastName + " " + form.motherFirstName || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Születési hely"
                                name="birthPlace"
                                value={form.birthPlace || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                            <TextField
                                label="Születési idő"
                                name="birthDate"
                                value={form.birthDate || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                        </Form>
                        <SubmitButton className={classes.nextButton} onClick={() => history.push("/blacklist/add/new")}>
                            Tovább
                        </SubmitButton>
                    </Card>
                </Grid>
            )}
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading, data, customerForm, filter } = state.blackList;
    return { form, loading, data, customerForm, filter };
}

const actionCreators = {
    changeForm: blackListActions.changeForm,
    defaultForm: blackListActions.defaultForm,
    clearForm: blackListActions.clearForm,
    checkCustomer: blackListActions.checkCustomer,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(BlackListAdd);
