import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import colors from "../../styles/colors.js";
import MenuItem from "@material-ui/core/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";

/**
 * @param to - route
 * @param icon - ikon neve
 * @param text - oldal neve
 */

const useStyles = makeStyles(() => ({
    item: {
        color: colors.primary,
        "&:hover, &:hover i": {
            backgroundColor: colors.editHover,
            color: colors.white,
            "& $icon": {
                color: colors.white,
            },
        },
        "&.$selected": {
            color: colors.white,
        },
    },
    icon: {
        color: colors.primary,
    },
    selected: {
        backgroundColor: colors.edit,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.editHover,
        },
        "& $icon": {
            color: colors.white,
        },
        "& $item": {
            color: colors.white,
        },
    },
}));

export default function NavMenuItem(props) {
    const classes = useStyles();

    return (
        <MenuItem
            component={NavLink}
            className={classes.item}
            activeClassName={clsx(classes.selected)}
            key={props.route}
            {...props}
        >
            {props.icon && (
                <ListItemIcon>
                    <FontAwesomeIcon icon={props.icon} className={classes.icon} />
                </ListItemIcon>
            )}
            <Typography variant="inherit">{props.text}</Typography>
        </MenuItem>
    );
}
