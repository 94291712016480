import { types } from "./types";

const initialState = {
    baseFilterStorageKey: 'clients.base',
    detailedFilterStorageKey: 'clients.detailed',
    baseFilter: {},
    detailedFilter: {},
    dataBase: [],
    dataDetailed: [],
    token: "client",
    detailedFilterDataFinanciers: [],
    detailedFilterDataConsumptionPlaceServices1: [],
    detailedFilterDataConsumptionPlaceServices2: [],
    currentTab: 0,
    baseInfo: {
        filter: {},
        sort: {
            lastName: "asc",
        },
        page: 1,
        metadata: {},
    },
    detailedInfo: {
        filter: {},
        sort: {
            lastName: "asc",
        },
        page: 1,
        metadata: {},
    },
    pageLoading: true,
    tableLoading: false,
    showBaseTable: false,
    showDetailedTable: false,
};

export function clients(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.isLoading,
            };
        case types.DEFAULT_FORM_BASE:
            return {
                ...state,
                baseFilter: initialState.baseFilter,
                baseInfo: initialState.baseInfo,
                currentTab: 0,
            };
        case types.FETCH_SET_BASE_INFO:
            return {
                ...state,
                baseInfo: {
                    ...state.baseInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_SET_DETAILED_INFO:
            return {
                ...state,
                detailedInfo: {
                    ...state.detailedInfo,
                    [action.key]: action.value,
                },
            };
        case types.DEFAULT_FORM_DETAILED:
            return {
                ...state,
                detailedFilter: initialState.detailedFilter,
                detailedInfo: initialState.detailedInfo,
                currentTab: 1,
            };
        case types.FETCH_REQUEST_BASE:
            return {
                ...state,
                tableLoading: true,
                currentTab: 0,
                pageLoading: !state.showBaseTable,
            };
        case types.FETCH_SUCCESS_BASE:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                baseInfo: {
                    ...state.baseInfo,
                    metadata: action.data.data.metadata,
                },
                dataBase: action?.data?.data?.data,
                showBaseTable: true,
            };
        case types.FETCH_FAILURE_BASE:
            return {
                ...state,
                tableLoading: false,
                dataBase: [],
                error: action.error,
            };
        case types.FETCH_REQUEST_DETAILED:
            return {
                ...state,
                tableLoading: true,
                currentTab: 1,
                pageLoading: !state.showDetailedTable,
            };
        case types.FETCH_SUCCESS_DETAILED:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                detailedInfo: {
                    ...state.detailedInfo,
                    metadata: action.data.data.metadata,
                },
                dataDetailed: action?.data?.data?.data,
                showDetailedTable: true,
            };
        case types.FETCH_FAILURE_DETAILED:
            return {
                ...state,
                tableLoading: false,
                dataDetailed: [],
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_BASE:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_BASE:
            return {
                ...state,
                exportId: action.data.data.id,
                exportFileUnit: action?.data?.data?.fileUnit || null
            };
        case types.EXPORT_INITIATE_FAILURE_BASE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.FETCH_FILTER_FINANCIERS_SUCCESS:
            return {
                ...state,
                detailedFilterDataFinanciers: action.data.data.data,
            };
        case types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS:
            return {
                ...state,
                detailedFilterDataConsumptionPlaceServices1: action.data.data.data,
            };
        case types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS:
            return {
                ...state,
                detailedFilterDataConsumptionPlaceServices2: action.data.data.data,
            };
        default:
            return state;
    }
}
