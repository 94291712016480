import {
    templateCreate,
    templateGet,
    templateGetValidationCreate,
    templateUpdate,
    templateExportInitiate,
    templateExportCheck,
    templateExportDownload,
} from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/failed-login-attempts", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const failedLoginAttemptsService = {
    filter,
    get: templateGet("failed-login-attempts"),
    update: templateUpdate("failed-login-attempts"),
    create: templateCreate("failed-login-attempts"),
    getValidationCreate: templateGetValidationCreate("failed-login-attempts"),
    exportInitiate: templateExportInitiate("failed-login-attempts"),
    exportCheck: templateExportCheck("failed-login-attempts"),
    exportDownload: templateExportDownload("failed-login-attempts"),
};

export default failedLoginAttemptsService;
