import { types } from "./types";

const initialState = {
    filter: {},
};

export function cTariff(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
            };
        default:
            return state;
    }
}
