import React from "react";
import { Grid } from "@material-ui/core";
import Card from "../../Common/Card";
import CancelButton from "../../Common/CancelButton";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { history } from "../../../store";
import { connect } from "react-redux";
import blackListActions from "../../../store/blackList/actions";
import notificationActions from "../../../store/notification/actions";
import PageTemplate from "../../Common/PageTemplate";

function BlackListAddNew(props) {
    const { form, loading } = props;

    const handleSave = () => {
        props
            .create(form)
            .then(() => props.addNotification("success", "Sikeres mentés!", "/blacklist"))
            .catch((e) => {
                if (e.validationErrors) {
                    props.changeForm({ ...form, errors: e.validationErrors }, "form");
                    props.addNotification("error", "Kérjük ellenőrizze az adatokat");
                } else {
                    props.addNotification("error", "Hiba a mentés során!");
                }
            });
    };

    const customerName = (data) => {
        if (data.title) return data.title + " " + data.lastName + " " + data.firstName;
        return data.lastName + " " + data.firstName;
    };

    return (
        <PageTemplate
            header={{
                title: "Ügyfél hozzáadása a tiltólistához",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    "/blacklist": "Tiltólista",
                    "add-to-blacklist": "Ügyfél hozzadása a tiltólistához",
                },
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card title="Új bejegyzés">
                    <Form form={form} changeform={props.changeForm} name="form">
                        <TextField
                            label="MAVIR ID"
                            name="mavirId"
                            value={form.mavirId || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                        <TextField
                            label="Ügyfél neve"
                            name="customerName"
                            value={customerName(form) || ""}
                            format={{ xs: 12, sm: 6 }}
                            disabled
                        />
                        <TextField
                            label="Anyja neve"
                            name="motherName"
                            value={form.motherLastName + " " + form.motherFirstName || ""}
                            format={{ xs: 12, sm: 6 }}
                            disabled
                        />
                        <TextField
                            label="Hatályosság kezdete"
                            name="startDate"
                            value={form.startDate || ""}
                            format={{ xs: 12, sm: 6 }}
                            type="date"
                        />
                        <TextField
                            label="Hatályosság vége"
                            name="endDate"
                            value={form.endDate || ""}
                            format={{ xs: 12, sm: 6 }}
                            type="date"
                        />
                        <TextField 
                            label="Indoklás"
                            name="reason"
                            value={form.reason || ""}
                            format={{ xs: 12 }}
                            rule={{
                                length: 512
                            }}
                        />
                        <SubmitButton onClick={handleSave}>Mentés</SubmitButton>
                        <CancelButton onClick={() => history.push("/blacklist")}>Mégse</CancelButton>
                    </Form>
                </Card>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading, customerForm } = state.blackList;
    return { form, loading, customerForm };
}

const actionCreators = {
    changeForm: blackListActions.changeForm,
    create: blackListActions.create,
    defaultForm: blackListActions.defaultForm,
    checkCustomer: blackListActions.checkCustomer,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(BlackListAddNew);
