export const types = {
    DEFAULT_FORM: "@@users/DEFAULT_FORM",
    CHANGE_FORM: "@@users/CHANGE_FORM",

    GET_ALL_USERS_REQUEST: "@@users/GET_ALL_USERS_REQUEST",
    GET_ALL_USERS_SUCCESS: "@@users/GET_ALL_USERS_SUCCESS",
    GET_ALL_USERS_FAILURE: "@@users/GET_ALL_USERS_FAILURE",

    FETCH_REQUEST: "@@users/FETCH_REQUEST",
    FETCH_SUCCESS: "@@users/FETCH_SUCCESS",
    FETCH_FAILURE: "@@users/FETCH_FAILURE",

    FETCH_PUBLIC_REQUEST: "@@users/FETCH_PUBLIC_REQUEST",
    FETCH_PUBLIC_SUCCESS: "@@users/FETCH_PUBLIC_SUCCESS",
    FETCH_PUBLIC_FAILURE: "@@users/FETCH_PUBLIC_FAILURE",

    GET_REQUEST: "@@users/GET_REQUEST",
    GET_SUCCESS: "@@users/GET_SUCCESS",
    GET_FAILURE: "@@users/GET_FAILURE",

    GET_USER_PROFILE_REQUEST: "@@users/GET_USER_PROFILE_REQUEST",
    GET_USER_PROFILE_SUCCESS: "@@users/GET_USER_PROFILE_SUCCESS",
    GET_USER_PROFILE_FAILURE: "@@users/GET_USER_PROFILE_FAILURE",

    UPDATE_REQUEST: "@@users/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@users/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@users/UPDATE_FAILURE",

    CREATE_REQUEST: "@@users/CREATE_REQUEST",
    CREATE_SUCCESS: "@@users/CREATE_SUCCESS",
    CREATE_FAILURE: "@@users/CREATE_FAILURE",

    GET_VALIDATION_CREATE_REQUEST: "@@users/GET_VALIDATION_CREATE_REQUEST",
    GET_VALIDATION_CREATE_SUCCESS: "@@users/GET_VALIDATION_CREATE_SUCCESS",
    GET_VALIDATION_CREATE_FAILURE: "@@users/GET_VALIDATION_CREATE_FAILURE",

    GET_VALIDATION_UPDATE_REQUEST: "@@users/GET_VALIDATION_UPDATE_REQUEST",
    GET_VALIDATION_UPDATE_SUCCESS: "@@users/GET_VALIDATION_UPDATE_SUCCESS",
    GET_VALIDATION_UPDATE_FAILURE: "@@users/GET_VALIDATION_UPDATE_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@users/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@users/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@users/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@users/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@users/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@users/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@users/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@users/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@users/EXPORT_DOWNLOAD_FAILURE",
};
