import SearchForm from "../../utils/SearchForm";
import API from "../../utils/API";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "../index";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/consumption-data/validator", {
        params: searchForm.getSend(),
    })
        .then((response) => response.data)
        .catch((error) => {
            return Promise.reject(error);
        });
}

const consumptionDataValidatorService = {
    filter,
    exportInitiate: templateExportInitiate("consumption-data/validator"),
    exportCheck: templateExportCheck("consumption-data/validator"),
    exportDownload: templateExportDownload("consumption-data/validator"),
};

export default consumptionDataValidatorService;
