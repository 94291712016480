//Redux kialakításához szükséges importok
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";

//reducerek importja
import { authentication } from "./login/reducer";
import { activeClientsByFinanciersAndPermissions } from "./activeClientsByFinanciersAndPermissions/reducer";
import { applicationConsumptionPlacesByContact } from "./applicationConsumptionPlacesByContact/reducer";
import { applicationDetails } from "./applicationDetails/reducer";
import { applicationFiling } from "./applicationFiling/reducer";
import { applicationsList } from "./applicationsList/reducer";
import { applicationReceipt } from "./applicationReceipt/reducer";
import { users } from "./users/reducer";
import { clients } from "./clients/reducer";
import { clientDetails } from "./clientDetails/reducer";
import { consumptionPlace } from "./consumptionPlace/reducer";
import { fixedTimeClients } from "./fixedTimeClients/reducer";
import { employers } from "./employers/reducer";
import { providers } from "./providers/reducer";
import { blackList } from "./blackList/reducer";
import { cities } from "./cities/reducer";
import { tax } from "./tax/reducer";
import { failedLoginAttempts } from "./failedLoginAttempts/reducer";
import { monthlyConsumptionData } from "./monthlyConsumptionData/reducer";
import { batchCancellation } from "./batchCancellation/reducer";
import { consumptionDataSummary } from "./consumptionDataSummary/reducer";
import { consumptionDataValidator } from "./consumptionDataValidator/reducer";
import { consumptionData } from "./consumptionData/reducer";
import { consumptionDataByConsumptionPlace } from "./consumptionDataByConsumptionPlace/reducer";
import { consumptionDataByConsumptionPlaceWithBlocks } from "./consumptionDataByConsumptionPlaceWithBlocks/reducer";
import { consumptionDataByPayments } from "./consumptionDataByPayments/reducer";
import { consumptionDataByPermission } from "./consumptionDataByPermission/reducer";
import { consumptionDataByServiceProvider } from "./consumptionDataByServiceProvider/reducer";
import { consumptionDataByServiceProviderWithBlocks } from "./consumptionDataByServiceProviderWithBlocks/reducer";
import { consumptionPeriods } from "./consumptionPeriods/reducer";
import { auditOfFinancingAccounts } from "./auditOfFinancingAccounts/reducer";
import { employmentTerminations } from "./employmentTerminations/reducer";
import { cTariff } from "./cTariff/reducer";
import { exportSettings } from "./export/reducer";
import { GDPR } from "./GDPR/reducer";
import { filesList } from "./filesList/reducer";
import { providerValidation } from "./providerValidation/reducer";
import { notification } from "./notification/reducer";
import { systemLog } from "./systemLog/reducer";
import { commands } from "./commands/reducer";
import { admissedApplications } from "./admissedApplications/reducer";
import { permissions } from "./permissions/reducer";
import { cancellations } from "./cancellations/reducer";
import { deniedApplications } from "./deniedApplications/reducer";
import { serviceProviderSelector } from "./serviceProviderSelector/reducer";
import isEmpty from "../utils/isEmpty";
import { customerModificationImport } from "./customerModificationImport/reducer";

export const history = createBrowserHistory();
const loggerMiddleware = createLogger();

/**
 * Űrlap változását figyelő függvénysablon.
 * @param {Object} types
 *          A reducer típusai
 * @param {Object} form
 *          Változtatott érték
 * @param {string} name
 *          Változtatásra váró űrlap neve (name attribútuma)
 * @returns {void} Void
 */
export const templateChangeForm = (types) => (form, name) => (dispatch) =>
    dispatch({ type: types.CHANGE_FORM, data: { form: form, name: name } });

/**
 * Űrlapot alapállapotba visszahelyező függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} name
 *              Visszaállítandó űrlap neve (name attribútuma)
 * @returns {void} Void
 */
export const templateDefaultForm = (types) => (name) => (dispatch) => dispatch({ type: types.DEFAULT_FORM, name });

/**
 * Megadott service fetch metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a fetch metódusát kell meghívni
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateFetch = (types, service) => () => (dispatch) => {
    dispatch({ type: types.FETCH_REQUEST });
    return service.fetch().then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service filter metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a filter metódusát kell meghívni
 * @param {Object} form
 *              Szűrő értéke
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateFilter = (types, service) => (form) => (dispatch) => {
    let formToSend = Object.assign({}, form);

    if (formToSend.filter && !isEmpty(formToSend.filter)) {
        for (const [key, value] of Object.entries(formToSend.filter)) {
            if (value === "Invalid-Date") delete formToSend.filter[key];
        }
    }

    dispatch({ type: types.FETCH_REQUEST, formToSend });
    return service.filter(formToSend).then(
        (data) => {
            dispatch({ type: types.FETCH_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FETCH_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Oldal első betöltésekor megadott service filter metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a filter metódusát kell meghívni
 * @param {Object} form
 *              Szűrő értéke
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateFirstFetch = (types, service) => (form) => (dispatch) => {
    dispatch({ type: types.FIRST_FETCH_REQUEST, form });
    return service.filter(form).then(
        (data) => {
            dispatch({ type: types.FIRST_FETCH_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.FIRST_FETCH_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service exportInitiate metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a exportInitiate metódusát kell meghívni
 * @param {Object} form
 *              Szűrő értéke objektumban
 * @param {string} filter
 *              Szűrő értéke a szűréskor kapott metadatában lévő szűrőazonosító
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateExportInitiate = (types, service) => (form, filter) => (dispatch) => {
    dispatch({ type: types.EXPORT_INITIATE_REQUEST });
    return service.exportInitiate(form, filter).then(
        (data) => {
            dispatch({ type: types.EXPORT_INITIATE_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.EXPORT_INITIATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service exportCheck metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a fetch metódusát kell meghívni
 * @param {number} id
 *              Vizsgálandó export azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateExportCheck = (types, service) => (id) => (dispatch) => {
    dispatch({ type: types.EXPORT_CHECK_REQUEST });
    return service.exportCheck(id).then(
        (data) => {
            dispatch({ type: types.EXPORT_CHECK_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.EXPORT_CHECK_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service exportCheck metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types A reducer típusai
 * @param {string} service
 *              Service, aminek a exportCheck metódusát kell meghívni
 * @param {number} id Vizsgálandó export azonosítója
 * @param {number} num Szétszedett fájlok közül melyiket töltse le
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateExportDownload = (types, service) => (id, num) => (dispatch) => {
    dispatch({ type: types.EXPORT_DOWNLOAD_REQUEST });
    return service.exportDownload(id, num).then(
        (data) => {
            dispatch({ type: types.EXPORT_DOWNLOAD_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.EXPORT_DOWNLOAD_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service update metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek az update metódusát kell meghívni
 * @param {Object} form
 *              Új érték
 * @param {number} id
 *              Változtatandó elem azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateUpdate = (types, service) => (id, form) => (dispatch) => {
    dispatch({ type: types.UPDATE_REQUEST });
    return service.update(id, form).then(
        (data) => {
            dispatch({ type: types.UPDATE_SUCCESS });
            dispatch({ type: types.DEFAULT_FORM, name: "form" });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.UPDATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Használata ua., mint a templateUpdate-nek.
 */
export const templateUpdateClose = (types, service) => (id, form) => (dispatch) => {
    dispatch({ type: types.UPDATE_REQUEST });
    return service.updateClose(id, form).then(
        (data) => {
            dispatch({ type: types.UPDATE_SUCCESS });
            dispatch({ type: types.DEFAULT_FORM, name: "form" });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.UPDATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service get metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a get metódusát kell meghívni
 * @param {number} id
 *              Lekérendő elem azonosítója
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateGet = (types, service) => (id) => (dispatch) => {
    dispatch({ type: types.GET_REQUEST });
    return service.get(id).then(
        (data) => {
            dispatch({ type: types.GET_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service create metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a create metódusát kell meghívni
 * @param {Object} form
 *              Létrehozandó érték
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateCreate = (types, service) => (form) => (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return service.create(form).then(
        (data) => {
            dispatch({ type: types.CREATE_SUCCESS });
            dispatch({ type: types.DEFAULT_FORM, name: "form" });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.CREATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Használata ua., mint a templateCreate-nek.
 */
export const templateCreateChild = (types, service) => (form) => (dispatch) => {
    dispatch({ type: types.CREATE_REQUEST });
    return service.createChild(form).then(
        (data) => {
            dispatch({ type: types.CREATE_SUCCESS });
            dispatch({ type: types.DEFAULT_FORM, name: "form" });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.CREATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service getValidationCreate metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a getValidationCreate metódusát kell meghívni
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateGetValidationCreate = (types, service) => () => (dispatch) => {
    dispatch({ type: types.GET_VALIDATION_CREATE_REQUEST });
    return service.getValidationCreate().then(
        (data) => {
            dispatch({ type: types.GET_VALIDATION_CREATE_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_VALIDATION_CREATE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

/**
 * Megadott service templateGetValidationUpdate metódusát lehívó és naplózó függvénysablon.
 * @param {Object} types
 *              A reducer típusai
 * @param {string} service
 *              Service, aminek a templateGetValidationUpdate metódusát kell meghívni
 * @returns {Promise} A művelet sikeressége szerinti Promise
 */
export const templateGetValidationUpdate = (types, service) => () => (dispatch) => {
    dispatch({ type: types.GET_VALIDATION_UPDATE_REQUEST });
    return service.getValidationUpdate().then(
        (data) => {
            dispatch({ type: types.GET_VALIDATION_UPDATE_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_VALIDATION_UPDATE_FAILURE, error });
            return Promise.resolve(error);
        }
    );
};

/**
 * Törli az űrlapban lévő értékeket.
 * @param {Object} types
 *                A reducer típusai
 * @returns {void} Void
 */
export const templateClearForm = (types) => () => (dispatch) => {
    dispatch({ type: types.CLEAR_FORM });
};

/**
 * A töltési fázis státuszát lehet vele módosítani.
 * @param {Object} types
 *                A reducer típusai
 * @param {boolean} isLoading
 *                Töltési fázis új státusza
 * @returns {void} Void
 */
export const templateSetLoading = (types) => (isLoading) => (dispatch) =>
    dispatch({ type: types.SET_LOADING, isLoading });

/**
 * Az oldal töltési fázis státuszát lehet vele módosítani.
 * @param {Object} types
 *                A reducer típusai
 * @param {boolean} isLoading
 *                Töltési fázis új státusza
 * @returns {void} Void
 */
export const templateSetPageLoading = (types) => (isLoading) => (dispatch) =>
    dispatch({ type: types.SET_PAGE_LOADING, isLoading });

/**
 *
 * @param {Object} types
 *                A reducer típusai
 * @param {string} key
 *                Változtatandó kulcs
 * @param {string} key
 *                Változtatandó érték
 * @returns {void} Void
 */
export const templateSetInfo = (types) => (key, value) => (dispatch) =>
    dispatch({ type: types.FETCH_SET_INFO, key, value });

const rootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        activeClientsByFinanciersAndPermissions,
        admissedApplications,
        applicationConsumptionPlacesByContact,
        applicationDetails,
        applicationFiling,
        applicationReceipt,
        applicationsList,
        auditOfFinancingAccounts,
        authentication,
        batchCancellation,
        blackList,
        cancellations,
        cities,
        clients,
        clientDetails,
        consumptionData,
        consumptionDataByConsumptionPlace,
        consumptionDataByConsumptionPlaceWithBlocks,
        consumptionDataByServiceProvider,
        consumptionDataByServiceProviderWithBlocks,
        consumptionDataByPayments,
        consumptionDataByPermission,
        consumptionDataSummary,
        consumptionDataValidator,
        consumptionPeriods,
        consumptionPlace,
        cTariff,
        deniedApplications,
        employers,
        employmentTerminations,
        exportSettings,
        GDPR,
        failedLoginAttempts,
        filesList,
        customerModificationImport,
        fixedTimeClients,
        monthlyConsumptionData,
        notification,
        permissions,
        providers,
        providerValidation,
        systemLog,
        tax,
        users,
        commands,
        serviceProviderSelector,
    });

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer(history),
    process.env.NODE_ENV !== 'production' ?
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware, loggerMiddleware)) :
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunkMiddleware))
);
