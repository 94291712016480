import { withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import colors from "../../styles/colors.js";

const Close = withStyles({
    root: {
        backgroundColor: colors.white,
        borderRadius: 3,
        color: colors.red,
        fontSize: "xx-large",
        marginTop: "0.7vh",
    },
})(CloseIcon);

export default Close;
