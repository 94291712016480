import { types } from "./types";

const initialState = {
    filterStorageKey: "operators.systemLog",
    filter: {},
    pageLoading: false,
    tableLoading: false,
    data: [],
    types: [],
    events: [],
    tableInfo: {
        filter: {},
        sort: {
            createdAt: "desc"
        },
        page: 1,
        metadata: {},
    },
};

export function systemLog(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                tableInfo: initialState.tableInfo,
                filter: initialState.filter,
                [action.name]: initialState[action.name],
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.FIRST_FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
                pageLoading: true,
            };
        case types.FIRST_FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: action.data.data.data,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.data?.metadata,
                },
            };
        case types.FIRST_FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: [],
                error: action.error,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.data?.metadata,
                },
                data: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                data: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                pageLoading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                form: action.data.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                pageLoading: false,
                form: {},
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_TYPES_AND_EVENTS_REQUEST:
            return {
                ...state,
            };
        case types.GET_TYPES_AND_EVENTS_SUCCESS:
            return {
                ...state,
                types: action.data.data.types,
                events: action.data.data.events,
            };
        case types.GET_TYPES_AND_EVENTS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
