import {
    templateExportInitiate,
    templateExportCheck,
    templateExportDownload,
    templateGet,
    templateGetValidationCreate,
    templateGetValidationUpdate,
    createSubmit,
    updateSubmit,
} from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    let formToSend = Object.assign({}, form);

    if (formToSend.reference) formToSend.reference = Number(formToSend.reference);

    const searchForm = new SearchForm(formToSend);

    return API.get("/service-providers", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const providersService = {
    filter,
    get: templateGet("service-providers"),
    update: updateSubmit("service-providers"),
    updateClose: updateSubmit("service-providers/close"),
    create: createSubmit("service-providers"),
    createChild: createSubmit("service-providers/child"),
    getValidationCreate: templateGetValidationCreate("service-providers"),
    getValidationUpdate: templateGetValidationUpdate("service-providers"),
    exportInitiate: templateExportInitiate("service-providers"),
    exportCheck: templateExportCheck("service-providers"),
    exportDownload: templateExportDownload("service-providers"),
};

export default providersService;
