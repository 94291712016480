export const types = {
    DEFAULT_FORM: "@@consumption-periods/DEFAULT_FORM",
    CHANGE_FORM: "@@consumption-periods/CHANGE_FORM",

    FETCH_REQUEST: "@@consumption-periods/FETCH_REQUEST",
    FETCH_SUCCESS: "@@consumption-periods/FETCH_SUCCESS",
    FETCH_FAILURE: "@@consumption-periods/FETCH_FAILURE",

    ACTIVATE_REQUEST: "@@consumption-periods/ACTIVATE_REQUEST",
    ACTIVATE_SUCCESS: "@@consumption-periods/ACTIVATE_SUCCESS",
    ACTIVATE_FAILURE: "@@consumption-periods/ACTIVATE_FAILURE",

    DEACTIVATE_REQUEST: "@@consumption-periods/DEACTIVATE_REQUEST",
    DEACTIVATE_SUCCESS: "@@consumption-periods/DEACTIVATE_SUCCESS",
    DEACTIVATE_FAILURE: "@@consumption-periods/DEACTIVATE_FAILURE",
};
