import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import blackListActions from "../../../store/blackList/actions";
import notificationActions from "../../../store/notification/actions";
import OperationButton from "../../Common/OperationButton";
import Autocomplete from "../../Common/Autocomplete";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import usersActions from "../../../store/users/actions";
import { userOptions } from "../../../utils/listOptions/userOptions";
import { makeStyles } from "@material-ui/core";
import Tooltip from "../../Common/Tooltip";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

const useStyles = makeStyles(() => ({
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

function BlackList(props) {
    const { filter, data, loading, token, users, filterStorageKey } = props;
    const { mavirId } = props?.location?.state ? props.location.state : "";

    function handleDelete(id) {
        props
            .close(id)
            .then(() => {
                props.search(filter);
                props.addNotification("success", "Sikeres törlés!");
            })
            .catch(() => props.addNotification("error", "Hiba lépett fel törlés közben."));
    }

    function OperationsBtnCellRenderer(params) {
        const id = params.data?.id;
        return (
            <>
                {params.data.status != -1 && (
                    <OperationButton
                        type="delete"
                        confirmation="Biztos törli ezt az elemet?"
                        handleClick={() => handleDelete(id)}
                    />
                )}
            </>
        );
    }

    function EndDateCellRenderer(params) {
        return params.data?.endDate === "" ? "2099-12-31" : params.data?.endDate;
    }

    function ReasonCellRenderer(params) {
        const classes = useStyles();

        const value = params.data?.reason;

        //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
        const reason = value === 0 ? "0" : value;

        return (
            <Tooltip title={reason ? reason : ""}>
                <div className={classes.truncate}>{reason ? reason.substring(0,30) : ""}</div>
            </Tooltip>
        );
    }

    function StatusCellRenderer(params) {
        switch (params.data.status) {
            case -1:
                return "Törölt";
            case 1:
                return "Aktív";
            case 0:
                return "Inaktív";
            default:
                return "-";
        }
    }

    const statusOptions = [
        { value: 1, label: "Aktív" },
        { value: 0, label: "Inaktív" },
        { value: -1, label: "Törölt" },
    ];

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Ügyfél neve", field: "customerName" },
            { headerName: "Születési dátum", field: "birthDate" },
            { headerName: "Bekerülés dátuma", field: "createdAt" },
            { headerName: "Hatályosság  kezdete", field: "startDate", sort: "desc" },
            { headerName: "Hatályosság vége", field: "endDate", cellRenderer: "endDateCellRenderer" },
            { headerName: "Indoklás", field: "reason"/* , cellRenderer: "reasonCellRenderer" */ },
            { headerName: "Bejelentő", field: "createdBy.name" },
            {
                headerName: "Állapot",
                field: "status",
                cellRenderer: "statusCellRenderer"
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "operationsBtnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data,
        frameworkComponents: {
            operationsBtnCellRenderer: OperationsBtnCellRenderer,
            statusCellRenderer: StatusCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
            reasonCellRenderer: ReasonCellRenderer,
            endDateCellRenderer: EndDateCellRenderer
        },
        autoSize: "true",
        to: "blacklist/add",
        newButtonText: "Ügyfél hozzáadása",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
    };

    useEffect(() => {
        let storageFilter = filterStorageGet(filterStorageKey);
        if (mavirId) {
            storageFilter = {mavirId : mavirId};
        }
        props.changeForm(storageFilter, "filter");
        props.getUsers();
        props.search(storageFilter);
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Tiltólista",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    blackList: "Tiltólista",
                },
            }}
            filter={{
                onSubmit: (formfilter) => {
                    props.search(formfilter);
                    filterStorageSave(filterStorageKey, formfilter);
                },
                defaultForm: (name) => {
                    props.defaultForm(name);
                    filterStorageSave(filterStorageKey, {});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
        >
            <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 6 }} />
            <TextField
                label="Ügyfél neve"
                name="customerName"
                value={filter.customerName || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Születési dátum (-tól)"
                name="birthDateFrom"
                value={filter.birthDateFrom || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Születési dátum (-ig)"
                name="birthDateTo"
                value={filter.birthDateTo || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Bekerülés dátuma (-tól)"
                name="createdAtFrom"
                value={filter.createdAtFrom || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Bekerülés dátuma (-ig)"
                name="createdAtTo"
                value={filter.createdAtTo || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Hatályosság kezdete (-tól)"
                name="startDateFrom"
                value={filter.startDateFrom || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Hatályosság kezdete (-ig)"
                name="startDateTo"
                value={filter.startDateTo || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Hatályosság vége (-tól)"
                name="endDateFrom"
                value={filter.endDateFrom || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Hatályosság vége (-ig)"
                name="endDateTo"
                value={filter.endDateTo || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <Autocomplete
                value={filter.createdBy || []}
                options={userOptions(users)}
                id="createdBy"
                name="createdBy"
                format={{ xs: 12, md: 6 }}
                label="Bejelentő"
            />
            <Autocomplete
                value={filter.status || []}
                options={statusOptions}
                id="status"
                name="status"
                label="Állapot"
                format={{ xs: 12, md: 6 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, filterStorageKey} = state.blackList;
    const users = state.users.dataPublic;
    return { filter, data, loading, token, users, filterStorageKey };
}

const actionCreators = {
    changeForm: blackListActions.changeForm,
    search: blackListActions.filter,
    defaultForm: blackListActions.defaultForm,
    close: blackListActions.close,
    addNotification: notificationActions.addNotification,
    exportInitiate: blackListActions.exportInitiate,
    exportCheck: blackListActions.exportCheck,
    exportDownload: blackListActions.exportDownload,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(BlackList);
