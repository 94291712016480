import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React, { Children, useState } from "react";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField";
import colors from "../../../../styles/colors";
import Select from "../../../Common/Select";
import Title from "../../../Common/Title";

export default function TaxExemptionForm(props) {
    const { form, type, employers, validation, contactEmployerId } = props;
    const [isTaxFree, setIsTaxFree] = useState(false);
    const [isContinuous, setIsContinuous] = useState(false);

    const formProps = {
        changeform: props.onChange,
        name: "taxExemption",
        form: form,
        validation: validation,
    };

    const isTaxFreeOption = {
        name: "isTaxFree",
        label: "Az igénylő adómentes juttatásként veheti-e igénybe az Alkalmazotti kedvezményt",
    };
    const isContinuousOption = {
        name: "isContinuous",
        label: "2009. augusztus 31. óta folyamatos munkaviszonyban áll a villamosenergia-ipari társaságnál",
    };
    const formerEmployers = ["formerEmployer1", "formerEmployer2", "formerEmployer3", "formerEmployer4", "formerEmployer5"];

    const employerOptions = employers.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
        };
    });

    const contactEmployer = employers?.find((employer) => employer?.id === contactEmployerId);

    const contactEmployerOption = [
        {
            value: contactEmployer?.id,
            label: contactEmployer?.name,
        },
    ];

    const formerEmployerOptions = [];

    for (let i = 0; i < 6; i++) formerEmployerOptions.push({ value: i, label: i });

    const handleTaxFreeChange = () => {
        setIsTaxFree(!isTaxFree);

        props.onChange({ ...form, taxFree: !isTaxFree }, "taxExemption");
    };

    const handleContinuousChange = () => {
        setIsContinuous(!isContinuous);

        props.onChange({ ...form, continuousEmployment: !isContinuous }, "taxExemption");
    };

    return (
        <Form {...formProps}>
            {type === "employee" || type === "trade_union_employee" ? (
                <Form {...formProps} childComponent format={{ xs: 12 }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name={isTaxFreeOption.name}
                                style={{
                                    color: colors.nextHover,
                                }}
                                checked={form.taxFree === undefined || form.taxFree === null ? false : form.taxFree}
                                onChange={handleTaxFreeChange}
                            />
                        }
                        label={isTaxFreeOption.label}
                        format={{ xs: 12 }}
                    />
                    {form.taxFree ? (
                        <Form {...formProps} childComponent format={{ xs: 12 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={isContinuousOption.name}
                                        style={{
                                            color: colors.nextHover,
                                        }}
                                        checked={
                                            form.continuousEmployment === undefined ||
                                            form.continuousEmployment === null
                                                ? false
                                                : form.continuousEmployment
                                        }
                                        onChange={handleContinuousChange}
                                    />
                                }
                                label={isContinuousOption.label}
                                format={{ xs: 12 }}
                            />
                            {form.continuousEmployment ? (
                                <Title title="Jelenlegi munkaviszony" {...formProps} format={{ xs: 12 }}>
                                    <TextField
                                        label="Kezdete"
                                        type="date"
                                        value={form.employmentStartDate}
                                        name="employmentStartDate"
                                        margin="none"
                                        format={{ xs: 12, md: 6 }}
                                    />
                                    <Select
                                        selectLabel="Munkáltató"
                                        name="employments"
                                        value={form.employments || ""}
                                        margin="none"
                                        optionList={contactEmployerOption}
                                        format={{ xs: 12, md: 6 }}
                                    />
                                    <Select
                                        selectLabel="Korábbi munkáltatók száma"
                                        name="formerEmployersCount"
                                        value={form.formerEmployersCount}
                                        helperText="Csak a 2008. 01. 01. utáni munkáltatókat kell megadni"
                                        optionList={formerEmployerOptions}
                                        format={{ xs: 12 }}
                                    />
                                    {Children.toArray(
                                        formerEmployers
                                            .slice(0, form.formerEmployersCount || 0)
                                            .map((employer, index) => (
                                                <Title
                                                    title={`${index + 1}. Korábbi munkaviszony`}
                                                    format={{ xs: 12 }}
                                                    {...formProps}
                                                >
                                                    <TextField
                                                        label="Kezdete"
                                                        type="date"
                                                        value={form[employer]?.employmentStartDate || ""}
                                                        name={`${employer}.employmentStartDate`}
                                                        margin="none"
                                                        format={{ xs: 12, md: 6 }}
                                                    />
                                                    <TextField
                                                        label="Vége"
                                                        type="date"
                                                        value={form[employer]?.employmentEndDate || ""}
                                                        name={`${employer}.employmentEndDate`}
                                                        margin="none"
                                                        format={{ xs: 12, md: 6 }}
                                                    />
                                                    <Select
                                                        selectLabel="Munkáltató"
                                                        value={form[employer]?.employments || ""}
                                                        name={`${employer}.employments`}
                                                        margin="none"
                                                        optionList={employerOptions}
                                                        format={{ xs: 12 }}
                                                    />
                                                </Title>
                                            ))
                                    )}
                                </Title>
                            ) : (
                                <Typography format={{ xs: 12 }}>
                                    Mivel az igénylő nem áll folyamatos munkaviszonyban villamosenergia-ipari
                                    társaságnál, ezért nem jogosult az adókedvezményre.
                                </Typography>
                            )}
                        </Form>
                    ) : (
                        <Form {...formProps} childComponent format={{ xs: 12 }}>
                            <Title title="Jelenlegi munkaviszony" {...formProps} format={{ xs: 12 }}>
                                <TextField
                                    label="Kezdete"
                                    type="date"
                                    value={form.employmentStartDate}
                                    name="employmentStartDate"
                                    margin="none"
                                    format={{ xs: 12, md: 6 }}
                                />
                                <Select
                                    selectLabel="Munkáltató"
                                    name="employments"
                                    value={form.employments || ""}
                                    margin="none"
                                    optionList={contactEmployerOption}
                                    format={{ xs: 12, md: 6 }}
                                />
                            </Title>
                        </Form>
                    )}
                </Form>
            ) : (
                <Typography>
                    Az adómentesség elbírálása nyugdíjasok és özvegyek esetében nem tartozik az Alkalmazotti Tarifa
                    Rendszer hatáskörébe.
                </Typography>
            )}
        </Form>
    );
}
