export const types = {
    DEFAULT_FORM: "@@consumption-data-validator/DEFAULT_FORM",
    CHANGE_FORM: "@@consumption-data-validator/CHANGE_FORM",

    SET_PAGE_LOADING: "@@consumption-data-validator/SET_PAGE_LOADING",

    FIRST_FETCH_REQUEST: "@@consumption-data-validator/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@consumption-data-validator/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@consumption-data-validator/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@consumption-data-validator/FETCH_REQUEST",
    FETCH_SUCCESS: "@@consumption-data-validator/FETCH_SUCCESS",
    FETCH_FAILURE: "@@consumption-data-validator/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@consumption-data-validator/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@consumption-data-validator/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@consumption-data-validator/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@consumption-data-validator/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@consumption-data-validator/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@consumption-data-validator/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@consumption-data-validator/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@consumption-data-validator/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@consumption-data-validator/EXPORT_DOWNLOAD_FAILURE",

    FETCH_SET_INFO: "@@monthly-consumption-data/FETCH_SET_INFO",
};
