import { types } from "./types";

const initialState = {
    filterStorageKey: "blacklist",
    filter: {},
    form: {},
    customerForm: {},
    token: "",
    status: null,
    file: null,
    loading: false,
};

export function blackList(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.CHECK_CUSTOMER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CHECK_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data,
            };
        case types.CHECK_CUSTOMER_FAILURE:
            return {
                ...state,
                loading: false,
            };
        case types.CLOSE_REQUEST:
            return {
                ...state,
            };
        case types.CLOSE_SUCCESS:
            return {
                ...state,
            };
        case types.CLOSE_FAILURE:
            return {
                ...state,
            };
        case types.CLEAR_FORM:
            return {
                ...state,
                form: {},
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
