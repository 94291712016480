import React, { useEffect } from "react";
import PageTemplate from "../../Common/PageTemplate";
import applicationAction from "../../../store/applicationFiling/actions";
import { connect } from "react-redux";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { getUser, getUserId, hasRole } from "../../../utils/User";
import applicationDetailsActions from "../../../store/applicationDetails/actions";
import notificationActions from "../../../store/notification/actions";
import colors from "../../../styles/colors";
import { PERMISSIONS } from "../../../utils/AppConst";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
}));

function Main(props) {
    const { loading, drafts } = props;
    const classes = useStyles();

    function BarcodeCellRenderer(params) {
        return (
            <Link to={`/applications/${params.data.id}`} className={classes.link}>
                {params.data.barCode}
            </Link>
        );
    }

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton type="edit" to={`/application-filing/recording-data/${params?.data?.id}`} />
                {(hasRole(PERMISSIONS.ADMIN) || params?.data?.createdBy?.id === getUserId()) && (
                    <OperationButton
                        type="cancel"
                        confirmation={`Biztosan törli a "${params?.data?.barCode}" vonalkódú igénylőlapot?`}
                        submittext="Igen"
                        handleClick={() => handleDeleteApplication(params?.data?.id)}
                    />
                )}
            </>
        );
    }

    const userItem = getUser();

    if (userItem === null) {
        localStorage.setItem("user", JSON.stringify({}));
    }

    const roles = userItem?.roles;

    useEffect(() => {
        props.getDrafts();
    }, []);

    const handleDeleteApplication = (id) => {
        props
            .deleteApplication(id)
            .then(() => props.addNotification("success", "Sikeres törlés!"))
            .then(() => props.getDrafts())
            .catch(() => props.addNotification("error", "Hiba lépett fel törlés közben."));
    };

    return (
        <PageTemplate header={{ title: "Főoldal", breadcrumbs: {} }} loading={loading}>
            {roles?.filter((role) => role === PERMISSIONS.SERVICE_PROVIDER || role === PERMISSIONS.FINANCIER)?.length <
                roles?.length && (
                <Table
                    title="Folyamatban lévő igénylésfelvitelek"
                    rowData={drafts}
                    columnDefs={[
                        {
                            headerName: "",
                            field: "",
                            maxWidth: 10,
                            cellStyle: (params) => {
                                switch (params?.data?.type) {
                                    case "application":
                                        return {
                                            background: colors.green,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        };
                                    case "modification":
                                        return {
                                            background: colors.edit,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        };
                                    case "termination":
                                        return {
                                            background: colors.red,
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                        };
                                    default:
                                        break;
                                }
                            },
                        },
                        { headerName: "Azonosító", field: "id" },
                        {
                            headerName: "Vonalkód",
                            field: "barCode",
                            cellRenderer: "barcodeCellRenderer",
                        },
                        { headerName: "Név", field: "name" },
                        { headerName: "Anyja neve", field: "motherName" },
                        {
                            headerName: "Feltöltő",
                            field: "createdBy.name",
                            hide: !roles?.includes(PERMISSIONS.ADMIN),
                        },
                        {
                            headerName: "Műveletek",
                            field: "operations",
                            cellRenderer: "btnCellRenderer",
                        },
                    ]}
                    autosize="true"
                    frameworkComponents={{
                        btnCellRenderer: BtnCellRenderer,
                        barcodeCellRenderer: BarcodeCellRenderer,
                    }}
                    noExportButton
                />
            )}
        </PageTemplate>
    );
}

function mapState(state) {
    const { drafts, loading } = state.applicationFiling;

    return { loading, drafts };
}

const actionCreators = {
    getDrafts: applicationAction.getDrafts,
    deleteApplication: applicationDetailsActions.deleteApplication,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Main);
