import colors from "../../../../styles/colors";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import { getUser, hasRole } from "../../../../utils/User";
import { Checkbox, FormControlLabel, makeStyles, Typography } from "@material-ui/core";
import { PERMISSIONS } from "../../../../utils/AppConst";
import { useState } from "react";

const useStyles = makeStyles(() => ({
    textLink: {
        fontSize: "0.9rem",
        marginLeft: "14px",
        marginRight: "14px",
        marginTop: "4px",
        color: "#377a06",
        "&:hover": {
            cursor: "pointer",
        },
    },
}));

const FORM_TYPES_WITH_LAST_EMPLOYER = [
    "pensioner",
    "pensioners_widow",
    "trade_union_pensioner",
    "trade_union_pensioner_widow",
    "employees_widow",
    "trade_union_employees_widow",
];

// Munkavállaló
function optionOne(form, employers) {
    return (
        <Select
            optionList={employers}
            selectLabel="A jelenlegi munkáltató / szakszervezet megnevezése"
            name="employerId"
            format={{ xs: 12 }}
            value={form.employerId || ""}
        />
    );
}

// Nyugdíjas
function optionTwo(form, employers, formProps) {
    const classes = useStyles();
    const [lastEmployerSelect, setLastEmployerSelect] = useState(!form.legacyLastEmployerName);

    const handlePensionerRegistrationNumberChange = (e) => {
        let value = e.target?.value || "";
        if (value?.length < form?.pensionerRegistrationNumber?.length) {
            formProps.changeform({ ...form, pensionerRegistrationNumber: value }, formProps.name);
            return;
        }
        if (value.length > 11 || isNaN(value.slice(-1))) return;

        value = value.replaceAll("-", "");
        value = [value.slice(0, 3), value.slice(3, 8), value.slice(8)].filter((elem) => elem.length > 0).join("-");

        formProps.changeform({ ...form, pensionerRegistrationNumber: value }, formProps.name);
    };

    return (
        <Form {...formProps} childComponent format={{ xs: 12 }}>
            <FormControlLabel
                control={
                    <Checkbox
                        name="isInProgress"
                        style={{
                            color: colors.nextHover,
                        }}
                        checked={form.isInProgress || form.pensionerRegistrationNumber === "in_progress"}
                    />
                }
                label="Nyugdíjazás folyamatban"
                format={{ xs: 12 }}
            />
            {!form.isInProgress && form.pensionerRegistrationNumber !== "in_progress" && (
                <TextField
                    label="Nyugdíjas törzsszáma"
                    value={
                        form.pensionerRegistrationNumber === "in_progress" ? "" : form.pensionerRegistrationNumber || ""
                    }
                    name="pensionerRegistrationNumber"
                    format={{ xs: 12 }}
                    rule={{
                        length: 11,
                        number: "true",
                    }}
                    onChange={handlePensionerRegistrationNumberChange}
                    helperText="Helyes formátum: 123-12345-1"
                />
            )}
            {lastEmployerSelect ? (
                <Select
                    optionList={employers}
                    selectLabel="Utolsó munkáltató kiválasztása"
                    name="lastEmployerId"
                    value={form.lastEmployerId || ""}
                    format={{ xs: 12 }}
                />
            ) : (
                <TextField
                    label="Utolsó munkáltató megnevezése"
                    value={form.legacyLastEmployerName || ""}
                    name="legacyLastEmployerName"
                    margin="none"
                    format={{ xs: 12 }}
                />
            )}
            {lastEmployerSelect ? (
                <Typography
                    onClick={() => {
                        setLastEmployerSelect(false);
                        formProps.changeform({ ...form, lastEmployerId: null }, formProps.name);
                    }}
                    className={classes.textLink}
                >
                    Egyéb munkáltató felvétele.
                </Typography>
            ) : (
                <Typography
                    onClick={() => {
                        setLastEmployerSelect(true);
                        formProps.changeform({ ...form, legacyLastEmployerName: null }, formProps.name);
                    }}
                    className={classes.textLink}
                >
                    Vissza a munkáltatók listájához.
                </Typography>
            )}
        </Form>
    );
}

// Özvegy
function optionThree(form, employers, formProps) {
    return (
        <Form {...formProps} childComponent>
            <Title title="Az elhunyt házastárs neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.lastName || ""}
                    name="deceased.lastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.firstName || ""}
                    name="deceased.firstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt születési neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.birthLastName || ""}
                    name="deceased.birthLastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.birthFirstName || ""}
                    name="deceased.birthFirstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt anyjának születési neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.motherLastName || ""}
                    name="deceased.motherLastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.motherFirstName || ""}
                    name="deceased.motherFirstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt születési helye, ideje" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Születési hely"
                    value={form.deceased?.birthPlace || ""}
                    name="deceased.birthPlace"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Születési ideje"
                    value={form.deceased?.birthDate || null}
                    name="deceased.birthDate"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                    type="date"
                />
            </Title>
            <Title title="Az elhalálozás időpontja" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Dátum"
                    value={form.deceased?.deathDate || ""}
                    name="deceased.deathDate"
                    margin="none"
                    type="date"
                    format={{ xs: 12 }}
                />
            </Title>
            <Title title="Halotti anyakönyvi kivonat száma" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Kivonat száma"
                    value={form.deceased?.deathCertificateNumber || ""}
                    name="deceased.deathCertificateNumber"
                    margin="none"
                    format={{ xs: 12 }}
                    rule={{
                        length: 128,
                    }}
                    helperText="Javasolt formátum: ME 1754757"
                />
            </Title>
            <Title title="Utolsó munkáltató" {...formProps} format={{ xs: 12 }}>
                <Select
                    optionList={employers}
                    selectLabel="Utolsó munkáltató kiválasztása"
                    name="deceased.lastEmployerId"
                    value={form.deceased?.lastEmployerId || ""}
                    margin="none"
                    format={{ xs: 12 }}
                />
            </Title>
            <Title
                title="Az elhunyttal kötött házasságra vonatkozó házassági anyakönyvi kivonat száma"
                {...formProps}
                format={{ xs: 12 }}
            >
                <TextField
                    label="Kivonat száma"
                    value={form.deceased?.marriageCertificateNumber || ""}
                    name="deceased.marriageCertificateNumber"
                    margin="none"
                    format={{ xs: 12 }}
                    rule={{
                        length: 128,
                    }}
                    helperText="Javasolt formátum: A637212 vagy 12/2365"
                />
            </Title>
            <Title
                title="Az Ön (igénylő) öregségi nyugdíjra való jogosultságának (várható) kezdete"
                {...formProps}
                format={{ xs: 12 }}
            >
                <TextField
                    label="Dátum"
                    value={form.retirementDate || ""}
                    name="retirementDate"
                    type="date"
                    margin="none"
                    format={{ xs: 12 }}
                />
            </Title>
        </Form>
    );
}

// Nyugdíjas özvegye
function optionFour(form, employers, formProps) {
    const classes = useStyles();

    const [lastEmployerSelect, setLastEmployerSelect] = useState(!form.deceased?.legacyLastEmployerName);

    return (
        <Form {...formProps} childComponent>
            <Title title="Az elhunyt házastárs neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.lastName || ""}
                    name="deceased.lastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.firstName || ""}
                    name="deceased.firstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt születési neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.birthLastName || ""}
                    name="deceased.birthLastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.birthFirstName || ""}
                    name="deceased.birthFirstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt anyjának születési neve" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    value={form.deceased?.motherLastName || ""}
                    name="deceased.motherLastName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Keresztnév"
                    value={form.deceased?.motherFirstName || ""}
                    name="deceased.motherFirstName"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Az elhunyt születési helye, ideje" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Születési hely"
                    value={form.deceased?.birthPlace || ""}
                    name="deceased.birthPlace"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Születési ideje"
                    value={form.deceased?.birthDate || null}
                    name="deceased.birthDate"
                    margin="none"
                    format={{ xs: 12, md: 6 }}
                    type="date"
                />
            </Title>
            <Title title="Az elhalálozás időpontja" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Dátum"
                    value={form.deceased?.deathDate || ""}
                    name="deceased.deathDate"
                    margin="none"
                    type="date"
                    format={{ xs: 12 }}
                />
            </Title>
            <Title title="Halotti anyakönyvi kivonat száma" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Kivonat száma"
                    value={form.deceased?.deathCertificateNumber || ""}
                    name="deceased.deathCertificateNumber"
                    margin="none"
                    format={{ xs: 12 }}
                    rule={{
                        length: 128,
                    }}
                    helperText="Javasolt formátum: ME 1754757"
                />
            </Title>
            {lastEmployerSelect ? (
                <Title title="Utolsó munkáltató" {...formProps} format={{ xs: 12 }}>
                    <Select
                        selectLabel="Utolsó munkáltató kiválasztása"
                        name="deceased.lastEmployerId"
                        value={form.deceased?.lastEmployerId || ""}
                        optionList={employers}
                        format={{ xs: 12 }}
                    />
                    <Typography
                        onClick={() => {
                            setLastEmployerSelect(false);
                            formProps.changeform(
                                {
                                    ...form,
                                    deceased: {
                                        ...form.deceased,
                                        lastEmployerId: undefined,
                                    },
                                },
                                formProps.name
                            );
                        }}
                        className={classes.textLink}
                    >
                        Egyéb munkáltató felvétele.
                    </Typography>
                </Title>
            ) : (
                <Title title="Utolsó munkáltató megnevezése" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        label="Utolsó munkáltató megnevezése"
                        name="deceased.legacyLastEmployerName"
                        value={form.deceased?.legacyLastEmployerName || ""}
                        margin="none"
                        format={{ xs: 12 }}
                    />
                    <Typography
                        onClick={() => {
                            setLastEmployerSelect(true);
                            formProps.changeform(
                                {
                                    ...form,
                                    deceased: {
                                        ...form.deceased,
                                        legacyLastEmployerName: undefined,
                                    },
                                },
                                formProps.name
                            );
                        }}
                        className={classes.textLink}
                    >
                        Vissza a munkáltatók listájához.
                    </Typography>
                </Title>
            )}

            <Title
                title="Az elhunyttal kötött házasságra vonatkozó házassági anyakönyvi kivonat száma"
                {...formProps}
                format={{ xs: 12 }}
            >
                <TextField
                    label="Kivonat száma"
                    value={form.deceased?.marriageCertificateNumber || ""}
                    name="deceased.marriageCertificateNumber"
                    margin="none"
                    format={{ xs: 12 }}
                    rule={{
                        length: 128,
                    }}
                    helperText="Javasolt formátum: A637212 vagy 12/2365"
                />
            </Title>
            <Title
                title="Az Ön (igénylő) öregségi nyugdíjra való jogosultságának (várható) kezdete"
                {...formProps}
                format={{ xs: 12 }}
            >
                <TextField
                    label="Dátum"
                    value={form.retirementDate || ""}
                    name="retirementDate"
                    type="date"
                    margin="none"
                    format={{ xs: 12 }}
                />
            </Title>
        </Form>
    );
}

export default function PermissionsForm(props) {
    const { form, validation, employers } = props;
    let optionList;

    const forms = [optionOne, optionTwo, optionThree, optionFour, optionOne, optionTwo, optionThree, optionFour];

    
    const permissionTypeOptions = [
        {
            value: "employee",
            label: "Munkavállaló (nem szakszervezeti alkalmazott)",
        },
        {
            value: "pensioner",
            label: "Nyugdíjas (nem nyugdíjazott szakszervezeti alkalmazott)",
        },
        {
            value: "employees_widow",
            label: "Munkavállaló özvegye (nem szakszervezeti alkalmazott özvegye)",
        },
        {
            value: "pensioners_widow",
            label: "Nyugdíjas özvegye (nem nyugdíjazott szakszervezeti alkalmazott özvegye)",
        },
        { value: "trade_union_employee", label: "Szakszervezeti alkalmazott" },
        {
            value: "trade_union_pensioner",
            label: "Nyugdíjazott szakszervezeti alkalmazott",
        },
        {
            value: "trade_union_employees_widow",
            label: "Szakszervezeti alkalmazott özvegye",
        },
        {
            value: "trade_union_pensioner_widow",
            label: "Nyugdíjazott szakszervezeti alkalmazott özvegye",
        },
    ];

    const employerContactPermissionTypeOptions = [
        {
            value: "employee",
            label: "Munkavállaló (nem szakszervezeti alkalmazott)",
        },
        { value: "trade_union_employee", label: "Szakszervezeti alkalmazott" },
    ];

    const pensionerContactPermissionTypeOptions = [
        {
            value: "pensioner",
            label: "Nyugdíjas (nem nyugdíjazott szakszervezeti alkalmazott)",
        },
        {
            value: "employees_widow",
            label: "Munkavállaló özvegye (nem szakszervezeti alkalmazott özvegye)",
        },
        {
            value: "pensioners_widow",
            label: "Nyugdíjas özvegye (nem nyugdíjazott szakszervezeti alkalmazott özvegye)",
        },
        {
            value: "trade_union_pensioner",
            label: "Nyugdíjazott szakszervezeti alkalmazott",
        },
        {
            value: "trade_union_employees_widow",
            label: "Szakszervezeti alkalmazott özvegye",
        },
        {
            value: "trade_union_pensioner_widow",
            label: "Nyugdíjazott szakszervezeti alkalmazott özvegye",
        },
    ];

    if (
        hasRole(PERMISSIONS.EMPLOYER_CONTACT) &&
        !hasRole(PERMISSIONS.ADMIN) &&
        !hasRole(PERMISSIONS.PENSIONER_CONTACT)
    ) {
        optionList = employerContactPermissionTypeOptions;
    } else {
        optionList = permissionTypeOptions;
    }

    if (
        !hasRole(PERMISSIONS.EMPLOYER_CONTACT) &&
        !hasRole(PERMISSIONS.ADMIN) &&
        hasRole(PERMISSIONS.PENSIONER_CONTACT)
    ) {
        optionList = pensionerContactPermissionTypeOptions;
    } else {
        optionList = permissionTypeOptions;
    }

    const user = getUser();
    const employerOptions =
        user && user.employers && user.employers.length !== 0 && !FORM_TYPES_WITH_LAST_EMPLOYER.includes(form.type)
            ? user.employers.map((employer) => {
                  return {
                      value: employer.id,
                      label: employer.name,
                  };
              })
            : employers.map((employer) => {
                  return {
                      value: employer.id,
                      label: employer.name,
                  };
              });

    const frontendValidation = [
        {
            field: "pensionerRegistrationNumber",
            type: "pensionerRegistrationNumber",
        },
        {
            field: "deceased.marriageCertificateNumber",
            type: "marriageCertificateNumber",
        },
    ];

    const formProps = {
        name: "legalBasis",
        changeform: props.onChange,
        form: form,
        validation: validation.concat(frontendValidation),
    };

    return (
        <Form {...formProps}>
            <Select
                selectLabel="Jogosultság alapja"
                optionList={optionList}
                name="type"
                format={{ xs: 12 }}
                value={form.type || ""}
            />
            {form.type &&
                forms[permissionTypeOptions.findIndex((e) => e.value === form.type)](form, employerOptions, formProps)}
        </Form>
    );
}
