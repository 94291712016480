import { templateChangeForm, templateDefaultForm, templateFetch, templateGet } from "..";
import citiesService from "../../services/cities";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    fetch: templateFetch(types, citiesService),
    defaultForm: templateDefaultForm(types, citiesService),
    get: templateGet(types, citiesService),
};

export default actions;
