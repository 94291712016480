import React from "react";
import Card from "../../Common/Card";
import PageTemplate from "../../Common/PageTemplate";
import ProviderForm from "./ProviderForm";

export default function CloseProvider(props) {
    const { id } = props.match.params;

    return (
        <PageTemplate
            header={{
                breadcrumbs: {
                    operators: "Operátorok",
                    "/providers": "Szolgáltatók",
                    "edit-provider": "Szolgáltató megszüntetése",
                },
            }}
        >
            <Card title="Szolgáltató megszüntetése" fullWidth>
                <ProviderForm id={id} isClose={true} />
            </Card>
        </PageTemplate>
    );
}
