import API from "../../utils/API";
import { history } from "../../store";
import { PERIODS_OF_VALIDITIES } from "../../utils/AppConst";

function login(form, desiredUrl = null) {
    return API.post("/login_check", form)
        .then((response) => {
            setToken(response.data.token);
            setRefreshToken(response.data.refresh_token);
            getUser().then(() => (desiredUrl ? history.push(desiredUrl) : history.push("/")));
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function refreshToken() {
    const rToken = getRefreshToken();
    return API.post("/token-refresh", { refresh_token: rToken })
        .then((response) => {
            setToken(response.data.token);
            setRefreshToken(response.data.refresh_token);
            return response.data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("token_created");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token_created");
}

function getUser() {
    return API.get("/profile")
        .then((response) => {
            setUser(response.data);
            return Promise.resolve(response);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
}

function getToken() {
    return localStorage.getItem("token");
}

function getTokenCreated() {
    return new Date(localStorage.getItem("token_created"));
}

function getTokenValidity() {
    if (getToken() === null) return false;
    return new Date() - getTokenCreated() < PERIODS_OF_VALIDITIES.accessToken;
}

function setToken(token) {
    localStorage.setItem("token", token);
    localStorage.setItem("token_created", new Date());
}

function getRefreshToken() {
    return localStorage.getItem("refresh_token");
}

function getRefreshTokenCreated() {
    return new Date(localStorage.getItem("refresh_token_created"));
}

function getRefreshTokenValidity() {
    if (getRefreshTokenCreated() === null) return false;
    return new Date() - getRefreshTokenCreated() < PERIODS_OF_VALIDITIES.refreshToken;
}

function setRefreshToken(token) {
    localStorage.setItem("refresh_token", token);
    localStorage.setItem("refresh_token_created", new Date());
}

function getUserObject() {
    return JSON.parse(localStorage.getItem("user"));
}

const userService = {
    login,
    logout,
    getUser,
    setUser,
    getToken,
    setToken,
    refreshToken,
    getUserObject,
    setRefreshToken,
    getRefreshToken,
    getTokenValidity,
    getRefreshTokenCreated,
    getRefreshTokenValidity,
};

export default userService;
