import { withStyles } from "@material-ui/core";
import PlayForWork from "@material-ui/icons/PlayForWork";
import colors from "../../styles/colors.js";

const DownloadResponse = withStyles({
    root: {
        backgroundColor: colors.back,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.backHover,
        },
    },
})(PlayForWork);

export default DownloadResponse;
