import { Typography } from "@material-ui/core";
import { history } from "../../store";
import OffLayoutTemplate from "../Common/OffLayoutTemplate";
import SubmitButton from "../Common/SubmitButton";

export default function ErrorPageTemplate(props) {
    const { code, description } = props;
    return (
        <OffLayoutTemplate title={code}>
            <Typography align="center" variant="h1" style={{ zIndex: 1 }}>
                {code}
            </Typography>
            <Typography align="center" variant="h4" style={{ zIndex: 1 }} noWrap>
                {description}
            </Typography>
            <SubmitButton fullWidth style={{ marginTop: "10%", height: 40 }} onClick={() => history.push("/")}>
                Vissza a főoldalra
            </SubmitButton>
        </OffLayoutTemplate>
    );
}
