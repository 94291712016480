import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    Checkbox,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import PageTemplate from "../../Common/PageTemplate";
import Card from "../../Common/Card";
import Form from "../../Common/Form";
import consumptionPeriodsActions from "../../../store/consumptionPeriods/actions";
import colors from "../../../styles/colors";
import Loading from "../../Common/Loading";
import notificationActions from "../../../store/notification/actions";

const option = [true, false];

const useStyles = makeStyles((theme) => ({
    root: {},
    tableCell: {
        border: "1px solid black",
        padding: "0px",
    },
    table: {
        minWidth: 650,
        fontSize: "1rem",
        textAlign: "center",
    },
    tableHeader: {
        background: colors.primary,
        fontWeight: "bold",
        color: colors.white,
        height: "43px",
    },
    firstCols: {
        background: colors.primary,
        fontWeight: "bold",
        color: colors.white,
    },
    checkbox: {
        color: colors.primary,
    },
    saveButton: {
        marginTop: theme.spacing(1),
    },
}));

function ConsumptionPeriods(props) {
    const { form, data, loading } = props;
    const classes = useStyles();

    useEffect(() => props.search(form), []);
    if (loading) return <Loading />;

    function handleClick(e) {
        if (!e.target.checked) {
            props
                .deactivate(e.target.name)
                .then((response) => {
                    if (response.data?.violations?.length > 0) {
                        props.addNotification("error", response.data.violations[0].message);
                    }
                })
                .catch((error) => props.addNotification("error", error?.response?.data?.detail || "Hiba"));
        } else {
            props
                .activate(e.target.name)
                .then((response) => {
                    if (response.data?.violations?.length > 0) {
                        props.addNotification("error", response.data.violations[0].message);
                    }
                })
                .catch((error) => props.addNotification("error", error?.response?.data?.detail || "Hiba"));
        }
    }

    const years = data?.map((item) => item.year)?.filter((value, index, self) => self.indexOf(value) === index);

    const months = data?.map((item) => item.month)?.filter((value, index, self) => self.indexOf(value) === index);

    const yearsWithMonths = [];

    years?.forEach((year) => {
        data?.filter((value) => {
            if (year === value.year) yearsWithMonths.push(`${value.year}${value.month}`);
        });
    });

    return (
        <PageTemplate
            header={{
                title: "Fogyasztási időszakok",
                breadcrumbs: {
                    operators: "Operátorok",
                    "consumption-periods": "Fogyasztási időszakok",
                },
            }}
        >
            <Grid item xs={12}>
                <Card title="Fogyasztási időszakok engedélyezése">
                    <Form form={form} changeform={props.changeForm} name="form">
                        {data.length > 0 ? (
                            <TableContainer>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className={`${classes.tableCell} ${classes.tableHeader}`}
                                            ></TableCell>
                                            {years.map((year) => (
                                                <TableCell
                                                    align="center"
                                                    className={`${classes.tableCell} ${classes.tableHeader}`}
                                                    key={year}
                                                >
                                                    {year}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {months.map((month) => (
                                            <TableRow key={month}>
                                                <TableCell
                                                    align="center"
                                                    className={`${classes.tableCell} ${classes.firstCols}`}
                                                >
                                                    {month}
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableCell}>
                                                    <Checkbox
                                                        color="default"
                                                        className={classes.checkbox}
                                                        name={data[parseInt(month) - 1]?.id.toString()}
                                                        option={option}
                                                        defaultChecked={data[parseInt(month) - 1]?.active}
                                                        onClick={(e) => handleClick(e)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableCell}>
                                                    <Checkbox
                                                        color="default"
                                                        className={classes.checkbox}
                                                        name={data[parseInt(month) + 11]?.id.toString()}
                                                        option={option}
                                                        defaultChecked={data[parseInt(month) + 11]?.active}
                                                        onClick={(e) => handleClick(e)}
                                                    />
                                                </TableCell>
                                                <TableCell align="center" className={classes.tableCell}>
                                                    {yearsWithMonths.includes(years[2].toString() + month) ? (
                                                        <Checkbox
                                                            color="default"
                                                            className={classes.checkbox}
                                                            name={data[parseInt(month) + 23]?.id.toString()}
                                                            option={option}
                                                            defaultChecked={data[parseInt(month) + 23]?.active}
                                                            onClick={(e) => handleClick(e)}
                                                        />
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography>Hiba történt a fogyasztási időszakok betöltése során</Typography>
                        )}
                    </Form>
                </Card>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, data, loading } = state.consumptionPeriods;
    return { form, data, loading };
}

const actionCreators = {
    changeForm: consumptionPeriodsActions.changeForm,
    search: consumptionPeriodsActions.filter,
    fetch: consumptionPeriodsActions.fetch,
    activate: consumptionPeriodsActions.activate,
    deactivate: consumptionPeriodsActions.deactivate,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(ConsumptionPeriods);
