import exportService from "../../services/export";
import { types } from "./types";

function getXlsxLimit() {
    return (dispatch) => {
        dispatch({ type: types.GET_XLSX_LIMIT_REQUEST });
        return exportService.getXlsxLimit().then(
            (data) => {
                dispatch({ type: types.GET_XLSX_LIMIT_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_XLSX_LIMIT_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    getXlsxLimit,
};

export default actions;
