import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import colors from "../../styles/colors";
import Form from "./Form";

const useStyle = makeStyles(() => ({
    title: {
        fontSize: "larger",
        borderBottom: "1px solid " + colors.primary,
        color: colors.primary,
    },
    bold: {
        fontWeight: "bolder",
    },
}));

/**
 * @param title lesz a cím,
 * @param format a méretezést adja meg,
 */
export default function Title(props) {
    const classes = useStyle();

    return (
        <Form childComponent format={{ xs: 12 }} {...props}>
            <Typography className={classes.title + (props.noBold ? "" : " " + classes.bold)} format={{ xs: 12 }}>
                {props.title}
            </Typography>
            {props.children}
        </Form>
    );
}
