import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import OperationButton from "../../Common/OperationButton";
import { connect } from "react-redux";
import applicationsListActions from "../../../store/applicationsList/actions";
import applicationReceiptActions from "../../../store/applicationReceipt/actions";
import notificationActions from "../../../store/notification/actions";
import Autocomplete from "../../Common/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import usersActions from "../../../store/users/actions";
import { APPLICATION_STATUS_FUNCTION, APPLICATION_STATUS_OPTIONS, PERMISSIONS } from "../../../utils/AppConst";
import colors from "../../../styles/colors";
import Select from "../../Common/Select";
import { setInfo } from "../../../utils/FilterHelper";
import { userOptions } from "../../../utils/listOptions/userOptions";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";
import "./styles.css";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
}));

const showDeletedOptions = [
    { label: "Nem jelenik meg", value: "false" },
    { label: "Megjelenik", value: "true" },
    { label: "Csak sztornózott", value: "deletedOnly" },
];

const isArrivedOptions = [
    { label: "Igen", value: "true" },
    { label: "Nem", value: "false" },
];

function StatusCellRenderer(params) {
    return APPLICATION_STATUS_FUNCTION(params?.data?.status);
}

function ApplicationsList(props) {
    const {
        filter,
        data,
        pageLoading,
        tableLoading,
        providers,
        employers,
        token,
        tableInfo,
        users,
        usersWhoArrived,
        filterStorageKey,
    } = props;
    const classes = useStyles();

    const handleDelete = (id) => {
        props
            .deleteArrival(id)
            .then(() => props.addNotification("success", "Sikeres törlés!"))
            .then(() => props.search(filter))
            .catch(() => props.addNotification("error", "Hiba lépett fel törlés közben."));
    };

    const downloadEdms = (barCode, forceVal = false) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .downloadEdms(barCode, forceVal)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch((error) => {
                const errorMessage = error?.response?.status === 404 ? "A fájl nem található" : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
    };

    function BarcodeCellRenderer(params) {
        return (
            <Link to={`/applications/${params.data.id}`} className={classes.link}>
                {params.data.barCode}
            </Link>
        );
    }

    function ArrivalBarCodeCellRenderer(params) {
        return (
            <>
                {params.data.arrivalBarCode ? (
                    <Link to={`#`} onClick={() => downloadEdms(params?.data?.arrivalBarCode, true)} className={classes.link}>
                        {params.data.arrivalBarCode}
                    </Link>
                ) : params?.data?.status === "done" &&
                  (roles?.includes(PERMISSIONS.ADMIN) || roles?.includes(PERMISSIONS.C_TARIFF)) ? (
                    <Link to={`/application-receipt/${params.data.barCode}`} className={classes.link}>
                        Érkeztetés
                    </Link>
                ) : (
                    ""
                )}
            </>
        );
    }

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton type="info" to={`/applications/${params.data.id}`} />
                {params.data.arrivalBarCode &&
                    (roles?.includes(PERMISSIONS.ADMIN) || roles?.includes(PERMISSIONS.C_TARIFF)) && (
                        <OperationButton
                            type="delete-arrival"
                            confirmation={`Biztosan törli a "${params.data.arrivalBarCode}" számú érkeztetést?`}
                            submittext="Igen"
                            handleClick={() => handleDelete(params.data.id)}
                        />
                    )}
            </>
        );
    }

    const table = {
        columnDefs: [
            {
                headerName: "",
                field: "",
                maxWidth: 10,
                cellStyle: (params) => {
                    if (params?.data?.isDeleted) {
                        return {
                            background: colors.menuGroup, //szürke
                            paddingLeft: 0,
                            paddingRight: 0,
                        };
                    }
                    switch (params?.data?.type) {
                        case "application":
                            return {
                                background: colors.green,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "modification":
                            return {
                                background: colors.edit,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        case "termination":
                            return {
                                background: colors.red,
                                paddingLeft: 0,
                                paddingRight: 0,
                            };
                        default:
                            break;
                    }
                },
            },
            {
                headerName: "Vonalkód",
                field: "barCode",
                cellRenderer: "barcodeCellRenderer",
            },
            {
                headerName: "Érkeztetési vonalkód",
                field: "arrivalBarCode",
                cellRenderer: "arrivalBarCodeCellRenderer",
            },
            {
                headerName: "Státusz",
                field: "status",
                cellRenderer: "statusCellRenderer",
                maxWidth: 100,
            },
            { headerName: "Ügyfél neve", field: "name" },
            { headerName: "Anyja neve", field: "motherName" },
            { headerName: "Kapcsolattartó", field: "contact.name" },
            { headerName: "Feltöltő", field: "createdBy.name" },
            { headerName: "Létrehozás dátuma", field: "createdAt" },
            { headerName: "Feltöltés dátuma", field: "finalizedAt" },
            { headerName: "Jogosultság vége", field: "relatedPermissionEnd" },
            { headerName: "Érkeztető", field: "arrivedBy.name" },
            { headerName: "Érkeztetve", field: "arrivedAt" },
            {
                headerName: "Információ",
                field: "info",
                cellRenderer: "btnCellRenderer",
                sortable: false,
                minWidth: 150,
            },
        ],
        rowClassRules: {
            "deleted-application": (params) => params?.data?.isDeleted,
        },
        autoSize: "true",
        loading: tableLoading,
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            barcodeCellRenderer: BarcodeCellRenderer,
            statusCellRenderer: StatusCellRenderer,
            arrivalBarCodeCellRenderer: ArrivalBarCodeCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    useEffect(() => {
        let storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        props.getEmployers();
        props.getProviders();
        props.getUsers();
        props.getUsersWhoArrived();
        setInfo({ ...props, search: props.firstFetch }, "sort", { createdAt: "desc" }, "filter", storageFilter);
    }, []);

    const employerOptions = employers.map((employer) => {
        return {
            value: `${employer.id}_e`,
            label: employer.name,
            type_en: "employer",
            type_hu: "Munkáltatók",
        };
    });

    const providerOptions = providers.map((provider) => {
        return {
            value: `${provider.id}_p`,
            label: provider.name,
            type_en: "serviceProvider",
            type_hu: "Szolgáltatók",
        };
    });

    const optionsArray = employerOptions?.concat(providerOptions);

    const formProps = {
        onSubmit: () => {
            setInfo(props, "filter", filter, "sort", { createdAt: "desc" });
            filterStorageSave(filterStorageKey, filter);
        },
        defaultFormAction: () => {
            props.defaultForm("filter");
            filterStorageSave(filterStorageKey, {});
            setInfo(props, "filter", filter, "sort", { createdAt: "desc" });
        },
        form: filter,
        name: "filter",
        changeform: props.changeForm,
    };

    const roles = JSON.parse(localStorage.getItem("user")).roles;

    return (
        <ListPageTemplate
            header={{
                title: "Igénylések listája",
                breadcrumbs: {
                    applications: "Igénylések",
                    "applications-list": "Igénylések listája",
                },
            }}
            filter={{ ...formProps }}
            table={table}
            loading={pageLoading}
        >
            <TextField label="Vonalkód" name="barCode" value={filter.barCode || ""} format={{ xs: 12, md: 6 }} />
            <TextField
                label="Érkeztetési vonalkód"
                name="arrivalBarCode"
                value={filter.arrivalBarCode || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField label="Ügyfél neve" name="name" value={filter.name || ""} format={{ xs: 12, md: 6 }} />
            <TextField
                label="Anyja neve"
                name="motherName"
                value={filter.motherName || ""}
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                value={filter.status || []}
                options={APPLICATION_STATUS_OPTIONS}
                id="status"
                name="status"
                format={{ xs: 12, md: 6 }}
                label="Státusz"
            />
            <Autocomplete
                value={filter.createdBy || []}
                options={userOptions(users)}
                id="createdBy"
                name="createdBy"
                format={{ xs: 12, md: 6 }}
                label="Feltöltő"
            />
            <TextField
                label="Létrehozás dátuma (-tól)"
                type="date"
                name="createdAtFrom"
                value={filter.createdAtFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Létrehozás dátuma (-ig)"
                type="date"
                name="createdAtTo"
                value={filter.createdAtTo || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Feltöltés dátuma (-tól)"
                type="date"
                name="finalizedAtFrom"
                value={filter.finalizedAtFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Feltöltés dátuma (-ig)"
                type="date"
                name="finalizedAtTo"
                value={filter.finalizedAtTo || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Jogosultság vége (-tól)"
                type="date"
                name="relatedPermissionEndFrom"
                value={filter.relatedPermissionEndFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Jogosultság vége (-ig)"
                type="date"
                name="relatedPermissionEndTo"
                value={filter.relatedPermissionEndTo || ""}
                format={{ xs: 12, md: 6 }}
            />
            {!(
                (!roles?.includes(PERMISSIONS.ADMIN) && !roles?.includes(PERMISSIONS.C_TARIFF)) ||
                roles?.includes(PERMISSIONS.EMPLOYER_CONTACT) ||
                roles?.includes(PERMISSIONS.PENSIONER_CONTACT)
            ) && (
                <Autocomplete
                    value={filter.groupedContact || []}
                    options={optionsArray}
                    groupBy={(option) => option.type_hu}
                    id="groupedContact"
                    name="groupedContact"
                    format={{ xs: 12, md: 6 }}
                    label="Kapcsolattartó"
                />
            )}
            <Select
                selectLabel="Sztornózott igénylés"
                name="showDeleted"
                optionList={showDeletedOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.showDeleted || ""}
            />
            <Autocomplete
                label="Érkeztetve"
                name="isArrived"
                options={isArrivedOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.isArrived || []}
            />
            <Autocomplete
                value={filter.arrivedBy || []}
                options={userOptions(usersWhoArrived)}
                id="arrivedBy"
                name="arrivedBy"
                format={{ xs: 12, md: 6 }}
                label="Érkeztető"
            />
            <TextField
                label="Érkeztetés dátuma (-tól)"
                type="date"
                name="dateArrivedFrom"
                value={filter.dateArrivedFrom || ""}
                format={{ xs: 12, md: 6 }}
            />
            <TextField
                label="Érkeztetés dátuma (-ig)"
                type="date"
                name="dateArrivedTo"
                value={filter.dateArrivedTo || ""}
                format={{ xs: 12, md: 6 }}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, usersWhoArrived, filterStorageKey } =
        state.applicationsList;
    const employers = state.employers.data;
    const providers = state.providers.data;
    const users = state.users.dataPublic;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        employers,
        providers,
        tableInfo,
        users,
        usersWhoArrived,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: applicationsListActions.changeForm,
    search: applicationsListActions.filter,
    firstFetch: applicationsListActions.firstFetch,
    defaultForm: applicationsListActions.defaultForm,
    deleteArrival: applicationReceiptActions.deleteArrival,
    exportInitiate: applicationsListActions.exportInitiate,
    exportCheck: applicationsListActions.exportCheck,
    exportDownload: applicationsListActions.exportDownload,
    addNotification: notificationActions.addNotification,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    getUsers: () => usersActions.filterPublic({}),
    downloadEdms: applicationsListActions.downloadEdms,
    setInfo: applicationsListActions.setInfo,
    getUsersWhoArrived: applicationsListActions.getUsersWhoArrived,
};

export default connect(mapState, actionCreators)(ApplicationsList);
