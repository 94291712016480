import React, { createContext, useContext, useState } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

export const ExportStatusContext = createContext({
    exportStatus: [],
    setExportStatus: console.error,
});

const ExportStatusDisplay = () => {
    const { exportStatus } = useExportStatus();
    const status = exportStatus[0];
    const [dragging, setDragging] = useState(false);
    const [pos, setPos] = useState({ x: 24, y: window.innerHeight - 150 });

    if (!status) {
        return null;
    }

    const onMouseDown = () => {
        setDragging(true);
    };

    const onMouseUp = () => {
        setDragging(false);
    };

    const onMouseMove = (e) => {
        if (dragging) {
            // prevent top-left corner jumping
            if (pos.x === 0 && pos.y === 0) {
                setPos({ x: e.clientX, y: e.clientY });
            }
            setPos({ x: pos.x + e.movementX, y: pos.y + e.movementY });

            // prevent dragging out of screen
            if (pos.x < 0) {
                setPos({ ...pos, x: 0 });
            }

            if (pos.y < 0) {
                setPos({ ...pos, y: 0 });
            }

            if (pos.x > window.innerWidth - 300) {
                setPos({ ...pos, x: window.innerWidth - 300 });
            }

            if (pos.y > window.innerHeight - 100) {
                setPos({ ...pos, y: window.innerHeight - 100 });
            }
        }
    };

    return (
        <div
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
            className="noTransition"
            style={{
                display: exportStatus.length > 0 ? 'flex' : 'none',
                cursor: dragging ? 'grabbing' : 'grab',
                position: 'fixed',
                top: `${pos.y}px`,
                left: `${pos.x}px`,
                zIndex: 9999,
                userSelect: 'none',
            }}
        >
            <div
                className="borderRadius"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: '12px 24px',
                    backgroundColor: '#838383',
                    minWidth: '300px',
                    color: 'white',
                    borderRadius: '12px',
                }}
            >
                <div>Exportálás folyamatban...</div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                        <span>
                            {status.rowCount ? `${status.processedRows} / ${status.rowCount}` : 'Inicializálás...'}
                        </span>
                        <span>{status.rowCount ? Math.floor((status.processedRows / status.rowCount) * 100) : 0}%</span>
                    </div>
                    <LinearProgress
                        variant="buffer"
                        value={(status.processedRows / status.rowCount) * 100}
                        valueBuffer={(status.processedRows / status.rowCount) * 100}
                        style={{ 
                            backgroundColor: 'white', 
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#ecbf54',
                            },
                        }}
                    />
                </div>
                <div
                    style={{
                        display: exportStatus.length > 1 ? 'block' : 'none',
                        marginTop: '4px',
                        fontSize: '14px',
                        color: 'rgba(255,255,255,0.8)',
                    }}
                >
                    További exportok: {exportStatus.length - 1} db
                </div>
            </div>
        </div>
    );
};

export const useExportStatus = () => useContext(ExportStatusContext);

export const ExportStatusProvider = (props) => {
    const { children } = props;
    const [exportStatus, setExportStatus] = useState([]);

    return (
        <ExportStatusContext.Provider value={{ exportStatus, setExportStatus }}>
            <ExportStatusDisplay />
            {children}
        </ExportStatusContext.Provider>
    );
};
