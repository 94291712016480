export const types = {
    DEFAULT_FORM: "@@customer-modification-import-list/DEFAULT_FORM",
    CHANGE_FORM: "@@customer-modification-import-list/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@customer-modification-import-list/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@customer-modification-import-list/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@customer-modification-import-list/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@customer-modification-import-list/FETCH_REQUEST",
    FETCH_SUCCESS: "@@customer-modification-import-list/FETCH_SUCCESS",
    FETCH_FAILURE: "@@customer-modification-import-list/FETCH_FAILURE",

    UPLOAD_REQUEST: "@@customer-modification-import-list/UPLOAD_REQUEST",
    UPLOAD_SUCCESS: "@@customer-modification-import-list/UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "@@customer-modification-import-list/UPLOAD_FAILURE",

    DOWNLOAD_REQUEST: "@@customer-modification-import-list/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@customer-modification-import-list/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@customer-modification-import-list/DOWNLOAD_FAILURE",

    DOWNLOAD_VALID_REQUEST: "@@customer-modification-import-list/DOWNLOAD_VALID_REQUEST",
    DOWNLOAD_VALID_SUCCESS: "@@customer-modification-import-list/DOWNLOAD_VALID_SUCCESS",
    DOWNLOAD_VALID_FAILURE: "@@customer-modification-import-list/DOWNLOAD_VALID_FAILURE",

    DOWNLOAD_INVALID_REQUEST: "@@customer-modification-import-list/DOWNLOAD_INVALID_REQUEST",
    DOWNLOAD_INVALID_SUCCESS: "@@customer-modification-import-list/DOWNLOAD_INVALID_REQUEST",
    DOWNLOAD_INVALID_FAILURE: "@@customer-modification-import-list/DOWNLOAD_INVALID_REQUEST",

    DOWNLOAD_REPLY_REQUEST: "@@customer-modification-import-list/DOWNLOAD_REPLY_REQUEST",
    DOWNLOAD_REPLY_SUCCESS: "@@customer-modification-import-list/DOWNLOAD_REPLY_SUCCESS",
    DOWNLOAD_REPLY_FAILURE: "@@customer-modification-import-list/DOWNLOAD_REPLY_FAILURE",

    FETCH_SET_INFO: "@@customer-modification-import-list/FETCH_SET_INFO",
};
