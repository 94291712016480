export const types = {
    DEFAULT_FORM: "@@gdpr/DEFAULT_FORM",
    CHANGE_FORM: "@@gdpr/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@gdprFIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@gdprFIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@gdprFIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@gdpr/FETCH_REQUEST",
    FETCH_SUCCESS: "@@gdpr/FETCH_SUCCESS",
    FETCH_FAILURE: "@@gdpr/FETCH_FAILURE",

    GET_REQUEST: "@@gdpr/GET_REQUEST",
    GET_SUCCESS: "@@gdpr/GET_SUCCESS",
    GET_FAILURE: "@@gdpr/GET_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@gdpr/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@gdpr/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@gdpr/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@gdpr/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@gdpr/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@gdpr/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@gdpr/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@gdpr/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@gdpr/EXPORT_DOWNLOAD_FAILURE",

    DELETE_APPLICATION_REQUEST: "@@gdpr/DELETE_APPLICATION_REQUEST",
    DELETE_APPLICATION_SUCCESS: "@@gdpr/DELETE_APPLICATION_SUCCESS",
    DELETE_APPLICATION_FAILURE: "@@gdpr/DELETE_APPLICATION_FAILURE",

    DELETE_FILTERED_APPLICATIONS_REQUEST: "@@gdpr/DELETE_FILTERED_APPLICATIONS_REQUEST",
    DELETE_FILTERED_APPLICATIONS_SUCCESS: "@@gdpr/DELETE_FILTERED_APPLICATIONS_SUCCESS",
    DELETE_FILTERED_APPLICATIONS_FAILURE: "@@gdpr/DELETE_FILTERED_APPLICATIONS_FAILURE",

    GET_LOG_STATUS_REQUEST: "@@gdpr/GET_LOG_STATUS_REQUEST",
    GET_LOG_STATUS_SUCCESS: "@@gdpr/GET_LOG_STATUS_SUCCESS",
    GET_LOG_STATUS_FAILURE: "@@gdpr/GET_LOG_STATUS_FAILURE",

    FETCH_SET_INFO: "@@gdprFETCH_SET_INFO",
};
