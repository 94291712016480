import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
} from "..";
import consumptionDataService from "../../services/consumptionData";
import { types } from "./types";

function getYears() {
    return (dispatch) => {
        dispatch({ type: types.GET_YEARS_REQUEST });
        return consumptionDataService.getYears().then(
            (data) => {
                dispatch({ type: types.GET_YEARS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_YEARS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, consumptionDataService),
    exportInitiate: templateExportInitiate(types, consumptionDataService),
    exportCheck: templateExportCheck(types, consumptionDataService),
    exportDownload: templateExportDownload(types, consumptionDataService),
    firstFetch: templateFirstFetch(types, consumptionDataService),
    getYears: getYears,
    setInfo,
};

export default actions;
