import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import clientsActions from "../../../store/clients/actions";
import { setInfo } from "../../../utils/FilterHelper";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import BaseFilterTab from "./BaseFilterTab";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import DetailedFilterTab from "./DetailedFilterTab";
import { PERMISSIONS } from "../../../utils/AppConst";
import { filterStorageGet } from "../../../utils/FilterStorage";
import isEmpty from "../../../utils/isEmpty";

function Clients(props) {
    const {
        baseFilter,
        detailedFilter,
        dataBase,
        dataDetailed,
        searchBase,
        searchDetailed,
        currentTab,
        detailedFilterDataFinanciers,
        detailedFilterDataConsumptionPlaceServices1,
        detailedFilterDataConsumptionPlaceServices2,
        pageLoading,
        tableLoading,
        showBaseTable,
        showDetailedTable,
        providers,
        employers,
    } = props;

    const roles = JSON.parse(localStorage.getItem("user"))?.roles;

    const baseInfos = {
        search: searchBase,
        info: props.baseInfo,
        setInfo: props.setBaseInfo,
    };

    const detailedInfos = {
        search: searchDetailed,
        info: props.detailedInfo,
        setInfo: props.setDetailedInfo,
    };

    useEffect(() => {
        props.filterBaseDefaultForm();
        const baseFilterStorageFilter = filterStorageGet(props.baseFilterStorageKey);
        const detailedFilterStorageFilter = filterStorageGet(props.detailedFilterStorageKey);
        if (isEmpty(baseFilterStorageFilter)) {
            props.filterBaseDefaultForm();
        } else {
            props.changeForm(baseFilterStorageFilter, "baseFilter");
        }
        props.changeForm(detailedFilterStorageFilter, "detailedFilter");

        if (roles.includes(PERMISSIONS.ADMIN)) {
            axios
                .all([
                    props.getFilterFinanciers(),
                    props.getConsumptionPlaceServices1(),
                    props.getConsumptionPlaceServices2(),
                    props.getEmployers(),
                    props.getProviders(),
                    setInfo(detailedInfos, "filter", detailedFilterStorageFilter, "sort", { lastName: "asc" }),
                    setInfo(baseInfos, "filter", baseFilterStorageFilter, "sort", { lastName: "asc" }),
                ])
                .then(
                    axios.spread(() => {
                        props.setPageLoading(false);
                    })
                );
        } else {
            axios.all([
                props.getFilterFinanciers(),
                setInfo(baseInfos, "filter", baseFilter, "sort", { lastName: "asc" })
            ]).then(
                axios.spread(() => {
                    props.setPageLoading(false);
                })
            );
        }
    }, []);

    const tabContents = [
        {
            label: "Alap nézet",
            value: (
                <BaseFilterTab
                    filter={baseFilter}
                    filterStorageKey={props.baseFilterStorageKey}
                    data={dataBase}
                    exportInitiate={props.exportInitiateBase}
                    exportCheck={props.exportCheckBase}
                    exportDownload={props.exportDownloadBase}
                    token={props.token}
                    pageLoading={pageLoading}
                    tableLoading={tableLoading}
                    showTable={showBaseTable}
                    changeForm={props.changeForm}
                    defaultForm={props.filterBaseDefaultForm}
                    financiers={detailedFilterDataFinanciers}
                    {...baseInfos}
                />
            ),
        },
        {
            label: "Részletes nézet",
            value: (
                <DetailedFilterTab
                    filter={detailedFilter}
                    filterStorageKey={props.detailedFilterStorageKey}
                    data={dataDetailed}
                    exportInitiate={props.exportInitiateDetailed}
                    exportCheck={props.exportCheckDetailed}
                    exportDownload={props.exportDownloadDetailed}
                    token={props.token}
                    pageLoading={pageLoading}
                    tableLoading={tableLoading}
                    showTable={showDetailedTable}
                    getFilterFinanciers={props.getFilterFinanciers}
                    getConsumptionPlaceServices1={props.getConsumptionPlaceServices1}
                    getConsumptionPlaceServices2={props.getConsumptionPlaceServices2}
                    filterData={{
                        financiers: detailedFilterDataFinanciers,
                        dataConspServ1: detailedFilterDataConsumptionPlaceServices1,
                        dataConspServ2: detailedFilterDataConsumptionPlaceServices2,
                    }}
                    changeForm={props.changeForm}
                    defaultForm={props.filterDetailedDefaultForm}
                    providers={providers}
                    employers={employers}
                    {...detailedInfos}
                />
            ),
        },
    ];

    return (
        <PageTemplate
            header={{
                title: "Ügyfelek",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    "clients-list": "Ügyfelek listája",
                },
            }}
            loading={pageLoading}
        >
            {JSON.parse(localStorage.getItem("user"))?.roles?.includes(PERMISSIONS.ADMIN) ? (
                <TabsCompleted tabContents={tabContents} currentTab={currentTab} />
            ) : (
                tabContents[0].value
            )}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        baseFilter,
        detailedFilter,
        dataBase,
        dataDetailed,
        defaultFormBase,
        defaultFormDetailed,
        token,
        currentTab,
        detailedFilterDataFinanciers,
        detailedFilterDataConsumptionPlaceServices1,
        detailedFilterDataConsumptionPlaceServices2,
        pageLoading,
        tableLoading,
        baseInfo,
        detailedInfo,
        showBaseTable,
        showDetailedTable,
        baseFilterStorageKey,
        detailedFilterStorageKey
    } = state.clients;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return {
        baseFilter,
        detailedFilter,
        dataBase,
        dataDetailed,
        defaultFormBase,
        defaultFormDetailed,
        token,
        currentTab,
        detailedFilterDataFinanciers,
        detailedFilterDataConsumptionPlaceServices1,
        detailedFilterDataConsumptionPlaceServices2,
        pageLoading,
        tableLoading,
        baseInfo,
        detailedInfo,
        showBaseTable,
        showDetailedTable,
        providers,
        employers,
        baseFilterStorageKey,
        detailedFilterStorageKey
    };
}

const actionCreators = {
    changeForm: clientsActions.changeForm,
    searchBase: clientsActions.filterBase,
    searchDetailed: clientsActions.filterDetailed,
    filterBaseDefaultForm: clientsActions.defaultFormBase,
    filterDetailedDefaultForm: clientsActions.defaultFormDetailed,
    exportInitiateBase: clientsActions.exportInitiateBase,
    exportInitiateDetailed: clientsActions.exportInitiateDetailed,
    exportCheckBase: clientsActions.exportCheckBase,
    exportCheckDetailed: clientsActions.exportCheckDetailed,
    exportDownloadBase: clientsActions.exportDownloadBase,
    exportDownloadDetailed: clientsActions.exportDownloadDetailed,
    getFilterFinanciers: clientsActions.getFilterFinanciers,
    getConsumptionPlaceServices1: clientsActions.getConsumptionPlaceServices1,
    getConsumptionPlaceServices2: clientsActions.getConsumptionPlaceServices2,
    setBaseInfo: clientsActions.setBaseInfo,
    setDetailedInfo: clientsActions.setDetailedInfo,
    setPageLoading: clientsActions.setPageLoading,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
};

export default connect(mapState, actionCreators)(Clients);
