import {
    faAddressBook,
    faArrowRight,
    faBan,
    faBolt,
    faBook,
    faBriefcase,
    faCalendarAlt,
    faCalendarCheck,
    faChartArea,
    faChartBar,
    faChartLine,
    faCheck,
    faClipboardCheck,
    faDatabase,
    faEquals,
    faExclamationTriangle,
    faFile,
    faList,
    faListOl,
    faPaste,
    faPercentage,
    faReceipt,
    faSignInAlt,
    faStop,
    faUserClock,
    faUserLock,
    faUsers,
    faUserTag,
    faUserTimes,
} from "@fortawesome/free-solid-svg-icons";
import OperationButton from "../Components/Common/OperationButton";
import AddressTooltip from "../Components/Common/AddressTooltip";

export const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const DATE_PICKER_FORMAT_DATE = "YYYY-MM-DD";
export const DATE_PICKER_FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm";
export const DATE_PICKER_FORMAT_DATE_TIME_JS = "YYYY-MM-DD\\THH:mm";

export const TODAY = new Date();
export const YESTERDAY = new Date(TODAY);

YESTERDAY.setDate(YESTERDAY.getDate() - 1);

export const REFRESH_INTERVAL_TIME = 60 * 1000; //percenként
export const DO_REFRESH_INTERVAL = 30 * 60 * 1000; //fél óránként

export const PERIODS_OF_VALIDITIES = {
    //milliszekundum
    accessToken: 60 * 60 * 1000,
    refreshToken: 3 * 60 * 60 * 1000,
};

export const INVOICE_OPTIONS = [
    { value: "normal", label: "Normál" },
    { value: "cancellation", label: "Sztornó" },
    { value: "clearing", label: "Elszámoló" },
    { value: "replacement", label: "Helyettesítő" },
];

export function INVOICE_OPTIONS_FUNCTION(label) {
    switch (label) {
        case "normal":
        case "N":
            return "Normál";
        case "cancellation":
        case "S":
            return "Sztornó";
        case "clearing":
        case "E":
            return "Elszámoló";
        case "replacement":
        case "H":
            return "Helyettesítő";
        default:
            return "";
    }
}

export const APPLICATION_STATUS_OPTIONS = [
    { value: "new", label: "1" },
    { value: "sorted", label: "3" },
    { value: "partially_verified", label: "4" },
    { value: "verified", label: "5" },
    { value: "accepted", label: "6" },
    { value: "done", label: "9" },

    { value: "adjustable_error", label: "-6" },
    { value: "no_contract", label: "-7" },
    { value: "fatal_error", label: "-8" },
    { value: "consumption_place_error", label: "-9" },
    { value: "banned", label: "-10" },
    { value: "similar", label: "-11" },
    { value: "exceedance", label: "-12" },
    { value: "other_error", label: "-13" },
    { value: "date_error", label: "-14" },

    { value: "invalid_employment_start", label: "-16" },
    { value: "invalid_work_years", label: "-17" },
];

export function APPLICATION_STATUS_FUNCTION(status) {
    switch (status) {
        case "draft":
            return 0;
        case "new":
            return 1;
        case "sorted":
            return 3;
        case "partially_verified":
            return 4;
        case "verified":
            return 5;
        case "accepted":
            return 6;
        case "done":
            return 9;

        case "adjustable_error":
            return -6;
        case "no_contract":
            return -7;
        case "fatal_error":
            return -8;
        case "consumption_place_error":
            return -9;
        case "banned":
            return -10;
        case "similar":
            return -11;
        case "exceedance":
            return -12;
        case "other_error":
            return -13;
        case "date_error":
            return -14;

        case "invalid_employment_start":
            return -16;
        case "invalid_work_years":
            return -17;
        default:
            break;
    }
}

export function APPLICATION_TYPE_FUNCTION(type) {
    switch (type) {
        case "application":
            return "Új igénylés";
        case "modification":
            return "Módosítás";
        case "termination":
            return "Megszüntetés";
        default:
            return "";
    }
}

export const FLOOR_OPTIONS = [
    { value: "tetőtér", label: "tetőtér" },
    { value: "pince", label: "pince" },
    { value: "garázs", label: "garázs" },
    { value: "magasföldszint", label: "magasföldszint" },
    { value: "félemelet", label: "félemelet" },
    { value: "földszint", label: "földszint" },
    { value: "alagsor", label: "alagsor" },
    { value: "1. em.", label: "1. em." },
    { value: "2. em.", label: "2. em." },
    { value: "3. em.", label: "3. em." },
    { value: "4. em.", label: "4. em." },
    { value: "5. em.", label: "5. em." },
    { value: "6. em.", label: "6. em." },
    { value: "7. em.", label: "7. em." },
    { value: "8. em.", label: "8. em." },
    { value: "9. em.", label: "9. em." },
    { value: "10. em.", label: "10. em." },
    { value: "11. em.", label: "11. em." },
    { value: "12. em.", label: "12. em." },
    { value: "13. em.", label: "13. em." },
    { value: "14. em.", label: "14. em." },
    { value: "15. em.", label: "15. em." },
    { value: "16. em.", label: "16. em." },
    { value: "17. em.", label: "17. em." },
    { value: "18. em.", label: "18. em." },
    { value: "19. em.", label: "19. em." },
    { value: "20. em.", label: "20. em." },
    { value: "21. em.", label: "21. em." },
];

export const FINANCIER_OPTIONS = [
    { value: "bakonyi", label: "Bakonyi Erőmű Zrt." },
    { value: "mvmii", label: "MVMI Informatikai Zrt." },
    { value: "mvmio", label: "MVM OVIT Zrt." },
    { value: "kk", label: "Közös Kassza" },
];

export const TAX_STATUS_OPTIONS = [
    { value: "taxable", label: "Adóköteles" },
    { value: "taxfree", label: "Adómentes" },
];

export const IS_ACTIVE_OPTIONS = [
    { value: "yes", label: "Aktív" },
    { value: "no", label: "Inaktív" },
];

export const IS_CORRECT_OPTIONS = [
    { value: true, label: "Igen" },
    { value: false, label: "Nem" },
];

export const ARRAY_OPTIONS = [
    { value: "all", label: "Összes" },
    { value: "1", label: "1. tömb" },
    { value: "2", label: "2. tömb" },
    { value: "3", label: "3. tömb" },
    { value: "4", label: "4. tömb" },
    { value: "5", label: "5. tömb" },
    { value: "6", label: "6. tömb" },
];

export const CONSUMPTION_PLACE_OPTIONS = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
];

export const MONTH_OPTIONS = [
    { value: "1", label: "1", text: "Január" },
    { value: "2", label: "2", text: "Február" },
    { value: "3", label: "3", text: "Március" },
    { value: "4", label: "4", text: "Április" },
    { value: "5", label: "5", text: "Május" },
    { value: "6", label: "6", text: "Június" },
    { value: "7", label: "7", text: "Július" },
    { value: "8", label: "8", text: "Augusztus" },
    { value: "9", label: "9", text: "Szeptember" },
    { value: "10", label: "10", text: "Október" },
    { value: "11", label: "11", text: "November" },
    { value: "12", label: "12", text: "December" },
];

export const FILTER_MONTH_OPTIONS = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
];

export const PROVIDER_OPTIONS = [
    {
        value: "ELMŰ-ÉMÁSZ Energiaszolgáltató Zrt.",
        label: "ELMŰ-ÉMÁSZ Energiaszolgáltató Zrt.",
    },
    { value: "NKM Energia Zrt.", label: "NKM Energia Zrt." },
    { value: "ELMŰ Nyrt.", label: "ELMŰ Nyrt." },
    { value: "ÉMÁSZ Nyrt.", label: "ÉMÁSZ Nyrt." },
    {
        value: "E.ON Energiakereskedelmi Kft.",
        label: "E.ON Energiakereskedelmi Kft.",
    },
];

export const LEGAL_BASIS_OPTIONS = [
    { value: "employee", label: "Munkavállaló" },
    { value: "pensioner", label: "Nyugdíjas" },
    { value: "widow", label: "Özvegy" },
];

export const LEGAL_BASIS_OPTIONS_CLIENTS = [
    { value: "employee", label: "Munkavállaló (nem szakszervezeti alkalmazott)" },
    { value: "pensioner", label: "Nyugdíjas (nem nyugdíjazott szakszervezeti alkalmazott)" },
    { value: "employees_widow", label: "Munkavállaló özvegye (nem szakszervezeti alkalmazott özvegye)" },
    { value: "pensioners_widow", label: "Nyugdíjas özvegye (nem nyugdíjazott szakszervezeti alkalmazott özvegye)" },
    { value: "trade_union_employee", label: "Szakszervezeti alkalmazott" },
    { value: "trade_union_pensioner", label: "Nyugdíjazott szakszervezeti alkalmazott" },
    { value: "trade_union_employees_widow", label: "Szakszervezeti alkalmazott özvegye" },
    { value: "trade_union_pensioner_widow", label: "Nyugdíjazott szakszervezeti alkalmazott özvegye" },
];

export const TITLE_OF_USE_OPTIONS = [
    { value: "Tulajdon", label: "Tulajdon" },
    { value: "Bérlet", label: "Bérlet" },
    { value: "Társbérlet", label: "Társbérlet" },
    { value: "Haszonélvezet", label: "Haszonélvezet" },
    { value: "Albérlet", label: "Albérlet" },
];

//évek és hónapok tárolása
const date = new Date();
const currentYear = date.getFullYear();
const lastYear = currentYear - 1;
const beforeLastYear = currentYear - 2;
const currentMonth = date.getMonth();

export const LAST_TWO_YEARS = [beforeLastYear, lastYear, currentYear];

export const YEAR_OPTIONS = [];

for (let i = 0; i < 11; i++) {
    YEAR_OPTIONS.push({
        value: (currentYear - i).toString(),
        label: (currentYear - i).toString(),
    });
}

//tárolja az utolsó két évet és hónapokat
//36 hónapra van állítva a ciklus - ez a legtöbb amit meg kell jeleníteni, december hónapokban
export const LAST_TWO_YEARS_WITH_MONTHS = [];

let month = currentMonth;
let year = currentYear;

for (let i = 0; i < currentMonth + 35; i++) {
    if (month < 0) {
        month = 11;
        year--;
    }
    LAST_TWO_YEARS_WITH_MONTHS.push(year.toString() + (month + 1).toString());
    month--;
}

export const WARNING_TEXT =
    "FIGYELEM! A következő funkcióval a jogosultak adatait módosíthatja. A módosításokat csak többszöri ellenőrzés után érvényesítse, mert a téves módosításokkal a jogosultságot veszélyezteti!";

export const CUSTOMER_NAME_FUNC = (data) => {
    if (data?.lastName !== undefined && data?.firstName !== undefined) {
        if (data?.title) return data?.title + " " + data?.lastName + " " + data?.firstName;
        return data?.lastName + " " + data?.firstName;
    }
    return "";
};

export const MOTHER_NAME_FUNC = (data) => {
    if (data?.motherLastName !== undefined && data?.motherFirstName !== undefined) {
        return data?.motherLastName + " " + data?.motherFirstName;
    }
    return "";
};

export const BIRTHDATE_FUNC = (data) => {
    if (data?.birthPlace !== undefined && data?.birthDate !== undefined) {
        return data?.birthPlace + ", " + data?.birthDate;
    }
    return "";
};

export const ARRIVED_INFO_FUNC = (data, downloadEdms) => {
    let arrivedInfo = "";

    const name = data?.arrivedBy?.name || "-";
    const arrivedAt = data?.arrivedAt || "-";

    if (data?.arrivalBarCode) {
        arrivedInfo = `${data.arrivalBarCode} (${name}, ${arrivedAt})`;
    } else arrivedInfo = "Nincs érkeztetve";
    return (
        <>
            {arrivedInfo}
            {data?.arrivalBarCode && <OperationButton type="info" onClick={() => downloadEdms(data.arrivalBarCode, true)} />}
        </>
    );
};

export const ADDRESS_FUNC = (data) => {
    let address = (data?.postCode || " ") + " " + (data?.city || " ") + ", " + (data?.street || " ") + " " + 
        (data?.streetType || " ") + " " + (data?.houseNumber || " ");
    if (data?.floor) address += ", " + data.floor;
    if (data?.door) address += "/" + data.door + ". ajtó";

    let toolTipStringArray = [
        `Irányítószám: ${data?.postCode || "-"}`,
        `Város: ${data?.city || "-"}`,
        `Közterület neve: ${data?.street || "-"}`,
        `Közterület típusa: ${data?.streetType || "-"}`,
        `Házszám: ${data?.houseNumber || "-"}`,
    ];

    if (data?.floor) toolTipStringArray.push(`Emelet: ${data.floor}`);
    if (data?.door) toolTipStringArray.push(`Ajtó: ${data.door}`);

    const toolTipDiv = <div>
        <strong>Irányítószám: </strong>{`${data?.postCode || "-"}`}<br/>
        <strong>Város: </strong>{`${data?.city || "-"}`} <br/>
        <strong>Közterület neve: </strong>{`${data?.street || "-"}`} <br/>
        <strong>Közterület típusa: </strong>{`${data?.streetType || "-"}`} <br/>
        <strong>Házszám: </strong>{`${data?.houseNumber || "-"}`} <br/>
        { data?.floor && 
            <>
                <strong>Irányítószám: </strong> 
                {data.floor}
            </>
        }
        { data?.floor && <br/>}
        { data?.door && 
            <>
                <strong>Ajtó: </strong> 
                {data.door}
            </>
        }
    </div>

    return (
        <AddressTooltip
            title={toolTipDiv}
        >
            <div 
                style={{ 
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }}
            >
                {address ? address : ""}
            </div>
        </AddressTooltip>
    );
};

export const LEGAL_BASIS_TYPE = (type) => {
    switch (type) {
        case "employee":
            return "Munkavállaló";
        case "employees_widow":
            return "Munkavállaló özvegye";
        case "pensioner":
            return "Nyugdíjas";
        case "pensioners_widow":
            return "Nyugdíjas özvegye";
        case "trade_union_employee":
            return "Szakszervezeti alkalmazott";
        case "trade_union_employees_widow":
            return "Szakszervezeti alkalmazott özvegye";
        case "trade_union_pensioner":
            return "Nyugdíjazott szakszervezeti alkalmazott";
        case "trade_union_pensioner_widow":
            return "Nyugdíjazott szakszervezeti alkalmazott özvegye";
        default:
            return "";
    }
};

export const LEGAL_BASIS_TYPE_FOR_CUSTOMER_PAGE = (type) => {
    switch (type) {
        case "employee":
        case "trade_union_employee":
            return "Munkavállaló";
        case "pensioner":
        case "trade_union_pensioner":
            return "Nyugdíjas";
        case "employees_widow":
        case "trade_union_employees_widow":
        case "pensioners_widow":
        case "trade_union_pensioner_widow":
            return "Özvegy";
        default:
            break;
    }
};

export const CONSUMPTION_PLACE_TYPE = (type) => {
    switch (type) {
        case "property":
            return "Tulajdon";
        case "rental":
            return "Bérlet";
        case "corental":
            return "Társbérlet";
        case "usufruct":
            return "Haszonélvezet";
        case "apartment":
            return "Albérlet";
        default:
            break;
    }
};

export const MOD_REASON_FUNC = (type) => {
    switch (type) {
        case "change":
            return "A kedvezményes felhasználási helyek megváltoztatása";
        case "legal_basis_end":
            return "A kedvezményes vételezés befejezése a kedvezmény feltételét jelentő használati jogcím megszűnése miatt";
        case "other":
            return "A kedvezmény igénybevételének megszüntetése";
        default:
            break;
    }
};

export const INVOICE_OPTIONS_DATA_SUMMARY = [
    { value: "N", label: "N" },
    { value: "H", label: "H" },
    { value: "E", label: "E" },
    { value: "S", label: "S" },
];

export function CLEAR_ERROR_MESSAGES_FUNC(application) {
    if (application.applicant?.errors?.length > 0) application.applicant.errors = [];
    if (application.legalBasis?.errors?.length > 0) application.legalBasis.errors = [];
    if (application.primaryConsumptionPlace?.errors?.length > 0) application.primaryConsumptionPlace.errors = [];
    if (application.secondaryConsumptionPlace?.errors?.length > 0) application.secondaryConsumptionPlace.errors = [];
    if (application.primaryConsumptionPlaceTermination?.errors?.length > 0)
        application.primaryConsumptionPlaceTermination.errors = [];
    if (application.secondaryConsumptionPlaceTermination?.errors?.length > 0)
        application.secondaryConsumptionPlaceTermination.errors = [];
    if (application.statement?.errors?.length > 0) application.statement.errors = [];
    if (application.taxExemption?.errors?.length > 0) application.taxExemption.errors = [];

    return application;
}

export function numberFormat(val) {
    if (val === 0) {
        return "0";
    }
    if (!val) {
        return "";
    }
    return val
        .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export const PERMISSIONS = {
    ADMIN: "ROLE_ADMIN",
    PENSIONER_CONTACT: "ROLE_PENSIONER_CONTACT",
    EMPLOYER_CONTACT: "ROLE_EMPLOYER_CONTACT",
    FINANCIER: "ROLE_FINANCIER",
    SERVICE_PROVIDER: "ROLE_SERVICE_PROVIDER",
    C_TARIFF: "ROLE_C_TARIFF",
    USER: "ROLE_USER",
};

//Felhasználók szerkesztése (USER_PROFILE_VIEW)
export const PERMISSION_OPTIONS = [
    { value: PERMISSIONS.ADMIN, label: "admin" },
    { value: PERMISSIONS.PENSIONER_CONTACT, label: "nyugdíjas kapcsolattartó" },
    { value: PERMISSIONS.EMPLOYER_CONTACT, label: "munkáltatói kapcsolattartó" },
    { value: PERMISSIONS.FINANCIER, label: "finanszírozó" },
    { value: PERMISSIONS.SERVICE_PROVIDER, label: "szolgáltató" },
    { value: PERMISSIONS.C_TARIFF, label: "C-Tarifa" },
    { value: PERMISSIONS.USER, label: "Jogosulatlan felhasználó" },
];


export const subMenus = {
    applicationFiling: {
        to: "/application-filing",
        icon: faBook,
        text: "Igénylőlap felvitele",
    },
    applicationReceipt: {
        to: "/application-receipt",
        icon: faArrowRight,
        text: "Igénylőlap érkeztetése",
    },
    applicationsList: {
        to: "/applications-list",
        icon: faList,
        text: "Igénylések listája",
    },
    providerValidation: {
        to: "/provider-validation",
        icon: faCheck,
        text: "Szolgáltatói adatellenőrzés",
    },
    permissionAssignmentResult: {
        to: "/application-permissions",
        icon: faUserTag,
        text: "Jogosultságok",
    },
    applicationCancellations: {
        to: "/application-cancellations",
        icon: faBan,
        text: "Megszüntetések",
    },
    applicationsDenied: {
        to: "/applications-denied",
        icon: faStop,
        text: "Elutasított igénylések",
    },
    clients: {
        to: "/clients",
        icon: faAddressBook,
        text: "Ügyfelek",
    },
    fixedTimeCustomer: {
        to: "/fixed-time-clients",
        icon: faUserClock,
        text: "Határozott idejű ügyfelek",
    },
    employmentTerminations: {
        to: "/employment-terminations",
        icon: faPaste,
        text: "Űrlapok",
    },
    tax: {
        to: "/tax",
        icon: faPercentage,
        text: "Adó",
    },
    blacklist: {
        to: "/blacklist",
        icon: faUserTimes,
        text: "Tiltólista",
    },
    consumptionData: {
        to: "/consumption-data",
        icon: faDatabase,
        text: "Fogyasztási adatok",
    },
    filesList: {
        to: "/files-list",
        icon: faFile,
        text: "Fájlok listája",
    },
    batchCancellation: {
        to: "/batch-cancellation",
        icon: faBan,
        text: "Kötegelt sztornózás",
    },
    monthlyConsumptionData: {
        to: "/monthly-consumption-data",
        icon: faCalendarAlt,
        text: "Havi fogyasztási adatok",
    },
    auditOfFinancingAccounts: {
        to: "/audit-of-financing-accounts",
        icon: faReceipt,
        text: "Számlaellenőrzés",
    },
    consumptionDataSummary: {
        to: "/consumption-data-summary",
        icon: faEquals,
        text: "Összesítő",
    },
    consumptionDataValidator: {
        to: "/consumption-data-validator",
        icon: faClipboardCheck,
        text: "Validátor",
    },
    cTariff: {
        to: "/statistics/c-tariff/3-2-2",
        icon: faChartBar,
        text: "C-Tarifa (3.2.2)",
    },
    GKM1: {
        to: "/statistics/gkm/4-1-1",
        icon: faChartArea,
        text: "GKM (4.1.1)",
    },
    GKM2: {
        to: "/statistics/gkm/4-1-2",
        icon: faChartLine,
        text: "GKM (4.1.2)",
    },
    GKM3: {
        to: "/statistics/gkm/4-1-3",
        icon: faChartLine,
        text: "GKM (4.1.3)",
    },
    GKM4: {
        to: "/statistics/gkm/4-1-4",
        icon: faChartBar,
        text: "GKM (4.1.4)",
    },
    GKM5: {
        to: "/statistics/gkm/4-1-5",
        icon: faChartBar,
        text: "GKM (4.1.5)",
    },
    MEKH: {
        to: "/statistics/mekh/4-2-2",
        icon: faChartArea,
        text: "MEKH (4.2.2)",
    },
    other: {
        to: "/statistics/other/1-1",
        icon: faChartLine,
        text: "Egyéb (1.1)",
    },
    users: {
        to: "/users",
        icon: faUsers,
        text: "Felhasználók",
    },
    employer: {
        to: "/employers",
        icon: faBriefcase,
        text: "Munkáltatók",
    },
    serviceProvider: {
        to: "/providers",
        icon: faBolt,
        text: "Szolgáltatók",
    },
    consumptionPeriods: {
        to: "/consumption-periods",
        icon: faCalendarCheck,
        text: "Fogyasztási időszakok",
    },
    commands: {
        to: "/commands",
        icon: faExclamationTriangle,
        text: "Parancsok",
    },
    GDPR: {
        to: "/gdpr",
        icon: faUserLock,
        text: "GDPR felülvizsgálat",
    },
    failedLoginAttempt: {
        to: "/failed-login-attempts",
        icon: faSignInAlt,
        text: "Sikertelen bejelentkezések",
    },
    systemLog: {
        to: "/system-log",
        icon: faBook,
        text: "Rendszernapló",
    },
    customerModificationImportList: {
        to: "/customer-modification-import-list",
        icon: faListOl,
        text: "Adatellenőrző importlista",
    },
};

export const mainMenus = {
    applications: {
        name: "Igénylések",
        subMenus: [
            subMenus.applicationFiling,
            subMenus.applicationReceipt,
            subMenus.applicationsList,
            subMenus.providerValidation,
        ],
    },
    admissedApplications: {
        name: "Elbírált igénylések",
        subMenus: [subMenus.permissionAssignmentResult, subMenus.applicationCancellations, subMenus.applicationsDenied],
    },
    clients: {
        name: "Ügyfelek",
        subMenus: [
            subMenus.clients,
            subMenus.fixedTimeCustomer,
            subMenus.employmentTerminations,
            subMenus.tax,
            subMenus.blacklist,
        ],
    },
    finance: {
        name: "Pénzügyek",
        subMenus: [
            subMenus.consumptionData,
            subMenus.filesList,
            subMenus.batchCancellation,
            subMenus.monthlyConsumptionData,
            subMenus.auditOfFinancingAccounts,
            subMenus.consumptionDataSummary,
            subMenus.consumptionDataValidator,
        ],
    },
    statistics: {
        name: "Statisztikák",
        subMenus: [subMenus.cTariff, subMenus.GKM1, subMenus.GKM2, subMenus.GKM3, subMenus.GKM4, subMenus.GKM5, subMenus.MEKH, subMenus.other],
    },
    operator: {
        name: "Operátor",
        subMenus: [
            subMenus.users,
            subMenus.employer,
            subMenus.serviceProvider,
            subMenus.consumptionPeriods,
            subMenus.commands,
            subMenus.GDPR,
            subMenus.failedLoginAttempt,
            subMenus.systemLog,
            subMenus.customerModificationImportList
        ],
    },
};

export const NO_RESPONSIBILITY_TEXT = "Felhívjuk szíves figyelmüket arra, hogy az ATR-ben a kapcsolattartók által feltöltött (rögzített) adatok nem tekinthetőek hiteles forrásadatoknak az adókötelezettség megállapításához, ezért az ATR-ben rögzített adatok tartalmáért a MAVIR ZRT. felelősséget nem vállal.";

export const GET_STAT_DETAILS = [
    { title: "", body: "" },
    {
        title: "3.2.2 Kapcsolattartóhoz tartózó igénylések fogyasztási hely szerinti bontásban",
        shortTitle: "3.2.2",
        body: "A paraméterként megadott idő intervallumban adott kapcsolattartó által feltöltött és befogadott igénylőlapok fogyasztási hely és igénylőlap típus szerinti bontásban.",
    },
    {
        title: "4.1.1 Kedvezményesen vételező fogyasztók kiemelt jellemző adatai",
        shortTitle: "4.1.1",
        body: "A táblázat mutatja a jogosultak egyes csoportjaira vonatkozóan a létszámot, a fogyasztást, a kiszámlázást teljes áron és az átlagárat.",
    },
    {
        title: "4.1.2 A felhasználási helyeken mért fogyasztás alakulása",
        shortTitle: "4.1.2",
        body: "A táblázat a jogosultak egyes csoportjaira vonatkozóan, az egy illetve két felhasználási hellyel rendelkezők szerinti bontásban mutatja a felhasználási helyek db számát és a fogyasztott energia mennyiségét.",
    },
    {
        title: "4.1.3 A felhasználási helyeken mért fogyasztás alakulása",
        shortTitle: "4.1.3",
        body: "A táblázat a jogosultak egyes csoportjaira vonatkozóan, az egy illetve két felhasználási hellyel rendelkezők szerinti bontásban mutatja a felhasználási helyek db számát és a fogyasztott energia mennyiségét (I-VI.tömb szerint).",
    },
    {
        title: "4.1.4 Az egyetemes szolgáltatók által kedvezményesen számlázott térítési igények szolgáltatónként és összevontan",
        shortTitle: "4.1.4",
        body: "A táblázat megmutatja a jogosultak egyes csoportja szerint a kedvezménnyel vételezett mennyiséget, a térítési igényt és a fajlagos értékeket",
    },
    {
        title: "4.1.5 Az egyetemes szolgáltatók által kedvezményesen számlázott térítési igények szolgáltatónként és összevontan",
        shortTitle: "4.1.5",
        body: "A táblázat megmutatja a jogosultak egyes csoportja szerint a kedvezménnyel vételezett mennyiséget, a térítési igényt és a fajlagos értékeket (I-VI.tömb szerint).",
    },
    {
        title: "4.2.2 MAVIR által teljesített kifizetések az egyetemes szolgáltatók részére",
        shortTitle: "4.2.2",
        body: "MAVIR által teljesített kifizetések az egyetemes szolgáltatók részére",
    },
    {
        title: "1.1 Az aktív ügyfelek száma finanszírozók és jogalapok szerint",
        shortTitle: "1.1",
        body: "A kimutatás készítése napján kedvezményesen vételező ügyfelek száma finanszírozók és jogalap szerinti bontásban. (Az aktív jogosultságok, és ezen jogosultság létrejöttekor leadott igénylőlapon szereplő jogalap szerint.)",
    },
];

export const FILES = [
    {
        text: "GKM rendelet",
        title: "gkm_rendelet",
        url: "gkm"
    },
    {
        text: "Új igénylőlap",
        title: "igenylolap",
        url: "application",
    },
    {
        text: "Magyar közlöny alkalmazott kedvezmeny módosítás",
        title: "magyar_kozlony_alk_kedvezmeny_modositas",
        url: "modification"
    },
    {
        text: "Szolgáltatói adatellenőrzésre átadott állomány adatstruktúra",
        title: "asz-oktol_adatellenorzott_adatstruktura_minta_xls_0205_m",
        url: "service-provider-data-verification"
    },
];

export const USER_MANUALS = {
    "ROLE_ADMIN": {
        text: "Felhasználói kézikönyv (Admin)",
        title: "MAVIR_ATR_admin_felhasznaloi_kezikonyv",
        url: "admin"
    },
    "ROLE_C_TARIFF": {
        text: "Felhasználói kézikönyv (C-tarifa)",
        title: "MAVIR_ATR_Ctarifa_felhasznaloi_kezikonyv",
        url: "c-tariff"
    },
    "ROLE_FINANCIER": {
        text: "Felhasználói kézikönyv (Finanszírozó)",
        title: "MAVIR_ATR_finanszirozo_felhasznaloi_kezikonyv",
        url: "financier"
    },
    "ROLE_EMPLOYER_CONTACT": {
        text: "Felhasználói kézikönyv (Munkáltatói kapcsolattartó)",
        title: "MAVIR_ATR_Munkaltatoi_kapcsolattarto_felhasznaloi_kezikonyv",
        url: "employer-contact"
    },
    "ROLE_PENSIONER_CONTACT": {
        text: "Felhasználói kézikönyv (Nyugdíjas kapcsolattartó)",
        title: "MAVIR_ATR_Nyugdijas_kapcsolattarto_felhasznaloi_kezikonyv",
        url: "pensioner-contact"
    },
    "ROLE_SERVICE_PROVIDER": {
        text: "Felhasználói kézikönyv (Szolgáltató)",
        title: "MAVIR_ATR_Szolgaltato_felhasznaloi_kezikonyv",
        url: "service-provider"
    },
}