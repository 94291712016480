import {
    Grid,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import colors from "../../../styles/colors";
import Card from "../../Common/Card";
import Form from "../../Common/Form";
import PageTemplate from "../../Common/PageTemplate";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import applicationReceiptActions from "../../../store/applicationReceipt/actions";
import notificationActions from "../../../store/notification/actions";
import {
    APPLICATION_STATUS_FUNCTION,
    BIRTHDATE_FUNC,
    APPLICATION_TYPE_FUNCTION,
    CUSTOMER_NAME_FUNC,
    MOTHER_NAME_FUNC,
} from "../../../utils/AppConst";

const useStyles = makeStyles((theme) => ({
    thead: {
        fontWeight: 550,
        backgroundColor: colors.primary,
        color: colors.white,
    },
    visible: {
        marginTop: theme.spacing(2),
    },
    searchButton: {
        marginBottom: theme.spacing(0.5),
    },
    arrivalButton: {
        marginTop: theme.spacing(0.5),
    },
    textField: {
        marginTop: theme.spacing(2),
    },
    tableContainer: {
        marginBottom: theme.spacing(2),
    },
}));

function ApplicationReceipt(props) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const { searchForm, arrivalForm, data, loading } = props;
    const { barcode } = props.match.params;

    const handleSubmit = () => {
        if (searchForm.barcode?.length === 9) {
            props
                .checkArrival(searchForm.barcode)
                .then(() => {
                    setVisible(true);
                })
                .catch(() => {
                    setVisible(false);
                    props.addNotification("error", "Nincs ilyen igénylőlap.");
                });
        } else {
            props.addNotification("error", "Az igénylőlap vonalkódja 9 számjegy hosszú.");
        }
    };

    const handleArrival = () => {
        if (arrivalForm.arrivalBarcode?.length !== 8) {
            props.addNotification("error", "Az érkeztetési vonalkód 8 számjegy hosszú.");
        } else {
            if (data?.arrivalBarCode) {
                props.addNotification("error", "Az igénylés már érkeztetve lett.");
            } else {
                props
                    .arrive(data?.id, arrivalForm.arrivalBarcode)
                    .then(() => {
                        props.addNotification("success", "Sikeres érkeztetés!");
                        setVisible(false);
                        props.defaultForm();
                        document.getElementById("barcode-input").focus();
                    })
                    .catch((error) => {
                            const violationMessage = error?.response?.data?.detail || error?.response?.data?.message || "";

                            if (violationMessage.length > 0) {
                                props.addNotification("error", violationMessage);
                            }

                            props.addNotification("error", arrivalForm?.error ? arrivalForm?.error : "Hiba lépett fel érkeztetés során")
                        }
                    );
            }
        }
    };

    useEffect(() => {
        props.defaultForm();
        if (barcode) props.changeForm({ ...searchForm, barcode: barcode }, "searchForm");
    }, []);

    return (
        <PageTemplate
            header={{
                title: "",
                breadcrumbs: {
                    application: "Igénylések",
                    "application-receipt": "Igénylőlap érkeztetése",
                },
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card fullWidth title="Igénylőlap érkeztetése">
                    <Form form={searchForm} changeform={props.changeForm} onSubmit={handleSubmit} name="searchForm">
                        <TextField
                            id="barcode-input"
                            label="Vonalkód"
                            name="barcode"
                            value={searchForm.barcode || ""}
                            format={{ xs: 12 }}
                            rule={{
                                number: "true",
                                length: 9,
                            }}
                            autoFocus={!visible}
                        />
                        <SubmitButton type="submit" format={{ xs: 12 }} className={classes.searchButton}>
                            Igénylőlap keresése
                        </SubmitButton>
                    </Form>
                    {visible && 
                    <Form form={arrivalForm} changeform={props.changeForm} onSubmit={handleArrival} name="arrivalForm">
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table>
                                <TableHead className={classes.thead}>
                                    <TableRow>
                                        <TableCell className={classes.thead}>Igénylő neve</TableCell>
                                        <TableCell className={classes.thead}>Anyja neve</TableCell>
                                        <TableCell className={classes.thead}>Születési helye, ideje</TableCell>
                                        <TableCell className={classes.thead}>Igénylőlap típusa</TableCell>
                                        <TableCell className={classes.thead}>Igénylőlap státusza</TableCell>
                                        <TableCell className={classes.thead}>Kapcsolattartó</TableCell>
                                        <TableCell className={classes.thead}>Érkeztetési vonalkód</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>{CUSTOMER_NAME_FUNC(data) || " "}</TableCell>
                                        <TableCell>{MOTHER_NAME_FUNC(data)}</TableCell>
                                        <TableCell>{BIRTHDATE_FUNC(data)}</TableCell>
                                        <TableCell>{APPLICATION_TYPE_FUNCTION(data?.type || " ")}</TableCell>
                                        <TableCell>{APPLICATION_STATUS_FUNCTION(data?.status) || " "}</TableCell>
                                        <TableCell>{data?.contact?.name || " "}</TableCell>
                                        <TableCell>{data?.arrivalBarCode || "-"}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TextField
                            label="Érkeztetési vonalkód"
                            name="arrivalBarcode"
                            className={classes.textField}
                            value={arrivalForm.arrivalBarcode || ""}
                            format={{ xs: 12 }}
                            rule={{
                                number: "true",
                                length: 8,
                            }}
                            autoFocus
                        />
                        <SubmitButton type="submit" className={classes.arrivalButton}>
                            Igénylőlap érkeztetése
                        </SubmitButton>
                    </Form>
                    }
                </Card>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { searchForm, arrivalForm, data, loading } = state.applicationReceipt;
    return { searchForm, arrivalForm, data, loading };
}

const actionCreators = {
    changeForm: applicationReceiptActions.changeForm,
    checkArrival: applicationReceiptActions.checkArrival,
    arrive: applicationReceiptActions.arrive,
    deleteArrival: applicationReceiptActions.deleteArrival,
    addNotification: notificationActions.addNotification,
    defaultForm: applicationReceiptActions.defaultForm,
};

export default connect(mapState, actionCreators)(ApplicationReceipt);
