import React, { useState } from "react";
import PageTemplate from "../../Common/PageTemplate";
import { connect } from "react-redux";
import batchCancellationActions from "../../../store/batchCancellation/actions";
import Card from "../../Common/Card";
import { Dialog, DialogActions, DialogContent, Grid, makeStyles } from "@material-ui/core";
import Form from "../../Common/Form";
import TextField from "../../Common/TextField";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import Title from "../../Common/Title";
import colors from "../../../styles/colors";
import notificationActions from "../../../store/notification/actions";
import loginService from "../../../services/login";

const useStyle = makeStyles((theme) => ({
    grid: {
        margin: theme.spacing(1),
    },
    goButton: {
        marginTop: theme.spacing(0.5),
    },
    cancelButton: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(0.5),
    },
    title: {
        fontSize: "larger",
        borderBottom: "1px solid " + colors.primary,
    },
}));

function BatchCancellation(props) {
    const { form } = props;
    const classes = useStyle();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        props
            .cancelPeriod(form)
            .then(() => props.addNotification("success", "Sikeres sztornózás!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel sztornózás közben."));
        handleClose();
    };

    const formProps = {
        form: form,
        changeform: props.changeForm,
        name: "form",
    };
    
    const serviceProviderName = loginService.getUserObject()?.serviceProvider?.name;

    const disableSubmit = !serviceProviderName || form.from === undefined || form.from === "" || form.to === undefined || form.to === "";

    return (
        <PageTemplate
            header={{
                title: "Kötegelt sztornózás",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "batch-cancellation": "Kötegelt sztornózás",
                },
            }}
        >
            <Grid item xs={12}>
                <Card fullwidth title="Fogyasztási adatok sztornózása">
                    <Form {...formProps}>
                        <Title title="Feltöltés dátuma" format={{ xs: 12 }} {...formProps}>
                            <TextField
                                label="-tól"
                                name="from"
                                value={form.from || ""}
                                format={{ xs: 12, md: 6 }}
                                type="date"
                            />
                            <TextField
                                label="-ig"
                                name="to"
                                value={form.to || ""}
                                format={{ xs: 12, md: 6 }}
                                type="date"
                            />
                        </Title>
                    </Form>
                    <Grid item container>
                        <SubmitButton disabled={disableSubmit} onClick={handleOpen} className={classes.goButton}>
                            Mehet
                        </SubmitButton>
                    </Grid>
                    {!serviceProviderName &&
                        <Grid item container>
                            <p>A felhasználóhoz nincs szolgáltató hozzárendelve, így a kötegelt sztornózás nem futtatható.</p>
                        </Grid>
                    }
                </Card>
            </Grid>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    {`Biztosan sztornózni szeretné a magadott időszakra vonatkozó ${serviceProviderName} társasághoz tartozó adatokat?`}
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit}>Sztornó</SubmitButton>
                    <CancelButton onClick={handleClose}>Mégse</CancelButton>
                </DialogActions>
            </Dialog>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form } = state.batchCancellation;
    return { form };
}

const actionCreators = {
    changeForm: batchCancellationActions.changeForm,
    cancelPeriod: batchCancellationActions.cancelPeriod,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(BatchCancellation);
