import DownloadAPI from "../../utils/downloadAPI";

function download(url) {
    return DownloadAPI.get(`/handbooks/${url}`)
        .then((data) => {
            return data;
        })
        .catch((error) => Promise.reject(error));
}

const handbooksService = {
    download
};

export default handbooksService;