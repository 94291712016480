import React from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import colors from "../../styles/colors.js";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

/**
 * @param to - route
 * @param icon - ikon neve
 * @param text - oldal neve
 */

const useStyles = makeStyles(() => ({
    item: {
        color: colors.primary,
        "&:hover, &:hover i": {
            backgroundColor: colors.primary,
            color: colors.white,
            "& $icon": {
                color: colors.white,
            },
        },
        "&.$selected": {
            color: colors.white,
        },
    },
    icon: {
        color: colors.primary,
    },
    selected: {
        backgroundColor: colors.primary,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.primary,
        },
        "& $icon": {
            color: colors.white,
        },
        "& $item": {
            color: colors.white,
        },
    },
}));

export default function StatMenuItem(props) {
    const classes = useStyles();

    return (
        <MenuItem
            component={NavLink}
            className={classes.item}
            activeClassName={clsx(classes.selected)}
            key={props.route}
            {...props}
        >
            <Typography variant="inherit" display="block">
                {props.text}
            </Typography>
        </MenuItem>
    );
}
