export const types = {
    DEFAULT_FORM: "@@consumption-data-summary/DEFAULT_FORM",
    CHANGE_FORM: "@@consumption-data-summary/CHANGE_FORM",

    FILTER_SUMMARY_REQUEST: "@@consumption-data-summary/FILTER_SUMMARY_REQUEST",
    FILTER_SUMMARY_SUCCESS: "@@consumption-data-summary/FILTER_SUMMARY_SUCCESS",
    FILTER_SUMMARY_FAILURE: "@@consumption-data-summary/FILTER_SUMMARY_FAILURE",

    FILTER_FIRST_DETAILS_REQUEST: "@@consumption-data-summary/FILTER_FIRST_DETAILS_REQUEST",
    FILTER_FIRST_DETAILS_SUCCESS: "@@consumption-data-summary/FILTER_FIRST_DETAILS_SUCCESS",
    FILTER_FIRST_DETAILS_FAILURE: "@@consumption-data-summary/FILTER_FIRST_DETAILS_FAILURE",

    FILTER_DETAILS_REQUEST: "@@consumption-data-summary/FILTER_DETAILS_REQUEST",
    FILTER_DETAILS_SUCCESS: "@@consumption-data-summary/FILTER_DETAILS_SUCCESS",
    FILTER_DETAILS_FAILURE: "@@consumption-data-summary/FILTER_DETAILS_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@consumption-data-summary/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@consumption-data-summary/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@consumption-data-summary/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@consumption-data-summary/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@consumption-data-summary/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@consumption-data-summary/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@consumption-data-summary/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@consumption-data-summary/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@consumption-data-summary/EXPORT_DOWNLOAD_FAILURE",

    FETCH_SET_DETAILS_INFO: "@@consumption-data-summary/FETCH_SET_DETAILS_INFO",

    SET_PAGE_LOADING: "@@consumption-data-summary/SET_PAGE_LOADING",
};
