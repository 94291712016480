import {
    templateChangeForm,
    templateDefaultForm,
    templateFilter,
    templateFirstFetch,
    templateSetInfo,
} from "..";
import customerModificationImportService from "../../services/customerModificationImport";
import downloadBlob from "../../utils/downloadBlob";
import { types } from "./types";

function upload(form) {
    return (dispatch) => {
        dispatch({ type: types.UPLOAD_REQUEST, form });
        return customerModificationImportService.upload(form).then(
            (data) => {
                dispatch({ type: types.UPLOAD_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function download(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });
        return customerModificationImportService.download(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadValid(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_VALID_REQUEST });
        return customerModificationImportService.downloadValid(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_VALID_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_VALID_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadInvalid(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_INVALID_REQUEST });
        return customerModificationImportService.downloadInvalid(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_INVALID_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_INVALID_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadReply(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REPLY_REQUEST });
        return customerModificationImportService.downloadReply(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_REPLY_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_REPLY_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, customerModificationImportService),
    setInfo: templateSetInfo(types),
    firstFetch: templateFirstFetch(types, customerModificationImportService),
    upload,
    download,
    downloadValid,
    downloadInvalid,
    downloadReply,
};

export default actions;
