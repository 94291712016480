import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
} from "..";
import gdprService from "../../services/gdpr";
import { types } from "./types";

function deleteApplication(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_APPLICATION_REQUEST, id });
        return gdprService.deleteApplication(id).then(
            (data) => {
                dispatch({ type: types.DELETE_APPLICATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DELETE_APPLICATION_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deleteFilteredApplications(filter) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_APPLICATION_REQUEST, filter });
        return gdprService.deleteFilteredApplications(filter).then(
            (data) => {
                dispatch({ type: types.DELETE_APPLICATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DELETE_APPLICATION_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

function getLogStatus(sessionId) {
    return (dispatch) => {
        dispatch({ type: types.GET_LOG_STATUS_REQUEST, sessionId });
        return gdprService.getLogStatus(sessionId).then(
            (data) => {
                dispatch({ type: types.GET_LOG_STATUS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_LOG_STATUS_FAILURE, error });
                return Promise.reject(error);
            }
        )
    }
}

const actions = {
    deleteApplication,
    deleteFilteredApplications,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, gdprService),
    firstFetch: templateFirstFetch(types, gdprService),
    getLogStatus,
    exportInitiate: templateExportInitiate(types, gdprService),
    exportCheck: templateExportCheck(types, gdprService),
    exportDownload: templateExportDownload(types, gdprService),
    setInfo,
};

export default actions;
