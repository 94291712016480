import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import colors from "../../../styles/colors";
import Card from "../../Common/Card";
import SubmitButton from "../../Common/SubmitButton";
import Table from "../../Common/Table";
import moment from "moment";

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY.MM.DD. HH:mm");
}

function StatusCellRenderer(params) {
    switch (params.data.status) {
        case "initiated":
            return "Feldolgozásra vár";
        case "processing":
            return "Feldolgozás alatt";
        case "finished":
            return "Feldolgozva";
        case "unknown_error":
            return "Hiba";
        default:
            break;
    }
}

const useStyles = makeStyles(() => ({
    buttonClass: {
        width: "100%",
        backgroundColor: colors.filter,
        "&:hover": {
            background: colors.filterHover,
            color: colors.white,
        },
    },
    gridClass: {
        padding: "20px 20px 36px",
        margin: "auto",
    },
}));

export default function CommandsTable(props) {
    const classes = useStyles();
    const { data, title, submitText } = props;

    const table = {
        columnDefs: [
            { headerName: "Végrehajtó", field: "createdBy.name" },
            {
                headerName: "Időpont",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
                sort: "desc",
            },
            {
                headerName: "Állapot",
                field: "status",
                cellRenderer: "statusCellRenderer",
            },
        ],
        rowData: data || [],
        frameworkComponents: {
            dateCellRenderer: DateCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
    };

    return (
        <Grid container item xs={12} md={6}>
            <Card fullWidth title={title}>
                <Grid item xs={12} md={6} className={classes.gridClass}>
                    <SubmitButton className={classes.buttonClass} onClick={props.onClick}>
                        {submitText}
                    </SubmitButton>
                </Grid>
                <Grid item xs={12}>
                    <Table noExportButton {...table}></Table>
                </Grid>
            </Card>
        </Grid>
    );
}
