import React from "react";
import { AgGridReact } from "ag-grid-react";

export default function AgGrid(props) {
    const AG_GRID_LOCALE = {
        loadingOoo: "Betöltés...", //i18next.t('table.loading'),
        noRowsToShow: "Nincs megjeleníthető adat.", //i18next.t('table.no_rows'),
        page: "Oldal:", //i18next.t('table.page'),
        nextPage: "Következő", //i18next.t('mainpage.next'),
        lastPage: "Utolsó", //i18next.t('table.last'),
        firstPage: "Első", //i18next.t('table.first'),
        previousPage: "Előző", //i18next.t('mainpage.previous'),
        to: "/",
        of: "Összesen:", // i18next.t('table.of'),
    };
    return <AgGridReact {...props} localeText={AG_GRID_LOCALE} />;
}
