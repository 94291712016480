import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
} from "..";
import { types } from "./types";
import fixedTimeClientsService from "../../services/fixedTimeClients";

function getFilterFinanciers() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_FINANCIERS_REQUEST });
        return fixedTimeClientsService.getFilterFinanciers().then(
            (data) => {
                dispatch({ type: types.FETCH_FILTER_FINANCIERS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FILTER_FINANCIERS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

function getConsumptionPlaceServices1() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_REQUEST });
        return fixedTimeClientsService.getConsumptionPlaceServices(1).then(
            (data) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

function getConsumptionPlaceServices2() {
    return (dispatch) => {
        dispatch({ type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_REQUEST });
        return fixedTimeClientsService.getConsumptionPlaceServices(2).then(
            (data) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS,
                    data,
                });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({
                    type: types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_FAILURE,
                    error,
                });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, fixedTimeClientsService),
    getFilterFinanciers: getFilterFinanciers,
    getConsumptionPlaceServices1: getConsumptionPlaceServices1,
    getConsumptionPlaceServices2: getConsumptionPlaceServices2,
    exportInitiate: templateExportInitiate(types, fixedTimeClientsService),
    exportCheck: templateExportCheck(types, fixedTimeClientsService),
    exportDownload: templateExportDownload(types, fixedTimeClientsService),
    setInfo,
};

export default actions;
