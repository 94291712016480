import Dialog from "../../Common/Dialog";
import TextMenuItem from "../../Common/TextMenuItem";
import { Grid, makeStyles } from "@material-ui/core";
import colors from "../../../styles/colors";
import { Children } from "react";
import { FILES, PERMISSIONS, USER_MANUALS } from "../../../utils/AppConst";
import { hasRole } from "../../../utils/User";
import { useDispatch } from "react-redux";
import handbooksActions from "../../../store/handbooks/actions";
import notificationsActions from "../../../store/notification/actions";

const useStyles = makeStyles(() => ({
    item: {
        backgroundColor: colors.white,
        color: colors.primary,
        "&:hover": {
            backgroundColor: colors.primary,
            color: colors.white,
        },
    },
    link: {
        textDecoration: "none",
        width: "100%",
    },
    dialog: {
        width: "50%",
    },
}));

function getUserManuals() {
    const result = [];
    const roles = Object.values(PERMISSIONS);

    for (let i = 0; i < roles.length; i++) {
        if (roles[i] && hasRole(roles[i])) {
            result.push(USER_MANUALS?.[roles?.[i]]);
        }
    }

    return result;
}

const userManuals = getUserManuals();

const Opener = () => <TextMenuItem>Segítség</TextMenuItem>;

const Item = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(notificationsActions.addNotification("info", "A fájl letöltése folyamatban..."));
        dispatch(handbooksActions.download(props.url, props.title))
            .then(() => dispatch(notificationsActions.addNotification("success", "Sikeres letöltés!")))
            .catch(() => dispatch(notificationsActions.addNotification("error", "Hiba lépett fel letöltés közben.")));
    }

    return (
        <Grid container item xs={12}>
            <TextMenuItem className={classes.item} onClick={handleClick}>{props.text}</TextMenuItem>
        </Grid>
    );
};

export default function Helper() {
    return (
        <Dialog opener={Opener()} title="Segítség" fullWidth>
            {Children.toArray(FILES.map(Item))}
            {Children.toArray(userManuals?.map(Item))}
        </Dialog>
    );
}
