import React, { Children, useEffect } from "react";
import { Grid } from "@material-ui/core";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import Title from "../../../Common/Title";
import InputBase from "../../../Common/InputBase";
import Card from "../../../Common/Card";

function getContactVal(data, type) {
    type = typeof type === typeof undefined ? "name" : type;
    if (data.contactEmployer) {
        return (type === "id" ? "employer_" : "") + data.contactEmployer[type];
    } else if (data.contactProvider) {
        return (type === "id" ? "provider_" : "") + data.contactProvider[type];
    } else {
        return "-";
    }
}

function getContactOptions(contacts) {
    return (
        <React.Fragment>
            <optgroup label="Munkáltató">
                {contacts && contacts.employers
                    ? Children.toArray(
                          contacts.employers.map((employer) => {
                              return <option value={"employer_" + employer.id}>{employer.name}</option>;
                          })
                      )
                    : ""}
            </optgroup>
            <optgroup label="Szolgáltató">
                {contacts && contacts.providers
                    ? contacts.providers.map((provider) => {
                          return <option value={"provider_" + provider.id}>{provider.name}</option>;
                      })
                    : ""}
            </optgroup>
        </React.Fragment>
    );
}

export default function Contact(props) {
    const { form, name, edit, data, contacts } = props;

    const formProps = {
        form: form,
        name: name,
        changeform: props.changeForm,
        noBold: true,
    };

    useEffect(() => {
        const value = form.contact;
        const type = value?.split("_")[0] || "";
        const id = value?.split("_")[1] || -1;

        if (type === "employer") {
            const newValue = contacts.employers.find((elem) => elem.id === Number(id));
            props.changeForm(
                {
                    ...form,
                    contactEmployer: {
                        id: id,
                        name: newValue.name,
                    },
                    contactProvider: null,
                },
                "form"
            );
        } else if (type === "provider") {
            const newValue = contacts.providers.find((elem) => elem.id === Number(id));
            props.changeForm(
                {
                    ...form,
                    contactProvider: {
                        id: id,
                        name: newValue.name,
                    },
                    contactEmployer: null,
                },
                "form"
            );
        }
    }, [form.contact]);

    return (
        <Card title="Kapcsolat" edit={edit} fullWidth>
            {edit ? (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="E-mail cím" {...formProps}>
                            <TextField label="E-mail" name="email" value={form.email || ""} format={{ xs: 12 }} />
                        </Title>
                        <Title title="Telefonszám" {...formProps}>
                            <TextField
                                label="Telefon"
                                name="phone"
                                value={form.phone || ""}
                                format={{ xs: 12 }}
                                helperText="Helyes formátum: +36201234567"
                            />
                        </Title>
                        <Title title="Kapcsolattartó" {...formProps}>
                            <Select name="contact" value={getContactVal(form, "id")} format={{ xs: 12 }} native>
                                {getContactOptions(contacts)}
                            </Select>
                        </Title>
                    </Grid>
                </Form>
            ) : (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="E-mail cím" {...formProps}>
                            <InputBase label="E-mail" value={data.email || ""} format={{ xs: 12 }} disabled />
                        </Title>
                        <Title title="Telefonszám" {...formProps}>
                            <InputBase label="Telefon" value={data.phone || ""} format={{ xs: 12 }} disabled />
                        </Title>
                        <Title title="Kapcsolattartó" {...formProps}>
                            <InputBase value={getContactVal(data)} format={{ xs: 12 }} disabled />
                        </Title>
                    </Grid>
                </Form>
            )}
        </Card>
    );
}
