import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filterSummary(form) {
    const searchForm = new SearchForm(form);

    return API.get("/finance/summary/summary-data", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function filterDetails(form) {
    const searchForm = new SearchForm(form);

    return API.get("/finance/summary", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const consumptionDataSummaryService = {
    filterSummary,
    filterDetails,
    exportInitiate: templateExportInitiate("finance/summary"),
    exportCheck: templateExportCheck("finance/summary"),
    exportDownload: templateExportDownload("finance/summary"),
};

export default consumptionDataSummaryService;
