import { Grid, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { GET_STAT_DETAILS } from "../../utils/AppConst";
import PageTemplate from "../Common/PageTemplate";
import StatMenuItem from "../Common/StatMenuItem";

const useStyle = makeStyles((theme) => ({
    textGrid: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(6),
        paddingRight: theme.spacing(6),
    },
}));

const allStatistics = ["c-tariff/3-2-2", "gkm/4-1-1", "gkm/4-1-2", "gkm/4-1-3", "gkm/4-1-4", "gkm/4-1-5", "mekh/4-2-2", "other/1-1"];

function Statistics(props) {
    const [details, setDetails] = useState(GET_STAT_DETAILS[0]);
    const classes = useStyle();
    const { category } = props.match.params;

    let detailsArray = [];
    let statistics = [];
    let breadCrumb;

    detailsArray.push(GET_STAT_DETAILS[0]);

    switch (category) {
        case "c-tariff":
            detailsArray.push(GET_STAT_DETAILS[1]);
            statistics.push(allStatistics[0]);
            breadCrumb = "C-Tarifa kimutatások";
            break;
        case "gkm":
            detailsArray.push(GET_STAT_DETAILS[2]);
            detailsArray.push(GET_STAT_DETAILS[3]);
            detailsArray.push(GET_STAT_DETAILS[4]);
            statistics.push(allStatistics[1]);
            statistics.push(allStatistics[2]);
            statistics.push(allStatistics[3]);
            breadCrumb = "GKM kimutatások";
            break;
        case "mekh":
            detailsArray.push(GET_STAT_DETAILS[5]);
            statistics.push(allStatistics[4]);
            breadCrumb = "MEKH kimutatások";
            break;
        case "other":
            detailsArray.push(GET_STAT_DETAILS[6]);
            statistics.push(allStatistics[5]);
            breadCrumb = "Egyéb kimutatások";
            break;
        default:
            detailsArray = GET_STAT_DETAILS;
            statistics = allStatistics;
    }

    return (
        <PageTemplate
            header={{
                title: "Statisztikák",
                breadcrumbs: {
                    statistics: "Statisztikák",
                    category: breadCrumb ? breadCrumb : "",
                },
            }}
        >
            <Grid item container xs={12}>
                {statistics.map((statistic, index) => (
                    <Grid item xs={12}>
                        <StatMenuItem
                            onMouseOver={() => setDetails(detailsArray[index + 1])}
                            onMouseLeave={() => setDetails(detailsArray[0])}
                            to={"/statistics/" + statistic}
                            text={detailsArray[index + 1].title}
                            style={{ whiteSpace: "normal", transition: "all 0.2s" }}
                        />
                        {details?.title === detailsArray[index + 1]?.title && (
                            <Grid item xs={12} className={classes.textGrid}>
                                <i>{details.body}</i>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        </PageTemplate>
    );
}

export default Statistics;
