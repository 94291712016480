import { withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import colors from "../../styles/colors.js";

const Add = withStyles({
    root: {
        backgroundColor: colors.green,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.greenHover,
        },
    },
})(AddIcon);

export default Add;
