import { mainMenus, PERMISSIONS, subMenus } from "../../../utils/AppConst";
import { hasRole } from "../../../utils/User";

export default class RolesRules {
    constructor(roles) {
        this.roles = roles;
    }

    getMenu() {
        const roles = this.roles || [];
        let result = [];
        let tmp = [];

        if (hasRole(PERMISSIONS.ADMIN)) {
            return Object.values(mainMenus);
        }

        roles.map((role) => {
            switch (role) {
                case PERMISSIONS.ADMIN:
                    result = Object.values(mainMenus);
                    break;
                case PERMISSIONS.PENSIONER_CONTACT:
                    {
                        tmp = [
                            {
                                name: "Igénylések",
                                subMenus: [subMenus.applicationFiling, subMenus.applicationsList],
                            },
                            mainMenus.admissedApplications,
                            {
                                name: "Ügyfelek",
                                subMenus: [subMenus.clients, subMenus.employmentTerminations],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                case PERMISSIONS.EMPLOYER_CONTACT:
                    {
                        tmp = [
                            {
                                name: "Igénylések",
                                subMenus: [subMenus.applicationFiling, subMenus.applicationsList],
                            },
                            mainMenus.admissedApplications,
                            {
                                name: "Ügyfelek",
                                subMenus: [subMenus.clients, subMenus.employmentTerminations],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                case PERMISSIONS.FINANCIER:
                    {
                        tmp = [
                            mainMenus.admissedApplications,
                            {
                                name: "Ügyfelek",
                                subMenus: [subMenus.tax],
                            },
                            {
                                name: "Pénzügyek",
                                subMenus: [subMenus.monthlyConsumptionData, subMenus.auditOfFinancingAccounts],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                case PERMISSIONS.SERVICE_PROVIDER:
                    {
                        tmp = [
                            {
                                name: "Igénylések",
                                subMenus: [subMenus.providerValidation],
                            },
                            mainMenus.admissedApplications,
                            {
                                name: "Ügyfelek",
                                subMenus: [subMenus.clients, subMenus.employmentTerminations],
                            },
                            {
                                name: "Pénzügyek",
                                subMenus: [
                                    subMenus.consumptionData,
                                    subMenus.monthlyConsumptionData,
                                    subMenus.filesList,
                                    subMenus.batchCancellation,
                                    subMenus.auditOfFinancingAccounts,
                                ],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                case PERMISSIONS.C_TARIFF:
                    {
                        tmp = [
                            {
                                name: "Igénylések",
                                subMenus: [subMenus.applicationReceipt, subMenus.applicationsList],
                            },
                            {
                                name: "Ügyfelek",
                                subMenus: [subMenus.clients, subMenus.tax, subMenus.fixedTimeCustomer],
                            },
                            {
                                name: "Pénzügyek",
                                subMenus: [subMenus.consumptionDataValidator],
                            },
                            {
                                name: "Statisztikák",
                                subMenus: [
                                    subMenus.cTariff,
                                    subMenus.GKM1,
                                    subMenus.GKM2,
                                    subMenus.GKM3,
                                    subMenus.MEKH,
                                    subMenus.other,
                                ],
                            },
                            {
                                name: "Operátor",
                                subMenus: [subMenus.employer],
                            },
                        ];
                    }
                    result = this.mergeObjects(result, tmp);
                    break;
                default:
                    break;
            }
        });

        return result;
    }

    mergeObjects(dest, src) {
        const result = [];
        let i = 0;
        for (const value of Object.values(src)) {
            const findPair = Object.values(dest).filter((d) => d.name === value.name);
            const pair = findPair.length > 0 ? findPair[0] : { name: value.name, subMenus: [] };
            result[i] = {
                name: value.name,
                subMenus: [
                    ...value.subMenus,
                    ...pair.subMenus.filter((fmenu) => !value.subMenus.map((vmenu) => vmenu.to).includes(fmenu.to)),
                ].sort((a, b) => this.findIndexInSubMenus(a.to) - this.findIndexInSubMenus(b.to)),
            };
            i++;
        }

        return [...dest.filter((d) => src.filter((s) => s.name === d.name).length === 0), ...result].sort(
            (a, b) => this.findIndexInMainMenus(a.name) - this.findIndexInMainMenus(b.name)
        );
    }

    findIndexInSubMenus(to) {
        return Object.values(subMenus).findIndex((menu) => menu.to === to);
    }

    findIndexInMainMenus(name) {
        return Object.values(mainMenus).findIndex((menu) => menu.name === name);
    }
}
