import { Grid } from "@material-ui/core";
import Filter from "../../Common/Filter";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import TextField from "../../Common/TextField";
import Autocomplete from "../../Common/Autocomplete";
import { IS_ACTIVE_OPTIONS } from "../../../utils/AppConst";
import { setInfo } from "../../../utils/FilterHelper";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { filterStorageSave } from "../../../utils/FilterStorage";

function InfoBtnCellRenderer() {
    return <OperationButton type="info" to={`#`} />;
}

function PermissionToCellRenderer(params) {
    if (params.data.permissionFrom && params.value) {
        return params.value;
    }
    if (params.data.permissionFrom && params.value === "") {
        return "2099-12-31";
    }
    return "";
}

export default function BaseFilterTab(props) {
    const { filter, data, token, info, tableLoading, showTable, filterStorageKey, financiers } = props;

    function IsActiveCellRenderer(params) {
        return params.data.isActive === "yes" ? "Aktív" : "Inaktív";
    }

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Előnév", field: "title" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            { headerName: "Születési hely", field: "birthPlace" },
            { headerName: "Születési idő", field: "birthDate" },
            { headerName: "Anyja vezetékneve", field: "motherLastName" },
            { headerName: "Anyja keresztneve", field: "motherFirstName" },
            { headerName: "Város", field: "addressCity" },
            { headerName: "Cím", field: "addressStreet" },
            { headerName: "Irányítószám", field: "addressPostcode" },
            { headerName: "Telefonszám", field: "phoneNumber" },
            { headerName: "Finanszírozó", field: "financier" },
            { headerName: "Jogosultság", field: "isActive", cellRenderer: "isActiveCellRenderer" },
            { headerName: "Jogosultság kezdete", field: "permissionFrom" },
            { headerName: "Jogosultság vége", field: "permissionTo", cellRenderer: "permissionToCellRenderer" },
        ],
        loading: tableLoading,
        rowData: data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            infoBtnCellRenderer: InfoBtnCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
            isActiveCellRenderer: IsActiveCellRenderer,
            permissionToCellRenderer: PermissionToCellRenderer,
        },
        autoSize: "true",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            info: info,
            token: token,
        },
        filterData: filter,
        searchfunc: props.search,
        info: info,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    const FINANCIER_OPTIONS = financiers?.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    return (
        <Grid item container xs={12} spacing={2}>
            <Filter
                onSubmit={() => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                }}
                defaultFormAction={() => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    setInfo(props, "filter", {}, "sort", { lastName: "asc" });
                }}
                form={filter}
                name="baseFilter"
                changeform={props.changeForm}
            >
                <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 3 }} />
                <TextField label="Előnév" name="title" value={filter.title || ""} format={{ xs: 12, md: 3 }} />
                <TextField
                    label="Vezetéknév"
                    name="lastName"
                    value={filter.lastName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Keresztnév"
                    name="firstName"
                    value={filter.firstName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Születési hely"
                    name="birthPlace"
                    value={filter.birthPlace || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Születési idő (-tól)"
                    name="birthDateFrom"
                    value={filter.birthDateFrom || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Születési idő (-ig)"
                    name="birthDateTo"
                    value={filter.birthDateTo || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Anyja vezetékneve"
                    name="motherLastName"
                    value={filter.motherLastName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Anyja keresztneve"
                    name="motherFirstName"
                    value={filter.motherFirstName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Telefonszám"
                    name="phoneNumber"
                    value={filter.phoneNumber || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Város"
                    name="addressCity"
                    value={filter.addressCity || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Cím"
                    name="addressStreet"
                    value={filter.addressStreet || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Irányítószám"
                    name="addressPostcode"
                    value={filter.addressPostcode || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="Jogosultság"
                    name="isActive"
                    id="isActive"
                    format={{ xs: 12, md: 3 }}
                    value={filter.isActive || []}
                    options={IS_ACTIVE_OPTIONS}
                />
                <TextField
                    label="Jogosultság kezdete (-tól)"
                    name="permissionFromStart"
                    value={filter.permissionFromStart || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Jogosultság kezdete (-ig)"
                    name="permissionFromEnd"
                    value={filter.permissionFromEnd || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Jogosultság vége (-tól)"
                    name="permissionToStart"
                    value={filter.permissionToStart || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Jogosultság vége (-ig)"
                    name="permissionToEnd"
                    value={filter.permissionToEnd || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <Autocomplete
                    label="Finanszírozó"
                    name="financier"
                    id="financier"
                    value={filter.financier || []}
                    format={{ xs: 12, md: 3 }}
                    options={FINANCIER_OPTIONS}
                />
            </Filter>
            {showTable && (
                <Grid item xs={12}>
                    <Table {...table} />
                </Grid>
            )}
        </Grid>
    );
}
