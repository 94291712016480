export const types = {
    DEFAULT_FORM: "@@employers/DEFAULT_FORM",
    CHANGE_FORM: "@@employers/CHANGE_FORM",

    FETCH_REQUEST: "@@employers/FETCH_REQUEST",
    FETCH_SUCCESS: "@@employers/FETCH_SUCCESS",
    FETCH_FAILURE: "@@employers/FETCH_FAILURE",

    GET_REQUEST: "@@employers/GET_REQUEST",
    GET_SUCCESS: "@@employers/GET_SUCCESS",
    GET_FAILURE: "@@employers/GET_FAILURE",

    UPDATE_REQUEST: "@@employers/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@employers/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@employers/UPDATE_FAILURE",

    CREATE_REQUEST: "@@employers/CREATE_REQUEST",
    CREATE_SUCCESS: "@@employers/CREATE_SUCCESS",
    CREATE_FAILURE: "@@employers/CREATE_FAILURE",

    GET_VALIDATION_CREATE_REQUEST: "@@employers/GET_VALIDATION_CREATE_REQUEST",
    GET_VALIDATION_CREATE_SUCCESS: "@@employers/GET_VALIDATION_CREATE_SUCCESS",
    GET_VALIDATION_CREATE_FAILURE: "@@employers/GET_VALIDATION_CREATE_FAILURE",

    GET_VALIDATION_UPDATE_REQUEST: "@@employers/GET_VALIDATION_UPDATE_REQUEST",
    GET_VALIDATION_UPDATE_SUCCESS: "@@employers/GET_VALIDATION_UPDATE_SUCCESS",
    GET_VALIDATION_UPDATE_FAILURE: "@@employers/GET_VALIDATION_UPDATE_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@employers/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@employers/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@employers/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@employers/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@employers/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@employers/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@employers/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@employers/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@employers/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@employers/CLEAR_FORM",
};
