import searchAPI from "../../utils/searchAPI";
import API from "../../utils/API";

function checkArrival(barCode) {
    return searchAPI
        .get(`/applications/arrive/check-arrival/${barCode}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function arrive(id, arrivalBarCode) {
    return API.post(`/applications/arrive/${id}`, {
        arrivalBarCode: arrivalBarCode,
    })
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function deleteArrival(id) {
    return API.post(`/applications/arrive/delete-arrival/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const applicationsListService = {
    checkArrival,
    arrive,
    deleteArrival,
};

export default applicationsListService;
