import React from "react";
import { Grid } from "@material-ui/core";
import TextField from "../../../Common/TextField";
import InputBase from "../../../Common/InputBase";
import Form from "../../../Common/Form";
import Title from "../../../Common/Title";
import Card from "../../../Common/Card";

export default function PersonalDetails(props) {
    const { form, name, edit, data } = props;

    const formProps = {
        name: name,
        changeform: props.changeForm,
        form: form,
        noBold: true,
    };

    //TODO: fix this
    const handlePensionerRegistrationNumberChange = (e) => {
        let value = e.target?.value || "";
        if (value?.length < form?.pensionerRegistrationNumber?.length) {
            formProps.changeform({ ...form, pensionerRegistrationNumber: value }, formProps.name);
            return;
        }
        if (value.length > 11 || isNaN(value.slice(-1))) return;

        value = value.replaceAll("-", "");
        value = [value.slice(0, 3), value.slice(3, 8), value.slice(8)].filter((elem) => elem.length > 0).join("-");

        formProps.changeform({ ...form, pensionerRegistrationNumber: value }, formProps.name);
    };

    return (
        <Card title="Személyes adatok" edit={edit} fullWidth>
            {edit ? (
                <Form {...formProps}>
                    <Title title="Név" format={{ xs: 12 }} {...formProps}>
                        <TextField label="Előnév" name="title" value={form.title || ""} format={{ xs: 12, md: 2 }} />
                        <TextField
                            label="Vezetéknév"
                            name="lastName"
                            value={form.lastName || ""}
                            format={{ xs: 12, md: 5 }}
                        />
                        <TextField
                            label="Keresztnév"
                            name="firstName"
                            value={form.firstName || ""}
                            format={{ xs: 12, md: 5 }}
                        />
                    </Title>
                    <Title title="Anyja születési neve" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Vezetéknév"
                            name="motherLastName"
                            value={form.motherLastName || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <TextField
                            label="Keresztnév"
                            name="motherFirstName"
                            value={form.motherFirstName || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                    </Title>
                    <Title title="Születési név" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Vezetéknév"
                            name="birthLastName"
                            value={form.birthLastName || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <TextField
                            label="Keresztnév"
                            name="birthFirstName"
                            value={form.birthFirstName || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                    </Title>
                    <Title title="Születési hely, idő" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Hely"
                            name="birthPlace"
                            value={form.birthPlace || ""}
                            format={{ xs: 12, md: 6 }}
                        />

                        <TextField
                            label="Idő"
                            name="birthDate"
                            value={form.birthDate || ""}
                            type="date"
                            format={{ xs: 12, md: 6 }}
                        />
                    </Title>
                    <Title title="Nyugdíjas törzsszám" format={{ xs: 12 }} {...formProps}>
                        <TextField
                            label="Nyugdíjas törzsszám"
                            name="pensionerRegistrationNumber"
                            value={form.pensionerRegistrationNumber || ""}
                            format={{ xs: 12 }}
                            rule={{
                                length: 11,
                                number: "true",
                            }}
                            onChange={handlePensionerRegistrationNumberChange}
                            helperText="Helyes formátum: 123-12345-1"
                        />
                    </Title>
                </Form>
            ) : (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="Név" {...formProps}>
                            <InputBase label="Előnév" value={data.title || ""} format={{ xs: 12, md: 2 }} disabled />
                            <InputBase
                                label="Vezetéknév"
                                value={data.lastName || ""}
                                format={{ xs: 12, md: 5 }}
                                disabled
                            />
                            <InputBase
                                label="Keresztnév"
                                value={data.firstName || ""}
                                format={{ xs: 12, md: 5 }}
                                disabled
                            />
                        </Title>
                        <Title title="Anyja születési neve" {...formProps}>
                            <InputBase
                                label="Vezetéknév"
                                value={data.motherLastName || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <InputBase
                                label="Keresztnév"
                                value={data.motherFirstName || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                        </Title>
                        <Title title="Születési név" {...formProps}>
                            <InputBase
                                label="Vezetéknév"
                                value={data.birthLastName || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <InputBase
                                label="Keresztnév"
                                value={data.birthFirstName || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                        </Title>
                        <Title title="Születési hely, idő" {...formProps}>
                            <InputBase label="Hely" value={data.birthPlace || ""} format={{ xs: 12, md: 6 }} disabled />

                            <InputBase label="Idő" value={data.birthDate || ""} format={{ xs: 12, md: 6 }} disabled />
                        </Title>
                        {data?.pensionerRegistrationNumber && (
                            <Title title="Nyugdíjas törzsszám" {...formProps}>
                                <InputBase
                                    label="Nyugdíjas törzsszám"
                                    value={data.pensionerRegistrationNumber || ""}
                                    format={{ xs: 12 }}
                                    disabled
                                />
                            </Title>
                        )}
                    </Grid>
                </Form>
            )}
        </Card>
    );
}
