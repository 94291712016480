import { Grid } from "@material-ui/core";
import React, { Children } from "react";

/**
 *
 * @param childComponent - Form-on belül való alkalmazáshoz. Ezzel egyszerűbb a formázás.
 */

export default function Form(props) {
    const { form, validation } = props;
    let previousErrors = typeof form !== typeof undefined ? form.errors || [] : [];
    const clientValidation = props.clientValidation !== undefined ? props.clientValidation : true;

    const getRules = (name) => (validation ? validation.filter((v) => v.field === name) : []);

    const handleChange = (e, v, i) => {
        let val;
        let name;
        let object = null;

        if (i === "remove-option") {
            val = v;
            name =
                e.target.parentNode.nodeName === "svg"
                    ? e.target.parentNode.parentNode.getAttribute("name")
                    : e.target.parentNode.getAttribute("name");
        } else {
            val = e.target.className?.includes("Autocomplete")
                ? v
                : e.target.type === "checkbox"
                ? e.target.checked
                : e.target.value;
            name = e.target.className?.includes("Autocomplete") ? e.target.id.split("-")[0] : e.target.name;

            let stop = false;
            if (e.target.attributes) {
                [...e.target.attributes].map((attr) => {
                    switch (attr.name) {
                        case "number": {
                            if (isNaN(val.slice(-1))) {
                                val = val.slice(0, -1);
                                stop = true;
                                break;
                            } else {
                                stop = stop || false;
                                break;
                            }
                        }
                        case "length": {
                            if (val.length > attr.value) {
                                val = val.slice(0, -1);
                                stop = true;
                                break;
                            } else {
                                stop = stop || false;
                                break;
                            }
                        }
                        case "float": {
                            const floatReg = /\d+(\.)?(\d)?$/;
                            if (!floatReg.test(val) && val.length > 0) {
                                val = val.slice(0, -1);
                                stop = true;
                                break;
                            } else {
                                stop = stop || false;
                                break;
                            }
                        }

                        default:
                            break;
                    }
                    return 0; //warning miatt kell neki visszatérési érték
                });
            }
            if (stop) return; //ne spammelje a konzolt
        }
        if (name?.includes(".")) {
            object = name.split(".")[0];
            name = name.split(".")[1];
        }
        const changedName = object ? object : name;
        val = object ? { ...form[object], [name]: val } : val;
        const lengthRule = getRules(name).filter((v) => v.type === "length");
        if (lengthRule.length > 0 && typeof val === 'string') val = val.slice(0, lengthRule[0].options.max);

        if (v && !v.length) {
            //select-nél, ha választ értéket, akkor nem üres, azaz nem lehet hiba sem
            handleBlur(e);

            props.changeform(
                {
                    ...((props.name !== "legalBasis" || name !== "type") && form),
                    [changedName]: val,
                    errors: form.errors?.filter((error) => error.name !== name),
                },
                props.name
            );
        } else {
            if (typeof props.changeform !== typeof undefined) {
                props.changeform(
                    {
                        ...((props.name !== "legalBasis" || name !== "type") && form),
                        [changedName]: val,
                    },
                    props.name
                );
            }
        }
    };

    const handleBlur = (e) => {
        const name = e.target.name;
        const rules = getRules(name);

        for (let rule of rules) {
            switch (rule.type) {
                case "not_blank": {
                    if (e.target.value === "")
                        previousErrors.push({
                            name: name,
                            label: "A mező kitöltése kötelező",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "date": {
                    const dateReg = new RegExp(rule.options?.pattern.slice(1, -1));
                    if (!dateReg.test(e.target.value))
                        previousErrors.push({
                            name: name,
                            label: "Nem megfelelő dátum formátum",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "post_code": {
                    const postCodeReg = new RegExp(rule.options?.pattern.slice(1, -1));
                    if (!postCodeReg.test(e.target.value))
                        previousErrors.push({
                            name: name,
                            label: "Nem megfelelő irányítószám formátum",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "email": {
                    const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                    if (!emailReg.test(e.target.value) && e.target.value.length > 0)
                        previousErrors.push({
                            name: name,
                            label: "Nem megfelelő e-mail formátum",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "number": {
                    const numberReg = /^[0-9]*$/;
                    if (!numberReg.test(e.target.value))
                        previousErrors.push({
                            name: name,
                            label: "A mező csak számot tartalmazhat",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "past": {
                    //ha múltbeli (beleértve ma is) dátumnak kell lennie
                    if (new Date(e.target.value).getTime() > new Date().getTime())
                        previousErrors.push({
                            name: name,
                            label: "Nem lehet jövőbeli dátum",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                case "future": {
                    //ha jövőbeli (beleértve ma is) dátumnak kell lennie
                    if (new Date(e.target.value).getTime() < new Date().getTime())
                        previousErrors.push({
                            name: name,
                            label: "Nem lehet múltbéli dátum",
                        });
                    else previousErrors = previousErrors.filter((v) => v.name !== name);
                    break;
                }
                //tipp: ha pontosan mai dátumra kell validálni, akkor a past és future szabályok egyszerre való alkalmazásával megkapjuk
                default:
                    break;
            }
        }

        if (rules.length > 0) props.changeform({ ...form, errors: previousErrors }, props.name);
    };

    const getError = (name) => previousErrors.filter((v) => v.name === name).length > 0;

    const getHelperText = (name) => previousErrors.filter((v) => v.name === name)[0].label;
    const getHelperTextChild = (child) => {
        if (child.props.validatorKey) {
            return getError(child.props.validatorKey) && getHelperText(child.props.validatorKey);
        } else {
            return getError(child.props.name) && getHelperText(child.props.name);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        previousErrors = form.errors || [];
        props.changeform({ ...form, errors: [] }, props.name);
        for (const [key, value] of Object.entries(form)) {
            if (key !== "errors") handleBlur({ target: { name: key, value: value } });
            if (value?.value !== undefined && value?.label !== undefined) {
                props.changeform({ ...form, [key]: value.value });
            }
        }
        if (previousErrors.length > 0 && clientValidation) return;

        if (props.onSubmit !== undefined) props.onSubmit();
    };

    const buildElem = (child) =>
        child.props && child.props.button ? (
            <Grid item {...child.props.format}>
                {child}
            </Grid>
        ) : child.props.container ? (
            Children.toArray(Children.toArray(child.props.children)).map((grandChild) => {
                const actualInput = Children.toArray(grandChild.props.children)[0];

                const toUseStyle = grandChild.props.format ? grandChild.props.format : grandChild.props;

                return (
                    <Grid {...toUseStyle}>
                        {React.cloneElement(actualInput, {
                            onChange: handleChange,
                            onBlur: handleBlur,
                            ...child?.props?.type?.includes("date") && {
                                formToChild: props.form,
                                formNameToChild: props.name,
                                functionToChild: props.changeform,
                            },
                            ...(getRules(actualInput.props.name).length > 0 && {
                                error: getError(actualInput.props.name),
                                helperText: getError(actualInput.props.name) && getHelperText(actualInput.props.name),
                            }),
                        })}
                    </Grid>
                );
            })
        ) : (
            <Grid item {...child.props.format}>
                {React.cloneElement(child, {
                    onChange: child?.props?.type?.includes("date") ? child.props.onChange : (child.props.onChange || handleChange),
                    onBlur: handleBlur,
                    ...child?.props?.type?.includes("date") && {
                        formToChild: props.form,
                        formNameToChild: props.name,
                        functionToChild: props.changeform,
                    },
                    ...((getRules(child.props.name).length > 0 ||
                        (typeof form !== typeof undefined && form.errors?.length > 0)) && {
                        error: getError(child.props.validatorKey) || getError(child.props.name),
                        helperText: child.props.helperText || getHelperTextChild(child),
                    }),
                })}
            </Grid>
        );

    const contentGenerator = () => (
        <Grid item container spacing={1}>
            {Children.toArray(
                Children.toArray(props.children).map((child) => {
                    if (!child.props?.className?.includes("hidden")) return buildElem(child);
                    else return "";
                })
            )}
        </Grid>
    );

    return props.childComponent ? (
        contentGenerator()
    ) : (
        <form name={props.name} onSubmit={handleSubmit} className={props.className || ""}>
            {contentGenerator()}
        </form>
    );
}
