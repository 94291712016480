import { templateDefaultForm, templateGet } from "..";
import applicationsDetailsService from "../../services/applicationDetails";
import applicationDetailsService from "../../services/applicationDetails";
import { types } from "./types";

function deleteApplication(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_APPLICATION_REQUEST, id });
        return applicationsDetailsService.deleteApplication(id).then(
            (data) => {
                dispatch({ type: types.DELETE_APPLICATION_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DELETE_APPLICATION_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    deleteApplication,
    defaultForm: templateDefaultForm(types),
    get: templateGet(types, applicationDetailsService),
};

export default actions;
