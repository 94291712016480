import { createSubmit, templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import searchAPI from "../../utils/searchAPI";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/blacklist", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function checkCustomer(mavirId) {
    return searchAPI
        .get(`/blacklist/check-customer/${mavirId}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

function close(id) {
    return API.post(`/blacklist/${id}/close`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const blackListService = {
    filter,
    checkCustomer,
    close,
    create: createSubmit("blacklist"),
    exportInitiate: templateExportInitiate("blacklist"),
    exportCheck: templateExportCheck("blacklist"),
    exportDownload: templateExportDownload("blacklist"),
};

export default blackListService;
