import React, { useEffect, useState } from "react";
import TextField from "../../Common/TextField";
import Table from "../../Common/Table";
import Filter from "../../Common/Filter";
import Card from "../../Common/Card";
import consumptionDataValidatorActions from "../../../store/consumptionDataValidator/actions";
import Autocomplete from "../../Common/Autocomplete";
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import PageTemplate from "../../Common/PageTemplate";
import OperationButton from "../../Common/OperationButton";
import axios from "axios";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import consumptionDataActions from "../../../store/consumptionData/actions";
import { connect } from "react-redux";
import { setInfo } from "../../../utils/FilterHelper";
import { FILTER_MONTH_OPTIONS } from "../../../utils/AppConst";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function BtnCellRenderer(params) {
    return <>{params.value ? <OperationButton type="check" /> : <OperationButton type="close" />}</>;
}

function ConsumptionDataValidator(props) {
    const { filter, employers, providers, years, pageLoading, tableLoading, showTable, data, tableInfo, token, customerFilterStorageKey, consumptionPeriodFilterStorageKey } = props;
    const [value, setValue] = useState("");

    useEffect(() => {
        props.defaultForm();
        axios.all([props.getEmployers(), props.getProviders(), props.getYears()]).then(
            axios.spread(() => {
                props.setPageLoading(false);
            })
        );
    }, []);

    const getFilterStorageKey = (value) => {
        let filterStorageKey = "";
        if(value === 'consumption-period'){
            filterStorageKey = consumptionPeriodFilterStorageKey;
        }else if(value === 'from-costumers'){
            filterStorageKey = customerFilterStorageKey;
        }
        return filterStorageKey;
    }

    const handleChange = (event) => {
        const storageFilter = filterStorageGet(getFilterStorageKey(event.target.value));
        props.changeForm(storageFilter, "filter");
        setInfo({ ...props, search: showTable ? props.search : props.firstFetch }, "filter", storageFilter);
        setValue(event.target.value);
    };

    const onSubmit = () => {
        filterStorageSave(getFilterStorageKey(value), filter);
        setInfo({ ...props, search: showTable ? props.search : props.firstFetch }, "filter", filter);
    };

    const financierOptions = employers.map((employer) => {
        return {
            value: employer.id,
            label: employer.name,
        };
    });

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    const yearOptions = years.map((year) => {
        return {
            value: year.id,
            label: `${year.name}`,
        };
    });

    const rowData = data.map((data) => {
        return {
            mavirId: data.mavirId,
            permission: `${data.from} - ${data.to}`,
            invoiceSerialNumber: data.serialNumber,
            accountingPeriod: `${data.accountingStartDate} - ${data.accountingEndDate}`,
            yearMonth: `${data.year}/${data.month}`,
            financierValidation: data.validFinancier,
            periodStartValidation: data.validStart,
            periodEndValidation: data.validEnd,
        };
    });

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Jogosultság", field: "permission" },
            { headerName: "Számla sorszáma", field: "invoiceSerialNumber" },
            { headerName: "Elszámolási időszak", field: "accountingPeriod" },
            { headerName: "Év/Hónap", field: "yearMonth" },
            {
                headerName: "Finanszírozó validálás",
                field: "financierValidation",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
            {
                headerName: "Időszak kezdete validálás",
                field: "periodStartValidation",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
            {
                headerName: "Időszak vége validálás",
                field: "periodEndValidation",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: rowData,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        autoSize: "true",
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading,
    };

    return (
        <PageTemplate
            header={{
                title: "Fogyasztási adatok validátor",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "consumption-data-validator": "Fogyasztási adatok validátor",
                },
            }}
            filter={{
                onSubmit: () => {
                    setInfo({ ...props, search: showTable ? props.search : props.firstFetch }, "filter", filter);
                },
                defaultFormAction: () => {
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            loading={pageLoading}
        >
            <Grid item xs={12}>
                <Card title="Szűrés alapja">
                    <FormControl component="fieldset">
                        <RadioGroup aria-label="filtering-basis" name="filter1" value={value} onChange={handleChange}>
                            <FormControlLabel
                                defaultChecked
                                value="from-costumers"
                                control={<Radio color="primary" />}
                                label="Ügyfelek irányából"
                            />
                            <FormControlLabel
                                value="consumption-period"
                                control={<Radio color="primary" />}
                                label="Fogyasztási időszak alapján"
                            />
                        </RadioGroup>
                    </FormControl>
                </Card>
            </Grid>
            {value === "from-costumers" && (
                <Filter
                    onSubmit={onSubmit}
                    defaultFormAction={() => {
                        filterStorageSave(getFilterStorageKey(value), {});
                        props.defaultForm();
                        setInfo({ ...props, search: props.search }, "filter", {});
                    }}
                    form={filter}
                    name="filter"
                    changeform={props.changeForm}
                >
                    <TextField
                        label="MAVIR ID"
                        name="mavirId"
                        value={filter.mavirId || ""}
                        format={{ xs: 12, md: 3 }}
                    />
                    <TextField
                        label="Jogosultság kezdete"
                        name="permissionStart"
                        value={filter.permissionStart || ""}
                        format={{ xs: 12, md: 3 }}
                        InputLabelProps={{ shrink: true }}
                        type="date"
                    />
                    <TextField
                        label="Jogosultság vége"
                        name="permissionEnd"
                        value={filter.permissionEnd || ""}
                        format={{ xs: 12, md: 3 }}
                        InputLabelProps={{ shrink: true }}
                        type="date"
                    />
                    <Autocomplete
                        value={filter.financier || []}
                        options={financierOptions}
                        id="financier"
                        name="financier"
                        label="Finanszírozók"
                        format={{ xs: 12, md: 3 }}
                    />
                </Filter>
            )}
            {value === "consumption-period" && (
                <Filter
                    form={filter}
                    name="filter"
                    changeform={props.changeForm}
                    onSubmit={onSubmit}
                    defaultFormAction={() => {
                        filterStorageSave(getFilterStorageKey(value), {});
                        props.defaultForm();
                        setInfo({ ...props, search: props.search }, "filter", {});
                    }}
                >
                    <TextField
                        label="MAVIR ID"
                        name="mavirId"
                        value={filter.mavirId || ""}
                        format={{ xs: 12, md: 3 }}
                    />
                    <Autocomplete
                        multiple
                        value={filter.year || []}
                        options={yearOptions}
                        id="year"
                        name="year"
                        label="Év"
                        format={{ xs: 12, md: 3 }}
                    />
                    <Autocomplete
                        value={filter.month || []}
                        options={FILTER_MONTH_OPTIONS}
                        id="month"
                        name="month"
                        label="Hónap"
                        format={{ xs: 12, md: 3 }}
                    />
                    <Autocomplete
                        value={filter.provider || []}
                        options={providerOptions}
                        id="provider"
                        name="provider"
                        label="Szolgáltató"
                        format={{ xs: 12, md: 3 }}
                    />
                    <Autocomplete
                        value={filter.financier || []}
                        options={financierOptions}
                        id="financier"
                        name="financier"
                        label="Finanszírozók"
                        format={{ xs: 12, md: 3 }}
                    />
                </Filter>
            )}
            {showTable && <Table {...table} />}
        </PageTemplate>
    );
}

function mapState(state) {
    const { filter, pageLoading, tableLoading, showTable, data, tableInfo, token, customerFilterStorageKey, consumptionPeriodFilterStorageKey } = state.consumptionDataValidator;
    const employers = state.employers.data;
    const providers = state.providers.data;
    const years = state.consumptionData.years;
    return {
        filter,
        employers,
        providers,
        years,
        pageLoading,
        tableLoading,
        showTable,
        data,
        tableInfo,
        token,
        customerFilterStorageKey,
        consumptionPeriodFilterStorageKey
    };
}

const actionCreators = {
    changeForm: consumptionDataValidatorActions.changeForm,
    defaultForm: consumptionDataValidatorActions.defaultForm,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    getYears: consumptionDataActions.getYears,
    setPageLoading: consumptionDataValidatorActions.setPageLoading,
    search: consumptionDataValidatorActions.filter,
    firstFetch: consumptionDataValidatorActions.firstFetch,
    exportInitiate: consumptionDataValidatorActions.exportInitiate,
    exportCheck: consumptionDataValidatorActions.exportCheck,
    exportDownload: consumptionDataValidatorActions.exportDownload,
    setInfo: consumptionDataValidatorActions.setInfo,
};

export default connect(mapState, actionCreators)(ConsumptionDataValidator);
