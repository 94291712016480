import { Dialog, DialogActions, DialogContent, DialogContentText, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { Children, useEffect } from "react";
import PageTemplate from "../../Common/PageTemplate";
import DataCard from "../../Common/DataCard";
import SubmitButton from "../../Common/SubmitButton";
import CancelButton from "../../Common/CancelButton";
import applicationDetailsActions from "../../../store/applicationDetails/actions";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import applicationReceiptActions from "../../../store/applicationReceipt/actions";
import notificationActions from "../../../store/notification/actions";
import applicationsListActions from "../../../store/applicationsList/actions";
import { history } from "../../../store";
import { connect } from "react-redux";
import {
    basicDetails,
    discountDetails,
    modificationDetails,
    permissionDetails,
    personalDetails,
    statementDetails,
    taxExemptionDetails,
} from "./helperFunctions";
import { hasRole } from "../../../utils/User";
import { PERMISSIONS } from "../../../utils/AppConst";
import Card from "../../Common/Card";
import colors from "../../../styles/colors";
import isEmpty from "../../../utils/isEmpty";

const useStyles = makeStyles((theme) => ({
    gridItem: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    container: {
        marginTop: theme.spacing(2),
    },
    center: {},
    errorText: {
        color: colors.red,
        fontWeight: "bold"
    },
    mainPageBtn: {
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    listBtn: {
        paddingTop: theme.spacing(1),
    }
}));

function ApplicationDetails(props) {
    const classes = useStyles();
    const { id } = props.match.params;
    const { data, loading, employers, providers } = props;
    let contents = [];
    const [openDeleteArrivalDialog, setOpenDeleteArrivalDialog] = React.useState(false);
    const [openDeleteApplicationDialog, setOpenDeleteApplicationDialog] = React.useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const [permission, setPermission] = React.useState(false);
    const handleCloseDeleteArrivalDialog = () => setOpenDeleteArrivalDialog(false);
    const handleCloseDeleteApplicationDialog = () => setOpenDeleteApplicationDialog(false);

    useEffect(() => {
        props.get(id);
        props.getEmployers();
        props.getProviders();
    }, []);

    useEffect(() => {
        setPermission(false);
        if (user?.roles?.includes(PERMISSIONS.ADMIN)) setPermission(true);
        if (data?.status === "draft" && data?.createdBy?.id === user.id) setPermission(true);
        if (data?.status === "new") {
            const contact = data?.contact;

            if (user.roles.includes(PERMISSIONS.PENSIONER_CONTACT) && contact?.id === user?.serviceProvider?.id)
                setPermission(true);

            if (user.roles.includes(PERMISSIONS.EMPLOYER_CONTACT)) {
                const employers = user?.employers;

                employers.map((employer) => {
                    if (contact?.id === employer.id) {
                        setPermission(true);
                    }
                });
            }
        }
    }, [data?.status]);

    const handleDeleteApplication = (id) => {
        props
            .deleteApplication(id)
            .then(() => props.addNotification("success", "Sikeres törlés!"))
            .then(history.push("/"))
            .catch(() => props.addNotification("error", "Hiba lépett fel törlés közben."));
    };

    const handleDeleteArrival = (id) => {
        props
            .deleteArrival(id)
            .then(() => handleCloseDeleteArrivalDialog())
            .then(() => props.get(id))
            .then(() => props.addNotification("success", "Sikeres törlés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel törlés közben."));
    };

    const handleDownloadEdms = (barCode, forceVal = false) => {
        props
            .downloadEdms(barCode, forceVal)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch((error) => {
                props.addNotification("error", error?.response?.status === 404 ? "A fájl nem található" : "Hiba lépett fel letöltés közben.")
            });
    }


    const allContents = [
        { title: "1. Az igénylő személyes adatai", data: personalDetails(data) },
        {
            title: "2. A jogosultságokra vonatkozó adatok",
            data: permissionDetails(data, employers),
        },
        {
            title: "3. A kedvezményes villamosenergia-vételezésre vonatkozó adatok",
            data: discountDetails(data, providers),
        },
        {
            title: "4. Kedvezményes vételezés módosításához/megszüntetéséhez kapcsolódó adatok",
            data: modificationDetails(data, providers),
        },
        { title: "5. Nyilatkozat", data: statementDetails(data) },
        {
            title: "6. Adómentességre vonatkozó adatok",
            data: taxExemptionDetails(data, employers),
        },
    ];

    if (data?.type === "application")
        contents.push(allContents[0], allContents[1], allContents[2], allContents[4], allContents[5]);
    if (data?.type === "modification")
        contents.push(allContents[0], allContents[1], allContents[2], allContents[3], allContents[4], allContents[5]);
    if (data?.type === "termination") contents.push(allContents[0], allContents[3], allContents[4]);

    const showErrorPage = isEmpty(data);

    const dataForDataCard = basicDetails(data, handleDownloadEdms);

    return (
        <PageTemplate
            header={{
                title: "Igénylőlap",
                breadcrumbs: {
                    applications: "Igénylések",
                    "/applications-list": "Igénylések listája",
                    "application-details": "Igénylőlap",
                },
            }}
            loading={loading}
        >
            <Grid container spacing={0} justify="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={12}>
                    {!showErrorPage ? 
                    <DataCard title="Igénylőlap adatai" data={dataForDataCard}>
                        {Children.toArray(
                            contents?.map((content) => (
                                <Grid item className={classes.gridItem}>
                                    <DataCard title={content.title} data={content.data} />
                                </Grid>
                            ))
                        )}
                        <Grid container className={classes.container} spacing={1}>
                            {permission && !data?.deletedAt && (
                                <Grid item>
                                    <SubmitButton onClick={() => setOpenDeleteApplicationDialog(true)}>
                                        Igénylés sztornózása
                                    </SubmitButton>
                                </Grid>
                            )}
                            {data?.arrivalBarCode && (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.C_TARIFF)) && (
                                <Grid item>
                                    <SubmitButton onClick={() => setOpenDeleteArrivalDialog(true)}>
                                        Érkeztetés törlése
                                    </SubmitButton>
                                </Grid>
                            )}
                            {data?.status === "done" &&
                                !data?.arrivalBarCode &&
                                (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.C_TARIFF)) && (
                                    <Grid item>
                                        <SubmitButton
                                            onClick={() => history.push(`/application-receipt/${data?.barCode}`)}
                                        >
                                            Igénylőlap érkeztetése
                                        </SubmitButton>
                                    </Grid>
                                )}
                            <Grid item>
                                <CancelButton onClick={() => history.push("/applications-list")}>
                                    Vissza a listára
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </DataCard>
                    :
                    <Card title="Hiba">
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.errorText}>
                                    Hiba lépett fel az igénylőlap betöltése közben!
                                </Typography>
                            </Grid>
                            <Grid item className={classes.mainPageBtn}>
                                <SubmitButton onClick={() => history.push("/")} >
                                    Vissza a főoldalra
                                </SubmitButton>
                            </Grid>
                            <Grid className={classes.listBtn}>
                                <CancelButton onClick={() => history.push("/applications-list")} >
                                    Vissza a listára
                                </CancelButton>
                            </Grid>
                        </Grid>
                    </Card>
                    }
                </Grid>
            </Grid>
            <Dialog open={openDeleteArrivalDialog} onClose={handleCloseDeleteArrivalDialog}>
                <DialogContent>
                    <DialogContentText>{"Biztosan törli az érkeztetést?"}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={() => handleDeleteArrival(data?.id)}>{"Törlés"}</SubmitButton>
                    <CancelButton
                        onClick={() => {
                            handleCloseDeleteArrivalDialog();
                        }}
                    >
                        {"Mégse"}
                    </CancelButton>
                </DialogActions>
            </Dialog>
            <Dialog open={openDeleteApplicationDialog} onClose={handleCloseDeleteApplicationDialog}>
                <DialogContent>
                    <DialogContentText>{"Biztosan sztornózza az igénylőlapot?"}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={() => handleDeleteApplication(data?.id)}>{"Sztornó"}</SubmitButton>
                    <CancelButton
                        onClick={() => {
                            handleCloseDeleteApplicationDialog();
                        }}
                    >
                        {"Mégse"}
                    </CancelButton>
                </DialogActions>
            </Dialog>
        </PageTemplate>
    );
}

function mapState(state) {
    const { data, loading } = state.applicationDetails;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return { data, loading, employers, providers };
}

const actionCreators = {
    get: applicationDetailsActions.get,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    deleteArrival: applicationReceiptActions.deleteArrival,
    deleteApplication: applicationDetailsActions.deleteApplication,
    addNotification: notificationActions.addNotification,
    downloadEdms: applicationsListActions.downloadEdms,
};

export default connect(mapState, actionCreators)(ApplicationDetails);
