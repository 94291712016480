import ReactDOM from "react-dom";
import React from "react";
import TextField from "../Common/TextField";

export const getDatePicker = () => {
    function Datepicker() {}
    Datepicker.prototype.init = function (params) {
        const fillZeros = (a) => {
            return Number(a) < 10 ? "0" + a : a;
        };
        const getFormatedDate = (dateString) => {
            if (dateString) {
                const dateParse = new Date(
                    dateString.split(".")[0] + "-" + dateString.split(".")[1] + "-" + dateString.split(".")[2]
                );
                const dd = dateParse.getDate();
                const mm = dateParse.getMonth() + 1; //January is 0!
                const yyyy = dateParse.getFullYear();
                return yyyy + "-" + fillZeros(mm) + "-" + fillZeros(dd);
            }
        };
        this.textInput = React.createRef();
        const eInput = React.createElement(TextField, {
            type: "date",
            defaultValue: getFormatedDate(params.value),
            ref: this.textInput,
        });
        this.div = document.createElement("div");
        this.div.className = "ag-cell-parent-append";
        ReactDOM.render(eInput, this.div);
    };
    Datepicker.prototype.getGui = function () {
        return this.div;
    };
    Datepicker.prototype.afterGuiAttached = function () {
        this.textInput.current.focus();
    };
    Datepicker.prototype.getValue = function () {
        let value = this.textInput.current.querySelector("input").value;
        return value.split("-")[0] + "." + value.split("-")[1] + "." + value.split("-")[2];
    };
    Datepicker.prototype.destroy = function () {};
    Datepicker.prototype.isPopup = function () {
        return false;
    };
    return Datepicker;
};
