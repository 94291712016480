import { types } from "./types";

const initialState = {
    form: {
        type: "application",
    },
    formOptions: {
        receptionDetails: true,
        receptionEditDetails: false,
    },
    applicant: {
        address: {},
    },
    legalBasis: {
        type: "employee",
    },
    primaryConsumptionPlace: {
        address: {},
        numberOfConsumptionMeters: 1,
    },
    secondaryConsumptionPlace: {
        address: {},
        numberOfConsumptionMeters: 1,
    },
    toEdit: [],
    primaryConsumptionPlaceTermination: {
        address: {},
        numberOfConsumptionMeters: 1,
    },
    secondaryConsumptionPlaceTermination: {
        address: {},
        numberOfConsumptionMeters: 1,
    },
    receptionEditDetailsForm: {
        firstPlace: {
            numberOfConsumptionMeters: 1,
        },
        secondPlace: {
            numberOfConsumptionMeters: 1,
        },
    },
    statement: {
        attachedCertificate: "certificateOne",
    },
    taxExemption: {
        employments: [],
        taxFree: false,
        continuousEmployment: false,
    },
    validation: [],
    data: {},
    drafts: [],
    loading: false,
};

export function applicationFiling(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                [action.name]: initialState[action.name],
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_VALIDATION_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_VALIDATION_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                validation: action.data.data.constraints,
            };
        case types.GET_VALIDATION_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_DRAFTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_DRAFTS_SUCCESS:
            return {
                ...state,
                loading: false,
                drafts: action.data.data.data,
            };
        case types.GET_DRAFTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.FINALIZE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FINALIZE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.FINALIZE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
