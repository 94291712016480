import axios from "axios";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import activeClientsByFinanciersAndPermissionsActions from "../../../../store/activeClientsByFinanciersAndPermissions/actions";
import { GET_STAT_DETAILS } from "../../../../utils/AppConst";
import PageTemplate from "../../../Common/PageTemplate";
import Table from "../../../Common/Table";
import NumberFormatCellRenderer from "../../../../utils/cellRenderer/NumberFormatCellRenderer";
import colors from "../../../../styles/colors";

function ActiveClients(props) {
    const { data, token, loading } = props;

    useEffect(() => {
        axios.all([props.search()]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
    }, []);

    const table = {
        columnDefs: [
            { headerName: "Finanszírozó", field: "financierName" },
            { headerName: "Azonosító", field: "financierReference" },
            { headerName: "Munkavállaló", field: "employee", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Nyugdíjas", field: "pensioner", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Munkavállaló özvegye", field: "employees_widow", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Nyugdíjas özvegye", field: "pensioners_widow", cellRenderer: "numberFormatCellRenderer" },
            {
                headerName: "Szakszervezeti alkalmazott",
                field: "trade_union_employee",
                cellRenderer: "numberFormatCellRenderer"
            },
            {
                headerName: "Nyugdíjazott szakszervezeti alkalmazott",
                field: "trade_union_pensioner",
                cellRenderer: "numberFormatCellRenderer"
            },
            {
                headerName: "Szakszervezeti alkalmazott özvegye",
                field: "trade_union_employees_widow",
                cellRenderer: "numberFormatCellRenderer"
            },
            {
                headerName: "Nyugdíjazott szakszervezeti alkalmazott özvegye",
                field: "trade_union_pensioner_widow",
                cellRenderer: "numberFormatCellRenderer"
            },
        ],
        getRowStyle: params => {
            if (params.node.rowIndex === 0) {
                return { background: colors.primary, color: colors.white, fontWeight: "bold" }
            }
        },
        autoSize: "true",
        rowData: data,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            token: token,
        },
        frameworkComponents: {
            numberFormatCellRenderer: NumberFormatCellRenderer,
        },
        hidePagination: true,
    };

    return (
        <PageTemplate
            header={{
                title: GET_STAT_DETAILS[8]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/other": "Egyéb kimutatások",
                    1.1: GET_STAT_DETAILS[8]?.title,
                },
            }}
            loading={loading}
            statPageDetail={GET_STAT_DETAILS[8]?.body}
        >
            <Table {...table} />
        </PageTemplate>
    );
}

function mapState(state) {
    const { data, loading, token } = state.activeClientsByFinanciersAndPermissions;
    return { data, loading, token };
}

const actionCreators = {
    search: activeClientsByFinanciersAndPermissionsActions.filter,
    exportInitiate: activeClientsByFinanciersAndPermissionsActions.exportInitiate,
    exportCheck: activeClientsByFinanciersAndPermissionsActions.exportCheck,
    exportDownload: activeClientsByFinanciersAndPermissionsActions.exportDownload,
    setLoading: activeClientsByFinanciersAndPermissionsActions.setLoading,
};

export default connect(mapState, actionCreators)(ActiveClients);
