import { Grid, makeStyles, Paper, Step, StepContent, StepLabel, Stepper, Typography } from "@material-ui/core";
import React, { Children, useEffect, useState } from "react";
import Card from "../../Common/Card";
import Form from "../../Common/Form";
import PageTemplate from "../../Common/PageTemplate";
import SubmitButton from "../../Common/SubmitButton";
import applicationFilingActions from "../../../store/applicationFiling/actions";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import notificationActions from "../../../store/notification/actions";
import { connect } from "react-redux";
import "./style.scss";
import colors from "../../../styles/colors";
import PersonalDataForm from "./forms/PersonalDataForm";
import Checkbox from "../../Common/Checkbox";
import PermissionsForm from "./forms/PermissionsForm";
import ReceptionDetailsForm from "./forms/ReceptionDetailsForm";
import StatementForm from "./forms/StatementForm";
import TaxExemptionForm from "./forms/TaxExemptionForm";
import { history } from "../../../store";
import isEmpty from "../../../utils/isEmpty";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginTop: theme.spacing(3),
    },
    resetContainer: {
        padding: theme.spacing(3),
        textAlign: "center",
        fontSize: "larger",
    },
    next: {
        backgroundColor: colors.next,
        "&:hover": {
            backgroundColor: colors.nextHover,
            color: colors.white,
        },
    },
    finish: {
        backgroundColor: colors.green,
        "&:hover": {
            backgroundColor: colors.greenHover,
            color: colors.white,
        },
    },
    back: {
        backgroundColor: colors.back,
        "&:hover": {
            backgroundColor: colors.backHover,
            color: colors.white,
        },
    },
    formOptions: {
        borderBottom: "2px solid" + colors.primary,
        padding: theme.spacing(3),
        width: "100%",
    },
}));

function getSteps() {
    return [
        { value: "applicant", label: "Az igénylő személyes adatai" },
        { value: "legalBasis", label: "A jogosultságokra vonatkozó adatok" },
        {
            value: "primaryConsumptionPlace",
            label: "Kedvezményes villamosenergia-vételezésre vonatkozó adatok",
        },
        {
            value: "primaryConsumptionPlaceTermination",
            label: "Kedvezményes vételezés módosításához / megszüntetéséhez kapcsolódó adatok",
        },
        { value: "statement", label: "Nyilatkozat" },
        {
            value: "taxExemption",
            label: "Adómentesség megállapítására vonatkozó adatok",
        },
        { value: "finalize", label: "Véglegesítés" },
    ];
}

function ApplicationFilingRecordingData(props) {
    const { id } = props.match.params;
    const [application, setApplication] = useState({});

    const {
        secondaryConsumptionPlaceTermination,
        primaryConsumptionPlaceTermination,
        secondaryConsumptionPlace,
        primaryConsumptionPlace,
        applicant,
        taxExemption,
        legalBasis,
        statement,
        formOptions,
        validation,
        providers,
        employers,
        loading,
        toEdit,
    } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps().map((elem) => elem.label);
    const [numberOfPlaces, setNumberOfPlaces] = useState(secondaryConsumptionPlace.type === undefined ? 1 : 2);

    useEffect(() => {
        setNumberOfPlaces(secondaryConsumptionPlace.type === undefined ? 1 : 2);
    }, [secondaryConsumptionPlace.type])

    useEffect(() => {
        props.get(id).then((data) => {
            const app = data?.data?.data;

            const prim = app.primaryConsumptionPlace;
            const sec = app.secondaryConsumptionPlace;
            const primTerm = app.primaryConsumptionPlaceTermination;
            const secTerm = app.secondaryConsumptionPlaceTermination;

            //taxExemption átalakítása, hogy jól jelenjen meg nekünk:
            if (!isEmpty(app.taxExemption)) {
                app.taxExemption.formerEmployersCount = app.taxExemption.employments?.length - 1;
                app.taxExemption.employmentStartDate = app.taxExemption.employments?.[0]?.from?.split('T')?.[0];
    
                for (let i = 1; i < app.taxExemption.employments.length; i++) {
                    app.taxExemption[`formerEmployer${i}`] = {
                        employmentStartDate: app.taxExemption.employments?.[i]?.from?.split('T')?.[0],
                        employmentEndDate: app.taxExemption.employments?.[i]?.to?.split('T')?.[0],
                        employments: app.taxExemption.employments?.[i]?.employerId,
                    }
                }
    
                app.taxExemption.employments = app.taxExemption?.employments?.[0]?.employerId;
            }

            if (prim) {
                app.primaryConsumptionPlace.numberOfConsumptionMeters = prim.consumptionMeters?.length;
                for (let i = 0; i < prim.consumptionMeters?.length; i++) {
                    app.primaryConsumptionPlace[`consumptionMeter${i + 1}Reference`] =
                        prim.consumptionMeters[i].reference;
                    app.primaryConsumptionPlace[`consumptionMeter${i + 1}Reading`] = prim.consumptionMeters[i].reading;
                }
            } else app.primaryConsumptionPlace = {};

            if (sec) {
                app.secondaryConsumptionPlace.numberOfConsumptionMeters = sec.consumptionMeters?.length;
                for (let i = 0; i < sec.consumptionMeters?.length; i++) {
                    app.secondaryConsumptionPlace[`consumptionMeter${i + 1}Reference`] =
                        sec.consumptionMeters[i].reference;
                    app.secondaryConsumptionPlace[`consumptionMeter${i + 1}Reading`] = sec.consumptionMeters[i].reading;
                }
            } else app.secondaryConsumptionPlace = {};

            if (primTerm) {
                app.primaryConsumptionPlaceTermination.numberOfConsumptionMeters = primTerm.consumptionMeters?.length;
                for (let i = 0; i < primTerm.consumptionMeters?.length; i++) {
                    app.primaryConsumptionPlaceTermination[`consumptionMeter${i + 1}Reference`] =
                        primTerm.consumptionMeters[i].reference;
                    app.primaryConsumptionPlaceTermination[`consumptionMeter${i + 1}Reading`] =
                        primTerm.consumptionMeters[i].reading;
                }
            } else app.primaryConsumptionPlaceTermination = {};

            if (secTerm) {
                app.secondaryConsumptionPlaceTermination.numberOfConsumptionMeters = secTerm.consumptionMeters?.length;
                for (let i = 0; i < secTerm.consumptionMeters?.length; i++) {
                    app.secondaryConsumptionPlaceTermination[`consumptionMeter${i + 1}Reference`] =
                        secTerm.consumptionMeters[i].reference;
                    app.secondaryConsumptionPlaceTermination[`consumptionMeter${i + 1}Reading`] =
                        secTerm.consumptionMeters[i].reading;
                }
            } else app.secondaryConsumptionPlaceTermination = {};

            app.statement.attachedCertificate = app.statement.attachedCertificate || "certificateOne";
            //itt történik a changeform:
            Object.keys(app).map((key) => props.changeForm(app[key], key));

            if (app.type === "termination") {
                props.changeForm(
                    {
                        ...formOptions,
                        receptionEditDetails: true,
                        receptionDetails: false,
                    },
                    "formOptions"
                );
            } else if (app.type === "modification") {
                props.changeForm(
                    {
                        ...formOptions,
                        receptionDetails: true,
                        receptionEditDetails:
                            app.primaryConsumptionPlaceTermination !== undefined ||
                            app.secondaryConsumptionPlaceTermination !== undefined,
                    },
                    "formOptions"
                );
            } else if (app.type === "application") {
                props.changeForm(
                    {
                        ...formOptions,
                        receptionDetails: true,
                        receptionEditDetails: false,
                    },
                    "formOptions"
                );
            }
            //a végén beállítom a lokális application-t
            setApplication(app);
        });
        props.getEmployers();
        props.getProviders();
        props.getValidationUpdate();
    }, []);

    

    useEffect(() => {
        props.changeForm({}, "taxExemption");
        props.changeForm({}, "statement");
    }, [legalBasis?.type]);

    const buildConsumptionMeters = (place, placeId, placeType) => {
        let resultArray = [];

        if (!place) return;

        if (placeId === 1 && placeType === "normal")
            resultArray = application.primaryConsumptionPlace.consumptionMeters
                ? application.primaryConsumptionPlace.consumptionMeters
                : [];
        if (placeId === 1 && placeType === "termination")
            resultArray = application.primaryConsumptionPlaceTermination.consumptionMeters
                ? application.primaryConsumptionPlaceTermination.consumptionMeters
                : [];
        if (placeId === 2 && placeType === "normal")
            resultArray = application.secondaryConsumptionPlace.consumptionMeters
                ? application.secondaryConsumptionPlace.consumptionMeters
                : [];
        if (placeId === 2 && placeType === "termination")
            resultArray = application.secondaryConsumptionPlaceTermination.consumptionMeters
                ? application.secondaryConsumptionPlaceTermination.consumptionMeters
                : [];

        for (const [key, value] of Object.entries(place)) {
            if (key.includes("consumptionMeter")) {
                const toChange = key.split("consumptionMeter")[1].toLowerCase();
                const index = Number(toChange[0]) - 1;
                resultArray[index] = resultArray[index] ? resultArray[index] : {};
                resultArray[index][toChange.slice(1)] = value;
            }
        }

        return resultArray;
    };

    const handleResponse = (response, step) => {
        let backed = false; //ne léptesse kétszer vissza
        const viols = response?.data?.violations || [];
        if (viols.length > 0) {
            const prevErrors = {};
            viols.map((viol) => {
                const property = viol.property;
                const splittedProperty = property.split(".");
                const mainForm = splittedProperty[0];

                if (!prevErrors[mainForm]) prevErrors[mainForm] = [];

                mainForm === "legalBasis"
                    ? props.changeForm(
                          {
                              ...props[mainForm],
                              pensionerRegistrationNumber: "",
                              errors: [
                                  ...prevErrors[mainForm],
                                  {
                                      name: property.slice(mainForm.length + 1),
                                      label: viol.message,
                                  },
                              ],
                          },
                          mainForm
                      )
                    : props.changeForm(
                          {
                              ...props[mainForm],
                              errors: [
                                  ...prevErrors[mainForm],
                                  {
                                      name: property.slice(mainForm.length + 1),
                                      label: viol.message,
                                  },
                              ],
                          },
                          mainForm
                      );

                prevErrors[mainForm].push({
                    name: property.slice(mainForm.length + 1),
                    label: viol.message,
                });

                if (splittedProperty.includes("consumptionMeters")) {
                    props.changeForm(
                        {
                            ...props[mainForm],
                            errors: [
                                ...prevErrors[mainForm],
                                {
                                    name: "numberOfConsumptionMeters",
                                    label: viol.message,
                                },
                            ],
                        },
                        mainForm
                    );

                    prevErrors[mainForm].push({
                        name: "numberOfConsumptionMeters",
                        label: viol.message,
                    });
                }

                if (splittedProperty.length > 2 && splittedProperty[1].includes("consumptionMeters[")) {
                    const index = splittedProperty[1].split("[")[1][0];
                    props.changeForm(
                        {
                            ...props[mainForm],
                            errors: [
                                ...prevErrors[mainForm],
                                {
                                    name: `consumptionMeter${Number(index) + 1}${capitalize(splittedProperty[2])}`,
                                    label: viol.message,
                                },
                            ],
                        },
                        mainForm
                    );

                    prevErrors[mainForm].push({
                        name: `consumptionMeter${Number(index) + 1}${capitalize(splittedProperty[2])}`,
                        label: viol.message,
                    });
                }
            });

            if (step === 2) {
                if (
                    viols.filter((viol) => viol.property.split(".")[0] === "secondaryConsumptionPlace").length > 0 &&
                    viols.filter((viol) => viol.property.split(".")[0] === "primaryConsumptionPlace").length === 0
                ) {
                    if (!backed) setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    backed = true;
                    props.addNotification("error", "Kérem javítsa a második fogyasztási helyhez tartozó hibákat!");
                }
            }

            //2. fogy hely validáció megszüntetés esetén:
            if (step === 3) {
                if (
                    viols.filter((viol) => viol.property.split(".")[0] === "secondaryConsumptionPlaceTermination")
                        .length > 0
                ) {
                    if (!backed) setActiveStep((prevActiveStep) => prevActiveStep - 1);
                    backed = true;
                    props.addNotification("error", "Kérem javítsa a második fogyasztási helyhez tartozó hibákat!");
                }
            }

            if (
                viols.filter(
                    (viol) => viol.property.split(".")[0] === getSteps().filter(filterObject)[activeStep].value
                ).length > 0
            ) {
                if (!backed) setActiveStep((prevActiveStep) => prevActiveStep - 1);
                backed = true;
            }
        }
    };

    const buildForm = (step) => {
        const resultForm = {};
        const filteredSteps = getSteps().filter((st) => steps.filter(filter).includes(st.label));
        
        if (step < filteredSteps.length - 1) {
            for (let i = 0; i <= filteredSteps.length - 1; i++) {
                resultForm[filteredSteps[i].value] = props[filteredSteps[i].value];
            }
            if (resultForm.primaryConsumptionPlace && props.secondaryConsumptionPlace && numberOfPlaces === 2) {
                resultForm.secondaryConsumptionPlace = props.secondaryConsumptionPlace;
            }
            if (resultForm.primaryConsumptionPlaceTermination && props.secondaryConsumptionPlaceTermination) {
                resultForm.secondaryConsumptionPlaceTermination = props.secondaryConsumptionPlaceTermination;
            }
        } else {
            Object.keys(application).map((key) => (resultForm[key] = props[key] || application[key]));
        }

        if (step > 1) {
            if (!resultForm.primaryConsumptionPlace) {
                resultForm.primaryConsumptionPlace = {};
            }
            if (!resultForm.secondaryConsumptionPlace) {
                resultForm.secondaryConsumptionPlace = {};
            }
            if (resultForm.primaryConsumptionPlace?.type) {
                resultForm.primaryConsumptionPlace.consumptionMeters = buildConsumptionMeters(
                    resultForm.primaryConsumptionPlace,
                    1,
                    "normal"
                );
            }
            if (resultForm.secondaryConsumptionPlace?.type) {
                resultForm.secondaryConsumptionPlace.consumptionMeters = buildConsumptionMeters(
                    resultForm.secondaryConsumptionPlace,
                    2,
                    "normal"
                );
            }
            if (resultForm.primaryConsumptionPlaceTermination?.type)
                resultForm.primaryConsumptionPlaceTermination.consumptionMeters = buildConsumptionMeters(
                    resultForm.primaryConsumptionPlaceTermination,
                    1,
                    "termination"
                );
            if (resultForm.secondaryConsumptionPlaceTermination?.type)
                resultForm.secondaryConsumptionPlaceTermination.consumptionMeters = buildConsumptionMeters(
                    resultForm.secondaryConsumptionPlaceTermination,
                    2,
                    "termination"
                );
            if (resultForm.secondaryConsumptionPlace.quantity) {
                resultForm.secondaryConsumptionPlace.quantity = parseInt(
                    resultForm.secondaryConsumptionPlace.quantity,
                    10
                );
            }

            if (
                !resultForm.legalBasis?.type?.includes("widow") &&
                resultForm.legalBasis &&
                application.type !== "termination"
            ) {
                resultForm.legalBasis.deceased = {};
            }
        }

        //5. lépés adómentesség: taxExemption form átalakítása:
        const employerIterator = resultForm.taxExemption?.formerEmployersCount || 0;
        const employers = [];

        if (resultForm.taxExemption?.employmentStartDate) {
            employers.push({
                from: resultForm.taxExemption?.employmentStartDate,
                to: null,
                employerId: resultForm.taxExemption?.employments,
            });
        }

        for (let i = 1; i <= employerIterator; i++) {
            const objectName = "formerEmployer" + i;
            const currentObject = resultForm.taxExemption[objectName];

            employers.push({
                from: currentObject?.employmentStartDate,
                to: currentObject?.employmentEndDate,
                employerId: currentObject?.employments,
            });
        }

        if (
            step >= getSteps().length - 2 || 
            (application !== null && typeof application === 'object' && Object.keys(application?.taxExemption)?.length > 1)
        ) {
            resultForm.taxExemption = {
                taxFree: resultForm.taxExemption?.taxFree,
                continuousEmployment: resultForm.taxExemption?.continuousEmployment,
                employments: employers,
            };
        }

        if (application.type === "termination") {
            const tmp = {};
            Object.keys(resultForm).map((key) => {
                if (
                    key !== "legalBasis" &&
                    key !== "primaryConsumptionPlace" &&
                    key !== "secondaryConsumptionPlace" &&
                    key !== taxExemption
                )
                    tmp[key] = resultForm[key];
            });

            if (!(toEdit.map(tE => tE.value).includes("primaryConsumptionPlaceTermination") && formOptions.receptionEditDetails) && step >= 3) {
                delete tmp.primaryConsumptionPlaceTermination;
                props.changeForm({}, "primaryConsumptionPlaceTermination")
            }

            if (!(toEdit.map(tE => tE.value).includes("secondaryConsumptionPlaceTermination") && formOptions.receptionEditDetails) && step >= 3) {
                delete tmp.secondaryConsumptionPlaceTermination;
                props.changeForm({}, "secondaryConsumptionPlaceTermination")
            }

            return tmp;
        }

        if (resultForm.legalBasis?.isInProgress) {
            resultForm.legalBasis.pensionerRegistrationNumber = "in_progress";
        }

        if (!(toEdit.map(tE => tE.value).includes("primaryConsumptionPlaceTermination") && formOptions.receptionEditDetails) && step >= 3) {
            delete resultForm.primaryConsumptionPlaceTermination;
            props.changeForm({}, "primaryConsumptionPlaceTermination")
        }

        if (!(toEdit.map(tE => tE.value).includes("secondaryConsumptionPlaceTermination") && formOptions.receptionEditDetails) && step >= 3) {
            delete resultForm.secondaryConsumptionPlaceTermination;
            props.changeForm({}, "secondaryConsumptionPlaceTermination")
        }

        return resultForm;
    };

    const handleSubmit = (step) => {
        if (steps.filter(filter).length - 1 === activeStep) {
            props.finalize(id, buildForm(step)).catch(() => props.addNotification("error", "Hiba a mentés során"));
        } else {
            props
                .update(id, buildForm(step))
                .then((response) => handleResponse(response, step))
                .catch((error) => {
                    props.addNotification("error", "Hiba a mentés során");
                    handleResponse(error.response, step);
                });
        }
    };

    const capitalize = (name) => name.charAt(0).toUpperCase() + name.slice(1);

    const getValidation = (name) => {
        const resultValidation = [];

        validation.map((val) => {
            if (val.field.split(".")[0] === name)
                resultValidation.push({
                    ...val,
                    field: val.field.substr(val.field.indexOf(".") + 1),
                });
            return 0;
        });

        return resultValidation;
    };

    // https://silinfo.atlassian.net/browse/MAVIRATR-59 és https://silinfo.atlassian.net/browse/MAVIRATRUZ-325
    const ignoreProvider = [22, 32, 36, 86];
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <PersonalDataForm
                        form={applicant}
                        onChange={props.changeForm}
                        validation={getValidation("applicant")}
                    />
                );
            case 1:
                return (
                    <PermissionsForm
                        form={legalBasis}
                        onChange={props.changeForm}
                        validation={getValidation("legalBasis")}
                        employers={employers}
                    />
                );
            case 2:
                return (
                    <ReceptionDetailsForm
                        form={{
                            primaryConsumptionPlace: primaryConsumptionPlace,
                            secondaryConsumptionPlace: secondaryConsumptionPlace,
                        }}
                        validation={{
                            primaryConsumptionPlace: getValidation("primaryConsumptionPlace"),
                            secondaryConsumptionPlace: getValidation("secondaryConsumptionPlace"),
                        }}
                        numberOfPlaces={numberOfPlaces}
                        setNumberOfPlaces={setNumberOfPlaces}
                        providers={providers.filter(provider =>  ignoreProvider.every(val => val !== provider.reference))} // https://silinfo.atlassian.net/browse/MAVIRATR-59
                        onChange={props.changeForm}
                    />
                );
            case 3:
                return (
                    <ReceptionDetailsForm
                        form={{
                            primaryConsumptionPlaceTermination: primaryConsumptionPlaceTermination,
                            secondaryConsumptionPlaceTermination: secondaryConsumptionPlaceTermination,
                        }}
                        validation={{
                            primaryConsumptionPlaceTermination: getValidation("primaryConsumptionPlaceTermination"),
                            secondaryConsumptionPlaceTermination: getValidation("secondaryConsumptionPlaceTermination"),
                        }}
                        providers={providers.filter(provider =>  ignoreProvider.every(val => val !== provider.reference))} // https://silinfo.atlassian.net/browse/MAVIRATR-59
                        onChange={props.changeForm}
                        legalBasisType={legalBasis?.type}
                        edit
                    />
                );
            case 4:
                return (
                    <StatementForm
                        form={statement}
                        onChange={props.changeForm}
                        validation={getValidation("statement")}
                        applicationType={application.type}
                    />
                );
            case 5:
                return (
                    <TaxExemptionForm
                        form={taxExemption}
                        onChange={props.changeForm}
                        type={legalBasis?.type}
                        employers={employers}
                        validation={getValidation("taxExemption")}
                        contactEmployerId={legalBasis?.employerId}
                    />
                );
            case 6:
                return <Typography>A felvitel gombra kattintva véglegesítheti az igénylőlapot.</Typography>;
            default:
                return "Unknown step";
        }
    };

    const deleteErrors = () => {
        for (let [key, value] of Object.entries(props)) {
            if (value?.errors !== undefined) {
                props.changeForm({ ...value, errors: [] }, key);
            }
        }
    };

    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    const handleNext = (step) => {
        let length;
        deleteErrors();
        if (application.type === "application") length = 6;
        if (application.type === "modification") length = 6;
        if (application.type === "termination") length = 6;

        //2. lépésben muszáj választani jogalapot:
        if (length - 1 !== step) {
            if (step !== 1 || legalBasis?.type) { 
                //3. lépésben muszáj megadni fogyasztásmérő órát:
                if (step === 2) {      
                    if (Object.keys(primaryConsumptionPlace).length === 0) {
                        props.addNotification("error", "Töltse ki a hiányzó adatokat!");
                    } 
                    else {
                        if (numberOfPlaces === 1) {
                            props.changeForm({}, "secondaryConsumptionPlace");
                        }
                        handleSubmit(step);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }
                //4. lépésben muszáj választani igazolást:
                else if (length - 2 === step && !statement.type && application.type !== "termination") {
                    props.addNotification("error", "Válasszon igazolást!");
                } else if (statement?.durationYears === "none") {
                    delete statement?.durationYears;
                    delete statement?.durationMonths;
                    handleSubmit(step);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
                //2. lépés megszüntetésnél muszáj választani felhasználási helyet:
                else if (application.type === "termination" && step === 3 && toEdit.length === 0)
                    props.addNotification("error", "Válasszon legalább egy fogyasztási helyet!");
                else {
                    handleSubmit(step);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
            } else if (!legalBasis?.type) {
                props.addNotification("error", "Válasszon jogalapot!");
            }
        }

        //5. lépés (adómentesség)
        if (length - 1 === step) {
            if (legalBasis?.type === "employee" || legalBasis?.type === "trade_union_employee") {
                //ha 'taxFree' nem true akkor csak 'employments' és 'employmentStartDate' kell:
                if (taxExemption.taxFree === null || !taxExemption.taxFree) {
                    if (
                        Object.prototype.hasOwnProperty.call(taxExemption, "employments") &&
                        Object.prototype.hasOwnProperty.call(taxExemption, "employmentStartDate")
                    ) {
                        handleSubmit(step);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    } else props.addNotification("error", "Töltse ki munkaviszonyhoz tartozó hiányzó adatokat!");
                }
                //ha 'taxFree' true de 'continuousEmployment' nem true akkor nem kell semmi:
                if (taxExemption.taxFree) {
                    Object.prototype.hasOwnProperty.call(taxExemption, "continuousEmployment");
                    if (
                        !Object.prototype.hasOwnProperty.call(taxExemption, "continuousEmployment") ||
                        taxExemption.continuousEmployment === false
                    ) {
                        props.addNotification(
                            "error",
                            "Mivel az igénylő nem áll folyamatos munkaviszonyban villamosenergia-ipari társaságnál, ezért nem jogosult az adókedvezményre."
                        );
                    }
                }
                //ha 'taxFree' true és 'continuousEmployment' is true akkor annyi korábbi munkáltatót kell megadni,
                //amennyi meg van jelölve a korábbi munkáltatók számában ('formerEmployersCount'):
                if (taxExemption.taxFree && taxExemption.continuousEmployment) {
                    if (
                        Object.prototype.hasOwnProperty.call(taxExemption, "employments") &&
                        Object.prototype.hasOwnProperty.call(taxExemption, "employmentStartDate")
                    ) {
                        if (taxExemption.formerEmployersCount === 0) {
                            handleSubmit(step);
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        }
                        if (taxExemption.formerEmployersCount > 0) {
                            let formerEmployerChecker = 0;
                            for (let i = 1; i <= taxExemption.formerEmployersCount; i++) {
                                if (
                                    taxExemption[`formerEmployer${i}`] &&
                                    Object.prototype.hasOwnProperty.call(
                                        taxExemption[`formerEmployer${i}`],
                                        "employments"
                                    ) &&
                                    Object.prototype.hasOwnProperty.call(
                                        taxExemption[`formerEmployer${i}`],
                                        "employmentStartDate"
                                    ) &&
                                    Object.prototype.hasOwnProperty.call(
                                        taxExemption[`formerEmployer${i}`],
                                        "employmentEndDate"
                                    )
                                ) {
                                    formerEmployerChecker++;
                                }
                            }
                            if (formerEmployerChecker === taxExemption.formerEmployersCount) {
                                handleSubmit(step);
                                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            } else props.addNotification("error", "Töltse ki a hiányzó adatokat!");
                        }
                    } else props.addNotification("error", "Töltse ki a hiányzó adatokat!");
                }
            } else {
                handleSubmit(step);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        }
    };

    const options = [
        {
            name: "receptionDetails",
            label: "Kedvezményes villamosenergia-vételezésre vonatkozó adatok",
        },
        {
            name: "receptionEditDetails",
            label: "Kedvezményes vételezés módosításához / megszüntetéséhez kapcsolódó adatok",
        },
    ];

    const filter = (e) => {
        if (steps[1] === e) return application.type !== "termination";
        if (steps[2] === e) return application.type !== "termination" && formOptions.receptionDetails;
        if (steps[3] === e) return application.type !== "application" && formOptions.receptionEditDetails;
        if (steps[5] === e) return application.type !== "termination";

        return true;
    };

    const filterObject = (e) => filter(e.label);

    return (
        <PageTemplate
            header={{
                title: "Adatok rögzítése",
                breadcrumbs: {
                    applications: "Igénylések",
                    "/application-filing": "Igénylőlap felvitele",
                    barcode: application?.barCode,
                },
            }}
            loading={loading}
        >
            <Card fullWidth title="Kitöltendő adatok">
                <Form form={formOptions} changeform={props.changeForm} name="formOptions">
                    {application.type === "modification" && activeStep === 0 && (
                        <Grid item format={{ xs: 12 }} className={classes.formOptions} xs={12}>
                            {Children.toArray(
                                options.map((e) => {
                                    if (!(e.name === "receptionDetails" && application.type === "modification"))  {
                                        return (
                                                <Grid item xs={12}>
                                                    <Checkbox option={e} checked={formOptions[e.name]} />
                                                </Grid>
                                        )}}))}
                        </Grid>
                    )}
                </Form>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map(
                        (label, index) =>
                            steps.filter(filter).includes(label) && (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                    <StepContent>
                                        {getStepContent(index)}
                                        <Grid item container xs={12}>
                                            {activeStep !== 0 && 
                                                <Grid item>
                                                    <SubmitButton
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                        className={classes.button}
                                                        classes={{
                                                            root: classes.back,
                                                        }}
                                                    >
                                                        Vissza
                                                    </SubmitButton>
                                                </Grid>
                                            }
                                            {activeStep === steps.filter(filter).length - 1 && (
                                                <Grid item>
                                                    <SubmitButton
                                                        onClick={() => window.open(`/applications/${application?.id}`)}
                                                        className={classes.button}
                                                    >
                                                        {"Igénylőlap megtekintése új lapon"}
                                                    </SubmitButton>
                                                </Grid>
                                            )}
                                            <Grid item>
                                                <SubmitButton
                                                    onClick={() => handleNext(index)}
                                                    className={classes.button}
                                                    classes={{
                                                        root:
                                                            activeStep === steps.filter(filter).length - 1
                                                                ? classes.finish
                                                                : classes.next,
                                                    }}
                                                >
                                                    {activeStep === steps.filter(filter).length - 1
                                                        ? "Felvitel"
                                                        : "Következő"}
                                                </SubmitButton>
                                            </Grid>
                                        </Grid>
                                    </StepContent>
                                </Step>
                            )
                    )}
                </Stepper>
                {activeStep === steps.filter(filter).length && (
                    <Grid item>
                        <Paper square elevation={0} className={classes.resetContainer}>
                            Az igénylőlap sikeresen rögzítve a rendszerben!
                        </Paper>
                        <Typography align="center">
                            <SubmitButton
                                onClick={() => history.push(`/applications/${application?.id}`)}
                                className={classes.button}
                            >
                                {"Igénylőlap megtekintése"}
                            </SubmitButton>
                        </Typography>
                    </Grid>
                )}
            </Card>
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        receptionDetailsForm,
        receptionEditDetailsForm,
        applicant,
        legalBasis,
        statement,
        taxExemption,
        formOptions,
        loading,
        validation,
        data,
        secondaryConsumptionPlace,
        primaryConsumptionPlace,
        primaryConsumptionPlaceTermination,
        secondaryConsumptionPlaceTermination,
        toEdit,
    } = state.applicationFiling;
    const employers = state.employers.data;
    const providers = state.providers.data;

    return {
        receptionDetailsForm,
        receptionEditDetailsForm,
        applicant,
        legalBasis,
        statement,
        taxExemption,
        formOptions,
        loading,
        validation,
        data,
        employers,
        providers,
        secondaryConsumptionPlace,
        primaryConsumptionPlace,
        primaryConsumptionPlaceTermination,
        secondaryConsumptionPlaceTermination,
        toEdit,
    };
}

const actionCreators = {
    changeForm: applicationFilingActions.changeForm,
    get: applicationFilingActions.get,
    update: applicationFilingActions.update,
    getValidationUpdate: applicationFilingActions.getValidationUpdate,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
    finalize: applicationFilingActions.finalize,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(ApplicationFilingRecordingData);
