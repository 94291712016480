export const types = {
    DEFAULT_FORM: "@@provider-validation/DEFAULT_FORM",
    CHANGE_FORM: "@@provider-validation/CHANGE_FORM",

    FETCH_REQUEST_DOWNLOAD: "@@provider-validation/FETCH_REQUEST_DOWNLOAD",
    FETCH_SUCCESS_DOWNLOAD: "@@provider-validation/FETCH_SUCCESS_DOWNLOAD",
    FETCH_FAILURE_DOWNLOAD: "@@provider-validation/FETCH_FAILURE_DOWNLOAD",

    FETCH_REQUEST_UPLOAD: "@@provider-validation/FETCH_REQUEST_UPLOAD",
    FETCH_SUCCESS_UPLOAD: "@@provider-validation/FETCH_SUCCESS_UPLOAD",
    FETCH_FAILURE_UPLOAD: "@@provider-validation/FETCH_FAILURE_UPLOAD",

    DEFAULT_FORM_DOWNLOAD: "@@provider-validation/DEFAULT_FORM_DOWNLOAD",
    DEFAULT_FORM_UPLOAD: "@@provider-validation/DEFAULT_FORM_UPLOAD",

    DOWNLOAD_REQUEST_DOWNLOAD: "@@provider-validation/DOWNLOAD_REQUEST_DOWNLOAD",
    DOWNLOAD_SUCCESS_DOWNLOAD: "@@provider-validation/DOWNLOAD_SUCCESS_DOWNLOAD",
    DOWNLOAD_FAILURE_DOWNLOAD: "@@provider-validation/DOWNLOAD_FAILURE_DOWNLOAD",

    DOWNLOAD_REQUEST_UPLOAD: "@@provider-validation/DOWNLOAD_REQUEST_UPLOAD",
    DOWNLOAD_SUCCESS_UPLOAD: "@@provider-validation/DOWNLOAD_SUCCESS_UPLOAD",
    DOWNLOAD_FAILURE_UPLOAD: "@@provider-validation/DOWNLOAD_FAILURE_UPLOAD",

    DOWNLOAD_REQUEST_UPLOAD_REPLY: "@@provider-validation/DOWNLOAD_REQUEST_UPLOAD_REPLY",
    DOWNLOAD_SUCCESS_UPLOAD_REPLY: "@@provider-validation/DOWNLOAD_SUCCESS_UPLOAD_REPLY",
    DOWNLOAD_FAILURE_UPLOAD_REPLY: "@@provider-validation/DOWNLOAD_FAILURE_UPLOAD_REPLY",

    UPLOAD_REQUEST: "@@provider-validation/UPLOAD_REQUEST",
    UPLOAD_SUCCESS: "@@provider-validation/UPLOAD_SUCCESS",
    UPLOAD_FAILURE: "@@provider-validation/UPLOAD_FAILURE",

    EXPORT_INITIATE_REQUEST_DOWNLOAD: "@@provider-validation/EXPORT_INITIATE_REQUEST_DOWNLOAD",
    EXPORT_INITIATE_SUCCESS_DOWNLOAD: "@@provider-validation/EXPORT_INITIATE_SUCCESS_DOWNLOAD",
    EXPORT_INITIATE_FAILURE_DOWNLOAD: "@@provider-validation/EXPORT_INITIATE_FAILURE_DOWNLOAD",

    EXPORT_INITIATE_REQUEST_UPLOAD: "@@provider-validation/EXPORT_INITIATE_REQUEST_UPLOAD",
    EXPORT_INITIATE_SUCCESS_UPLOAD: "@@provider-validation/EXPORT_INITIATE_SUCCESS_UPLOAD",
    EXPORT_INITIATE_FAILURE_UPLOAD: "@@provider-validation/EXPORT_INITIATE_FAILURE_UPLOAD",

    EXPORT_CHECK_REQUEST_DOWNLOAD: "@@provider-validation/EXPORT_CHECK_REQUEST_DOWNLOAD",
    EXPORT_CHECK_SUCCESS_DOWNLOAD: "@@provider-validation/EXPORT_CHECK_SUCCESS_DOWNLOAD",
    EXPORT_CHECK_FAILURE_DOWNLOAD: "@@provider-validation/EXPORT_CHECK_FAILURE_DOWNLOAD",

    EXPORT_CHECK_REQUEST_UPLOAD: "@@provider-validation/EXPORT_CHECK_REQUEST_UPLOAD",
    EXPORT_CHECK_SUCCESS_UPLOAD: "@@provider-validation/EXPORT_CHECK_SUCCESS_UPLOAD",
    EXPORT_CHECK_FAILURE_UPLOAD: "@@provider-validation/EXPORT_CHECK_FAILURE_UPLOAD",

    EXPORT_DOWNLOAD_REQUEST_DOWNLOAD: "@@provider-validation/EXPORT_DOWNLOAD_REQUEST_DOWNLOAD",
    EXPORT_DOWNLOAD_SUCCESS_DOWNLOAD: "@@provider-validation/EXPORT_DOWNLOAD_SUCCESS_DOWNLOAD",
    EXPORT_DOWNLOAD_FAILURE_DOWNLOAD: "@@provider-validation/EXPORT_DOWNLOAD_FAILURE_DOWNLOAD",

    EXPORT_DOWNLOAD_REQUEST_UPLOAD: "@@provider-validation/EXPORT_DOWNLOAD_REQUEST_UPLOAD",
    EXPORT_DOWNLOAD_SUCCESS_UPLOAD: "@@provider-validation/EXPORT_DOWNLOAD_SUCCESS_UPLOAD",
    EXPORT_DOWNLOAD_FAILURE_UPLOAD: "@@provider-validation/EXPORT_DOWNLOAD_FAILURE_UPLOAD",

    CHANGE_STATUS_REQUEST_DOWNLOAD: "@@provider-validation/CHANGE_STATUS_REQUEST_DOWNLOAD",
    CHANGE_STATUS_SUCCESS_DOWNLOAD: "@@provider-validation/CHANGE_STATUS_SUCCESS_DOWNLOAD",
    CHANGE_STATUS_FAILURE_DOWNLOAD: "@@provider-validation/CHANGE_STATUS_FAILURE_DOWNLOAD",

    CHANGE_STATUS_REQUEST_UPLOAD: "@@provider-validation/CHANGE_STATUS_REQUEST_UPLOAD",
    CHANGE_STATUS_SUCCESS_UPLOAD: "@@provider-validation/CHANGE_STATUS_SUCCESS_UPLOAD",
    CHANGE_STATUS_FAILURE_UPLOAD: "@@provider-validation/CHANGE_STATUS_FAILURE_UPLOAD",

    FETCH_SET_DOWNLOAD_INFO: "@@provider-validation/FETCH_SET_DOWNLOAD_INFO",
    FETCH_SET_UPLOAD_INFO: "@@provider-validation/FETCH_SET_UPLOAD_INFO",
    SET_PAGE_LOADING: "@@provider-validation/SET_PAGE_LOADING",

    GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_REQUEST:
        "@@provider-validation/GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_REQUEST",
    GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_SUCCESS:
        "@@provider-validation/GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_SUCCESS",
    GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_FAILURE:
        "@@provider-validation/GET_LOG_SERVICE_PROVIDER_DATA_VERIFICATION_FAILURE",

    GET_LOG_SORTING_RESULTS_REQUEST: "@@provider-validation/GET_LOG_SORTING_RESULTS_REQUEST",
    GET_LOG_SORTING_RESULTS_SUCCESS: "@@provider-validation/GET_LOG_SORTING_RESULTS_SUCCESS",
    GET_LOG_SORTING_RESULTS_FAILURE: "@@provider-validation/GET_LOG_SORTING_RESULTS_FAILURE",
};
