import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateGet,
    templateGetValidationCreate,
    templateGetValidationUpdate,
} from "..";
import usersService from "../../services/users";
import { PERMISSIONS } from "../../utils/AppConst";
import { types } from "./types";

function getUserProfile(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_USER_PROFILE_REQUEST, id });
        return usersService.getUserProfile(id).then(
            (data) => {
                //munkáltatók átváltása
                let convertedEmployers = [];
                data?.data?.employers?.map((employer) => {
                    convertedEmployers.push(employer?.id);
                });
                data.data.employers = convertedEmployers.length !== 0 ? convertedEmployers : [];

                //szolgáltató átváltása
                data.data.serviceProvider === null
                    ? (data.data.serviceProvider = null)
                    : (data.data.serviceProvider = data.data?.serviceProvider?.id);

                //választható szolgáltatók átváltása
                let convertedAssignableServiceProviders = [];
                data?.data?.assignableServiceProviders?.map((provider) => {
                    convertedAssignableServiceProviders.push(provider?.id);
                });
                data.data.assignableServiceProviders = convertedAssignableServiceProviders.length !== 0 ? convertedAssignableServiceProviders : [];

                dispatch({ type: types.GET_USER_PROFILE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_USER_PROFILE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function update(id, form) {
    if (
        !(
            form.roles?.includes(PERMISSIONS.FINANCIER) ||
            form.roles?.includes(PERMISSIONS.EMPLOYER_CONTACT) ||
            form.roles?.includes(PERMISSIONS.ADMIN)
        )
    )
        delete form.employers;

    if (
        !(
            form.roles?.includes(PERMISSIONS.SERVICE_PROVIDER) ||
            form.roles?.includes(PERMISSIONS.PENSIONER_CONTACT) ||
            form.roles?.includes(PERMISSIONS.ADMIN)
        )
    ) {
        delete form.assignableServiceProviders;
        delete form.serviceProvider;
    }

    if (form.serviceProvider === "none") delete form.serviceProvider;

    return (dispatch) => {
        dispatch({ type: types.UPDATE_REQUEST });
        return usersService.update(id, form).then(
            (data) => {
                dispatch({ type: types.UPDATE_SUCCESS });
                dispatch({ type: types.DEFAULT_FORM, name: "form" });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPDATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function create(form) {
    if (
        !(
            form.roles?.includes(PERMISSIONS.FINANCIER) ||
            form.roles?.includes(PERMISSIONS.EMPLOYER_CONTACT) ||
            form.roles?.includes(PERMISSIONS.ADMIN)
        )
    )
        delete form.employers;

    if (
        !(
            form.roles?.includes(PERMISSIONS.SERVICE_PROVIDER) ||
            form.roles?.includes(PERMISSIONS.PENSIONER_CONTACT) ||
            form.roles?.includes(PERMISSIONS.ADMIN)
        )
    ) {
        delete form.assignableServiceProviders;
        delete form.serviceProvider;
    }

    if (form.serviceProvider === "none") delete form.serviceProvider;

    return (dispatch) => {
        dispatch({ type: types.CREATE_REQUEST });
        return usersService.create(form).then(
            (data) => {
                dispatch({ type: types.CREATE_SUCCESS });
                dispatch({ type: types.DEFAULT_FORM, name: "form" });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CREATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function filterPublic(form) {
    return (dispatch) => {
        dispatch({ type: types.FETCH_PUBLIC_REQUEST, form });
        return usersService.filterPublic(form).then(
            (data) => {
                dispatch({ type: types.FETCH_PUBLIC_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_PUBLIC_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getAllUsers() {
    return (dispatch) => {
        dispatch({ type: types.GET_ALL_USERS_REQUEST });
        return usersService.fetch().then(
            (data) => {
                dispatch({ type: types.GET_ALL_USERS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ALL_USERS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    getUserProfile,
    getAllUsers,
    create,
    update,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types, usersService),
    filterPublic,
    filter: templateFilter(types, usersService),
    get: templateGet(types, usersService),
    getValidationCreate: templateGetValidationCreate(types, usersService),
    getValidationUpdate: templateGetValidationUpdate(types, usersService),
    exportInitiate: templateExportInitiate(types, usersService),
    exportCheck: templateExportCheck(types, usersService),
    exportDownload: templateExportDownload(types, usersService),
};

export default actions;
