import React from "react";
import { Link } from "react-router-dom";
import { Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from "@material-ui/core";
import SubmitButton from "./SubmitButton";
import CancelButton from "./CancelButton";
import Edit from "../Icons/Edit";
import Delete from "../Icons/Delete";
import Info from "../Icons/Info";
import Tooltip from "./Tooltip";
import Check from "../Icons/Check";
import Close from "../Icons/Close";
import Cancel from "../Icons/Cancel";
import Download from "../Icons/Download";
import House from "../Icons/House";
import Clock from "../Icons/Clock";
import Update from "../Icons/Update";
import Add from "../Icons/Add";
import Abolition from "../Icons/Abolition";
import EditOutlined from "../Icons/CardEdit";
import CardEdit from "../Icons/CardEdit";
import SmallSearch from "../Icons/SmallSearch";
import Upload from "../Icons/Upload";
import TrackChanges from "../Icons/TrackChanges";
import SwitchButton from "./SwitchButton";
import DownloadResponse from "../Icons/DownloadResponse";
import DisabledHouse from "../Icons/DisabledHouse";
import DownloadInvalid from "../Icons/DownloadInvalid";
import DownloadValid from "../Icons/DownloadValid";

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
        position: "relative",
        top: "40%",
    },
}));

function getIcon(type, disabled = null) {
    //igény szerint bővíthető
    switch (type) {
        case "edit":
            return <Edit />;
        case "info":
        case "track-changes-table":
            return <Info />;
        case "delete":
            return <Delete />;
        case "check":
            return <Check />;
        case "close":
            return <Close />;
        case "cancel":
        case "delete-arrival":
            return <Cancel />;
        case "download-response":
            return <DownloadResponse />;
        case "download":
            return <Download />;
        case "download-valid":
            return <DownloadValid />;
        case "download-invalid":
            return <DownloadInvalid />;
        case "edit-outlined":
            return <EditOutlined />;
        case "upload":
            return <Upload />;
        //clientDetails táblázathoz:
        case "track-changes":
            return <TrackChanges />;
        case "card-edit":
            return <CardEdit />;
        case "house":
            if (disabled) {
                return <DisabledHouse />;
            }
            return <House />;
        case "clock":
            return <Clock />;
        case "update":
            return <Update />;
        case "search":
            return <SmallSearch />;
        case "add":
            return <Add />;
        case "new-child":
            return <Add />;
        case "abolition":
            return <Abolition />;
        case "switch":
            return <SwitchButton />;
        default:
            return;
    }
}

function getTitle(type) {
    switch (type) {
        case "edit":
        case "card-edit":
            return "Szerkesztés";
        case "info":
            return "Információ";
        case "delete":
            return "Törlés";
        case "cancel":
            return "Sztornó";
        case "delete-arrival":
            return "Érkeztetés törlése";
        case "download":
            return "Letöltés";
        case "download-valid":
            return "Érvényes Excel letöltése";
        case "download-invalid":
            return "Érvénytelen Excel letöltése";
        case "add":
            return "Hozzáadás";
        case "update":
            return "Mérőóra cseréje";
        case "house":
            return "Fogyasztási hely adminisztrációja";
        case "track-changes":
        case "track-changes-table":
            return "Módosítások nyomonkövetése";
        case "search":
            return "Ügyfél keresése a tiltólistán";
        case "download-response":
            return "Válaszfájl letöltése";
        case "new-child":
            return "Jogutódlás";
        case "abolition":
            return "Megszüntetés";
        default:
            return;
    }
}

/**
 * @param type - gomb típusa, lehetséges verziókat lásd fent
 * @param submittext - megerősítő gombon megjelenő üzenet (opcionális, default: "Törlés")
 * @param confirmation - "Biztos, hogy végre akarja hajtani a ...-ot?" (opcionális, default: "Biztosan törölni kívánja? A művelet nem visszavonható.")
 * @param canceltext - elvetés gombon megjelenező üzenet (opcionális, default: "Mégsem")
 * @param params - paraméterek, amit a BtnCellRenderer is kap
 * Minden további paraméter a Link-nek lesz átadva (to, onClick, stb.)
 */

export default function OperationButton(props) {
    const { type, to, confirmation, submittext, canceltext, disabled } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    const classes = useStyles();

    const handleSubmit = () => {
        if (props.handleClick) props.handleClick();
        handleClose();
    };

    return (
        <>
            {disabled ? (
                <Tooltip title={getTitle(type) || ""}>{getIcon(type, disabled)}</Tooltip>
            ) : (
                <Tooltip title={getTitle(type) || ""}>
                    <Link
                        to={to || "#"}
                        onClick={
                            type === "delete" || type === "cancel" || type === "delete-arrival"
                                ? () => setOpen(true)
                                : props.onClick
                        }
                        className={classes.container}
                    >
                        {getIcon(type)}
                    </Link>
                </Tooltip>
            )}

            {type === "delete" || type === "cancel" || type === "delete-arrival" ? (
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        <DialogContentText>{confirmation || "Biztos?"}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <SubmitButton onClick={handleSubmit}>{submittext || "Törlés"}</SubmitButton>
                        <CancelButton
                            onClick={() => {
                                handleClose();
                            }}
                        >
                            {canceltext || "Mégse"}
                        </CancelButton>
                    </DialogActions>
                </Dialog>
            ) : (
                ""
            )}
        </>
    );
}
