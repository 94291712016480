import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/statistics/consumption-data-by-permission", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const consumptionDataByPermissionService = {
    filter,
    exportInitiate: templateExportInitiate("statistics/consumption-data-by-permission"),
    exportCheck: templateExportCheck("statistics/consumption-data-by-permission"),
    exportDownload: templateExportDownload("statistics/consumption-data-by-permission"),
};

export default consumptionDataByPermissionService;
