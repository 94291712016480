import { Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Address from "../ClientDetails/cards/Address";
import ContractDetails from "../ClientDetails/cards/ContractDetails";
import PageTemplate from "../../Common/PageTemplate";
import consumptionPlaceActions from "../../../store/consumptionPlace/actions.js";
import { connect } from "react-redux";
import ConsumptionMeters from "../ClientDetails/cards/ConsumptionMeters";
import Card from "../../Common/Card";
import Detail from "../../Common/Detail";
import CancelButton from "../../Common/CancelButton";
import axios from "axios";
import SubmitButton from "../../Common/SubmitButton";
import notificationActions from "../../../store/notification/actions";
import ReasonDialog from "../ClientDetails/dialogs/ReasonDialog";
import { history } from "../../../store";
import { PERMISSIONS } from "../../../utils/AppConst";

const useStyles = makeStyles((theme) => ({
    userTypography: {
        paddingBottom: "16px",
    },
    cancelButton: {
        marginTop: theme.spacing(2),
    },
    submitButton: {
        marginTop: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    editButton: {
        position: "absolute",
        right: "30px",
        zIndex: 100,
    },
}));

function ConsumptionPlace(props) {
    const classes = useStyles();
    const { form, loading, metadata, data } = props;
    const [openDialog, setOpenDialog] = useState(false);
    const [edit, setEdit] = useState(false);
    const user = JSON.parse(localStorage.getItem("user"));
    const mavirId = props.match.params.mavirId;
    const permissionId = props.match.params.permissionId;
    const placeIndex = props.match.params.placeIndex;

    useEffect(() => {
        axios
            .all([
                props.getData(mavirId, permissionId, placeIndex),
                props.getMetaData(mavirId, permissionId, placeIndex),
            ])
            .then(
                axios.spread(() => {
                    props.setLoading(false);
                })
            );
    }, []);

    useEffect(() => {
        if (!loading && data && Object.keys(data).length === 0) {
            setEdit(true);
        } else {
            setEdit(false);
        }
    }, [data]);

    const handleSubmit = (form) => {
        if (!("reason" in form)) {
            props.changeForm({ ...form, reason: "Új fogyasztási hely hozzáadása" }, "form");
            form["reason"] = "Új fogyasztási hely hozzáadása";
        }
        props
            .saveForm(mavirId, permissionId, placeIndex, form)
            .then(() => {
                props.addNotification("success", "Sikeres mentés!");
            })
            .then(() => setEdit(false))
            .then(() => props.getData(mavirId, permissionId, placeIndex))
            .catch((e) => {
                if (e.validationErrors) {
                    props.changeForm({ ...form, errors: e.validationErrors }, "form");
                    props.addNotification("error", "Kérjük ellenőrizze az adatokat");
                } else {
                    props.addNotification("error", "Hiba a mentés során!");
                }
            });
    };

    return (
        <PageTemplate
            loading={loading}
            header={{
                title: "Fogyasztási hely adminisztrációja",
                breadcrumbs: {
                    "/clients": "Ügyfelek",
                    ["/clients/" + mavirId]: "Ügyfél adminisztrációja",
                    "consumption-place": "Fogyasztási hely adminisztrációja",
                },
            }}
        >
            {!edit && user?.roles?.includes(PERMISSIONS.ADMIN) && (
                <SubmitButton
                    className={classes.editButton}
                    format={{ xs: 12 }}
                    type="submit"
                    onClick={() => {
                        props.clearForm();
                        props.setFormData(data);
                        setOpenDialog(true);
                    }}
                >
                    Szerkesztés
                </SubmitButton>
            )}
            <Card title={placeIndex == 1 ? "Első fogyasztási hely" : "Második fogyasztási hely"} fullWidth>
                <Grid className={classes.userTypography}>
                    <Detail title="Ügyfél" value={mavirId} />
                </Grid>
                <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Address
                            name="form"
                            form={form}
                            changeForm={props.changeForm}
                            edit={edit}
                            data={data}
                            validation={metadata && metadata?.address ? metadata?.address.constraints : []}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContractDetails
                            name="form"
                            form={form}
                            metadata={metadata}
                            data={data}
                            changeForm={props.changeForm}
                            edit={edit}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConsumptionMeters
                            name="form"
                            form={form}
                            metadata={metadata}
                            data={data}
                            changeForm={props.changeForm}
                            edit={edit}
                        />
                    </Grid>
                </Grid>
                {edit && (
                    <Grid item xs={12} md={6}>
                        <SubmitButton
                            button="true"
                            type="submit"
                            onClick={() => handleSubmit(form)}
                            className={classes.submitButton}
                        >
                            Mentés
                        </SubmitButton>
                        <CancelButton onClick={() => setEdit(false)} className={classes.cancelButton}>
                            Vissza
                        </CancelButton>
                    </Grid>
                )}
            </Card>
            <ReasonDialog
                form={form}
                initState={true}
                changeForm={props.changeForm}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                saveDialog={() => setEdit(true)}
                backMethod={() => history.push("./../../")}
            />
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading, metadata, data } = state.consumptionPlace;
    return { form, loading, metadata, data };
}

const actionCreators = {
    changeForm: consumptionPlaceActions.changeForm,
    clearForm: consumptionPlaceActions.clearForm,
    getData: consumptionPlaceActions.getData,
    getMetaData: consumptionPlaceActions.getMetaData,
    setLoading: consumptionPlaceActions.setLoading,
    saveForm: consumptionPlaceActions.saveForm,
    setFormData: consumptionPlaceActions.setFormData,
    setData: consumptionPlaceActions.setData,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(ConsumptionPlace);
