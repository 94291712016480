import {
    templateChangeForm,
    templateCreate,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateGet,
    templateGetValidationCreate,
    templateUpdate,
} from "..";
import failedLoginAttemptsService from "../../services/failedLoginAttempts";
import { types } from "./types";

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types, failedLoginAttemptsService),
    filter: templateFilter(types, failedLoginAttemptsService),
    firstFetch: templateFirstFetch(types, failedLoginAttemptsService),
    get: templateGet(types, failedLoginAttemptsService),
    update: templateUpdate(types, failedLoginAttemptsService),
    create: templateCreate(types, failedLoginAttemptsService),
    getValidationCreate: templateGetValidationCreate(types, failedLoginAttemptsService),
    exportInitiate: templateExportInitiate(types, failedLoginAttemptsService),
    exportCheck: templateExportCheck(types, failedLoginAttemptsService),
    exportDownload: templateExportDownload(types, failedLoginAttemptsService),
    setInfo,
};

export default actions;
