import { Grid } from "@material-ui/core";
import React from "react";
import Header from "./Header";
import Loading from "./Loading";

/**
 *
 * @param header - objektum, amit a Header vár (lásd Header dok.)
 */

export default function PageTemplate(props) {
    if (props.loading) return <Loading />;

    return (
        <Grid container spacing={1}>
            <Header {...props.header} />
            {props.statPageDetail && (
                <Grid item xs={12} style={{ padding: "16px" }}>
                    <i>{props.statPageDetail}</i>
                </Grid>
            )}
            {props.children}
        </Grid>
    );
}
