import { types } from "./types";

const initialState = {
    form: {},
};

export function batchCancellation(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                form: initialState.form,
            };
        case types.CANCEL_PERIOD_REQUEST:
            return {
                ...state,
            };
        case types.CANCEL_PERIOD_SUCCESS:
            return {
                ...state,
            };
        case types.CANCEL_PERIOD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
