import UsersForm from "./UsersForm";

export default function CreateUserForm(props) {
    const { id } = props.match.params;
    const breadcrumbs = {
        operators: "Operátorok",
        "/users": "Felhasználók",
        "add-user": "Felhasználó szerkesztése",
    };

    return <UsersForm breadcrumbs={breadcrumbs} title="Felhasználó szerkesztése" id={id} />;
}
