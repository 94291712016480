import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import providersAction from "../../../store/providers/actions";
import OperationButton from "../../Common/OperationButton";
import Autocomplete from "../../Common/Autocomplete";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function OperationsBtnCellRenderer(params) {
    return (
        <>
            <OperationButton type="edit" to={`/providers/edit/${params.data.id}`} />
            {params.data.canExtend ? (
                <OperationButton type="new-child" to={`/providers/new-child/${params.data.id}`} />
            ) : (
                ""
            )}
            {params.data.contractTo == null || params.data.contractTo == "" ? (
                <OperationButton type="abolition" to={`/providers/close/${params.data.id}`} />
            ) : (
                ""
            )}
        </>
    );
}

function ActiveCellRenderer(params) {
    return params.data.active ? "Aktív" : "Inaktív";
}

function Providers(props) {
    const { filter, data, loading, token, filterStorageKey } = props;
    const statusOptions = [
        { value: 1, label: "Aktív" },
        { value: 0, label: "Inaktív" },
    ];
    const table = {
        columnDefs: [
            { headerName: "Azonosító", field: "reference", maxWidth: 150 },
            { headerName: "Megnevezés", field: "name" },
            { headerName: "Székhely", field: "headquarters" },
            { headerName: "Érvényesség kezdete", field: "contractFrom" },
            { headerName: "Érvényesség vége", field: "contractTo" },
            {
                headerName: "Állapot",
                field: "active",
                cellRenderer: "activeCellRenderer",
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "operationsBtnCellRenderer",
                sortable: false,
                minWidth: 150,
            },
        ],
        rowData: data,
        frameworkComponents: {
            operationsBtnCellRenderer: OperationsBtnCellRenderer,
            activeCellRenderer: ActiveCellRenderer,
        },
        to: "providers/new",
        newButtonText: "Szolgáltató hozzáadása",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter"), 
        props.search(filter)
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Szolgáltatók",
                breadcrumbs: {
                    operators: "Operátorok",
                    providers: "Szolgáltatók",
                },
            }}
            filter={{
                onSubmit: (filter) => {
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filter);
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm("filter");
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
        >
            <TextField 
                label="Azonosító" 
                name="reference"
                value={filter.reference || ""} 
                format={{ xs: 12, md: 3 }} 
                rule={{
                    number: "true"
                }}
            />
            <TextField label="Megnevezés" name="name" value={filter.name || ""} format={{ xs: 12, md: 3 }} />
            <TextField
                label="Székhely"
                name="headquarters"
                value={filter.headquarters || ""}
                format={{ xs: 12, md: 3 }}
            />
            <Autocomplete
                value={filter.active || []}
                options={statusOptions}
                id="active"
                name="active"
                label="Állapot"
                format={{ xs: 12, md: 3 }}
            />
            <TextField
                label="Érvényesség kezdete (-tól)"
                name="contractFromStart"
                value={filter.contractFromStart || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség kezdete (-ig)"
                name="contractFromEnd"
                value={filter.contractFromEnd || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség vége (-tól)"
                name="contractToStart"
                value={filter.contractToStart || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Érvényesség vége (-ig)"
                name="contractToEnd"
                value={filter.contractToEnd || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, filterStorageKey } = state.providers;
    return { filter, data, loading, token, filterStorageKey };
}

const actionCreators = {
    changeForm: providersAction.changeForm,
    search: providersAction.filter,
    defaultForm: providersAction.defaultForm,
    exportInitiate: providersAction.exportInitiate,
    exportCheck: providersAction.exportCheck,
    exportDownload: providersAction.exportDownload,
};

export default connect(mapState, actionCreators)(Providers);
