import React, { Children } from "react";
import TrackingChanges from "../dialogs/TrackingChanges";
import OperationButton from "../../../Common/OperationButton";
import { makeStyles } from "@material-ui/core";
import CardTable from "../../../Common/CardTable";
import { getDatePicker } from "../../../Editors/getDatePicker";
import { FINANCIER_OPTIONS, numberFormat, PERMISSIONS } from "../../../../utils/AppConst";
import Card from "../../../Common/Card";
import { Permission } from "./Permission";
import ConsumptionPlaceTrackingChanges from "../dialogs/ConsumptionPlaceTrackingChanges";
import { hasRole } from "../../../../utils/User";
import colors from "../../../../styles/colors";
import { ApplicationBarcodeCellRenderer } from "../../../../utils/cellRenderer/ApplicationBarcodeCellRenderer";

const useStyles = makeStyles((theme) => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
    goButton: {
        marginTop: theme.spacing(1.5),
    },
    cancelButton: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
}));

const legalBasisTrans = {
    employee: "Munkavállaló (nem szakszervezeti alkalmazott)",
    pensioner: "Nyugdíjas (nem nyugdíjazott szakszervezeti alkalmazott)",
    employees_widow: "Munkavállaló özvegye (nem szakszervezeti alkalmazott özvegye)",
    pensioners_widow: "Nyugdíjas özvegye (nem nyugdíjazott szakszervezeti alkalmazott özvegye)",
    trade_union_employee: "Szakszervezeti alkalmazott",
    trade_union_pensioner: "Nyugdíjazott szakszervezeti alkalmazott",
    trade_union_employees_widow: "Szakszervezeti alkalmazott özvegye",
    trade_union_pensioner_widow: "Nyugdíjazott szakszervezeti alkalmazott özvegye",
};

function getLegalBasis(legalBasisType) {
    if (legalBasisTrans[legalBasisType]) {
        return legalBasisTrans[legalBasisType];
    }
    return legalBasisType;
}

export default function Permissions(props) {
    const classes = useStyles();
    const { mavirID, form, edit, contacts } = props;
    const labels = FINANCIER_OPTIONS.map((x) => x.label);

    function FirstPlaceCellRenderer(params) {
        const isAdd = !(params.data.primaryConsumptionPlace && params.data.primaryConsumptionPlace.id);

        return (
            <>
                <OperationButton
                    type={isAdd ? "add" : "house"}
                    to={`/clients/${mavirID}/${params.data.id}/consumption-place/1`}
                    disabled={!params.data.primaryConsumptionPlace?.visible}
                />
                {!isAdd && params.data.primaryConsumptionPlace?.visible && (
                    <ConsumptionPlaceTrackingChanges
                        customerMavirId={mavirID}
                        permissionId={params.data.id}
                        placeIndex={1}
                    />
                )}
            </>
        );
    }

    function EndOfLegalBasisCellRenderer(params) {
        return params?.value ? params?.value : "2099-12-31";
    }

    function SecondPlaceCellRenderer(params) {
        const isAdd = !(params.data.secondaryConsumptionPlace && params.data.secondaryConsumptionPlace.id);

        if (hasRole(PERMISSIONS.ADMIN)) {
            return (
                <>
                    <OperationButton
                        type={isAdd ? "add" : "house"}
                        to={`/clients/${mavirID}/${params.data.id}/consumption-place/2`}
                    />
                    {!isAdd && (
                        <ConsumptionPlaceTrackingChanges
                            customerMavirId={mavirID}
                            permissionId={params.data.id}
                            placeIndex={2}
                        />
                    )}
                </>
            );
        }

        if (!isAdd) {
            return (
                <OperationButton
                    type="house"
                    to={`/clients/${mavirID}/${params.data.id}/consumption-place/2`}
                    disabled={!params.data.secondaryConsumptionPlace?.visible}
                />
            );
        }

        return "-";
    }

    function LFCCellRenderer() {
        return <TrackingChanges type="info" />;
    }

    const permissions = props.data.permissions
        ? props.data.permissions.map((permission) => {
              return {
                  id: permission.id,
                  application: permission.application ? permission.application : "",
                  financier: permission.financier ? permission.financier.name : "",
                  legalBasis: getLegalBasis(permission.legalBasisType),
                  startOfLegalBasis: permission.from,
                  endOfLegalBasis: permission.to,
                  limit: numberFormat(permission.limit) + " kWh",
                  primaryConsumptionPlace: permission.primaryConsumptionPlace,
                  secondaryConsumptionPlace: permission.secondaryConsumptionPlace,
              };
          })
        : [];

    const table = {
        columnDefs: [
            {
                headerName: "",
                field: "",
                maxWidth: 10,
                cellStyle: (params) => {
                    if (props?.data?.currentPermission?.id === params?.data?.id) {
                        return {
                            background: colors.green,
                            paddingLeft: 0,
                            paddingRight: 0,
                        };
                    }
                    return {
                        background: colors.lightPrimary,
                        paddingLeft: 0,
                        paddingRight: 0,
                    };
                },
            },
            {
                headerName: "Vonalkód",
                field: "application.barCode",
                cellRenderer: "applicationBarcodeCellRenderer",
            },
            {
                headerName: "Finanszírozó",
                field: "financier",
                editable: edit,
                cellEditor: "agSelectCellEditor",
                cellEditorParams: { values: labels },
            },
            { headerName: "Jogosultság", field: "legalBasis" },
            {
                headerName: "Jogosultság kezdete",
                field: "startOfLegalBasis",
                editable: edit,
                cellEditor: "datePicker",
                minWidth: 200,
            },
            {
                headerName: "Jogosultság vége",
                field: "endOfLegalBasis",
                cellRenderer: "endOfLegalBasisCellRenderer",
                editable: edit,
                cellEditor: "datePicker",
                minWidth: 200,
            },
            {
                headerName: "Első fogyh.",
                field: "firstPlaceOfConsumption",
                cellRenderer: "firstPlaceCellRenderer",
            },
            {
                headerName: "Második fogyh.",
                field: "secondPlaceOfConsumption",
                cellRenderer: "secondPlaceCellRenderer",
            },
            { headerName: "Korlát (kWh)", field: "limit", editable: edit },
        ],
        autoSize: "true",
        components: {
            datePicker: getDatePicker(),
        },
        rowData: permissions,
        frameworkComponents: {
            applicationBarcodeCellRenderer: ApplicationBarcodeCellRenderer,
            firstPlaceCellRenderer: FirstPlaceCellRenderer,
            secondPlaceCellRenderer: SecondPlaceCellRenderer,
            lFCCellRenderer: LFCCellRenderer,
            endOfLegalBasisCellRenderer: EndOfLegalBasisCellRenderer,
        },
    };

    return (
        <Card title="Jogosultságok" edit={edit} fullWidth>
            {edit ? (
                <React.Fragment>
                    {form.permissions &&
                        Children.toArray(
                            form.permissions.map((permission, key) => {
                                return (
                                    <Permission
                                        form={props.form}
                                        changeForm={props.changeForm}
                                        permissionKey={key}
                                        name="form"
                                        contacts={contacts}
                                    />
                                );
                            })
                        )}
                    {!form.permissions || form.permissions.length == 0 ? (
                        <div className={classes.emptyText}>Nincs hozzáadott jogosultság</div>
                    ) : (
                        ""
                    )}
                </React.Fragment>
            ) : (
                <CardTable showPagination={false} {...table} />
            )}
        </Card>
    );
}
