import React from "react";
import Card from "../../Common/Card";
import ProviderForm from "./ProviderForm";
import PageTemplate from "../../Common/PageTemplate";

export default function CreateProvider() {
    return (
        <PageTemplate
            header={{
                breadcrumbs: {
                    operators: "Operátorok",
                    "/providers": "Szolgáltatók",
                    "add-provider": "Szolgáltató hozzáadása",
                },
            }}
        >
            <Card title="Szolgáltató hozzáadása" fullWidth>
                <ProviderForm />
            </Card>
        </PageTemplate>
    );
}
