import axios from "axios";
import loginService from "../services/login";
import { history } from "../store";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
    responseType: "json",
});

instance.interceptors.request.use(
    (config) => {
        if (!config.headers.Authorization) {
            const AUTH_TOKEN = localStorage.getItem("token");
            if (AUTH_TOKEN) config.headers.Authorization = `Bearer ${AUTH_TOKEN}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    // remember to make this async as the store action will
    // need to be awaited
    async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error?.response?.status === 401) {
            loginService.logout();
            history.push("/login");

            return Promise.reject(error);
        } else {
            if (404 === error?.response?.status) {
                history.push("/404");
                return Promise.reject(error);
            } else if (403 === error?.response?.status) {
                history.push("/403");
                return Promise.reject(error);
            } else if (503 === error?.response?.status && error?.response?.data?.type === 'gdpr') {
                history.push("/503/gdpr");
                return Promise.reject(error);
            }
        }

        return Promise.reject(error);
    }
);

export default instance;
