import { templateGet } from "..";
import API from "../../utils/API";

function deleteApplication(id) {
    return API.delete(`/applications/${id}`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}
function getApplicationStatusLog(id) {
    return API.get(`/applications/${id}/status-log`)
        .then((data) => data)
        .catch((error) => Promise.reject(error));
}

const applicationsDetailsService = {
    deleteApplication,
    getApplicationStatusLog,
    get: templateGet("applications"),
};

export default applicationsDetailsService;
