import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../styles/colors.js";

//Használata ua., mint a MenuItem-nek
const TxtMenuItem = withStyles({
    root: {
        "&:active": {
            backgroundColor: colors.lightPrimary,
            color: colors.hoverFontColor,
        },
        "&:hover": {
            backgroundColor: colors.lightPrimary,
            color: colors.hoverFontColor,
        },
        cursor: "pointer",
        backgroundColor: colors.primary,
        color: colors.white,
        justifyContent: "space-between",
    },
})(MenuItem);

export default function TextMenuItem(props) {
    return <TxtMenuItem {...props} />;
}
