import React, { useEffect } from "react";
import CancelButton from "../../Common/CancelButton";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { history } from "../../../store";
import { connect } from "react-redux";
import employersActions from "../../../store/employers/actions";
import notificationActions from "../../../store/notification/actions";
import Loading from "../../Common/Loading";

function EmployerForm(props) {
    const { id, parent, isClose, form, loading, validation } = props;

    useEffect(() => {
        props.clearForm();

        if (id || parent) {
            props.get(id ?? parent);
            props.getValidationCreate();
        } else {
            props.getValidationUpdate();
        }
    }, []);

    const handleSubmit = () => {
        if (parent) {
            form.parent = parent;
        }
        (id
            ? isClose == true
                ? props.updateClose(id, form)
                : props.update(id, form)
            : parent
            ? props.createChild(form)
            : props.create(form)
        )
            .then(() => {
                props.addNotification("success", "Sikeres mentés!", "/employers");
            })
            .catch((e) => {
                if (e.validationErrors) {
                    props.changeForm({ ...form, errors: e.validationErrors }, "form");
                    props.addNotification("error", "Kérjük ellenőrizze az adatokat");
                } else {
                    props.addNotification("error", "Hiba a mentés során!");
                }
            });
    };

    if (loading) return <Loading />;

    return (
        <Form
            form={form}
            name="form"
            changeform={props.changeForm}
            onSubmit={handleSubmit}
            validation={validation}
            clientValidation={isClose === true ? false : true}
        >
            <TextField
                label="Megnevezés"
                name="name"
                value={form.name || ""}
                format={{ xs: 12, md: 6 }}
                disabled={isClose === true}
            />
            <TextField
                label="Azonosító"
                name="reference"
                value={form.reference || ""}
                format={{ xs: 12, md: 6 }}
                disabled={id || parent || isClose === true ? true : false}
            />
            <TextField
                label="Székhely"
                name="headquarters"
                value={form.headquarters || ""}
                format={{ xs: 12, md: 6 }}
                disabled={isClose === true}
            />
            <TextField
                label="Érvényesség kezdete"
                name="contractFrom"
                value={form.contractFrom || ""}
                format={{ xs: 12, md: 6 }}
                InputLabelProps={{ shrink: true }}
                type="date"
                disabled={(id && form.contractTo != null && form.contractTo != "") || isClose === true}
            />
            {isClose === true ? (
                <TextField
                    label="Érvényesség vége"
                    name="contractTo"
                    value={form.contractTo || ""}
                    format={{ xs: 12, md: 12 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
            ) : (
                <React.Fragment></React.Fragment>
            )}
            <SubmitButton button="true" type="submit">
                Mentés
            </SubmitButton>
            <CancelButton button="true" onClick={() => history.push(`/employers`)}>
                Vissza
            </CancelButton>
        </Form>
    );
}

function mapState(state) {
    const { form, loading, validation } = state.employers;
    return { form, loading, validation };
}

const actionCreators = {
    changeForm: employersActions.changeForm,
    get: employersActions.get,
    clearForm: employersActions.clearForm,
    update: employersActions.update,
    create: employersActions.create,
    createChild: employersActions.createChild,
    updateClose: employersActions.updateClose,
    addNotification: notificationActions.addNotification,
    getValidationCreate: employersActions.getValidationCreate,
    getValidationUpdate: employersActions.getValidationUpdate,
};

export default connect(mapState, actionCreators)(EmployerForm);
