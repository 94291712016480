import { types } from "./types";

const initialState = {
    filter: {},
    cachedFilter: {},
    years: [],
    searchData: {},
    detailsData: [],
    pageLoading: true,
    tableLoading: false,
    showSearchData: false,
    showDetailsData: false,
    error: null,
    tableInfo: {
        filter: {},
        sort: {},
        page: 1,
        metadata: {},
    },
};

export function auditOfFinancingAccounts(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: {},
                searchData: {},
                detailsData: [],
                tableInfo: initialState.tableInfo,
                showSearchData: false,
                showDetailsData: false,
                cachedFilter: initialState.cachedFilter,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.isLoading,
            };
        case types.GET_SEARCH_REQUEST:
            return {
                ...state,
                pageLoading: true,
                showSearchData: false,
                cachedFilter: state.filter,
            };
        case types.GET_SEARCH_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                searchData: action.data.data,
                showSearchData: action.data.data !== undefined,
                detailsData: [],
                showDetailsData: false,
            };
        case types.GET_SEARCH_FAILURE:
            return {
                ...state,
                pageLoading: false,
                showSearchData: false,
                showDetailsData: false,
            };
        case types.GET_FIRST_DETAILS_REQUEST:
            return {
                ...state,
                pageLoading: true,
                tableLoading: true,
                showDetailsData: false,
            };
        case types.GET_FIRST_DETAILS_SUCCESS:
            return {
                ...state,
                pageLoading: false,
                tableLoading: false,
                detailsData: action.data.data,
                showDetailsData: true,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.metadata,
                },
            };
        case types.GET_FIRST_DETAILS_FAILURE:
            return {
                ...state,
                pageLoading: false,
                tableLoading: false,
                showDetailsData: false,
            };
        case types.GET_DETAILS_REQUEST:
            return {
                ...state,
                tableLoading: true,
            };
        case types.GET_DETAILS_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                detailsData: action.data.data,
                showDetailsData: true,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.metadata,
                },
            };
        case types.GET_DETAILS_FAILURE:
            return {
                ...state,
                tableLoading: false,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.GET_YEARS_REQUEST:
            return {
                ...state,
            };
        case types.GET_YEARS_SUCCESS:
            return {
                ...state,
                years: action.data.data,
            };
        case types.GET_YEARS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
