import { Grid } from "@material-ui/core";
import Card from "../../Common/Card";
import PageTemplate from "../../Common/PageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import applicationFilingActions from "../../../store/applicationFiling/actions";
import Form from "../../Common/Form";
import SubmitButton from "../../Common/SubmitButton";
import Select from "../../Common/Select";
import notificationActions from "../../../store/notification/actions";
import { useEffect } from "react";

function ApplicationFiling(props) {
    const { form, loading } = props;
    const validation = [
        { field: "barCode", type: "not_blank" },
        { field: "mavirId", type: "not_blank" },
    ];

    const typeOptions = [
        {
            value: "application",
            label: "Új igénylés (MAVIR ID-val nem rendelkező ügyfelek számára)",
        },
        {
            value: "modification",
            label: "Módosítás (Adatmódosítás MAVIR ID-val rendelkező ügyfelek számára)",
        },
        {
            value: "termination",
            label: "Megszüntetés (MAVIR ID-val rendelkező ügyfelek jogosultságának megszüntetése)",
        },
    ];

    const handleSubmit = () => {
        props
            .create(form)
            .then((response) => {
                const viols = response.data.violations || [];
                if (viols.length > 0) {
                    viols.map((viol) =>
                        props.changeForm(
                            {
                                ...form,
                                errors: [...form.errors, { name: viol.property, label: viol.message }],
                            },
                            "form"
                        )
                    );
                } else
                    props.addNotification(
                        "info",
                        "Az igénylés felvitele elkezdődött",
                        `/application-filing/recording-data/${response.data.data.id}`
                    );
            })
            .catch((error) =>
                props.addNotification("error", error?.response?.data?.message || "Hiba a felvitel során")
            );
    };

    const formProps = {
        changeform: props.changeForm,
        name: "form",
        form: form,
        onSubmit: handleSubmit,
        validation: validation,
    };

    useEffect(() => props.defaultForm("form"), []);
    useEffect(() => (form.type === "application" ? (form.mavirId = null) : ""), [form.type]);

    const disableSubmit = form?.barCode === undefined || form?.barCode === null;

    return (
        <PageTemplate
            header={{
                title: "",
                breadcrumbs: {
                    applications: "Igénylések",
                    "application-filing": "Igénylőlap felvitele",
                },
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card title="Igénylőlap felvitele">
                    <Form {...formProps}>
                        <TextField
                            label="Vonalkód"
                            name="barCode"
                            value={form.barCode || ""}
                            format={{ xs: 12, md: 6 }}
                            rule={{
                                number: "true",
                                length: 9,
                            }}
                        />
                        <Select
                            selectLabel="Igénylőlap típusa"
                            name="type"
                            optionList={typeOptions}
                            format={{ xs: 12, md: 6 }}
                            value={form.type}
                            dontSort
                        />
                        { (form.type === "modification" || form.type === "termination") &&
                            <TextField
                                name="mavirId"
                                label="MAVIR ID"
                                value={form.mavirId || ""}
                                rule={{
                                    length: 32,
                                }}
                                format={{ xs: 12 }}
                            />
                        }
                        <SubmitButton format={{ xs: 12 }} type="submit" disabled={disableSubmit}>
                            Igénylőlap felvitele
                        </SubmitButton>
                    </Form>
                </Card>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading } = state.applicationFiling;
    return { form, loading };
}

const actionCreators = {
    changeForm: applicationFilingActions.changeForm,
    defaultForm: applicationFilingActions.defaultForm,
    create: applicationFilingActions.create,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(ApplicationFiling);
