import { types } from "./types";

const initialState = {
    loading: false,
    permissionAssignments: [],
    sortings: [],
};

export function commands(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
            };
        case types.FETCH_PERMISSION_ASSIGNMENTS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_PERMISSION_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissionAssignments: action.data.data.data,
            };
        case types.FETCH_PERMISSION_ASSIGNMENTS_FAILURE:
            return {
                ...state,
                loading: false,
                permissionAssignments: [],
                error: action.error,
            };
        case types.FETCH_SORTINGS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SORTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                sortings: action.data.data.data,
            };
        case types.FETCH_SORTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                sortings: [],
                error: action.error,
            };
        case types.SORTING_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.SORTING_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.SORTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.PERMISSION_ASSIGNMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.PERMISSION_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.PERMISSION_ASSIGNMENT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
}
