import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateSetLoading,
} from "..";
import { types } from "./types";
import monthlyConsumptionDataService from "../../services/monthlyConsumptionData";

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setLoading: templateSetLoading(types),
    firstFetch: templateFirstFetch(types, monthlyConsumptionDataService),
    filter: templateFilter(types, monthlyConsumptionDataService),
    exportInitiate: templateExportInitiate(types, monthlyConsumptionDataService),
    exportCheck: templateExportCheck(types, monthlyConsumptionDataService),
    exportDownload: templateExportDownload(types, monthlyConsumptionDataService),
    setInfo,
};

export default actions;
