export const types = {
    DEFAULT_FORM_PROVIDER: "@@admissed-applications/DEFAULT_FORM_PROVIDER",
    DEFAULT_FORM_CONTACT: "@@admissed-applications/DEFAULT_FORM_CONTACT",

    CHANGE_FORM: "@@admissed-applications/CHANGE_FORM",

    FETCH_REQUEST_PROVIDER: "@@admissed-applications/FETCH_REQUEST_PROVIDER",
    FETCH_SUCCESS_PROVIDER: "@@admissed-applications/FETCH_SUCCESS_PROVIDER",
    FETCH_FAILURE_PROVIDER: "@@admissed-applications/FETCH_FAILURE_PROVIDER",

    FETCH_REQUEST_CONTACT: "@@admissed-applications/FETCH_REQUEST_CONTACT",
    FETCH_SUCCESS_CONTACT: "@@admissed-applications/FETCH_SUCCESS_CONTACT",
    FETCH_FAILURE_CONTACT: "@@admissed-applications/FETCH_FAILURE_CONTACT",

    DOWNLOAD_REQUEST: "@@admissed-applications/DOWNLOAD_REQUEST",
    DOWNLOAD_SUCCESS: "@@admissed-applications/DOWNLOAD_SUCCESS",
    DOWNLOAD_FAILURE: "@@admissed-applications/DOWNLOAD_FAILURE",

    EXPORT_INITIATE_REQUEST_PROVIDER: "@@admissed-applications/EXPORT_INITIATE_REQUEST_PROVIDER",
    EXPORT_INITIATE_SUCCESS_PROVIDER: "@@admissed-applications/EXPORT_INITIATE_SUCCESS_PROVIDER",
    EXPORT_INITIATE_FAILURE_PROVIDER: "@@admissed-applications/EXPORT_INITIATE_FAILURE_PROVIDER",

    EXPORT_INITIATE_REQUEST_CONTACT: "@@admissed-applications/EXPORT_INITIATE_REQUEST_CONTACT",
    EXPORT_INITIATE_SUCCESS_CONTACT: "@@admissed-applications/EXPORT_INITIATE_SUCCESS_CONTACT",
    EXPORT_INITIATE_FAILURE_CONTACT: "@@admissed-applications/EXPORT_INITIATE_FAILURE_CONTACT",

    EXPORT_CHECK_REQUEST_PROVIDER: "@@admissed-applications/EXPORT_CHECK_REQUEST_PROVIDER",
    EXPORT_CHECK_SUCCESS_PROVIDER: "@@admissed-applications/EXPORT_CHECK_SUCCESS_PROVIDER",
    EXPORT_CHECK_FAILURE_PROVIDER: "@@admissed-applications/EXPORT_CHECK_FAILURE_PROVIDER",

    EXPORT_CHECK_REQUEST_CONTACT: "@@admissed-applications/EXPORT_CHECK_REQUEST_CONTACT",
    EXPORT_CHECK_SUCCESS_CONTACT: "@@admissed-applications/EXPORT_CHECK_SUCCESS_CONTACT",
    EXPORT_CHECK_FAILURE_CONTACT: "@@admissed-applications/EXPORT_CHECK_FAILURE_CONTACT",

    EXPORT_DOWNLOAD_REQUEST_PROVIDER: "@@admissed-applications/EXPORT_DOWNLOAD_REQUEST_PROVIDER",
    EXPORT_DOWNLOAD_SUCCESS_PROVIDER: "@@admissed-applications/EXPORT_DOWNLOAD_SUCCESS_PROVIDER",
    EXPORT_DOWNLOAD_FAILURE_PROVIDER: "@@admissed-applications/EXPORT_DOWNLOAD_FAILURE_PROVIDER",

    EXPORT_DOWNLOAD_REQUEST_CONTACT: "@@admissed-applications/EXPORT_DOWNLOAD_REQUEST_CONTACT",
    EXPORT_DOWNLOAD_SUCCESS_CONTACT: "@@admissed-applications/EXPORT_DOWNLOAD_SUCCESS_CONTACT",
    EXPORT_DOWNLOAD_FAILURE_CONTACT: "@@admissed-applications/EXPORT_DOWNLOAD_FAILURE_CONTACT",

    CHANGE_STATUS_REQUEST: "@@admissed-applications/CHANGE_STATUS_REQUEST",
    CHANGE_STATUS_SUCCESS: "@@admissed-applications/CHANGE_STATUS_SUCCESS",
    CHANGE_STATUS_FAILURE: "@@admissed-applications/CHANGE_STATUS_FAILURE",
};
