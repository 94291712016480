import { templateChangeForm, templateDefaultForm, templateFetch, templateFilter } from "..";
import consumptionPeriodsService from "../../services/consumptionPeriods";
import { types } from "./types";

function activate(id) {
    return (dispatch) => {
        dispatch({ type: types.ACTIVATE_REQUEST });
        return consumptionPeriodsService.activate(id).then(
            (data) => {
                dispatch({ type: types.ACTIVATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.ACTIVATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deactivate(id) {
    return (dispatch) => {
        dispatch({ type: types.DEACTIVATE_REQUEST });
        return consumptionPeriodsService.deactivate(id).then(
            (data) => {
                dispatch({ type: types.DEACTIVATE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DEACTIVATE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    activate,
    deactivate,
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    fetch: templateFetch(types, consumptionPeriodsService),
    filter: templateFilter(types, consumptionPeriodsService),
};

export default actions;
