import React, { useEffect, useState } from "react";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import customerModificationImportActions from "../../../store/customerModificationImport/actions";
import providersActions from "../../../store/providers/actions";
import ListPageTemplate from "../../Common/ListPageTemplate";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from "@material-ui/core";
import CancelButton from "../../Common/CancelButton";
import SubmitButton from "../../Common/SubmitButton";
import OperationButton from "../../Common/OperationButton";
import notificationActions from "../../../store/notification/actions";
import moment from "moment";
import { hasServiceProvider } from "../../../utils/User";
import { setInfo } from "../../../utils/FilterHelper";
import StatusCellRenderer from "../../../utils/cellRenderer/FileStatusCellRenderer";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { filterStorageGet, filterStorageSave } from "../../../utils/FilterStorage";

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY.MM.DD. HH:mm");
}

function CustomerModificationImport(props) {
    const { filter, data, pageLoading, tableLoading, token, tableInfo, filterStorageKey } = props;
    const [open, setOpen] = useState(false);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [onlyCheck, setOnlyCheck] = useState(false);
    const [validateExcel, setValidateExcel] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        setDisableSubmit(true);
    };

    const handleClose = () => setOpen(false);

    const changeHandler = (event) => {
        if ([...event.target.files].filter((file) => file.name.slice(-5) !== ".xlsx").length === 0) {
            setSelectedFile([...event.target.files]);
            setDisableSubmit(false);
        } else {
            props.addNotification("error", "Kérem .xlsx kiterjesztésű fájlt adjon meg!");
            props.changeForm({ ...filter, file: null }, "filter");
            setSelectedFile(null);
            setDisableSubmit(true);
        }
    };

    const handleDownload = (id, fileName) => {
        props
            .download(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleDownloadReply = (id, fileName) => {
        props
            .downloadReply(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleDownloadValid = (id, fileName) => {
        props
            .downloadValid(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleDownloadInvalid = (id, fileName) => {
        props
            .downloadInvalid(id, fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    const handleSubmit = (e) => {
        handleClose();
        e.preventDefault();
        if (selectedFile?.length > 0) {
            selectedFile.map((file) => {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("onlyCheck", onlyCheck);
                formData.append("validateExcel", validateExcel);
                props
                    .upload(formData)
                    .then((response) => {
                        const viols = response.data.violations || [];
                        if (viols.length > 0) {
                            let prevErrors = [];
                            viols.map((viol) => {
                                const property = viol.property;
                                const message = viol.message;
                                if (!prevErrors) prevErrors = [];
                                props.changeForm(
                                    {
                                        ...filter,
                                        errors: [
                                            ...prevErrors,
                                            {
                                                name: property,
                                                label: message,
                                            },
                                        ],
                                    },
                                    "filter"
                                );
                                prevErrors.push({
                                    name: property,
                                    label: message,
                                });
                                props.addNotification("error", viol.message);
                            });
                        } else {
                            props.addNotification("success", "Sikeres feltöltés!");
                            handleClose();
                        }
                    })
                    .then(() => props.search(filter))
                    .catch((error) =>
                        props.addNotification(
                            "error",
                            error?.response?.data?.violations[0]?.message || "Hiba lépett fel feltöltés közben."
                        )
                    );
            });
        }
    };

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton
                    type="download"
                    onClick={() => handleDownload(params.data?.id, params.data?.fileName)}
                />
                {( params.data.status === 'import_unknown_error' || params.data.status === 'import_validation_error') && (
                    <OperationButton
                        type="download-response"
                        onClick={() => handleDownloadReply(params.data.id, params.data.replyFileName)}
                    />
                )}
                {(params.data.status === 'import_excel_validation') && (
                <><OperationButton
                        type="download-valid"
                        onClick={() => handleDownloadValid(params.data.id, params.data.validFileName)} /><OperationButton
                            type="download-invalid"
                            onClick={() => handleDownloadInvalid(params.data.id, params.data.invalidFileName)} /></>
                )}
            </>
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Fájl név", field: "fileName" },
            {
                headerName: "Állapot",
                field: "status",
                cellRenderer: "statusCellRenderer",
            },
            { headerName: "Feltöltő", field: "createdBy.name" },
            {
                headerName: "Feltöltés dátuma",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
            },
            {
                headerName: "Csak ellenőrzés",
                field: "checkOnly",
            },
            {
                headerName: "Műveletek",
                field: "operations",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
            statusCellRenderer: StatusCellRenderer,
        },
        newButtonText: hasServiceProvider() ? "Feltöltés" : null,
        newButtonIcon: <CloudUploadIcon />,
        to: "#",
        onClick: handleOpen,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        noExportButton: true,
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter");
        setInfo({ ...props, search: props.firstFetch }, "filter", storageFilter);
        props.getProviders();
    }, []);

    return (
        <ListPageTemplate
            header={{
                title: "Adatellenőrző importlista",
                breadcrumbs: {
                    operator: "Operátor",
                    "customer-modification-import-list": "Adatellenőrző lista",
                },
            }}
            filter={{
                onSubmit: () => {
                    filterStorageSave(filterStorageKey, filter);
                    setInfo(props, "filter", filter);
                },
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    props.search({});
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
        >
            <TextField label="Fájl neve" name="fileName" value={filter.fileName || ""} format={{ xs: 12, md: 4 }} />
            <TextField
                label="Feltöltés dátuma (-tól)"
                name="uploadedAtStart"
                value={filter.uploadedAtStart || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Feltöltés dátuma (-ig)"
                name="uploadedAtEnd"
                value={filter.uploadedAtEnd || ""}
                format={{ xs: 12, md: 4 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Fájl feltöltése</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Fájl"
                        type="file"
                        name="file"
                        format={{ xs: 12 }}
                        onChange={changeHandler}
                        inputProps={{ multiple: true, accept: ".xlsx" }}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkbox"
                                color="primary"
                                checked={onlyCheck}
                                onChange={(e) => setOnlyCheck(e.target.checked)}
                            />
                        }
                        label="Csak ellenőrzés"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="checkbox"
                                color="primary"
                                checked={validateExcel}
                                onChange={(e) => setValidateExcel(e.target.checked)}
                            />
                        }
                        label="Excel validálás"
                    />
                </DialogContent>
                <DialogActions>
                    <SubmitButton onClick={handleSubmit} disabled={disableSubmit}>
                        Feltöltés
                    </SubmitButton>
                    <CancelButton onClick={handleClose}>Mégsem</CancelButton>
                </DialogActions>
            </Dialog>
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, form, token, fileLog, tableInfo, filterStorageKey } = state.customerModificationImport;
    const providers = state.providers.data;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        form,
        token,
        fileLog,
        tableInfo,
        providers,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: customerModificationImportActions.changeForm,
    search: customerModificationImportActions.filter,
    firstFetch: customerModificationImportActions.firstFetch,
    defaultForm: customerModificationImportActions.defaultForm,
    cancel: customerModificationImportActions.cancel,
    download: customerModificationImportActions.download,
    downloadValid: customerModificationImportActions.downloadValid,
    downloadInvalid: customerModificationImportActions.downloadInvalid,
    downloadReply: customerModificationImportActions.downloadReply,
    upload: customerModificationImportActions.upload,
    addNotification: notificationActions.addNotification,
    exportInitiate: customerModificationImportActions.exportInitiate,
    exportCheck: customerModificationImportActions.exportCheck,
    exportDownload: customerModificationImportActions.exportDownload,
    getProviders: () => providersActions.filter({}),
    getLog: customerModificationImportActions.getLog,
    setInfo: customerModificationImportActions.setInfo,
};

export default connect(mapState, actionCreators)(CustomerModificationImport);
