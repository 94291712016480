export const types = {
    DEFAULT_FORM: "@@consumption-place/DEFAULT_FORM",
    CHANGE_FORM: "@@consumption-place/CHANGE_FORM",
    CLEAR_FORM: "@@consumption-place/CLEAR_FORM",
    SET_FORM_DATA: "@@consumption-place/SET_FORM_DATA",
    SET_DATA: "@@consumption-place/SET_DATA",

    GET_DATA_REQUEST: "@@consumption-place/GET_DATA_REQUEST",
    GET_DATA_SUCCESS: "@@consumption-place/GET_DATA_SUCCESS",
    GET_DATA_FAILURE: "@@consumption-place/GET_DATA_FAILURE",

    GET_META_DATA_REQUEST: "@@consumption-place/GET_META_DATA_REQUEST",
    GET_META_DATA_SUCCESS: "@@consumption-place/GET_META_DATA_SUCCESS",
    GET_META_DATA_FAILURE: "@@consumption-place/GET_META_DATA_FAILURE",

    UPDATE_REQUEST: "@@consumption-place/UPDATE_REQUEST",
    UPDATE_SUCCESS: "@@consumption-place/UPDATE_SUCCESS",
    UPDATE_FAILURE: "@@consumption-place/UPDATE_FAILURE",

    SET_LOADING: "@@consumption-place/SET_LOADING",
};
