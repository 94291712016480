import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Card from "../../Common/Card";
import CancelButton from "../../Common/CancelButton";
import Form from "../../Common/Form";
import Select from "../../Common/Select";
import SubmitButton from "../../Common/SubmitButton";
import TextField from "../../Common/TextField";
import { history } from "../../../store";
import { connect } from "react-redux";
import usersActions from "../../../store/users/actions";
import employersActions from "../../../store/employers/actions";
import providersActions from "../../../store/providers/actions";
import Autocomplete from "../../Common/Autocomplete";
import PageTemplate from "../../Common/PageTemplate";
import notificationActions from "../../../store/notification/actions";
import { PERMISSIONS, PERMISSION_OPTIONS } from "../../../utils/AppConst";
import { hasRole } from "../../../utils/User";

const statusOptions = [
    { value: "true", label: "Aktív" },
    { value: "false", label: "Inaktív" },
];

function UsersForm(props) {
    const { form, loading } = props;
    const newUser = props.id === undefined;
    const [serviceProviderFieldError, setServiceProviderFieldError] = useState(false);
    const validation = [
        { field: "username", type: "not_blank" },
        { field: "enabled", type: "not_blank" },
    ];

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        validation: validation,
    };

    useEffect(() => {
        if (!hasRole("ROLE_ADMIN")) {
            history.push("/403");
        }

        props.defaultForm();
        props.getEmployers();
        props.getProviders();
        if (!newUser) {
            props.getUserProfile(props.id);
        }
    }, []);

    useEffect(() => {
        if(form?.serviceProvider && form?.assignableServiceProviders) {
            setServiceProviderFieldError(form.assignableServiceProviders?.filter(assignableProvider => assignableProvider === form.serviceProvider)?.length === 0);
        }
    }, [form.assignableServiceProviders, form.serviceProvider]);

    const makeOptions = (data) => {
        return data
            ? data.map((elem) => {
                  return {
                      value: elem.id,
                      label: elem.name,
                  };
              })
            : [];
    };

    const employerOptions = makeOptions(props.employers);
    const providerOptions = makeOptions(props.providers);
    const assignableProviderOptions = makeOptions(props.providers);
    providerOptions.push({ value: "none", label: "Nincs szolgáltató" });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (form.roles?.includes(PERMISSIONS.USER)) {
            let prevErrors = [];
            props.changeForm({...form, errors: [
                ...prevErrors,
                { name: "roles", label: "Ez az érték helytelen" }
            ]}, "form");
            props.addNotification("error", "Nem sikerült menteni.");
            return;
        }

        (newUser ? props.create(form) : props.update(props.id, form))
            .then((response) => {
                const viols = response.data.violations || [];
                if (viols.length > 0) {
                    let prevErrors = [];
                    viols.map((viol) => {
                        const property = viol.property;
                        const message = viol.message;
                        if (!prevErrors) prevErrors = [];
                        props.changeForm(
                            {
                                ...form,
                                errors: [
                                    ...prevErrors,
                                    {
                                        name: property,
                                        label: message,
                                    },
                                ],
                            },
                            "form"
                        );
                        prevErrors.push({
                            name: property,
                            label: message,
                        });
                    });
                } else {
                    props.addNotification("success", "Sikeres mentés!", "/users");
                }
            })
            .catch((error) => {
                const viols = error.response.data.violations || [];

                const detailedViolation = error?.response?.data?.detail || error?.response?.data?.message || "";

                if (detailedViolation.length > 0) {
                    props.addNotification("error", detailedViolation || "Nem sikerült menteni");
                }
                
                if (viols.length > 0) {
                    let prevErrors = [];
                    viols.map((viol) => {
                        const property = viol.property;
                        const message = viol.message;
                        if (!prevErrors) prevErrors = [];
                        props.changeForm(
                            {
                                ...form,
                                errors: [
                                    ...prevErrors,
                                    {
                                        name: property,
                                        label: message,
                                    },
                                ],
                            },
                            "form"
                        );
                        prevErrors.push({
                            name: property,
                            label: message,
                        });
                    });
                    props.addNotification("error", "Nem sikerült menteni.");
                }
            });
    };

    const contacts = (
        <Autocomplete
            id="employers"
            name="employers"
            label="Kapcsolattartók"
            options={employerOptions.map((x) => x.value)}
            getOptionLabel={(value) => employerOptions?.find((x) => x.value === value)?.label || ""}
            getOptionSelected={(value) => form?.employers?.includes(value)}
            value={form?.employers || []}
            format={{ xs: 12, md: 6 }}
        />
    );

    const provider = (
            <Select
                name="serviceProvider"
                optionList={providerOptions}
                selectLabel="Elsődleges szolgáltató"
                value={form?.serviceProvider || ""}
                format={{ xs: 12, md: 6 }}
            />
    );

    const assignableProviders = (
        <Autocomplete
            id="assignableServiceProviders"
            name="assignableServiceProviders"
            label="Választható szolgáltatók"
            options={assignableProviderOptions.map((x) => x.value)}
            getOptionLabel={(value) => assignableProviderOptions?.find((x) => x.value === value)?.label || ""}
            getOptionSelected={(value) => form?.assignableServiceProviders?.includes(value)}
            value={form?.assignableServiceProviders || []}
            format={{ xs: 12, md: 6 }}
            error={serviceProviderFieldError}
            helperText="Kérem, adjon meg legalább egy szolgáltatót, amely megegyezhet az elsődleges szolgáltatóval is!"
        />
    );

    const roles = form?.roles;
    const contactsCondition =
        roles?.includes(PERMISSIONS.FINANCIER) ||
        roles?.includes(PERMISSIONS.EMPLOYER_CONTACT) ||
        roles?.includes(PERMISSIONS.ADMIN);
    const providerCondition =
        roles?.includes(PERMISSIONS.SERVICE_PROVIDER) ||
        roles?.includes(PERMISSIONS.PENSIONER_CONTACT) ||
        roles?.includes(PERMISSIONS.ADMIN);
    const assignableProvidersCondition = 
        roles?.includes(PERMISSIONS.SERVICE_PROVIDER) ||
        roles?.includes(PERMISSIONS.PENSIONER_CONTACT) ||
        roles?.includes(PERMISSIONS.ADMIN);

    useEffect(() => {
        props.changeForm({
            ...form, 
            employers: contactsCondition ? form.employers : undefined,
            assignableServiceProviders: assignableProvidersCondition ? form.assignableServiceProviders : undefined,
            serviceProvider: providerCondition ? form.serviceProvider : undefined,
        }, "form")
    }, [contactsCondition, assignableProvidersCondition, providerCondition]);

    return (
        <PageTemplate
            header={{
                breadcrumbs: props.breadcrumbs,
            }}
            loading={loading}
        >
            <Grid item xs={12}>
                <Card title={props.title}>
                    <Form {...formProps}>
                        <TextField
                            label="Felhasználónév"
                            name="username"
                            value={form?.username || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <Select
                            selectLabel="Állapot"
                            name="enabled"
                            optionList={statusOptions}
                            value={form?.enabled || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <TextField
                            label="Vezetékes telefonszám"
                            name="phoneLanding"
                            value={form?.phoneLanding || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <TextField
                            label="Mobiltelefonszám"
                            name="phoneMobile"
                            value={form?.phoneMobile || ""}
                            format={{ xs: 12, md: 6 }}
                        />
                        <Autocomplete
                            multiple
                            value={form?.roles || []}
                            options={PERMISSION_OPTIONS.map((x) => x.value)}
                            getOptionLabel={(value) => PERMISSION_OPTIONS?.find((x) => x.value === value)?.label}
                            getOptionSelected={(value) => form?.roles?.includes(value)}
                            id="roles"
                            name="roles"
                            label="Jogosultságok"
                            format={{ xs: 12, md: 6 }}
                        />
                        {contactsCondition && contacts}
                        {assignableProvidersCondition && assignableProviders}
                        {providerCondition && provider}
                        <div format={{ xs: 12 }}>
                            <Grid container spacing={1} xs={12}>
                                <Grid item>
                                    <SubmitButton type="submit" onClick={handleSubmit}>
                                        Mentés
                                    </SubmitButton>
                                </Grid>
                                <Grid item>
                                    <CancelButton onClick={() => history.push(`/users`)}>Vissza</CancelButton>
                                </Grid>
                            </Grid>
                        </div>
                    </Form>
                </Card>
            </Grid>
        </PageTemplate>
    );
}

function mapState(state) {
    const { form, loading, validation } = state.users;
    const employers = state.employers.data;
    const providers = state.providers.data;
    return { form, loading, validation, employers, providers };
}

const actionCreators = {
    changeForm: usersActions.changeForm,
    defaultForm: usersActions.defaultForm,
    get: usersActions.get,
    getUserProfile: usersActions.getUserProfile,
    update: usersActions.update,
    create: usersActions.create,
    addNotification: notificationActions.addNotification,
    getValidationCreate: usersActions.getValidationCreate,
    getValidationUpdate: usersActions.getValidationUpdate,
    getEmployers: () => employersActions.filter({}),
    getProviders: () => providersActions.filter({}),
};

export default connect(mapState, actionCreators)(UsersForm);
