import {
    Checkbox,
    Grid,
    makeStyles,
    Table as TableMU,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";
import moment from "moment";
import colors from "../../../styles/colors";
import { setInfo } from "../../../utils/FilterHelper";
import Autocomplete from "../../Common/Autocomplete";
import Filter from "../../Common/Filter";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import TextField from "../../Common/TextField";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CancelButton from "../../Common/CancelButton";
import { userOptions } from "../../../utils/listOptions/userOptions";
import { hasRole } from "../../../utils/User";
import { PERMISSIONS } from "../../../utils/AppConst";
import {filterStorageSave} from "../../../utils/FilterStorage";

const useStyles = makeStyles(() => ({
    checkbox: {
        color: colors.primary,
    },
}));

function DateCellRenderer(params) {
    return moment(params.data.createdAt).format("YYYY-MM-DD H:mm");
}

export default function PermissionsTab(props) {
    const { name, filter, data, token, info, tableLoading, users, filterStorageKey, type, subType } = props;

    const [fileLog, setFileLog] = useState([]);
    const [showFileLog, setShowFileLog] = useState(false);
    const [fileLogLoading, setFileLogLoading] = useState(false);

    const classes = useStyles();
    const option = [true, false];

    const setCheckBoxToTrue = (params) => {
        var rowNode = params.api.getRowNode(params.data.id);
        var newNode = rowNode;
        newNode.data.downloaded = true;
        rowNode.setData(newNode.data);
    };

    const checkBoxClickHandler = (params) => {
        props.changeStatus(params.data.id);
        var rowNode = params.api.getRowNode(params.data.id);
        var newNode = rowNode;
        newNode.data.downloaded = !newNode.data.downloaded;
        rowNode.setData(newNode.data);
    };

    const handleDownload = (params) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .download(params.data.id, params.data.fileName)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .then(() => setCheckBoxToTrue(params))
            .catch(() => props.addNotification("error", "Hiba lépett fel letöltés közben."));
    };

    function BtnCellRenderer(params) {
        return (
            <>
                <OperationButton type="download" onClick={() => handleDownload(params)} />
                <OperationButton
                    type="info"
                    onClick={() => {
                        setFileLog([]);
                        setFileLogLoading(true);
                        props.getLog(params.data.id).then((response) => {
                            setFileLog(response.data.data);
                            setFileLogLoading(false);
                            setShowFileLog(true);
                        });
                    }}
                />
            </>
        );
    }

    function CheckBoxCellRenderer(params) {
        return (
            <Checkbox
                color="default"
                className={classes.checkbox}
                option={option}
                defaultChecked={params.data.downloaded}
                onClick={() => checkBoxClickHandler(params)}
            />
        );
    }

    const table = {
        columnDefs: [
            { headerName: "Fájl neve", field: "fileName" },
            {
                headerName: "Feltöltés dátuma",
                field: "createdAt",
                cellRenderer: "dateCellRenderer",
            },
            { headerName: "Feltöltő", field: "createdBy.name" },
            {
                headerName: "Letöltve",
                field: "downloaded",
                cellRenderer: "checkBoxCellRenderer",
                sortable: false,
                maxWidth: 100,
            },
            {
                headerName: "Műveletek",
                field: "info",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ].filter(colDef => colDef.field !== "createdBy.name" || hasRole(PERMISSIONS.ADMIN)),
        rowData: data,
        frameworkComponents: {
            checkBoxCellRenderer: CheckBoxCellRenderer,
            btnCellRenderer: BtnCellRenderer,
            dateCellRenderer: DateCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: info,
        },
        filterData: filter,
        searchfunc: props.search,
        info: info,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading || fileLogLoading,
    };

    return (
        <Grid item container xs={12} spacing={2}>
            <Filter
                onSubmit={() => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                }}
                defaultFormAction={() => {
                    const tmpFilter = {type: type, subType: subType};
                    props.defaultForm();
                    filterStorageSave(filterStorageKey, tmpFilter);
                    setInfo(props, "filter", tmpFilter);
                }}
                form={filter}
                name={name}
                changeform={props.changeForm}
            >
                <TextField label="Fájl név" name="fileName" value={filter?.fileName || ""} format={{ xs: 12, md: 6 }} />
                <Autocomplete
                    value={filter.createdBy || []}
                    options={userOptions(users)}
                    id="createdBy"
                    name="createdBy"
                    format={{ xs: 12, md: 6 }}
                    label="Feltöltő"
                />
                <TextField
                    label="Feltöltés dátuma (-tól)"
                    name="uploadDateStart"
                    value={filter?.uploadDateStart || ""}
                    type="date"
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="Feltöltés dátuma (-ig)"
                    name="uploadDateEnd"
                    value={filter?.uploadDateEnd || ""}
                    type="date"
                    format={{ xs: 12, md: 6 }}
                />
            </Filter>
            <Grid item xs={12}>
                <Table {...table} />
            </Grid>
            <Dialog open={showFileLog}>
                <DialogTitle>Információk</DialogTitle>
                <DialogContent>{getInfoTable(fileLog)}</DialogContent>
                <DialogActions>
                    <CancelButton onClick={() => setShowFileLog(false)}>Bezárás</CancelButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}

function getInfoTable(data) {
    if (data === null || !Array.isArray(data) || data.length === 0) {
        return <i>Nincs naplózott információ</i>;
    }

    return (
        <TableMU>
            <TableHead>
                <TableRow>
                    <TableCell>Dátum</TableCell>
                    <TableCell>Felhasználó</TableCell>
                    <TableCell>Művelet</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.map((d) => {
                    return (
                        <TableRow>
                            <TableCell>{d.date}</TableCell>
                            <TableCell>{d.user ? d.user.name + " / " + d.user.email : "-"}</TableCell>
                            <TableCell>{d.action}</TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </TableMU>
    );
}
