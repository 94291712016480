import React, { useEffect } from "react";
import { connect } from "react-redux";
import consumptionDataByServiceProviderActions from "../../../../store/consumptionDataByServiceProvider/actions";
import providersActions from "../../../../store/providers/actions";
import employersActions from "../../../../store/employers/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import Select from "../../../Common/Select";
import axios from "axios";
import ConsumptionPlaceTypeCellRenderer from "../../../../utils/cellRenderer/ConsumptionPlaceTypeCellRenderer";
import NumberFormatCellRenderer from "../../../../utils/cellRenderer/NumberFormatCellRenderer";
import { FILTER_MONTH_OPTIONS, GET_STAT_DETAILS, YEAR_OPTIONS } from "../../../../utils/AppConst";
import { makeOptions } from "../../../../utils/listOptions/makeOptions";
import {filterStorageGet, filterStorageSave} from "../../../../utils/FilterStorage";

//4.1.4
function GKMFour(props) {
    const { filter, data, token, tableVisibility, loading, employers, providers, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"), 
            props.getProviders(), 
            props.getEmployers()
        ]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
        props.changeForm(false, "tableVisibility");
    }, []);

    const table = {
        columnDefs: [
            { headerName: "Szolgáltató", field: "serviceProvider" },
            { headerName: "Finanszírozó", field: "financier", hide: data[0]?.financier === null },
            { headerName: "Fogyasztási hely", field: "consumptionPlaceType", cellRenderer: "consumptionPlaceTypeCellRenderer" },
            { headerName: "Létszám", field: "numberOfCustomers" },
            { headerName: "Mennyiség (kWh)", field: "quantity", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Ár (Ft)", field: "grossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Fogyasztói ár (Ft)", field: "consumerGrossPrice", cellRenderer: "numberFormatCellRenderer" },
            { headerName: "Finanszírozói ár (Ft)", field: "financierGrossPrice", cellRenderer: "numberFormatCellRenderer" },
        ],
        autoSize: "true",
        rowData: data,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
        frameworkComponents: {
            consumptionPlaceTypeCellRenderer: ConsumptionPlaceTypeCellRenderer,
            numberFormatCellRenderer: NumberFormatCellRenderer,
        },
    };

    const providerOptions = makeOptions(providers);

    const financierOptions = makeOptions(employers);

    const disableSubmit =
        filter.yearFrom === undefined || filter.yearFrom === null || 
        filter.monthFrom === undefined || filter.monthFrom === null ||
        filter.yearTo === undefined || filter.yearTo === null || 
        filter.monthTo === undefined || filter.monthTo === null
    ;

    return (
        <ListPageTemplate
            header={{
                title: GET_STAT_DETAILS[5]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/gkm": "GKM kimutatások",
                    "4.1.4": GET_STAT_DETAILS[5]?.title,
                },
            }}
            filter={{
                onSubmit: (filterForm) => {
                    props.changeForm(true, "tableVisibility");
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filterForm);
                },
                onSubmitDisabled: disableSubmit,
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.changeForm(false, "tableVisibility");
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
            hideTable={!tableVisibility}
            statPageDetail={GET_STAT_DETAILS[5]?.body}
        >
            <Select
                selectLabel="Időszak kezdete (év)"
                name="yearFrom"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearFrom || ""}
            />
            <Select
                selectLabel="Időszak kezdete (hónap)"
                name="monthFrom"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthFrom || ""}
                dontSort
            />
            <Select
                selectLabel="Időszak vége (év)"
                name="yearTo"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearTo || ""}
            />
            <Select
                selectLabel="Időszak vége (hónap)"
                name="monthTo"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthTo || ""}
                dontSort
            />
            <Select
                selectLabel="Szolgáltató"
                name="serviceProvider"
                optionList={providerOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.serviceProvider || ""}
            />
             <Select
                selectLabel="Finanszírozó"
                name="financier"
                optionList={financierOptions}
                format={{ xs: 12, md: 6 }}
                value={filter.financier || ""}
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, tableVisibility, filterStorageKey } = state.consumptionDataByServiceProvider;
    const providers = state.providers.data;
    const employers = state.employers.data;
    return { filter, data, loading, token, providers, employers, tableVisibility, filterStorageKey };
}

const actionCreators = {
    changeForm: consumptionDataByServiceProviderActions.changeForm,
    defaultForm: consumptionDataByServiceProviderActions.defaultForm,
    search: consumptionDataByServiceProviderActions.filter,
    exportInitiate: consumptionDataByServiceProviderActions.exportInitiate,
    exportCheck: consumptionDataByServiceProviderActions.exportCheck,
    exportDownload: consumptionDataByServiceProviderActions.exportDownload,
    setLoading: consumptionDataByServiceProviderActions.setLoading,
    getProviders: () => providersActions.filter({}),
    getEmployers: () => employersActions.filter({}),
};

export default connect(mapState, actionCreators)(GKMFour);
