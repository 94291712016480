import { types } from "./types";

const initialState = {
    filterStorageKey: 'clients.fixed',
    filter: {},
    data: [],
    filterDataFinanciers: [],
    filterDataConsumptionPlaceServices1: [],
    filterDataConsumptionPlaceServices2: [],
    pageLoading: false,
    tableLoading: false,
    showTable: false,
    tableInfo: {
        filter: {},
        sort: {
            lastName: "asc",
        },
        page: 1,
        metadata: {},
    },
};

export function fixedTimeClients(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                tableInfo: initialState.tableInfo,
                filter: initialState.filter,
            };
        case types.FETCH_SET_INFO:
            return {
                ...state,
                tableInfo: {
                    ...state.tableInfo,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_FILTER_FINANCIERS_SUCCESS:
            return {
                ...state,
                filterDataFinanciers: action.data.data.data,
            };
        case types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES1_SUCCESS:
            return {
                ...state,
                filterDataConsumptionPlaceServices1: action.data.data.data,
            };
        case types.FETCH_FILTER_CONSUMPTION_PLACE_SERVICES2_SUCCESS:
            return {
                ...state,
                filterDataConsumptionPlaceServices2: action.data.data.data,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                tableLoading: true,
                pageLoading: !state.showTable,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: action.data.data.data,
                showTable: true,
                tableInfo: {
                    ...state.tableInfo,
                    metadata: action?.data?.data?.metadata,
                },
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                tableLoading: false,
                pageLoading: false,
                data: [],
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
