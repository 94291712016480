import React, { useEffect } from "react";
import { connect } from "react-redux";
import consumptionDataByPaymentsActions from "../../../../store/consumptionDataByPayments/actions";
import ListPageTemplate from "../../../Common/ListPageTemplate";
import providersActions from "../../../../store/providers/actions";
import axios from "axios";
import NumberFormatCellRenderer from "../../../../utils/cellRenderer/NumberFormatCellRenderer";
import { FILTER_MONTH_OPTIONS, GET_STAT_DETAILS, YEAR_OPTIONS } from "../../../../utils/AppConst";
import Select from "../../../Common/Select";
import {filterStorageGet, filterStorageSave} from "../../../../utils/FilterStorage";

//4.2.2
function MEKH(props) {
    const { filter, data, token, tableVisibility, loading, providers, filterStorageKey } = props;

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"),
            props.getProviders()
        ]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
        props.changeForm(false, "tableVisibility");
    }, []);

    const columnDefs = [
        { headerName: "Munkáltató", field: "financier.name", width: 200 },
        { headerName: "Azonosító", field: "financier.reference" },
    ];

    providers.forEach((provider) => {
        columnDefs.push({
            headerName: provider.name,
            field: `payments.${provider.id}`,
            cellRenderer: "numberFormatCellRenderer" 
        });
    });

    columnDefs.push({ headerName: "Összesen", field: "total", cellRenderer: "numberFormatCellRenderer" });

    const table = {
        columnDefs: columnDefs,
        autoSize: "true",
        rowData: data,
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
        },
        frameworkComponents: {
            numberFormatCellRenderer: NumberFormatCellRenderer,
        },
    };

    const disableSubmit =
        filter.yearFrom === undefined || filter.yearFrom === null || 
        filter.monthFrom === undefined || filter.monthFrom === null ||
        filter.yearTo === undefined || filter.yearTo === null || 
        filter.monthTo === undefined || filter.monthTo === null
    ;

    return (
        <ListPageTemplate
            header={{
                title: GET_STAT_DETAILS[7]?.shortTitle,
                breadcrumbs: {
                    statistics: "Statisztikák",
                    "/statistics/mekh": "MEKH kimutatások",
                    "4.2.1": GET_STAT_DETAILS[7]?.title,
                },
            }}
            filter={{
                onSubmit: (filterForm) => {
                    props.changeForm(true, "tableVisibility");
                    filterStorageSave(filterStorageKey, filter);
                    props.search(filterForm);
                },
                onSubmitDisabled: disableSubmit,
                defaultFormAction: () => {
                    filterStorageSave(filterStorageKey, {});
                    props.changeForm(false, "tableVisibility");
                    props.defaultForm();
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={loading}
            hideTable={!tableVisibility}
            statPageDetail={GET_STAT_DETAILS[7]?.body}
        >
             <Select
                selectLabel="Időszak kezdete (év)"
                name="yearFrom"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearFrom || ""}
            />
            <Select
                selectLabel="Időszak kezdete (hónap)"
                name="monthFrom"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthFrom || ""}
                dontSort
            />
            <Select
                selectLabel="Időszak vége (év)"
                name="yearTo"
                optionList={YEAR_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.yearTo || ""}
            />
            <Select
                selectLabel="Időszak vége (hónap)"
                name="monthTo"
                optionList={FILTER_MONTH_OPTIONS}
                format={{ xs: 12, md: 6 }}
                value={filter.monthTo || ""}
                dontSort
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, loading, token, tableVisibility, filterStorageKey } = state.consumptionDataByPayments;
    const providers = state.providers.data;
    return { filter, data, loading, token, providers, tableVisibility, filterStorageKey };
}

const actionCreators = {
    changeForm: consumptionDataByPaymentsActions.changeForm,
    defaultForm: consumptionDataByPaymentsActions.defaultForm,
    search: consumptionDataByPaymentsActions.filter,
    exportInitiate: consumptionDataByPaymentsActions.exportInitiate,
    exportCheck: consumptionDataByPaymentsActions.exportCheck,
    exportDownload: consumptionDataByPaymentsActions.exportDownload,
    setLoading: consumptionDataByPaymentsActions.setLoading,
    getProviders: () => providersActions.filter({}),
};

export default connect(mapState, actionCreators)(MEKH);
