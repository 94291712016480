import API from "../../utils/API";

function getXlsxLimit() {
    return API.get('/export/get-xlsx-limit')
    .then(data => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

const exportService = {
    getXlsxLimit
};

export default exportService;