import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/gdpr-review", {
        params: searchForm.getSend(),
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function deleteApplication(id) {
    return API.delete(`/gdpr-review/remove-application/${id}`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function deleteFilteredApplications(filter) {
    let filterToSend = Object.assign({}, filter);

    for (const [field, value] of Object.entries(filter)) {
        if (value === null) {
            delete filterToSend[field];
        }
    }

    return API.delete("gdpr-review/remove-filtered-applications", {
        params: filterToSend,
    })
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

function getLogStatus(sessionId) {
    return API.get(`/system-log/session-id/${sessionId}`)
        .then((data) => Promise.resolve(data))
        .catch((error) => Promise.reject(error));
}

const gdprService = {
    filter,
    deleteApplication,
    deleteFilteredApplications,
    getLogStatus,
    exportInitiate: templateExportInitiate("gdpr-review"),
    exportCheck: templateExportCheck("gdpr-review"),
    exportDownload: templateExportDownload("gdpr-review"),
};

export default gdprService;
