export const types = {
    LOGIN_REQUEST: "@@login/LOGIN_REQUEST",
    LOGIN_SUCCESS: "@@login/LOGIN_SUCCESS",
    LOGIN_FAILURE: "@@login/LOGIN_FAILURE",

    REFRESH_TOKEN_REQUEST: "@@login/REFRESH_TOKEN_REQUEST",
    REFRESH_TOKEN_SUCCESS: "@@login/REFRESH_TOKEN_SUCCESS",
    REFRESH_TOKEN_FAILURE: "@@login/REFRESH_TOKEN_FAILURE",

    CHECK_TOKEN_REQUEST: "@@login/CHECK_TOKEN_REQUEST",
    CHECK_TOKEN_SUCCESS: "@@login/CHECK_TOKEN_SUCCESS",
    CHECK_TOKEN_FAILURE: "@@login/CHECK_TOKEN_FAILURE",

    CHANGE_FORM: "@@login/CHANGE_FORM",

    GET_PROFILE_REQUEST: "@@login/GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "@@login/GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "@@login/GET_PROFILE_FAILURE",

    SAVE_DESIRED_URL: "@@login/SAVE_DESIRED_URL",
};
