import {
    templateChangeForm,
    templateClearForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateSetLoading,
} from "..";
import consumptionDataByServiceProviderWithBlocksService from "../../services/consumptionDataByServiceProviderWithBlocks";
import { types } from "./types";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    clearForm: templateClearForm(types, consumptionDataByServiceProviderWithBlocksService),
    filter: templateFilter(types, consumptionDataByServiceProviderWithBlocksService),
    exportInitiate: templateExportInitiate(types, consumptionDataByServiceProviderWithBlocksService),
    exportCheck: templateExportCheck(types, consumptionDataByServiceProviderWithBlocksService),
    exportDownload: templateExportDownload(types, consumptionDataByServiceProviderWithBlocksService),
    setLoading: templateSetLoading(types),
};

export default actions;
