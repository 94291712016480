import { templateChangeForm, templateDefaultForm } from "..";
import applicationReceiptService from "../../services/applicationReceipt";
import { types } from "./types";

function checkArrival(barCode) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_ARRIVAL_REQUEST, barCode });
        return applicationReceiptService.checkArrival(barCode).then(
            (data) => {
                dispatch({ type: types.CHECK_ARRIVAL_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHECK_ARRIVAL_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function arrive(id, arrivalBarCode) {
    return (dispatch) => {
        dispatch({ type: types.ARRIVE_REQUEST, id });
        return applicationReceiptService.arrive(id, arrivalBarCode).then(
            (data) => {
                dispatch({ type: types.ARRIVE_SUCCESS, data });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.ARRIVE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function deleteArrival(id) {
    return (dispatch) => {
        dispatch({ type: types.DELETE_ARRIVAL_REQUEST, id });
        return applicationReceiptService.deleteArrival(id).then(
            (data) => {
                dispatch({ type: types.DELETE_ARRIVAL_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DELETE_ARRIVAL_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    checkArrival,
    arrive,
    deleteArrival,
};

export default actions;
