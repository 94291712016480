import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    makeStyles,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AccordionSummary from "./AccordionSummary";
import colors from "../../styles/colors.js";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import SubmitButton from "./SubmitButton";
import CancelButton from "./CancelButton";
import Link from "./Link";
import SplitButton from "./SplitButton";
import AgGrid from "./AgGrid";
import Tooltip from "./Tooltip";
import { numberFormat } from "../../utils/AppConst";

import CustomHeader from "./TableCustomHeader.js";
import TableRefresh from "./TableRefresh.js";
import TablePaginator from "./TablePaginator";
import { useWindowSize } from "../../utils/useWindowSize";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    heading: {
        color: colors.white,
        fontSize: "large",
    },
    listActionsGrid: {
        justifyContent: "unset",
    },
    exportButton: {
        backgroundColor: colors.export,
        "&:hover": {
            background: colors.exportHover,
            color: colors.white,
        },
    },
    newButton: {
        backgroundColor: colors.green,
        "&:hover": {
            background: colors.greenHover,
            color: colors.white,
        },
    },
    deleteButton: {
        backgroundColor: colors.red,
        "&:hover": {
            background: colors.redHover,
            color: colors.white,
        },
    },
    truncate: {
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    accordionDetails: {
        padding: "unset",
    },
    detailButton: {
        marginLeft: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
    },
}));

const options = ["CSV fájlba", "Excel fájlba"];

export function TooltipRenderer(params) {
    const classes = useStyles();

    //itt ellenőrzünk 0-ra, hiszen az ag-grid nem jeleníti meg, hanem null-nak veszi
    const value = params.value === 0 ? "0" : params.value;

    return (
        <Tooltip title={value ? value : ""}>
            <div className={classes.truncate}>{value ? value : ""}</div>
        </Tooltip>
    );
}

function NumberFormatRenderer(params) {
    const classes = useStyles();
    return (
        <Tooltip title={params.value ? params.value : ""}>
            <div className={classes.truncate}>{numberFormat(params.value)}</div>
        </Tooltip>
    );
}

/**
 * @param columnDefs - oszlop tulajdonságok
 * @param rowData - adatok
 * @param frameworkComponents - használt függvények (btncellrenderer, stb.)
 * @param context - pluszban feldolgozandó adat (user.name, user.language, stb.)
 * @param newButtonText - "Új ... létrehozása" szöveg (opcionális)
 * @param to - ".../new" link (opcionális)
 * @param minWidth - oszlopok minimális szélessége (opcionális)
 * @param autoSize - legszélesebb adat is látszódik (opcionális; kevés oszlopos táblázatoknál nem ajánlott)
 */

export default function Table(props) {
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const classes = useStyles();
    const size = useWindowSize();

    const defaultColDef = {
        suppressMovable: true,
        flex: 1,
        sortable: typeof props.sortable !== typeof undefined ? props.sortable : true,
        cellRenderer: "tooltipRenderer",
        minWidth: 200,
    };

    const [openDeleteApplicationDialog, setOpenDeleteApplicationDialog] = useState(false);
    const handleCloseDeleteApplicationDialog = () => setOpenDeleteApplicationDialog(false);

    const frameworkComponents = props.frameworkComponents || {};

    frameworkComponents.tooltipRenderer = TooltipRenderer;
    frameworkComponents.numberFormatRenderer = NumberFormatRenderer;

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    };

    useEffect(() => {
        if (gridApi && gridColumnApi) {
            if (size.width / props.columnDefs.length < 250 || props.columnDefs.length > 7) {
                const colIds = gridColumnApi.getAllDisplayedColumns().map((col) => col.getColId());

                gridColumnApi.autoSizeColumns(colIds);
            } else {
                gridApi.sizeColumnsToFit();
            }
        }
    }, [size, props]);

    useEffect(() => {
        if (props.loading && gridApi) {
            gridApi.showLoadingOverlay();
            gridApi.refreshHeader();
        } else if (gridApi) {
            gridApi.hideOverlay();
        }
    }, [props.loading]);

    if (props.isPaginated) {
        frameworkComponents.agColumnHeader = CustomHeader;
        frameworkComponents.refreshTable = TableRefresh;
        frameworkComponents.searchfunc = props.searchfunc;
        frameworkComponents.setInfo = props.setInfo;
        frameworkComponents.info = props.info;
    }

    const handleDeleteButtonCommand = () => {
        if (props.deleteButtonCommand) {
            props.deleteButtonCommand();
        }
        handleCloseDeleteApplicationDialog();
    };

    return (
        <Grid item container className={classes.root}>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <Accordion className={classes.accordionRoot} expanded>
                        <AccordionSummary
                            text={<Typography className={classes.heading}>{props.title || "Lista"}</Typography>}
                            list="true"
                        />
                        <AccordionActions classes={{ root: classes.listActionsGrid }}>
                            <Grid item container xs={12} spacing={1} justify="flex-end">
                                {props.deleteButtonText && (
                                    <>
                                        <Grid item>
                                            <SubmitButton
                                                classes={{
                                                    root: classes.deleteButton,
                                                }}
                                                onClick={() => setOpenDeleteApplicationDialog(true)}
                                            >
                                                {props.deleteButtonText}
                                            </SubmitButton>
                                        </Grid>
                                        <Dialog
                                            open={openDeleteApplicationDialog}
                                            onClose={handleCloseDeleteApplicationDialog}
                                        >
                                            <DialogContent>
                                                <DialogContentText>{"Biztosan törli a találatokat?"}</DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <SubmitButton
                                                    onClick={() => {
                                                        handleDeleteButtonCommand();
                                                    }}
                                                >
                                                    {"Törlés"}
                                                </SubmitButton>
                                                <CancelButton
                                                    onClick={() => {
                                                        handleCloseDeleteApplicationDialog();
                                                    }}
                                                >
                                                    {"Mégse"}
                                                </CancelButton>
                                            </DialogActions>
                                        </Dialog>
                                    </>
                                )}
                                {props.newButtonText ? (
                                    <Grid item>
                                        <Link to={props.to} onClick={props.onClick}>
                                            <SubmitButton
                                                classes={{
                                                    root: classes.newButton,
                                                }}
                                                startIcon={props.newButtonIcon}
                                            >
                                                {props.newButtonText}
                                            </SubmitButton>
                                        </Link>
                                    </Grid>
                                ) : (
                                    ""
                                )}
                                {props.noExportButton ? (
                                    ""
                                ) : (
                                    <Grid item>
                                        <SplitButton
                                            options={options}
                                            placement="top"
                                            exportcontext={props.exportcontext}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionActions>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Grid item container xs={12}>
                                <div className="ag-theme-material agGridTable" style={{ width: "100%" }}>
                                    <AgGrid
                                        pagination={!props.isPaginated && !props.hidePagination}
                                        paginationPageSize={
                                            props.hidePagination === false || props.hidePagination === undefined
                                                ? 25
                                                : false
                                        }
                                        domLayout="autoHeight"
                                        defaultColDef={defaultColDef}
                                        frameworkComponents={frameworkComponents}
                                        enableCellTextSelection={true}
                                        getRowNodeId={(data) => data.id}
                                        onGridReady={onGridReady}
                                        {...props}
                                    />
                                </div>
                                {props.isPaginated && (
                                    <TablePaginator
                                        searchfunc={props.searchfunc}
                                        info={props.info}
                                        setInfo={props.setInfo}
                                    />
                                )}
                                <Grid className={classes.detailButton}>{props.submitButton}</Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
    );
}
