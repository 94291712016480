import { AccordionDetails, makeStyles } from "@material-ui/core";
import AccordionBasic from "@material-ui/core/Accordion";
import React, { useState } from "react";
import Expand from "../Icons/Expand";
import AccordionSummary from "./AccordionSummary";

const useStyles = makeStyles(() => ({
    accordionDetails: {
        display: "block",
    },
}));

/**
 *
 * @param expanded - alapból legyen-e lenyitva (default: true)
 * @param title - címe
 */

export default function Accordion(props) {
    const [expanded, setExpanded] = useState(props.expanded === null ? true : props.expanded);
    const classes = useStyles();

    return (
        <AccordionBasic expanded={expanded} onChange={() => setExpanded(!expanded)}>
            <AccordionSummary expandIcon={<Expand />} text={props.title} />
            <AccordionDetails className={classes.accordionDetails}>{props.children}</AccordionDetails>
        </AccordionBasic>
    );
}
