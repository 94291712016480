import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";
import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/statistics/active-customers-by-financiers-and-permissions", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const consumptionDataByPaymentsService = {
    filter,
    exportInitiate: templateExportInitiate("statistics/active-customers-by-financiers-and-permissions"),
    exportCheck: templateExportCheck("statistics/active-customers-by-financiers-and-permissions"),
    exportDownload: templateExportDownload("statistics/active-customers-by-financiers-and-permissions"),
};

export default consumptionDataByPaymentsService;
