import { withStyles } from "@material-ui/core";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import colors from "../../styles/colors.js";

const TrackChanges = withStyles({
    root: {
        backgroundColor: "#FFA500",
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.orangeHover,
        },
    },
})(TrackChangesIcon);

export default TrackChanges;
