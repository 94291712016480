import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import taxActions from "../../../store/tax/actions";
import Autocomplete from "../../Common/Autocomplete";
import { NO_RESPONSIBILITY_TEXT, PERMISSIONS, TAX_STATUS_OPTIONS } from "../../../utils/AppConst";
import axios from "axios";
import { history } from "../../../store";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import { hasRole } from "../../../utils/User";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function Tax(props) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, filterDataFinanciers, filterStorageKey } = props;

    useEffect(() => {
        let storageFilter = filterStorageGet(filterStorageKey);
        if (hasRole(PERMISSIONS.FINANCIER)) {
            axios.all([
                props.changeForm(storageFilter, "filter"),
                setInfo(props, "sort", { name: "asc" }, "filter", storageFilter)
            ]);
        } else if (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.C_TARIFF)) {
            axios.all([
                props.changeForm(storageFilter, "filter"),
                setInfo(props, "sort", { name: "asc" }, "filter", storageFilter),
                props.getFilterFinanciers()
            ]);
        } else {
            history.push("/403");
        }
    }, []);

    const financierOptions = filterDataFinanciers?.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    function TaxStatusCellRenderer(params) {
        const taxStatusOption = TAX_STATUS_OPTIONS?.find((element) => element.value == params.data.taxStatus);
        return taxStatusOption ? taxStatusOption.label : null;
    }

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Ügyfél neve", field: "name" },
            { headerName: "Finanszírozó", field: "financier" },
            {
                headerName: "Adóstátusz",
                field: "taxStatus",
                cellRenderer: "taxStatusCellRenderer",
            },
            { headerName: "Adóstátusz kezdete", field: "taxStatusStartedAt" },
            {
                headerName: "Adóstátusz megállapítása",
                field: "taxStatusDeterminedAt",
            },
        ],
        rowData: data,
        loading: tableLoading,
        frameworkComponents: {
            taxStatusCellRenderer: TaxStatusCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    return (
        <ListPageTemplate
            header={{
                title: "Adó",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    "clients-list": "Adó",
                },
            }}
            filter={{
                onSubmit: () => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                },
                defaultFormAction: () => {
                    props.defaultForm();
                    props.getFilterFinanciers();
                    filterStorageSave(filterStorageKey, {});
                    setInfo(props, "filter", {}, "sort", { lastName: "asc" });
                },
                form: filter,
                name: "filter",
                changeform: props.changeForm,
            }}
            table={table}
            loading={pageLoading}
            statPageDetail={NO_RESPONSIBILITY_TEXT}
        >
            <TextField label="MAVIR ID" name="mavirId" value={filter?.mavirId || ""} format={{ xs: 12, md: 3 }} />
            <TextField label="Ügyfél neve" name="name" value={filter?.name || ""} format={{ xs: 12, md: 3 }} />
            {!hasRole(PERMISSIONS.FINANCIER) && (
                <Autocomplete
                    value={filter?.financier || []}
                    options={financierOptions}
                    id="financier"
                    name="financier"
                    label="Finanszírozó"
                    format={{ xs: 12, md: 3 }}
                />
            )}
            <Autocomplete
                value={filter?.taxStatus || []}
                options={TAX_STATUS_OPTIONS}
                id="taxStatus"
                name="taxStatus"
                label="Adóstátusz"
                format={{ xs: 12, md: 3 }}
            />
            <TextField
                label="Adóstátusz kezdete (-tól)"
                name="taxStatusStartedAtFrom"
                value={filter?.taxStatusStartedAtFrom || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Adóstátusz kezdete (-ig)"
                name="taxStatusStartedAtTo"
                value={filter?.taxStatusStartedAtTo || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Adóstátusz megállapítása (-tól)"
                name="taxStatusDeterminedAtFrom"
                value={filter?.taxStatusDeterminedAtFrom || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
            <TextField
                label="Adóstátusz megállapítása (-ig)"
                name="taxStatusDeterminedAtTo"
                value={filter?.taxStatusDeterminedAtTo || ""}
                format={{ xs: 12, md: 3 }}
                InputLabelProps={{ shrink: true }}
                type="date"
            />
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, filterDataFinanciers, filterStorageKey } = state.tax;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        tableInfo,
        token,
        filterDataFinanciers,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: taxActions.changeForm,
    defaultForm: taxActions.defaultForm,
    search: taxActions.filter,
    exportInitiate: taxActions.exportInitiate,
    exportCheck: taxActions.exportCheck,
    exportDownload: taxActions.exportDownload,
    getFilterFinanciers: taxActions.getFilterFinanciers,
    firstFetch: taxActions.firstFetch,
    setInfo: taxActions.setInfo,
};

export default connect(mapState, actionCreators)(Tax);
