import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "..";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/consumption-data/monthly", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const employersService = {
    filter,
    exportInitiate: templateExportInitiate("consumption-data/monthly"),
    exportCheck: templateExportCheck("consumption-data/monthly"),
    exportDownload: templateExportDownload("consumption-data/monthly"),
};

export default employersService;
