import { types } from "./types";

const initialState = {
    limit: null,
};

export function exportSettings(state = initialState, action) {
    switch (action.type) {
        case types.GET_XLSX_LIMIT_REQUEST:
            return {
                ...state,
            };
        case types.GET_XLSX_LIMIT_SUCCESS:
            return {
                ...state,
                limit: action.data?.data?.limit
            };
        case types.GET_XLSX_LIMIT_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
