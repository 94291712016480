import { makeStyles } from "@material-ui/core";
import TextField from "../../Common/TextField";
import SubmitButton from "../../Common/SubmitButton";
import { connect } from "react-redux";
import loginActions from "../../../store/login/actions";
import Loading from "../../Common/Loading";
import Form from "../../Common/Form";
import OffLayoutTemplate from "../../Common/OffLayoutTemplate";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {useEffect} from "react";
import colors from "../../../styles/colors";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
        zIndex: 1,
        background: "rgba(255,255,255,0.7)"
    },
    submit: {
        margin: theme.spacing(3, 0, 0, 0),
    },
    button: {
        height: 40,
    },
    forward: {
        zIndex: 1
    },
    link: {
        textDecoration: "none",
        fontWeight: "bold",
        color: colors.filter,
        '&:hover': {
            color: colors.filterHover,
            textDecoration: "underline"
        }
    },
    captchaError: {
        fontSize: "small",
        color: colors.red,
    },
}));

function LoginForm(props) {
    const { form, loading, validation, reCaptcha, desiredUrl } = props;
    const classes = useStyles();
    const { executeRecaptcha } = useGoogleReCaptcha();
    //const [isCaptchaLoadError, setIsCapchaLoadError] = useState(false);
    const showRECAPTCHA = process.env.REACT_APP_RECAPTCHA_KEY !== process.env.REACT_APP_RECAPTCHA_FAKE;

    useEffect(() => {
        if (!showRECAPTCHA) {
            props.changeForm(process.env.REACT_APP_RECAPTCHA_FAKE, "reCaptcha");
        }
    }, [showRECAPTCHA]);


    /*
    if(showRECAPTCHA && !executeRecaptcha){ // FIXME: ezt 2-3 másodperc után kellene valahogy futtatni
        setIsCapchaLoadError(true);
    }
     */

    const handleSubmit = async () => {
        let loginParams = { ...form, reCaptcha };
        if (showRECAPTCHA && !!executeRecaptcha) {
            const token = await executeRecaptcha("login");
            await props.changeForm(token, "reCaptcha");
            loginParams = { ...form, reCaptcha: token };
        }
        props.login(loginParams, desiredUrl).catch((error) => {
            if (error?.response?.status === 401) {
                props.changeForm(
                    {
                        ...form,
                        errors: [
                            { name: "password", label: "Helytelen felhasználónév vagy jelszó" },
                            { name: "username", label: "" },
                        ],
                    },
                    "form"
                );
            }
            if (error?.response?.status === 422) {
                props.changeForm(
                    {
                        ...form,
                        errors: [{ name: "password", label: error?.response?.data?.message }],
                    },
                    "form"
                );
            }
            if (error?.response?.status === 429) {
                props.changeForm(
                    {
                        ...form,
                        errors: [{ name: "password", label: error?.response?.data?.message }],
                    },
                    "form"
                );
            }
        });
    };

    if (loading) return <Loading />;

    return (
        <OffLayoutTemplate title="Belépés">
            <Form
                className={classes.form}
                onSubmit={handleSubmit}
                form={form}
                changeform={props.changeForm}
                name="form"
                validation={validation}
            >
                <TextField
                    id="username"
                    name="username"
                    autoComplete="username"
                    value={form.username || ""}
                    label="Felhasználónév"
                    format={{ xs: 12 }}
                    autoFocus
                />
                <TextField
                    id="password"
                    name="password"
                    autoComplete="password"
                    value={form.password || ""}
                    label="Jelszó"
                    type="password"
                    format={{ xs: 12 }}
                />
                <a 
                    href="https://pma.mavir.hu/"
                    format={{ 
                        xs: 12, 
                        className: classes.forward, 
                        container: true,
                        justify: "flex-end"  
                    }}
                    target="_blank"
                    rel="noreferrer"
                    className={classes.link}
                >
                    Jelszóváltoztatás
                </a>
                {showRECAPTCHA && !executeRecaptcha &&
                    <span className={classes.captchaError}>
                        Figyelem! A Google Recaptcha szolgáltatásához nem sikerült kapcsolódni a böngészőből. A bejelentkezés nem lehetséges!
                    </span>
                }
                <SubmitButton
                    type="submit"
                    className={classes.submit}
                    format={{ xs: 12 }}
                    button="true"
                    fullWidth
                    classes={{
                        root: classes.button,
                    }}
                >
                    Belépés
                </SubmitButton>
            </Form>
        </OffLayoutTemplate>
    );
}

function mapState(state) {
    const { form, loading, validation, reCaptcha, desiredUrl } = state.authentication;
    return { form, loading, validation, reCaptcha, desiredUrl };
}

const actionCreators = {
    changeForm: loginActions.changeForm,
    login: loginActions.login,
};

export default connect(mapState, actionCreators)(LoginForm);
