import { ConnectedRouter } from "connected-react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { history } from "./store";
import "./App.scss";
import Layout from "./Components/Layout";
import PrivateRoute from "./Components/Common/PrivateRoute";
import Main from "./Components/Modules/Main";
import Login from "./Components/Modules/Login";
import ApplicationFiling from "./Components/Applications/ApplicationFiling";
import ApplicationFilingRecordingData from "./Components/Applications/ApplicationFiling/ApplicationFilingRecordingData";
import ApplicationsList from "./Components/Applications/ApplicationsList";
import ApplicationDetails from "./Components/Applications/ApplicationDetails";
import Users from "./Components/Operators/Users/";
import Employers from "./Components/Operators/Employers/";
import Providers from "./Components/Operators/Providers/";
import ConsumptionPeriods from "./Components/Operators/ConsumptionPeriods/";
import CreateProvider from "./Components/Operators/Providers/CreateProvider";
import CreateChildProvider from "./Components/Operators/Providers/CreateChildProvider";
import EditProvider from "./Components/Operators/Providers/EditProvider";
import CloseProvider from "./Components/Operators/Providers/CloseProvider";
import CreateEmployer from "./Components/Operators/Employers/CreateEmployer";
import EditEmployer from "./Components/Operators/Employers/EditEmployer";
import Clients from "./Components/Clients/Clients";
import ClientDetails from "./Components/Clients/ClientDetails";
import ConsumptionPlace from "./Components/Clients/ConsumptionPlace";
import Tax from "./Components/Clients/Tax/";
import FixedTimeClients from "./Components/Clients/FixedTimeClients/";
import FailedLoginAttempts from "./Components/Operators/FailedLoginAttempts";
import Commands from "./Components/Operators/Commands";
import EmploymentTerminations from "./Components/Clients/EmploymentTerminations";
import AddForm from "./Components/Clients/EmploymentTerminations/AddForm";
import BlackList from "./Components/Clients/BlackList";
import BlackListAdd from "./Components/Clients/BlackList/BlackListAdd";
import BlackListAddNew from "./Components/Clients/BlackList/BlackListAddNew";
import ConsumptionData from "./Components/Finance/ConsumptionData";
import FilesList from "./Components/Finance/FilesList";
import MonthlyConsumptionData from "./Components/Finance/MonthlyConsumptionData";
import ConsumptionDataSummary from "./Components/Finance/ConsumptionDataSummary";
import ConsumptionDataValidator from "./Components/Finance/ConsumptionDataValidator";
import BatchCancellation from "./Components/Finance/BatchCancellation";
import AuditOfFinancingAccounts from "./Components/Finance/AuditOfFinancingAccounts";
import Statistics from "./Components/Statistics";
import EditUserForm from "./Components/Operators/Users/EditUserForm";
import ApplicationReceipt from "./Components/Applications/ApplicationReceipt";
import ProviderValidation from "./Components/Applications/ProviderValidation";
import ScrollToTop from "./utils/ScrollToTop";
import SystemLog from "./Components/Operators/SystemLog";
import CTariff from "./Components/Statistics/Statements/CTariff";
import GKMOne from "./Components/Statistics/Statements/GKM/GKMOne";
import GKMTwo from "./Components/Statistics/Statements/GKM/GKMTwo";
import GKMThree from "./Components/Statistics/Statements/GKM/GKMThree";
import GKMFour from "./Components/Statistics/Statements/GKM/GKMFour";
import GKMFive from "./Components/Statistics/Statements/GKM/GKMFive";
import MEKH from "./Components/Statistics/Statements/MEKH";
import ActiveClients from "./Components/Statistics/Statements/Other/ActiveClients";
import AddConsumptionPlace from "./Components/Clients/ConsumptionPlace/AddConsumptionPlace";
import Permissions from "./Components/AdmissedApplications/Permissions";
import Cancellations from "./Components/AdmissedApplications/Cancellations";
import DeniedApplications from "./Components/AdmissedApplications/DeniedApplications";
import CreateChildEmployer from "./Components/Operators/Employers/CreateChildEmployer";
import loginActions from "./store/login/actions";
import { connect } from "react-redux";
import NotFound from "./Components/ErrorPages/NotFound";
import ServiceUnavailable from "./Components/ErrorPages/ServiceUnavailable";
import AccessDenied from "./Components/ErrorPages/AccessDenied";
import CloseEmployer from "./Components/Operators/Employers/CloseEmployer";
import { useEffect, useState } from "react";
import loginService from "./services/login";
import { DO_REFRESH_INTERVAL, REFRESH_INTERVAL_TIME } from "./utils/AppConst";
import GDPR from "./Components/Operators/GDPR";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ServiceProviderSelector from "./Components/Modules/ServiceProviderSelector";
import CustomerModificationImport from "./Components/Operators/CustomerModificationImport";

function App(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(loginService.getTokenValidity());

    if (!isLoggedIn && window.location.pathname !== "/login") props.saveDesiredUrl(window.location.pathname);

    useEffect(() => {
        setInterval(() => {
            if (!loginService.getTokenValidity()) {
                if (loginService.getRefreshTokenValidity()) {
                    props
                        .refreshToken()
                        .then(() => setIsLoggedIn(true))
                        .catch(() => {
                            setIsLoggedIn(false);
                            loginService.logout();
                        });
                } else {
                    setIsLoggedIn(false);
                    loginService.logout();
                }
            } else {
                if (new Date() - loginService.getRefreshTokenCreated() > DO_REFRESH_INTERVAL) {
                    props
                        .refreshToken()
                        .then(() => setIsLoggedIn(true))
                        .catch(() => {
                            setIsLoggedIn(false);
                            loginService.logout();
                        });
                } else {
                    setIsLoggedIn(true);
                }
            }
        }, REFRESH_INTERVAL_TIME);
    }, []);

    return (
        <>
            <ConnectedRouter history={history}>
                <ScrollToTop />
                {!isLoggedIn && <Redirect to="/login" />}
                <Switch>
                    <Route
                        path="/login"
                        exact
                        render={() =>
                            !isLoggedIn ? (
                                <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
                                    <Login />
                                </GoogleReCaptchaProvider>
                            ) : (
                                <Redirect to="/" />
                            )
                        }
                    />
                    <PrivateRoute layout={Layout} path="/" exact component={Main} />
                    <PrivateRoute layout={Layout} path="/application-filing" exact component={ApplicationFiling} />
                    <PrivateRoute
                        layout={Layout}
                        path="/application-filing/recording-data/:id"
                        exact
                        component={ApplicationFilingRecordingData}
                    />
                    <PrivateRoute layout={Layout} path="/applications-list" exact component={ApplicationsList} />
                    <PrivateRoute layout={Layout} path="/application-receipt" exact component={ApplicationReceipt} />
                    <PrivateRoute
                        layout={Layout}
                        path="/application-receipt/:barcode"
                        exact
                        component={ApplicationReceipt}
                    />
                    <PrivateRoute layout={Layout} path="/applications/:id" exact component={ApplicationDetails} />
                    <PrivateRoute layout={Layout} path="/provider-validation" exact component={ProviderValidation} />
                    <PrivateRoute layout={Layout} path="/application-permissions" exact component={Permissions} />
                    <PrivateRoute layout={Layout} path="/application-cancellations" exact component={Cancellations} />
                    <PrivateRoute layout={Layout} path="/applications-denied" exact component={DeniedApplications} />
                    <PrivateRoute layout={Layout} path="/users" exact component={Users} />
                    <PrivateRoute layout={Layout} path="/users/edit/:id" exact component={EditUserForm} />
                    <PrivateRoute layout={Layout} path="/employers" exact component={Employers} />
                    <PrivateRoute layout={Layout} path="/employers/new" exact component={CreateEmployer} />
                    <PrivateRoute layout={Layout} path="/employers/edit/:id" exact component={EditEmployer} />
                    <PrivateRoute
                        layout={Layout}
                        path="/employers/new-child/:id"
                        exact
                        component={CreateChildEmployer}
                    />
                    <PrivateRoute layout={Layout} path="/employers/close/:id" exact component={CloseEmployer} />
                    <PrivateRoute layout={Layout} path="/providers" exact component={Providers} />
                    <PrivateRoute layout={Layout} path="/providers/new" exact component={CreateProvider} />
                    <PrivateRoute
                        layout={Layout}
                        path="/providers/new-child/:id"
                        exact
                        component={CreateChildProvider}
                    />
                    <PrivateRoute layout={Layout} path="/providers/edit/:id" exact component={EditProvider} />
                    <PrivateRoute layout={Layout} path="/providers/close/:id" exact component={CloseProvider} />
                    <PrivateRoute layout={Layout} path="/clients" exact component={Clients} />
                    <PrivateRoute layout={Layout} path="/clients/:id" exact component={ClientDetails} />
                    <PrivateRoute
                        layout={Layout}
                        path="/clients/:mavirId/:permissionId/consumption-place/:placeIndex"
                        exact
                        component={ConsumptionPlace}
                    />
                    <PrivateRoute
                        layout={Layout}
                        path="/clients/:id/add-consumption-place"
                        exact
                        component={AddConsumptionPlace}
                    />
                    <PrivateRoute layout={Layout} path="/tax" exact component={Tax} />
                    <PrivateRoute layout={Layout} path="/failed-login-attempts" exact component={FailedLoginAttempts} />
                    <PrivateRoute layout={Layout} path="/system-log" exact component={SystemLog} />
                    <PrivateRoute layout={Layout} path="/customer-modification-import-list" exact component={CustomerModificationImport} />
                    <PrivateRoute layout={Layout} path="/commands" exact component={Commands} />
                    <PrivateRoute
                        layout={Layout}
                        path="/employment-terminations"
                        exact
                        component={EmploymentTerminations}
                    />
                    <PrivateRoute layout={Layout} path="/employment-terminations/add" exact component={AddForm} />
                    <PrivateRoute layout={Layout} path="/fixed-time-clients" exact component={FixedTimeClients} />
                    <PrivateRoute layout={Layout} path="/blacklist" exact component={BlackList} />
                    <PrivateRoute layout={Layout} path="/blacklist/add" exact component={BlackListAdd} />
                    <PrivateRoute layout={Layout} path="/blacklist/add/new" exact component={BlackListAddNew} />
                    <PrivateRoute layout={Layout} path="/consumption-data" exact component={ConsumptionData} />
                    <PrivateRoute layout={Layout} path="/files-list" exact component={FilesList} />
                    <PrivateRoute layout={Layout} path="/gdpr" exact component={GDPR} />
                    <PrivateRoute
                        layout={Layout}
                        path="/monthly-consumption-data"
                        exact
                        component={MonthlyConsumptionData}
                    />
                    <PrivateRoute layout={Layout} path="/batch-cancellation" exact component={BatchCancellation} />
                    <PrivateRoute
                        layout={Layout}
                        path="/consumption-data-summary"
                        exact
                        component={ConsumptionDataSummary}
                    />
                    <PrivateRoute
                        layout={Layout}
                        path="/consumption-data-validator"
                        exact
                        component={ConsumptionDataValidator}
                    />
                    <PrivateRoute layout={Layout} path="/consumption-periods" exact component={ConsumptionPeriods} />
                    <PrivateRoute
                        layout={Layout}
                        path="/audit-of-financing-accounts"
                        exact
                        component={AuditOfFinancingAccounts}
                    />
                    <PrivateRoute layout={Layout} path="/statistics/:category" exact component={Statistics} />
                    <PrivateRoute layout={Layout} path="/statistics/c-tariff/3-2-2" exact component={CTariff} />
                    <PrivateRoute layout={Layout} path="/statistics/gkm/4-1-1" exact component={GKMOne} />
                    <PrivateRoute layout={Layout} path="/statistics/gkm/4-1-2" exact component={GKMTwo} />
                    <PrivateRoute layout={Layout} path="/statistics/gkm/4-1-3" exact component={GKMThree} />
                    <PrivateRoute layout={Layout} path="/statistics/gkm/4-1-4" exact component={GKMFour} />
                    <PrivateRoute layout={Layout} path="/statistics/gkm/4-1-5" exact component={GKMFive} />
                    <PrivateRoute layout={Layout} path="/statistics/mekh/4-2-2" exact component={MEKH} />
                    <PrivateRoute layout={Layout} path="/statistics/other/1-1" exact component={ActiveClients} />
                    <PrivateRoute layout={Layout} path="/provider-selector" exact component={ServiceProviderSelector} />
                    <PrivateRoute
                        layout={Layout}
                        path="/logout"
                        exact
                        component={() => {
                            setIsLoggedIn(false);
                            loginService.logout();
                            return <Redirect to="/login" />;
                        }}
                    />
                    <Route path="/404" exact component={NotFound} />
                    <Route path="/403" exact component={AccessDenied} />
                    <Route path="/503/gdpr" exact component={() => <ServiceUnavailable description="GDPR törlés van folyamatban. Kérjük, próbálkozzon később." />} />
                    <Route component={NotFound} />
                </Switch>
            </ConnectedRouter>
        </>
    );
}

function mapState(state) {
    const { desiredUrl } = state.authentication;
    return { desiredUrl };
}

const actionCreators = {
    refreshToken: loginActions.refreshToken,
    changeForm: loginActions.changeForm,
    saveDesiredUrl: loginActions.saveDesiredUrl,
};

export default connect(mapState, actionCreators)(App);
