import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateSetInfo,
} from "..";
import filesListService from "../../services/filesList";
import downloadBlob from "../../utils/downloadBlob";
import { types } from "./types";

function cancel(id) {
    return (dispatch) => {
        dispatch({ type: types.CANCEL_REQUEST });
        return filesListService.cancel(id).then(
            (data) => {
                dispatch({ type: types.CANCEL_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CANCEL_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function upload(form) {
    return (dispatch) => {
        dispatch({ type: types.UPLOAD_REQUEST, form });
        return filesListService.upload(form).then(
            (data) => {
                dispatch({ type: types.UPLOAD_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.UPLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function download(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST });
        return filesListService.download(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function downloadReply(id, fileName) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REPLY_REQUEST });
        return filesListService.downloadReply(id).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_REPLY_SUCCESS, data });
                downloadBlob(data.data, fileName);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_REPLY_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getLog(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_LOG_REQUEST });
        return filesListService.getLog(id).then(
            (data) => {
                dispatch({ type: types.GET_LOG_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_LOG_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, filesListService),
    exportInitiate: templateExportInitiate(types, filesListService),
    exportCheck: templateExportCheck(types, filesListService),
    exportDownload: templateExportDownload(types, filesListService),
    setInfo: templateSetInfo(types),
    firstFetch: templateFirstFetch(types, filesListService),
    cancel,
    upload,
    download,
    downloadReply,
    getLog,
};

export default actions;
