import React, { useEffect } from "react";
import ListPageTemplate from "../../Common/ListPageTemplate";
import Title from "../../Common/Title";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import monthlyConsumptionDataActions from "../../../store/monthlyConsumptionData/actions";
import Autocomplete from "../../Common/Autocomplete";
import providersActions from "../../../store/providers/actions";
import employersActions from "../../../store/employers/actions";
import Select from "../../Common/Select";
import notificationActions from "../../../store/notification/actions";
import { ARRAY_OPTIONS, CONSUMPTION_PLACE_OPTIONS, FILTER_MONTH_OPTIONS } from "../../../utils/AppConst";
import consumptionDataActions from "../../../store/consumptionData/actions";
import axios from "axios";
import { hasServiceProvider } from "../../../utils/User";
import { setInfo } from "../../../utils/FilterHelper";
import ConsumptionPlaceTypeCellRenderer from "../../../utils/cellRenderer/ConsumptionPlaceTypeCellRenderer";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function MonthlyConsumptionData(props) {
    const { filter, data, pageLoading, tableLoading, providers, token, years, tableInfo, employers, filterStorageKey } = props;

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Év", field: "year" },
            { headerName: "Hónap", field: "month" },
            {
                headerName: "Fogyasztási hely",
                field: "consumptionPlaceType",
                cellRenderer: "consumptionPlaceCellRenderer",
            },
            { headerName: "Finanszírozó", field: "financier.name" },
            { headerName: "Mennyiség (kWh)", field: "quantity" },
            { headerName: "Alap ár (Ft)", field: "grossPrice" },
            { headerName: "Fogyasztói ár (Ft)", field: "consumerGrossPrice" },
            { headerName: "Finanszírozói  ár (Ft)", field: "financierGrossPrice" },
        ],
        autosize: "true",
        rowData: data,
        frameworkComponents: {
            consumptionPlaceCellRenderer: ConsumptionPlaceTypeCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
        loading: tableLoading,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"),
            props.getProviders(),
            props.getEmployers(),
            props.getYears(),
            setInfo({...props, search: props.firstFetch}, "filter", storageFilter)
        ]).then(
            axios.spread(() => {
                props.setLoading(false);
            })
        );
    }, []);

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    const employerOptions = employers.map((employer) => ({
        value: employer.id,
        label: employer.name,
    }));

    const yearOptions = years.map((year) => {
        return {
            value: year.id,
            label: `${year.name}`,
        };
    });

    const formProps = {
        form: filter,
        name: "filter",
        changeform: props.changeForm,
    };

    const filterProps = {
        defaultForm: props.defaultForm,
        form: filter,
        name: "filter",
        changeform: props.changeForm,
        onSubmit: () => {
            filterStorageSave(filterStorageKey, filter);
            setInfo(props, "filter", filter)
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            props.search({});
        },
    };

    return (
        <ListPageTemplate
            header={{
                title: "Havi fogyasztási adatok",
                breadcrumbs: {
                    finance: "Pénzügyek",
                    "monthly-consumption-data": "Havi fogyasztási adatok",
                },
            }}
            table={table}
            filter={filterProps}
            loading={pageLoading}
        >
            <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 6 }} />
            <Autocomplete
                multiple
                value={filter.financier || []}
                options={employerOptions}
                id="financier"
                name="financier"
                format={{ xs: 12, md: 6 }}
                label="Finanszírozó"
            />
            {hasServiceProvider() ? (
                <TextField disabled label="Szolgáltató" format={{ xs: 12 }} value={hasServiceProvider().name} />
            ) : (
                <Autocomplete
                    multiple
                    value={filter.serviceProvider || []}
                    options={providerOptions}
                    id="serviceProvider"
                    name="serviceProvider"
                    format={{ xs: 12 }}
                    label="Szolgáltató"
                />
            )}
            <Autocomplete
                multiple
                value={filter.year || []}
                options={yearOptions}
                id="year"
                name="year"
                label="Év"
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                value={filter.month || []}
                options={FILTER_MONTH_OPTIONS}
                id="month"
                name="month"
                label="Hónap"
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                value={filter.consumptionPlaceType || []}
                options={CONSUMPTION_PLACE_OPTIONS}
                id="consumptionPlaceType"
                name="consumptionPlaceType"
                label="Fogy. hely"
                format={{ xs: 12, md: 6 }}
            />
            <Select
                value={filter.block || ""}
                optionList={ARRAY_OPTIONS}
                id="block"
                name="block"
                selectLabel="Tömb"
                format={{ xs: 12, md: 6 }}
            />
            <Title title="Mennyiség (kWh)" format={{ xs: 12 }} {...formProps}>
                <TextField
                    label="min."
                    name="quantityMin"
                    value={filter.quantityMin || ""}
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="max."
                    name="quantityMax"
                    value={filter.quantityMax || ""}
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Alapár (Ft)" format={{ xs: 12 }} {...formProps}>
                <TextField label="min." name="priceMin" value={filter.priceMin || ""} format={{ xs: 12, md: 6 }} />
                <TextField label="max." name="priceMax" value={filter.priceMax || ""} format={{ xs: 12, md: 6 }} />
            </Title>
            <Title title="Fogyasztói ár (Ft)" format={{ xs: 12 }} {...formProps}>
                <TextField
                    label="min."
                    name="consumerPriceMin"
                    value={filter.consumerPriceMin || ""}
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="max."
                    name="consumerPriceMax"
                    value={filter.consumerPriceMax || ""}
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
            <Title title="Finanszírozói ár (Ft)" format={{ xs: 12 }} {...formProps}>
                <TextField
                    label="min."
                    name="financierPriceMin"
                    value={filter.financierPriceMin || ""}
                    format={{ xs: 12, md: 6 }}
                />
                <TextField
                    label="max."
                    name="financierPriceMax"
                    value={filter.financierPriceMax || ""}
                    format={{ xs: 12, md: 6 }}
                />
            </Title>
        </ListPageTemplate>
    );
}

function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, filterStorageKey } = state.monthlyConsumptionData;
    const providers = state.providers.data;
    const employers = state.employers.data;
    const years = state.consumptionData.years;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        providers,
        years,
        tableInfo,
        employers,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: monthlyConsumptionDataActions.changeForm,
    search: monthlyConsumptionDataActions.filter,
    firstFetch: monthlyConsumptionDataActions.firstFetch,
    defaultForm: monthlyConsumptionDataActions.defaultForm,
    getProviders: () => providersActions.filter({}),
    getEmployers: () => employersActions.filter({}),
    getYears: consumptionDataActions.getYears,
    setLoading: monthlyConsumptionDataActions.setLoading,
    addNotification: notificationActions.addNotification,
    exportInitiate: monthlyConsumptionDataActions.exportInitiate,
    exportCheck: monthlyConsumptionDataActions.exportCheck,
    exportDownload: monthlyConsumptionDataActions.exportDownload,
    setInfo: monthlyConsumptionDataActions.setInfo,
};

export default connect(mapState, actionCreators)(MonthlyConsumptionData);
