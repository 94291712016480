import React, { useEffect } from "react";
import { connect } from "react-redux";
import deniedApplicationsActions from "../../../store/deniedApplications/actions";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import notificationActions from "../../../store/notification/actions";
import DeniedApplicationsTab from "./DeniedApplicationsTab";
import { hasRole } from "../../../utils/User";
import permissionsActions from "../../../store/permissions/actions";
import { PERMISSIONS } from "../../../utils/AppConst";
import usersActions from "../../../store/users/actions";
import {filterStorageGet} from "../../../utils/FilterStorage";
import isEmpty from "../../../utils/isEmpty";

function DeniedApplications(props) {
    const {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        currentTab,
        contactFilter,
        providerFilter,
        users,
    } = props;

    useEffect(() => {
        const contactFilterStorageKey = filterStorageGet(props.contactFilterStorageKey);
        const providerFilterStorageKey = filterStorageGet(props.providerFilterStorageKey);
        if (!isEmpty(providerFilterStorageKey)) props.changeForm(providerFilterStorageKey, "providerFilter");
        if (!isEmpty(contactFilterStorageKey)) props.changeForm(contactFilterStorageKey, "contactFilter");
        props.getUsers();
        if (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.EMPLOYER_CONTACT) 
            || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER)) {
                props.searchContact({filter: contactFilter, sort: { createdAt: "desc" }});
        } 
        if (hasRole(PERMISSIONS.ADMIN) || hasRole(PERMISSIONS.SERVICE_PROVIDER)) {
            props.searchProvider({filter: providerFilter, sort: { createdAt: "desc" }});
        }
    }, []);

    const tabContents = [
        {
            label: "Szolgáltatói fájlok",
            value: (
                <DeniedApplicationsTab
                    name="providerFilter"
                    filter={providerFilter}
                    data={providerData}
                    search={props.searchProvider}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormProvider}
                    filterStorageKey={props.providerFilterStorageKey}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateProvider}
                    exportCheck={props.exportCheckProvider}
                    exportDownload={props.exportDownloadProvider}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={provider}
                    setInfo={props.setProviderInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="rejection"
                    subType="service_provider"
                />
            ),
        },
        {
            label: "Kapcsolattartói fájlok",
            value: (
                <DeniedApplicationsTab
                    name="contactFilter"
                    filter={contactFilter}
                    data={contactData}
                    search={props.searchContact}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormContact}
                    filterStorageKey={props.contactFilterStorageKey}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateContact}
                    exportCheck={props.exportCheckContact}
                    exportDownload={props.exportDownloadContact}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={contact}
                    setInfo={props.setContactInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="rejection"
                    subType="contact"
                />
            ),
        },
    ];

    function renderTabs() {
        if (
            hasRole(PERMISSIONS.ADMIN) || 
            (
                hasRole(PERMISSIONS.SERVICE_PROVIDER) && 
                (hasRole(PERMISSIONS.EMPLOYER_CONTACT) || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER))
            )
        ) {
            return <TabsCompleted tabContents={tabContents} currentTab={currentTab} />;
        }
        
        if (hasRole(PERMISSIONS.SERVICE_PROVIDER)) {
            return (
                <DeniedApplicationsTab
                    name="providerFilter"
                    filter={providerFilter}
                    data={providerData}
                    search={props.searchProvider}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormProvider}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateProvider}
                    exportCheck={props.exportCheckProvider}
                    exportDownload={props.exportDownloadProvider}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={provider}
                    setInfo={props.setProviderInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="rejection"
                    subType="service_provider"
                />
            );
        }
        if (hasRole(PERMISSIONS.EMPLOYER_CONTACT) || hasRole(PERMISSIONS.PENSIONER_CONTACT) || hasRole(PERMISSIONS.FINANCIER)) {
            return (
                <DeniedApplicationsTab
                    name="contactFilter"
                    filter={contactFilter}
                    data={contactData}
                    search={props.searchContact}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormContact}
                    changeStatus={props.changeStatus}
                    exportInitiate={props.exportInitiateContact}
                    exportCheck={props.exportCheckContact}
                    exportDownload={props.exportDownloadContact}
                    download={props.download}
                    addNotification={props.addNotification}
                    info={contact}
                    setInfo={props.setContactInfo}
                    tableLoading={tableLoading}
                    getLog={props.getLog}
                    users={users}
                    type="rejection"
                    subType="contact"
                />
            );
        }
    }

    return (
        <PageTemplate
            header={{
                title: "Jogosultság megállapításon végigment igénylések",
                breadcrumbs: {
                    application: "Igénylések",
                    "provider-validation": "Elbírált igénylések",
                    "denied-application": "Elutasított igénylések",
                },
            }}
            loading={pageLoading}
        >
            {renderTabs()}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        token,
        status,
        file,
        currentTab,
        contactFilter,
        providerFilter,
        providerFilterStorageKey,
        contactFilterStorageKey,
    } = state.deniedApplications;
    const users = state.users.dataPublic;
    return {
        providerData,
        contactData,
        provider,
        contact,
        pageLoading,
        tableLoading,
        token,
        status,
        file,
        currentTab,
        contactFilter,
        providerFilter,
        users,
        providerFilterStorageKey,
        contactFilterStorageKey,
    };
}

const actionCreators = {
    changeForm: deniedApplicationsActions.changeForm,
    searchProvider: deniedApplicationsActions.filterProvider,
    searchContact: deniedApplicationsActions.filterContact,
    download: deniedApplicationsActions.download,
    exportInitiateContact: deniedApplicationsActions.exportInitiateContact,
    exportCheckContact: deniedApplicationsActions.exportCheckContact,
    exportDownloadContact: deniedApplicationsActions.exportDownloadContact,
    exportInitiateProvider: deniedApplicationsActions.exportInitiateProvider,
    exportCheckProvider: deniedApplicationsActions.exportCheckProvider,
    exportDownloadProvider: deniedApplicationsActions.exportDownloadProvider,
    defaultFormProvider: deniedApplicationsActions.defaultFormProvider,
    defaultFormContact: deniedApplicationsActions.defaultFormContact,
    changeStatus: deniedApplicationsActions.changeStatus,
    addNotification: notificationActions.addNotification,
    setContactInfo: deniedApplicationsActions.setContactInfo,
    setProviderInfo: deniedApplicationsActions.setProviderInfo,
    setPageLoading: deniedApplicationsActions.setPageLoading,
    getLog: permissionsActions.getLog,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(DeniedApplications);
