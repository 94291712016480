import { Grid, makeStyles } from "@material-ui/core";
import React, { Children, useEffect, useState } from "react";
import Accordion from "../../../Common/Accordion";
import Address from "../../../Common/Address";
import Autocomplete from "../../../Common/Autocomplete";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";

const useStyles = makeStyles(() => ({
    accordion: {
        marginBottom: "8px",
    },
}));

export default function ReceptionDetailsForm(props) {
    const { form, validation, providers, edit, numberOfPlaces, setNumberOfPlaces, legalBasisType } = props;
    const classes = useStyles();

    const dateOfDeath =
        legalBasisType === "pensioner" ||
        legalBasisType === "pensioners_widow" ||
        legalBasisType === "trade_union_pensioner" ||
        legalBasisType === "trade_union_pensioner_widow";

    const numberOptions = [
        { value: 1, label: "Egy" },
        { value: 2, label: "Kettő" },
    ];

    const places = [
        { value: "primaryConsumptionPlace", label: "Első fogyasztási hely" },
        { value: "secondaryConsumptionPlace", label: "Második fogyasztási hely" },
    ];

    const pretenceOptions = [
        { value: "property", label: "Tulajdon" },
        { value: "rental", label: "Bérlet" },
        { value: "corental", label: "Társbérlet" },
        { value: "usufruct", label: "Haszonélvezet" },
        { value: "apartment", label: "Albérlet" },
    ];

    const consumptionMeters = [
        { id: "consumptionMeter1Reference", station: "consumptionMeter1Reading" },
        { id: "consumptionMeter2Reference", station: "consumptionMeter2Reading" },
        { id: "consumptionMeter3Reference", station: "consumptionMeter3Reading" },
    ];

    const toEditOptions = [
        {
            value: "primaryConsumptionPlaceTermination",
            label: "I. helyen kedvezményes vételezés befejezése vagy megszüntetése",
        },
        {
            value: "secondaryConsumptionPlaceTermination",
            label: "II. helyen kedvezményes vételezés befejezése vagy megszüntetése",
        },
    ];

    function initialState() {
        const tmp = [];
        toEditOptions.map((option) => {
            if (form[option.value]?.type !== undefined && form[option.value]?.type !== null) tmp.push(option);
        });

        return tmp?.length > 0 ? tmp : null;
    }

    const [toEdit, setToEdit] = useState(initialState());

    const providerOptions = providers.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    const frontendValidation = [1, 2, 3].map((i) => ({
        field: `consumptionMeter${i}Reading`,
        type: "consumptionMeter",
    }));

    frontendValidation.push({
        field: "consumerNumber",
        type: "maxLength",
        options: { max: 10 },
    });
    frontendValidation.push({
        field: "reference",
        type: "maxLength",
        options: { max: 9 },
    });

    const formProps = (e) => {
        return {
            name: e.value,
            changeform: props.onChange,
            form: form[e.value],
            validation: validation[e.value].concat(frontendValidation),
        };
    };

    const filter = (e) => (toEdit || []).map((tE) => tE.value).includes(e.value);
    const getContents = edit ? toEditOptions.filter(filter) : places.slice(0, numberOfPlaces);

    //ez szerkeszti a state-ben a toEdit tagot, illetve törli, ha kikerül a kiválasztottak közül:
    useEffect(() => {
        if (toEdit) {
            props.onChange(toEdit, "toEdit");
        }
    }, [toEdit]);

    const typeOptions = [
        {
            value: "change",
            label: "A kedvezményes felhasználási helyek megváltoztatása",
        },
        {
            value: "legal_basis_end",
            label: "A kedvezményes vételezés befejezése a kedvezmény feltételét jelentő használati jogcím megszűnése miatt",
        },
        { value: "other", label: "A kedvezmény igénybevételének megszüntetése" },
    ];

    return (
        <Grid item container>
            <Grid item xs={12}>
                {edit ? (
                    <Autocomplete
                        value={toEdit || []}
                        options={toEditOptions}
                        id="toEdit"
                        name="toEdit"
                        format={{ xs: 12 }}
                        label="Felhasználási hely"
                        onChange={(e, v) => setToEdit(v)}
                    />
                ) : (
                    <Select
                        optionList={numberOptions}
                        selectLabel="Fogyasztási helyek száma"
                        name="numberOfPlaces"
                        value={numberOfPlaces}
                        onChange={(e) => setNumberOfPlaces(e.target.value)}
                    />
                )}
            </Grid>
            {Children.toArray(
                getContents.map((e) => (
                    <Accordion title={e.label} expanded={true} className={classes.accordion}>
                        <Form {...formProps(e)}>
                            {edit && (
                                <Select
                                    optionList={typeOptions}
                                    selectLabel="A kedvezményes vételezés befejezésének vagy megszüntetésének oka:"
                                    name="type"
                                    value={form[e.value]?.type}
                                    format={{ xs: 12 }}
                                    dontSort="true"
                                />
                            )}
                            <Address format={{ xs: 12 }} id={e.value} {...formProps(e)} />
                            <Title
                                title="A kedvezményes felhasználási hely azonosítója"
                                {...formProps(e)}
                                format={{ xs: 12 }}
                            >
                                <TextField
                                    label="Azonosító"
                                    name={`reference`}
                                    value={form[e.value]?.reference || ""}
                                    margin="none"
                                    format={{ xs: 12 }}
                                    rule={{
                                        length: 9,
                                        number: "true",
                                    }}
                                />
                            </Title>
                            {!edit && (
                                <Title {...formProps(e)} format={{ xs: 12 }} title="A használat jogcíme">
                                    <Select
                                        optionList={pretenceOptions}
                                        selectLabel="Jogcím"
                                        name={`type`}
                                        value={form[e.value]?.type || ""}
                                        margin="none"
                                        format={{ xs: 12 }}
                                    />
                                </Title>
                            )}
                            <Title {...formProps(e)} format={{ xs: 12 }} title="Fogyasztásmérők">
                                <Select
                                    selectLabel="Darab"
                                    name={`numberOfConsumptionMeters`}
                                    value={form[e.value]?.numberOfConsumptionMeters || 1}
                                    margin="none"
                                    optionList={[
                                        { value: 1, label: "Egy" },
                                        { value: 2, label: "Kettő" },
                                        { value: 3, label: "Három" },
                                    ]}
                                    format={{ xs: 12 }}
                                    dontSort
                                />
                                {Children.toArray(
                                    consumptionMeters
                                        .slice(0, form[e.value]?.numberOfConsumptionMeters || 1)
                                        .map((meter, index) => (
                                            <Title {...formProps(e)} format={{ xs: 12 }} title="">
                                                <TextField
                                                    label={`Azonosító (${index + 1})`}
                                                    name={`${meter.id}`}
                                                    value={form[e.value] ? form[e.value][meter.id] || "" : ""}
                                                    margin="none"
                                                    format={{ xs: 12, md: 6 }}
                                                />
                                                <TextField
                                                    label={`Jelenlegi mérőállás (${index + 1})`}
                                                    name={`${meter.station}`}
                                                    value={form[e.value] ? form[e.value][meter.station] || "" : ""}
                                                    margin="none"
                                                    rule={{
                                                        float: "true",
                                                        length: 8,
                                                    }}
                                                    format={{ xs: 12, md: 6 }}
                                                />
                                            </Title>
                                        ))
                                )}
                            </Title>
                            <Title {...formProps(e)} format={{ xs: 12 }} title="Az egyetemes szolgáltató megnevezése">
                                <Select
                                    selectLabel="Szolgáltató"
                                    name={`serviceProvider`}
                                    value={form[e.value]?.serviceProvider || ""}
                                    margin="none"
                                    optionList={providerOptions}
                                    format={{ xs: 12 }}
                                />
                            </Title>
                            {edit && form[e.value]?.type === "other" && (
                                <TextField
                                    label={dateOfDeath ? "Elhalálozás dátuma" : "Munkaviszony vége"}
                                    name={`employmentEndDate`}
                                    value={form[e.value]?.employmentEndDate || ""}
                                    margin="none"
                                    format={{ xs: 12 }}
                                    type="date"
                                />
                            )}
                            {!edit && (
                                <Form {...formProps(e)} childComponent format={{ xs: 12 }}>
                                    <Title
                                        {...formProps(e)}
                                        format={{ xs: 12 }}
                                        title="Az igénylő egyetemes szolgáltató szerinti vevőkódja / ügyfélszáma / partnerszáma"
                                    >
                                        <TextField
                                            label="Kód/Szám"
                                            name="consumerNumber"
                                            value={form[e.value]?.consumerNumber || ""}
                                            margin="none"
                                            format={{ xs: 12 }}
                                        />
                                    </Title>
                                    <Title {...formProps(e)} format={{ xs: 12 }} title="Szerződésszám">
                                        <TextField
                                            label="Szám"
                                            name={`contractNumber`}
                                            value={form[e.value]?.contractNumber || ""}
                                            margin="none"
                                            format={{ xs: 12 }}
                                            rule={{
                                                number: "true",
                                            }}
                                        />
                                    </Title>
                                    {e.value !== "primaryConsumptionPlace" && (
                                        <Title
                                            {...formProps(e)}
                                            format={{ xs: 12 }}
                                            title="A tárgyévben kedvezményesen vételezni kívánt villamosenergia-mennyiség"
                                        >
                                            <TextField
                                                label="Mennyiség"
                                                name={`quantity`}
                                                value={form[e.value]?.quantity || ""}
                                                margin="none"
                                                format={{ xs: 12 }}
                                            />
                                        </Title>
                                    )}
                                </Form>
                            )}
                        </Form>
                    </Accordion>
                ))
            )}
        </Grid>
    );
}
