import handbooksService from "../../services/handbooks";
import downloadBlob from "../../utils/downloadBlob";
import { types } from "./types";


function download(url, title) {
    return (dispatch) => {
        dispatch({ type: types.DOWNLOAD_REQUEST, url });
        return handbooksService.download(url).then(
            (data) => {
                dispatch({ type: types.DOWNLOAD_SUCCESS, data });
                downloadBlob(data.data, title);
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.DOWNLOAD_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    download,
};

export default actions;
