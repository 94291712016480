import React, { useEffect } from "react";
import { connect } from "react-redux";
import providerValidationActions from "../../../store/providerValidation/actions";
import PageTemplate from "../../Common/PageTemplate";
import TabsCompleted from "../../Common/TabsCompleted";
import notificationActions from "../../../store/notification/actions";
import usersActions from "../../../store/users/actions";
import ProviderValidationTab from "./ProviderValidationTab";
import {filterStorageGet} from "../../../utils/FilterStorage";


function ProviderValidation(props) {
    const {
        downloadFilter,
        uploadFilter,
        downloadForm,
        uploadForm,
        downloadInfo,
        uploadInfo,
        downloadData,
        uploadData,
        pageLoading,
        tableLoading,
        currentTab,
        showDownloadTable,
        showUploadTable,
        users
    } = props;

    useEffect(() => {
        props.getUsers();
        const storageFilterUpload = filterStorageGet(props.uploadFilterStorageKey);
        const storageFilterDownload = filterStorageGet(props.downloadFilterStorageKey);
        props.changeForm(storageFilterDownload, "downloadFilter");
        props.changeForm(storageFilterUpload, "uploadFilter");
        props.searchUpload(storageFilterUpload);
        props.searchDownload(storageFilterDownload);
    }, []);

    const tabContents = [
        {
            label: "Letöltendő adatok",
            value: (
                <ProviderValidationTab
                    name="downloadFilter"
                    filter={downloadFilter}
                    form={downloadForm}
                    data={downloadData}
                    search={props.searchDownload}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormDownload}
                    changeStatus={props.changeStatusDownload}
                    exportInitiate={props.exportInitiateDownload}
                    exportCheck={props.exportCheckDownload}
                    exportDownload={props.exportDownloadDownload}
                    download={props.downloadDownload}
                    addNotification={props.addNotification}
                    filterStorageKey={props.downloadFilterStorageKey}
                    info={downloadInfo}
                    setInfo={props.setDownloadInfo}
                    tableLoading={tableLoading}
                    showTable={showDownloadTable}
                    getLog={props.getLogSortingResults}
                    users={users}
                />
            ),
        },
        {
            label: "Feltöltött adatok",
            value: (
                <ProviderValidationTab
                    name="uploadFilter"
                    filter={uploadFilter}
                    form={uploadForm}
                    data={uploadData}
                    search={props.searchUpload}
                    changeForm={props.changeForm}
                    token={props.token}
                    defaultForm={props.defaultFormUpload}
                    changeStatus={props.changeStatusUpload}
                    exportInitiate={props.exportInitiateUpload}
                    exportCheck={props.exportCheckUpload}
                    exportDownload={props.exportDownloadUpload}
                    download={props.downloadUpload}
                    downloadReply={props.downloadUploadReply}
                    upload={props.upload}
                    addNotification={props.addNotification}
                    filterStorageKey={props.uploadFilterStorageKey}
                    info={uploadInfo}
                    setInfo={props.setUploadInfo}
                    tableLoading={tableLoading}
                    showTable={showUploadTable}
                    getLog={props.getLogServiceProviderDataVerification}
                    users={users}
                />
            ),
        },
    ];

    return (
        <PageTemplate
            header={{
                title: "Szolgáltatói adatellenőrzés",
                breadcrumbs: {
                    application: "Igénylések",
                    "provider-validation": "Szolgáltatói adatellenőrzés",
                },
            }}
            loading={pageLoading}
        >
            <TabsCompleted tabContents={tabContents} currentTab={currentTab} />
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        downloadFilter,
        uploadFilter,
        downloadForm,
        uploadForm,
        downloadInfo,
        uploadInfo,
        downloadData,
        uploadData,
        token,
        pageLoading,
        tableLoading,
        file,
        currentTab,
        showDownloadTable,
        showUploadTable,
        downloadFilterStorageKey,
        uploadFilterStorageKey
    } = state.providerValidation;
    const users = state.users.dataPublic;
    return {
        downloadFilter,
        uploadFilter,
        downloadForm,
        uploadForm,
        downloadInfo,
        uploadInfo,
        downloadData,
        uploadData,
        token,
        pageLoading,
        tableLoading,
        file,
        currentTab,
        showDownloadTable,
        showUploadTable,
        users,
        downloadFilterStorageKey,
        uploadFilterStorageKey
    };
}

const actionCreators = {
    changeForm: providerValidationActions.changeForm,
    downloadDownload: providerValidationActions.downloadDownload,
    downloadUpload: providerValidationActions.downloadUpload,
    downloadUploadReply: providerValidationActions.downloadUploadReply,
    upload: providerValidationActions.upload,
    searchDownload: providerValidationActions.filterDownload,
    searchUpload: providerValidationActions.filterUpload,
    defaultFormDownload: providerValidationActions.defaultFormDownload,
    defaultFormUpload: providerValidationActions.defaultFormUpload,
    changeStatusDownload: providerValidationActions.changeStatusDownload,
    changeStatusUpload: providerValidationActions.changeStatusUpload,
    exportInitiateDownload: providerValidationActions.exportInitiateDownload,
    exportCheckDownload: providerValidationActions.exportCheckDownload,
    exportDownloadDownload: providerValidationActions.exportDownloadDownload,
    exportInitiateUpload: providerValidationActions.exportInitiateUpload,
    exportCheckUpload: providerValidationActions.exportCheckUpload,
    exportDownloadUpload: providerValidationActions.exportDownloadUpload,
    addNotification: notificationActions.addNotification,
    setDownloadInfo: providerValidationActions.setDownloadInfo,
    setUploadInfo: providerValidationActions.setUploadInfo,
    setPageLoading: providerValidationActions.setPageLoading,
    getLogServiceProviderDataVerification: providerValidationActions.getLogServiceProviderDataVerification,
    getLogSortingResults: providerValidationActions.getLogSortingResults,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(ProviderValidation);
