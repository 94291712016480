import React from "react";
import { Helmet } from "react-helmet-async";

/**
 *
 * @param title - oldal neve
 */

export default function HelmetTemplate(props) {
    return (
        <Helmet titleTemplate={`MAVIR ATR - %s`}>
            <meta charSet="utf-8" />
            <title>{props.title}</title>
        </Helmet>
    );
}
