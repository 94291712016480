import { withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import colors from "../../styles/colors.js";

const SmallSearch = withStyles({
    root: {
        backgroundColor: colors.next,
        borderRadius: 3,
        color: colors.white,
        marginRight: "-8px",
        "&:hover": {
            backgroundColor: colors.nextHover,
        },
    },
})(SearchIcon);

export default SmallSearch;
