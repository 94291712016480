import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useExportStatus } from '../Providers/ExportStatusProvider';
import useFetch from './UseFetch';
import API from "../../utils/API";
import downloadAPI from "../../utils/downloadAPI";

function useExportCheck() {
    const dispatch = useDispatch();
    const { setExportStatus } = useExportStatus();

    const handleExportCheck = useCallback(
        (type, id, fileUnit) => {
            setExportStatus(prev => [...prev, { id: +id, processedRows: 0, rowCount: 0 }]);
            let ready = false;
            let isError = false;
            let status;
            const checker = () =>
            setTimeout(() => {
                API.get('/export-checker/check-export/{type}/{id}'.replace('{type}', type).replace('{id}', '' + id))
                    .then((d) => {
                        status = d.data.status;
                        fileUnit = d.data.fileUnit;
                        ready = status === "finished" || status === "unknown_error" || status === "limit_error";
                        isError = status !== "finished";
                        setExportStatus((prev) => {
                            const index = prev.findIndex((e) => e.id === id);
                            if (index !== -1) {
                                prev[index].processedRows = d.data.processedRows;
                                prev[index].rowCount = d.data.rowCount;
                            }
                            return [...prev];
                        });
                    })
                    .catch((error) => console.log(error, "hiba"));
                if (ready) {
                    setExportStatus((prev) => prev.filter((e) => e.id !== id));
                    if (!isError) {
                        if (fileUnit > 0) {
                            for (let i = 1; i <= fileUnit; i++) {
                                downloadAPI.get(
                                    '/export-checker/download-export/{type}/{id}/{page}'
                                        .replace('{type}', type)
                                        .replace('{id}', '' + id)
                                        .replace('{page}', '' + i),
                                    )
                                    .then((response) => {
                                        let fileName =
                                            response.headers["content-disposition"]
                                                ?.split(";")[1]
                                                ?.split("=")[1]
                                                ?.split(".")[0] || `export_${i}`;
                                        downloadFile(response.data, fileName, response.data.type);
                                    })
                                    .catch(() => {
                                    });
                            }
                        } else {
                            downloadAPI.get(
                                '/export-checker/download-export/{type}/{id}/{page}'
                                    .replace('{type}', type)
                                    .replace('{id}', '' + id)
                                    .replace('{page}', '' + 0),
                                )
                                .then((response) => {
                                    let fileName = response.headers["content-disposition"]?.split(";")[1]?.split("=")[1]?.split(".")[0] || "export";
                                    downloadFile(response.data, fileName, response.data.type);
                                })
                                .catch(() => {
                                });
                        }
                    }
                } else {
                    checker();
                }
            }, 2000);

            checker();
        },
        [dispatch, setExportStatus]
    );

    return handleExportCheck;
}

function downloadFile(data, filename, mime) {
    const blob = new Blob([data], { type: mime || "application/octet-stream" });
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    const filenameTest = mime === "text/csv" ? filename + ".csv" : filename;
    tempLink.setAttribute("download", filenameTest);
    if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
    }
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    setTimeout(() => {
        window.URL.revokeObjectURL(blobURL);
    }, 100);
}

export default function ExportChecker() {
    const { data, loading } = useFetch('/export/in-progress', {responseKey: 'data'});
    const handleExportCheck = useExportCheck();

    useEffect(() => {
        if (!loading && data) {
            data.forEach(d => {
                handleExportCheck(d.type, d.id, d.fileUnit);
            });
        }
    }, [data, loading, handleExportCheck]);

    return null;
}
