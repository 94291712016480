import { Grid } from "@material-ui/core";
import React from "react";
import Card from "../../Common/Card";
import PageTemplate from "../../Common/PageTemplate";
import EmployerForm from "./EmployerForm";

export default function CreateEmployer() {
    return (
        <PageTemplate
            header={{
                breadcrumbs: {
                    operators: "Operátorok",
                    "/employers": "Munkáltatók",
                    "create-employer": "Munkáltató hozzáadása",
                },
            }}
        >
            <Grid item xs={12}>
                <Card title="Munkáltató hozzáadása">
                    <EmployerForm />
                </Card>
            </Grid>
        </PageTemplate>
    );
}
