import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "../index";

function filterBase(form) {
    const searchForm = new SearchForm(form);

    return API.get("/customers/base", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function filterDetailed(form) {
    const searchForm = new SearchForm(form);

    return API.get("/customers/detailed", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getFilterFinanciers() {
    return API.get("/customers/filter/get-financiers")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getConsumptionPlaceServices(consumptionPlace) {
    return API.get("/customers/detailed/filter/get-consumption-place-services/" + consumptionPlace)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const clientsService = {
    filterBase,
    filterDetailed,
    exportInitiateBase: templateExportInitiate("customers/base"),
    exportInitiateDetailed: templateExportInitiate("customers/detailed"),
    exportCheckBase: templateExportCheck("customers/base"),
    exportCheckDetailed: templateExportCheck("customers/detailed"),
    exportDownloadBase: templateExportDownload("customers/base"),
    exportDownloadDetailed: templateExportDownload("customers/detailed"),
    getFilterFinanciers,
    getConsumptionPlaceServices,
};

export default clientsService;
