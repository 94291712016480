const storage = localStorage;
const FILTER_DATA_PRE = "FILTER_DATA.";

function getKey(key){
    return FILTER_DATA_PRE + key;
}

function encodeValue(value){
    return JSON.stringify(value);
}

function decodeValue(value){
    try{
        const decoded = value !== null && typeof value !== typeof undefined ? JSON.parse(value) : {};
        return decoded || {};
    }catch (e){
        return {};
    }
}

export const filterStorageSave = (key, value) => {
    storage.setItem( getKey(key), encodeValue(value) );
}

export const filterStorageGet = (key) => {
    return decodeValue(storage.getItem( getKey(key) ));
}

export const clearFilterStorage = () => {
    for (let [key] of Object.entries(localStorage)) {
        if(key.indexOf(FILTER_DATA_PRE) === 0){
            storage.removeItem(key)
        }
    }
}