import API from "../../utils/API";
import SearchForm from "../../utils/SearchForm";
import { templateExportCheck, templateExportDownload, templateExportInitiate } from "../index";

function filter(form) {
    const searchForm = new SearchForm(form);

    return API.get("/fixed-time-customers", {
        params: searchForm.getSend(),
    })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getFilterFinanciers() {
    return API.get("/fixed-time-customers/filter/financiers")
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

function getConsumptionPlaceServices(consumptionPlace) {
    return API.get("/fixed-time-customers/filter/consumption-place-services/" + consumptionPlace)
        .then((data) => {
            return data;
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}

const fixedTimeClientsService = {
    filter,
    getFilterFinanciers,
    getConsumptionPlaceServices,
    exportInitiate: templateExportInitiate("fixed-time-customers"),
    exportCheck: templateExportCheck("fixed-time-customers"),
    exportDownload: templateExportDownload("fixed-time-customers"),
};

export default fixedTimeClientsService;
