import React, { useEffect } from "react";
import TextField from "../../Common/TextField";
import { connect } from "react-redux";
import fixedTimeClientsActions from "../../../store/fixedTimeClients/actions";
import Table from "../../Common/Table";
import PageTemplate from "../../Common/PageTemplate";
import Filter from "../../Common/Filter";
import Autocomplete from "../../Common/Autocomplete";
import axios from "axios";
import { LEGAL_BASIS_OPTIONS, LEGAL_BASIS_TYPE_FOR_CUSTOMER_PAGE } from "../../../utils/AppConst";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { setInfo } from "../../../utils/FilterHelper";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

function FixedTimeClients(props) {
    const {
        filter,
        pageLoading,
        tableLoading,
        tableInfo,
        data,
        token,
        filterDataFinanciers,
        filterDataConsumptionPlaceServices1,
        filterDataConsumptionPlaceServices2,
        showTable,
        filterStorageKey
    } = props;

    function LegalBasisCellRenderer(params) {
        return LEGAL_BASIS_TYPE_FOR_CUSTOMER_PAGE(params?.data?.legalBasis) || "";
    }

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Előnév", field: "title" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            {
                headerName: "1. fogy. hely. szolgáltató",
                field: "consumptionPlace1Provider",
            },
            { headerName: "1. fogy. hely. azonosító", field: "consumptionPlace1Id" },
            {
                headerName: "2. fogy. hely. szolgáltató",
                field: "consumptionPlace2Provider",
            },
            { headerName: "2. fogy. hely. azonosító", field: "consumptionPlace2Id" },
            {
                headerName: "Jogalap",
                field: "legalBasis",
                cellRenderer: "legalBasisCellRenderer",
            },
            { headerName: "Jogosultság vége", field: "permissionTo" },
            { headerName: "Finanszírozó", field: "financier" },
        ],
        loading: tableLoading,
        rowData: data,
        autoSize: "true",
        frameworkComponents: {
            legalBasisCellRenderer: LegalBasisCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        axios.all([
            props.changeForm(storageFilter, "filter"),
            props.getFilterFinanciers(),
            props.getConsumptionPlaceServices1(),
            props.getConsumptionPlaceServices2(),
            setInfo(props, "filter", storageFilter)
        ]);
    }, []);

    const FINANCIER_OPTIONS = filterDataFinanciers.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    const PROVIDER_OPTIONS1 = filterDataConsumptionPlaceServices1.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    const PROVIDER_OPTIONS2 = filterDataConsumptionPlaceServices2.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    return (
        <PageTemplate
            loading={pageLoading}
            header={{
                title: "Határozott idejű ügyfelek",
                breadcrumbs: {
                    clients: "Ügyfelek",
                    forms: "Határozott idejű jogosultsággal rendelkező ügyfelek",
                },
            }}
        >
            <Filter
                onSubmit={() => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                }}
                defaultFormAction={() => {
                    props.defaultForm();
                    props.search({});
                    filterStorageSave(filterStorageKey, {});
                }}
                form={filter}
                name="filter"
                changeform={props.changeForm}
            >
                <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 3 }} />
                <TextField label="Előnév" name="title" value={filter.title || ""} format={{ xs: 12, md: 3 }} />
                <TextField
                    label="Vezetéknév"
                    name="lastName"
                    value={filter.lastName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Keresztnév"
                    name="firstName"
                    value={filter.firstName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="1. fogy. hely szolgáltató"
                    name="consumptionPlace1Provider"
                    id="consumptionPlace1Provider"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace1Provider || []}
                    options={PROVIDER_OPTIONS1}
                />
                <TextField
                    label="1. fogy. hely azonosító"
                    name="consumptionPlace1Id"
                    value={filter.consumptionPlace1Id || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="2. fogy. hely szolgáltató"
                    name="consumptionPlace2Provider"
                    id="consumptionPlace2Provider"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace2Provider || []}
                    options={PROVIDER_OPTIONS2}
                />
                <TextField
                    label="2. fogy. hely azonosító"
                    name="consumptionPlace2Id"
                    value={filter.consumptionPlace2Id || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="Jogalap"
                    name="legalBasis"
                    id="legalBasis"
                    format={{ xs: 12, md: 3 }}
                    value={filter.legalBasis || []}
                    options={LEGAL_BASIS_OPTIONS}
                />
                <TextField
                    label="Jogosultság vége (-tól)"
                    name="permissionToStart"
                    value={filter.permissionToStart || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Jogosultság vége (-ig)"
                    name="permissionToEnd"
                    value={filter.permissionToEnd || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <Autocomplete
                    label="Finanszírozó"
                    name="financier"
                    id="financier"
                    value={filter.financier || []}
                    format={{ xs: 12, md: 3 }}
                    options={FINANCIER_OPTIONS}
                />
            </Filter>
            {showTable && <Table {...table}></Table>}
        </PageTemplate>
    );
}

function mapState(state) {
    const {
        filter,
        pageLoading,
        tableLoading,
        tableInfo,
        token,
        data,
        filterDataFinanciers,
        filterDataConsumptionPlaceServices1,
        filterDataConsumptionPlaceServices2,
        showTable,
        filterStorageKey,
    } = state.fixedTimeClients;
    return {
        filter,
        pageLoading,
        tableLoading,
        tableInfo,
        token,
        data,
        filterDataFinanciers,
        filterDataConsumptionPlaceServices1,
        filterDataConsumptionPlaceServices2,
        showTable,
        filterStorageKey,
    };
}

const actionCreators = {
    changeForm: fixedTimeClientsActions.changeForm,
    defaultForm: fixedTimeClientsActions.defaultForm,
    search: fixedTimeClientsActions.filter,
    getFilterFinanciers: fixedTimeClientsActions.getFilterFinanciers,
    getConsumptionPlaceServices1: fixedTimeClientsActions.getConsumptionPlaceServices1,
    getConsumptionPlaceServices2: fixedTimeClientsActions.getConsumptionPlaceServices2,
    exportInitiate: fixedTimeClientsActions.exportInitiate,
    exportCheck: fixedTimeClientsActions.exportCheck,
    exportDownload: fixedTimeClientsActions.exportDownload,
    setInfo: fixedTimeClientsActions.setInfo,
};

export default connect(mapState, actionCreators)(FixedTimeClients);
