import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import Detail from "../../../Common/Detail";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import OperationButton from "../../../Common/OperationButton";
import TrackingChanges from "../dialogs/TrackingChanges";
import Card from "../../../Common/Card";
import { isAdmin } from "../../../../utils/User";
import colors from "../../../../styles/colors";
import { Link } from "react-router-dom";
import { PERMISSIONS } from "../../../../utils/AppConst";

const useStyles = makeStyles(() => ({
    link: {
        color: colors.primary,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}));

export default function BasicData(props) {
    const { form, name, edit, data } = props;
    const classes = useStyles();

    function maviridValue(mavirId) {
        return (
            <>
                {mavirId}
                {isAdmin() && (
                    <TrackingChanges
                        type="track-changes"
                        mavirId={mavirId}
                        getChangesLog={props.getChangesLog}
                        loadingChangesLog={props.loadingChangesLog}
                        changesLog={props.changesLog}
                    />
                )}
            </>
        );
    }

    function blacklistValue(isOnTheBlacklist, mavirId) {
        return (
            <>
                {isOnTheBlacklist ? "Igen" : "Nem"}
                {JSON.parse(localStorage.getItem("user"))?.roles?.includes(PERMISSIONS.ADMIN) && (
                    <OperationButton
                        type="search"
                        to={{
                            pathname: "/blacklist",
                            state: { mavirId: mavirId },
                        }}
                    />
                )}
            </>
        );
    }

    const getClientsByComment = (comment) => [...(comment || "").matchAll(/MV[A-Z]{2}-\d{4}-\d{1}/g)];

    const getClientsContent = (comment) => {
        const clients = getClientsByComment(comment).map((elem) => elem[0]);
        return (
            <Grid item container xs={12}>
                {clients.map((client) => (
                    <Grid item xs={12} container justify="flex-end">
                        <Link to={"/clients/" + client} className={classes.link}>
                            {client}
                        </Link>
                    </Grid>
                ))}
            </Grid>
        );
    };

    return (
        <Card title="Alapadatok" edit={edit} fullWidth>
            <Grid item xs={12}>
                <Detail title="MAVIR ID" value={maviridValue(data.mavirId)} />
                <Detail title="Tiltólistán" value={blacklistValue(data.isOnTheBlacklist, data.mavirId)} />
            </Grid>
            <Form form={form} name={name} changeform={props.changeForm}>
                {!edit ? (
                    <div format={{ xs: 12 }}>
                        <Detail format={{ xs: 12 }} title="Megjegyzés" value={data.comment || ""} />
                        {getClientsByComment(data.comment).length > 0 && (
                            <Detail
                                format={{ xs: 12 }}
                                title="Kapcsolódó ügyfelek"
                                value={getClientsContent(data.comment)}
                            />
                        )}
                    </div>
                ) : (
                    <TextField
                        label="Megjegyzés"
                        name="comment"
                        value={form.comment || ""}
                        format={{ xs: 12 }}
                        multiline
                        rowsMax={4}
                        rule={{length: 2000}}
                    />
                )}
            </Form>
        </Card>
    );
}
