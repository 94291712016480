import { types } from "./types";

const initialState = {
    filterStorageKey: 'statistics.consumptionDataByConsumptionPlaceWithBlocks',
    filter: {},
    form: {},
    token: "",
    data: [],
    status: null,
    file: null,
    tableVisiblity: false,
    loading: true,
};

export function consumptionDataByConsumptionPlaceWithBlocks(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
                form: initialState.form,
                data: initialState.data,
                tableVisiblity: false,
            };
        case types.SET_LOADING:
            return {
                ...state,
                loading: action.isLoading,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                tableVisiblity: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
                tableVisiblity: true,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
                tableVisiblity: false,
            };
        case types.CLEAR_FORM:
            return {
                ...state,
                form: {},
                tableVisiblity: initialState.tableVisiblity,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
