import {templateChangeForm, templateDefaultForm } from '..';
import serviceProviderSelectorService from '../../services/serviceProviderSelector';
import loginService from '../../services/login';
import { types } from "./types";

const getUserProfile = () => (dispatch) => {
    dispatch({ type: types.GET_PROFILE_REQUEST });
    return loginService.getUser().then(
        (data) => {
            dispatch({ type: types.GET_PROFILE_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_PROFILE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const changeServiceProvider = (form) => (dispatch) => {
    dispatch({ type: types.CHANGE_REQUEST, form });
    return serviceProviderSelectorService.changeServiceProvider(form).then(
        (data) => {
            dispatch({ type: types.CHANGE_SUCCESS, data });
            dispatch({ type: types.DEFAULT_FORM, name: "form "});
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.CHANGE_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    changeServiceProvider,
    getUserProfile
}

export default actions;