import { withStyles } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import colors from "../../styles/colors.js";

const Update = withStyles({
    root: {
        backgroundColor: colors.next,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.nextHover,
        },
    },
})(UpdateIcon);

export default Update;
