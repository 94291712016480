import {
    templateChangeForm,
    templateClearForm,
    templateDefaultForm,
    templateGetValidationUpdate,
    templateSetLoading,
    templateUpdate,
} from "..";
import { types } from "./types";
import clientDetailsService from "../../services/clientDetails";

function getData(mavirId) {
    return (dispatch) => {
        dispatch({ type: types.GET_DATA_REQUEST, mavirId });
        return clientDetailsService.getData(mavirId).then(
            (data) => {
                dispatch({ type: types.GET_DATA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DATA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getContacts() {
    return (dispatch) => {
        dispatch({ type: types.GET_CONTACTS_REQUEST });
        return clientDetailsService.getContacts().then(
            (data) => {
                dispatch({ type: types.GET_CONTACTS_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_CONTACTS_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getChangesLog(mavirId) {
    return (dispatch) => {
        dispatch({ type: types.GET_CHANGES_LOG_REQUEST, mavirId });
        return clientDetailsService.getChangesLog(mavirId).then(
            (data) => {
                dispatch({ type: types.GET_CHANGES_LOG_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_CHANGES_LOG_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setData(data) {
    return (dispatch) => {
        dispatch({ type: types.SET_DATA, data });
    };
}

function setFormData(data) {
    return (dispatch) => {
        dispatch({ type: types.SET_FORM_DATA, data });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    clearForm: templateClearForm(types),
    defaultForm: templateDefaultForm(types),
    getData: getData,
    setFormData: setFormData,
    setData: setData,
    getContacts: getContacts,
    getChangesLog: getChangesLog,
    setLoading: templateSetLoading(types),
    saveForm: templateUpdate(types, clientDetailsService),
    getValidationUpdate: templateGetValidationUpdate(types, clientDetailsService),
};

export default actions;
