import { types } from "./types";

const initialState = {
    filterStorageKey: 'operators.users',
    filter: {},
    form: {},
    data: [],
    allUsers: [],
    dataPublic: [],
    loading: false,
    token: "",
};

export function users(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                filter: initialState.filter,
                form: initialState.form,
            };
        case types.GET_ALL_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                allUsers: action.data.data.data,
            };
        case types.GET_ALL_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                dataPublic: [],
                error: action.error,
            };
        case types.FETCH_PUBLIC_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_PUBLIC_SUCCESS:
            return {
                ...state,
                loading: false,
                dataPublic: action.data.data.data,
            };
        case types.FETCH_PUBLIC_FAILURE:
            return {
                ...state,
                loading: false,
                dataPublic: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                form: action.data.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };
        case types.GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                form: {
                    ...action.data?.data,
                    enabled: action.data?.data?.enabled ? "true" : "false"
                },
            };
        case types.GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                form: {},
                error: action.error,
            };
        case types.UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_VALIDATION_CREATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_VALIDATION_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                validation: action.data.data.constraints,
            };
        case types.GET_VALIDATION_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_VALIDATION_UPDATE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.GET_VALIDATION_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                validation: action.data.data.constraints,
            };
        case types.GET_VALIDATION_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
