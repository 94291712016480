export const types = {
    DEFAULT_FORM: "@@blacklist/DEFAULT_FORM",
    CHANGE_FORM: "@@blacklist/CHANGE_FORM",

    FETCH_REQUEST: "@@blacklist/FETCH_REQUEST",
    FETCH_SUCCESS: "@@blacklist/FETCH_SUCCESS",
    FETCH_FAILURE: "@@blacklist/FETCH_FAILURE",

    CREATE_REQUEST: "@@blacklist/CREATE_REQUEST",
    CREATE_SUCCESS: "@@blacklist/CREATE_SUCCESS",
    CREATE_FAILURE: "@@blacklist/CREATE_FAILURE",

    CLOSE_REQUEST: "@@blacklist/CLOSE_REQUEST",
    CLOSE_SUCCESS: "@@blacklist/CLOSE_SUCCESS",
    CLOSE_FAILURE: "@@blacklist/CLOSE_FAILURE",

    CHECK_CUSTOMER_REQUEST: "@@blacklist/CHECK_CUSTOMER_REQUEST",
    CHECK_CUSTOMER_SUCCESS: "@@blacklist/CHECK_CUSTOMER_SUCCESS",
    CHECK_CUSTOMER_FAILURE: "@@blacklist/CHECK_CUSTOMER_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@blacklist/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@blacklist/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@blacklist/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@blacklist/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@blacklist/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@blacklist/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@blacklist/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@blacklist/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@blacklist/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@blacklist/CLEAR_FORM",
};
