import {
    templateChangeForm,
    templateClearForm,
    templateCreate,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
} from "..";
import blackListService from "../../services/blacklist";
import { types } from "./types";

function checkCustomer(mavirId) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_CUSTOMER_REQUEST, mavirId });
        return blackListService.checkCustomer(mavirId).then(
            (data) => {
                dispatch({ type: types.CHECK_CUSTOMER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHECK_CUSTOMER_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function close(id) {
    return (dispatch) => {
        dispatch({ type: types.CLOSE_REQUEST, id });
        return blackListService.close(id).then(
            (data) => {
                dispatch({ type: types.CLOSE_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CLOSE_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    clearForm: templateClearForm(types, blackListService),
    filter: templateFilter(types, blackListService),
    create: templateCreate(types, blackListService),
    close,
    checkCustomer,
    exportInitiate: templateExportInitiate(types, blackListService),
    exportCheck: templateExportCheck(types, blackListService),
    exportDownload: templateExportDownload(types, blackListService),
};

export default actions;
