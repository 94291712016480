export const types = {
    DEFAULT_FORM: "@@system-log/DEFAULT_FORM",
    CHANGE_FORM: "@@system-log/CHANGE_FORM",

    FIRST_FETCH_REQUEST: "@@system-log/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@system-log/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@system-log/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@system-log/FETCH_REQUEST",
    FETCH_SUCCESS: "@@system-log/FETCH_SUCCESS",
    FETCH_FAILURE: "@@system-log/FETCH_FAILURE",

    GET_REQUEST: "@@system-log/GET_REQUEST",
    GET_SUCCESS: "@@system-log/GET_SUCCESS",
    GET_FAILURE: "@@system-log/GET_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@system-log/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@system-log/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@system-log/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@system-log/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@system-log/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@system-log/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@system-log/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@system-log/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@system-log/EXPORT_DOWNLOAD_FAILURE",

    GET_TYPES_AND_EVENTS_REQUEST: "@@system-log/GET_TYPES_AND_EVENTS_REQUEST",
    GET_TYPES_AND_EVENTS_SUCCESS: "@@system-log/GET_TYPES_AND_EVENTS_SUCCESS",
    GET_TYPES_AND_EVENTS_FAILURE: "@@system-log/GET_TYPES_AND_EVENTS_FAILURE",

    FETCH_SET_INFO: "@@system-log/FETCH_SET_INFO",
};
