import React from "react";
import { Grid } from "@material-ui/core";
import TextField from "../../../Common/TextField";
import Form from "../../../Common/Form";
import Title from "../../../Common/Title";
import InputBase from "../../../Common/InputBase";
import Card from "../../../Common/Card";
import Autocomplete from "../../../Common/Autocomplete";

export default function Address(props) {
    const { form, name, addNew, edit, data, validation } = props;
    const streetTypeOptions = validation.find((val) => val.type === "street_type")?.options.types || [];
    const floorOptions = validation.find((val) => val.type === "floor")?.options.types || [];

    if (floorOptions[0] !== "") {
        floorOptions.unshift("");
    }

    const formProps = {
        form: form,
        name: name,
        changeform: props.changeForm,
        noBold: true,
    };

    return (
        <Card title="Cím" edit={edit} fullWidth>
            {edit || addNew ? (
                <Form {...formProps}>
                    <Grid item xs={12} format={{ xs: 12 }}>
                        <Title title="Település" {...formProps}>
                            <TextField
                                label="Irányítószám"
                                name="address.postCode"
                                value={form?.address?.postCode ? form.address.postCode : ""}
                                format={{ xs: 12, md: 4 }}
                            />
                            <TextField
                                label="Település"
                                name="address.city"
                                value={form?.address?.city ? form.address.city : ""}
                                format={{ xs: 12, md: 8 }}
                            />
                        </Title>
                        <Title title="Közterület neve, jellege" {...formProps}>
                            <TextField
                                label="Név"
                                name="address.street"
                                value={form?.address?.street ? form.address.street : ""}
                                format={{ xs: 12, md: 6 }}
                            />
                            <Autocomplete
                                value={form?.address?.streetType || ""}
                                options={streetTypeOptions}
                                id="address.streetType"
                                name="address.streetType"
                                format={{ xs: 12, md: 6 }}
                                label="Közterület jellege"
                                multiple={false}
                                getOptionLabel={(option) => option}
                                getOptionSelected={(option, value) => option === value}
                            />
                        </Title>
                        <Title title="Házszám (emelet/ajtó)" {...formProps}>
                            <TextField
                                label="Házszám"
                                name="address.houseNumber"
                                value={form?.address?.houseNumber ? form.address.houseNumber : ""}
                                format={{ xs: 12, md: 6 }}
                            />
                            <Autocomplete
                                value={form?.address?.floor || ""}
                                options={floorOptions}
                                id="address.floor"
                                name="address.floor"
                                format={{ xs: 12, md: 6 }}
                                label="Emelet"
                                multiple={false}
                                getOptionLabel={(option) => option}
                                getOptionSelected={(option, value) => option === value}
                            />
                            <TextField
                                label="Ajtó"
                                name="address.door"
                                value={form?.address?.door ? form.address.door : ""}
                                format={{ xs: 12, md: 6 }}
                            />
                        </Title>
                        <Title title="Helyrajzi szám" {...formProps}>
                            <TextField
                                label="Helyrajzi szám"
                                name="address.parcelNumber"
                                value={form?.address?.parcelNumber ? form.address.parcelNumber : ""}
                                format={{ xs: 12, md: 12 }}
                            />
                        </Title>
                    </Grid>
                </Form>
            ) : (
                <Form {...formProps}>
                    <Grid item xs={12}>
                        <Title title="Település" {...formProps}>
                            <InputBase
                                label="Irányítószám"
                                value={data?.address?.postCode || ""}
                                format={{ xs: 12, md: 4 }}
                                disabled
                            />
                            <InputBase
                                label="Település"
                                value={data?.address?.city || ""}
                                format={{ xs: 12, md: 8 }}
                                disabled
                            />
                        </Title>
                        <Title title="Közterület neve, jellege" {...formProps}>
                            <InputBase
                                label="Név"
                                value={data?.address?.street || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <InputBase
                                label="Jelleg"
                                value={data?.address?.streetType || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                        </Title>
                        <Title title="Házszám (emelet/ajtó)" {...formProps}>
                            <InputBase
                                label="Házszám"
                                value={data?.address?.houseNumber || ""}
                                format={{ xs: 12, md: 4 }}
                                disabled
                            />
                            <InputBase
                                label="Emelet"
                                value={data?.address?.floor || ""}
                                format={{ xs: 12, md: 4 }}
                                disabled
                            />
                            <InputBase
                                label="Ajtó"
                                value={data?.address?.door || ""}
                                format={{ xs: 12, md: 4 }}
                                disabled
                            />
                        </Title>
                        <Title title="Helyrajzi szám" {...formProps}>
                            <InputBase
                                label="Helyrajzi szám"
                                value={data?.address?.parcelNumber || ""}
                                format={{ xs: 12, md: 12 }}
                                disabled
                            />
                        </Title>
                    </Grid>
                </Form>
            )}
        </Card>
    );
}
