import { types } from "./types";

const initialState = {
    filter: {},
    form: {},
    loading: false,
    data: [],
};

export function consumptionPeriods(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM:
            return {
                ...state,
                form: initialState.form,
            };
        case types.FETCH_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.error,
            };
        case types.ACTIVATE_REQUEST:
            return {
                ...state,
            };
        case types.ACTIVATE_SUCCESS:
            return {
                ...state,
            };
        case types.ACTIVATE_FAILURE:
            return {
                ...state,
            };
        case types.DEACTIVATE_REQUEST:
            return {
                ...state,
            };
        case types.DEACTIVATE_SUCCESS:
            return {
                ...state,
            };
        case types.DEACTIVATE_FAILURE:
            return {
                ...state,
            };
        default:
            return state;
    }
}
