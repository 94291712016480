import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
    link: {
        textDecoration: "none",
        "&:link, &:visited": {
            color: "#000000DE",
            textDecoration: "none",
            cursor: "pointer",
        },
    },
}));

export default function BarcodeCellRenderer(params) {
    const classes = useStyles();

    return (
        <Link to={`/applications/${params.data.id}`} className={classes.link}>
            {params.data.barCode}
        </Link>
    );
}
