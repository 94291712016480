import { withStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import colors from "../../styles/colors.js";

const Info = withStyles({
    root: {
        backgroundColor: colors.next,
        borderRadius: 3,
        color: colors.white,
        fontSize: "xx-large",
        marginTop: "0.7vh",
        "&:hover": {
            backgroundColor: colors.nextHover,
        },
    },
})(InfoIcon);

export default Info;
