import React, { useEffect } from "react";
import TextField from "../../Common/TextField";
import ListPageTemplate from "../../Common/ListPageTemplate";
import { connect } from "react-redux";
import systemLogActions from "../../../store/systemLog/actions";
import Autocomplete from "../../Common/Autocomplete";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import colors from "../../../styles/colors";
import Dialog from "../../Common/Dialog";
import OperationButton from "../../Common/OperationButton";
import usersActions from "../../../store/users/actions";
import { setInfo } from "../../../utils/FilterHelper";
import { subMenus } from "../../../utils/AppConst";
import { underscoreToCamelCase } from "../../../utils/underscoreToCamelCase";
import { userOptions } from "../../../utils/listOptions/userOptions";
import Title from "../../Common/Title";
import {filterStorageGet, filterStorageSave} from "../../../utils/FilterStorage";

const useStyles = makeStyles(() => ({
    tableHeader: {
        backgroundColor: colors.primary,
    },
    tableHeaderCell: {
        color: colors.white,
    },
}));

function BtnCellRenderer(params) {
    const classes = useStyles();

    const checkChanges = (entry) => {
        const oldData = entry.oldData;
        const newData = entry.newData;

        const oldDataPropNames = oldData ? Object.getOwnPropertyNames(oldData) : [];
        const newDataPropNames = newData ? Object.getOwnPropertyNames(newData) : [];
        const changedPropNames = Array.from(new Set(oldDataPropNames.concat(newDataPropNames)));

        return changedPropNames.map((propName) => {
            return {
                name: oldData?.[propName]?.title || newData?.[propName]?.title,
                old: oldData?.[propName]?.value || "",
                new: (entry?.type === "Export" ? 
                    (
                        subMenus[underscoreToCamelCase(newData?.[propName]?.value)]?.text
                        || newData?.[propName]?.value
                    ) 
                    : (newData?.[propName]?.value === 0 ? '0' : newData?.[propName]?.value)) || "",
            };
        });
    };

    return (
        <Dialog opener={<OperationButton type="info" />} title="Változások">
            <Table>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.tableHeaderCell}>Kulcs</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Régi érték</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Új érték</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Azonosító</TableCell>
                        <TableCell colSpan={2}>{params.data.id}</TableCell>
                    </TableRow>
                    {checkChanges(params.data).map((elem) => {
                        return (   
                            <TableRow>
                                <TableCell>{elem.name}</TableCell>
                                <TableCell>{elem?.old || "-"}</TableCell>
                                <TableCell>{elem?.new || "-"}</TableCell>
                            </TableRow>
                        )})}
                    <TableRow>
                        <TableCell>Esemény</TableCell>
                        <TableCell colSpan={2}>{params.data?.action}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Típus</TableCell>
                        <TableCell colSpan={2}>{params.data?.type}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Felhasználó</TableCell>
                        <TableCell colSpan={2}>{params.data?.createdBy?.name}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Dialog>
    );
}

function SystemLog(props) {
    const { filter, data, pageLoading, tableLoading, token, types, events, tableInfo, users, filterStorageKey } = props;
    const eventOptions = Object.keys(events).map((key) => {
        return {
            value: key,
            label: events[key].title,
        };
    });
    const typeOptions = Object.keys(types).map((key) => {    
        return {
            value: key,
            label: types[key].title,
        };
    });

    useEffect(() => {
        const storageFilter = filterStorageGet(filterStorageKey);
        props.changeForm(storageFilter, "filter"), 
        props.getTypesAndEvents();
        props.getUsers();
        setInfo({...props, search: props.firstFetch}, "sort", { createdAt: "desc" }, "filter", storageFilter);
    }, []);

    const table = {
        columnDefs: [
            { headerName: "Időpont", field: "createdAt" },
            { headerName: "Felhasználó", field: "createdBy.name" },
            { headerName: "Esemény", field: "action" },
            { headerName: "Típus", field: "type" },
            {
                headerName: "Változások",
                field: "changes",
                cellRenderer: "btnCellRenderer",
                sortable: false,
            },
        ],
        loading: tableLoading,
        rowData: data,
        frameworkComponents: {
            btnCellRenderer: BtnCellRenderer,
        },
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            token: token,
            info: tableInfo,
        },
        filterData: filter,
        searchfunc: props.search,
        info: tableInfo,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    const filterProps = {
        onSubmit: () => {
            setInfo(props, "filter", filter, "sort", { createdAt: "desc" });
            filterStorageSave(filterStorageKey, filter);
        },
        defaultFormAction: () => {
            filterStorageSave(filterStorageKey, {});
            props.defaultForm();
            setInfo(props, "sort", { createdAt: "desc" });
        },
        form: filter,
        name: "filter",
        changeform: props.changeForm,
    }

    return (
        <ListPageTemplate
            header={{
                title: "Rendszernapló",
                breadcrumbs: {
                    operators: "Operátorok",
                    "system-log": "Rendszernapló",
                },
            }}
            filter={filterProps}
            table={table}
            loading={pageLoading}
        >
            <Autocomplete
                value={filter.createdBy || []}
                options={userOptions(users)}
                id="createdBy"
                name="createdBy"
                format={{ xs: 12, md: 12 }}
                label="Felhasználó"
            />
            <Autocomplete
                value={filter.action || []}
                options={eventOptions?.sort((a, b) => ("" + (a.label || a.name || a)).localeCompare(b.label || b.name || b))}
                name="action"
                label="Esemény"
                format={{ xs: 12, md: 6 }}
            />
            <Autocomplete
                value={filter.type || []}
                options={typeOptions?.sort((a, b) => ("" + (a.label || a.name || a)).localeCompare(b.label || b.name || b))}
                name="type"
                label="Típus"
                format={{ xs: 12, md: 6 }}
            />
            <Title 
                title="Időpont"
                format={{ xs: 12 }}
                {...filterProps}
            >
                 <TextField
                    label="-tól"
                    name="createdAtStart"
                    value={filter.createdAtStart || ""}
                    format={{ xs: 12, md: 6 }}
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                />
                <TextField
                    label="-ig"
                    name="createdAtEnd"
                    value={filter.createdAtEnd || ""}
                    format={{ xs: 12, md: 6 }}
                    InputLabelProps={{ shrink: true }}
                    type="datetime-local"
                />
            </Title>
        </ListPageTemplate>
    );
}
function mapState(state) {
    const { filter, data, pageLoading, tableLoading, tableInfo, token, types, events, filterStorageKey } = state.systemLog;
    const users = state.users.dataPublic;
    return {
        filter,
        data,
        pageLoading,
        tableLoading,
        tableInfo,
        token,
        types,
        events,
        users, 
        filterStorageKey
    };
}

const actionCreators = {
    changeForm: systemLogActions.changeForm,
    search: systemLogActions.filter,
    defaultForm: systemLogActions.defaultForm,
    firstFetch: systemLogActions.firstFetch,
    exportInitiate: systemLogActions.exportInitiate,
    exportCheck: systemLogActions.exportCheck,
    exportDownload: systemLogActions.exportDownload,
    getTypesAndEvents: systemLogActions.getTypesAndEvents,
    setInfo: systemLogActions.setInfo,
    getUsers: () => usersActions.filterPublic({}),
};

export default connect(mapState, actionCreators)(SystemLog);
