import Address from "../../../Common/Address";
import Form from "../../../Common/Form";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";

export default function PersonalDataForm(props) {
    const { form, validation } = props;

    const formProps = {
        name: "applicant",
        changeform: props.onChange,
        form: form,
        validation: validation,
        format: {
            xs: 12,
        },
    };

    return (
        <Form {...formProps}>
            <Title {...formProps} title="Név">
                <TextField
                    label="Előnév"
                    name="title"
                    value={form.title || ""}
                    format={{ xs: 12, md: 2 }}
                    margin="none"
                    rule={{
                        length: 16,
                    }}
                />
                <TextField
                    label="Vezetéknév"
                    name="lastName"
                    value={form.lastName || ""}
                    format={{ xs: 12, md: 5 }}
                    margin="none"
                />
                <TextField
                    label="Keresztnév"
                    name="firstName"
                    value={form.firstName || ""}
                    format={{ xs: 12, md: 5 }}
                    margin="none"
                />
            </Title>
            <Title {...formProps} title="Születési név">
                <TextField
                    label="Vezetéknév"
                    name="birthLastName"
                    value={form.birthLastName || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
                <TextField
                    label="Keresztnév"
                    name="birthFirstName"
                    value={form.birthFirstName || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
            </Title>
            <Title {...formProps} title="Anyja születési neve">
                <TextField
                    label="Vezetéknév"
                    name="motherLastName"
                    value={form.motherLastName || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
                <TextField
                    label="Keresztnév"
                    name="motherFirstName"
                    value={form.motherFirstName || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
            </Title>
            <Title {...formProps} title="Születési hely és idő">
                <TextField
                    label="Születési hely"
                    name="birthPlace"
                    value={form.birthPlace || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
                <TextField
                    label="Születési idő"
                    name="birthDate"
                    value={form.birthDate || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                    type="date"
                />
            </Title>
            <Address id={1} {...formProps} />
            <Title {...formProps} title="Elérhetőség">
                <TextField
                    label="Telefonszám"
                    name="phoneNumber"
                    value={form.phoneNumber || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                    helperText="Helyes formátum: +36201234567"
                />
                <TextField
                    label="E-mail cím"
                    name="email"
                    value={form.email || ""}
                    format={{ xs: 12, md: 6 }}
                    margin="none"
                />
            </Title>
        </Form>
    );
}
