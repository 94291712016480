export const types = {
    CHANGE_FORM: "@@service_provider_selector/CHANGE_FORM",
    DEFAULT_FORM: "@@service_provider_selector/DEFAULT_FORM",

    CHANGE_REQUEST: "@@service_provider_selector/CHANGE_REQUEST",
    CHANGE_SUCCESS: "@@service_provider_selector/CHANGE_SUCCESS",
    CHANGE_FAILURE: "@@service_provider_selector/CHANGE_FAILURE",

    GET_PROFILE_REQUEST: "@@service_provider_selector/GET_PROFILE_REQUEST",
    GET_PROFILE_SUCCESS: "@@service_provider_selector/GET_PROFILE_SUCCESS",
    GET_PROFILE_FAILURE: "@@service_provider_selector/GET_PROFILE_FAILURE",
}