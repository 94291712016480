import {
    templateChangeForm,
    templateDefaultForm,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateFirstFetch,
    templateSetInfo,
    templateSetPageLoading,
} from "..";
import { types } from "./types";
import consumptionDataValidatorService from "../../services/ConsumptionDataValidator";

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    setPageLoading: templateSetPageLoading(types),
    filter: templateFilter(types, consumptionDataValidatorService),
    firstFetch: templateFirstFetch(types, consumptionDataValidatorService),
    exportInitiate: templateExportInitiate(types, consumptionDataValidatorService),
    exportCheck: templateExportCheck(types, consumptionDataValidatorService),
    exportDownload: templateExportDownload(types, consumptionDataValidatorService),
    setInfo: templateSetInfo(types),
};

export default actions;
