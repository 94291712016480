import { makeStyles } from "@material-ui/core";
import React from "react";
import CardTable from "../../../Common/CardTable";
import SubmitButton from "../../../Common/SubmitButton";
import colors from "../../../../styles/colors";
import Card from "../../../Common/Card";
import { ConsumptionMeter } from "./ConsumptionMeter";

const useStyles = makeStyles((theme) => ({
    goButton: {
        marginTop: theme.spacing(0.5),
    },
    cancelButton: {
        marginLeft: theme.spacing(1.5),
        marginTop: theme.spacing(0.5),
    },
    title: {
        backgroundColor: colors.primary,
        color: colors.white,
    },
    dialog: {
        minWidth: "500px",
        minHeight: "500px",
    },
    content: {
        padding: 0,
        "&:last-child": {
            paddingBottom: 0,
        },
    },
    addButton: {
        marginTop: theme.spacing(1),
    },
    emptyText: {
        color: colors.lightPrimary,
    },
    errorText: {
        color: colors.error,
        display: "block",
        fontSize: "1rem",
        marginTop: theme.spacing(1),
    },
}));

export default function ConsumptionMeters(props) {
    const classes = useStyles();
    const { form, edit } = props;

    const formErrors = form?.errors ?? [];

    const getHelperText = (name) => {
        const errors = formErrors?.filter((v) => v.name === name);
        return errors?.length > 0 ? errors[0]?.label : "";
    };

    const table = {
        columnDefs: [
            { headerName: "Szám", field: "reference" },
            { headerName: "Jelenlegi állás (kWh)", field: "reading" },
            { headerName: "Dátum", field: "readingDate" },
            { headerName: "Záró állás (kWh)", field: "closingReading" },
            { headerName: "Záró dátum", field: "closedAt" },
        ],
        rowData: form?.consumptionMeters,
    };

    return (
        <Card title="Fogyasztásmérő órák" edit={edit} fullWidth>
            {edit ? (
                <>
                    {form?.consumptionMeters &&
                        form?.consumptionMeters?.map((meter, key) => {
                            return (
                                <ConsumptionMeter
                                    form={props.form}
                                    changeForm={props.changeForm}
                                    meterKey={key}
                                    key={key}
                                    name="form"
                                />
                            );
                        })}
                    {!form?.consumptionMeters || form?.consumptionMeters?.length == 0 ? (
                        <div className={classes.emptyText}>Nincs hozzáadott mérőóra</div>
                    ) : (
                        ""
                    )}
                    <span className={classes.errorText}>{getHelperText("consumptionMeters")}</span>
                    {(form?.consumptionMeters?.length || !form?.consumptionMeters) < 3 && (
                        <SubmitButton
                            className={classes.addButton}
                            onClick={() => {
                                let formClone = Object.assign({}, form);
                                if (!formClone.consumptionMeters) {
                                    formClone.consumptionMeters = [];
                                }
                                formClone.consumptionMeters.push({
                                    //       serial: formClone.consumptionMeters[Object.keys(formClone.consumptionMeters).length-1].serial + 1
                                });
                                // Újrasorszámozás
                                let srl_ = 1;
                                formClone.consumptionMeters = formClone.consumptionMeters.map((element) => {
                                    element.serial = srl_++;
                                    return element;
                                });
                                props.changeForm(formClone, "form");
                            }}
                        >
                            Mérőóra hozzáadása
                        </SubmitButton>
                    )}
                </>
            ) : (
                <CardTable noExportButton showPagination={false} {...table}></CardTable>
            )}
        </Card>
    );
}
