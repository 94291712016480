const colors = {
    primary: "#838383",
    menuGroup: "#a3a3a3",
    lightPrimary: "#bfbfbf",
    orange: "#FFA500",
    orangeHover: "#e6a62e",
    white: "#fff",
    black: "#000",
    green: "#6aa96f",
    greenHover: "#578c5b",
    hoverFontColor: "#404040",
    secondaryHover: "#c4c4c4",
    next: "#3e8dd8",
    nextHover: "#3171ad",
    back: "#ecbf54",
    backHover: "#e3b330",
    red: "#e58588",
    redHover: "#c77376",
    export: "#49968e",
    exportHover: "#0E756E",
    background: "#f2f2f2",
    filter: "#3e8dd8",
    filterHover: "#3171ad",
    edit: "#ecbf54",
    editHover: "#e3b330",
    error: "#f44336",
};

export default colors;
