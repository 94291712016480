import { Grid } from "@material-ui/core";
import Filter from "../../Common/Filter";
import OperationButton from "../../Common/OperationButton";
import Table from "../../Common/Table";
import TextField from "../../Common/TextField";
import Autocomplete from "../../Common/Autocomplete";
import { setInfo } from "../../../utils/FilterHelper";
import { LEGAL_BASIS_TYPE_FOR_CUSTOMER_PAGE, LEGAL_BASIS_OPTIONS_CLIENTS, IS_ACTIVE_OPTIONS } from "../../../utils/AppConst";
import MavirIdCellRenderer from "../../../utils/cellRenderer/MavirIdCellRenderer";
import { filterStorageSave } from "../../../utils/FilterStorage";

function InfoBtnCellRenderer() {
    return <OperationButton type="info" to={`#`} />;
}
function PermissionToCellRenderer(params) {
    if (params.data.permissionFrom && params.value) {
        return params.value;
    }
    if (params.data.permissionFrom && params.value === "") {
        return "2099-12-31";
    }
    return "";
}

const taxStatusOptions = [
    { value: 0, label: "Adóköteles" },
    { value: 1, label: "Adómentes" },
];

const consumptionPlaceTypeOptions = [
    { value: 'property', label: "Tulajdon" },
    { value: 'rental', label: "Bérlet" },
    { value: 'corental', label: "Társbérlet" },
    { value: 'usufruct', label: "Haszonélvezet" },
    { value: 'apartment', label: "Albérlet" },
];

const IS_WRONG_PENSIONER_REGISTRATION_NUMBER_OPTIONS = [
    { value: "valid", label: "Érvényes" },
    { value: "invalid", label: "Hibás" },
    { value: "not_set", label: "Nincs megadva" },
];

export default function DetailedFilterTab(props) {
    const { filter, data, filterData, token, tableLoading, info, showTable, employers, providers, filterStorageKey } =
        props;

    function TaxStatusCellRenderer(params) {
        return params.data.taxStatus === true ? "Adómentes" : "Adóköteles";
    }

    function LegalBasisCellRenderer(params) {
        return LEGAL_BASIS_TYPE_FOR_CUSTOMER_PAGE(params?.data?.legalBasis) || "";
    }

    function IsActiveCellRenderer(params) {
        return params.data.isActive === "yes" ? "Aktív" : "Inaktív";
    }

    const table = {
        columnDefs: [
            {
                headerName: "MAVIR ID",
                field: "mavirId",
                cellRenderer: "mavirIdCellRenderer",
            },
            { headerName: "Előnév", field: "title" },
            { headerName: "Vezetéknév", field: "lastName" },
            { headerName: "Keresztnév", field: "firstName" },
            { headerName: "Születési hely", field: "birthPlace" },
            { headerName: "Születési idő", field: "birthDate" },
            { headerName: "Anyja vezetékneve", field: "motherLastName" },
            { headerName: "Anyja keresztneve", field: "motherFirstName" },
            { headerName: "Város", field: "addressCity" },
            { headerName: "Cím", field: "addressStreet" },
            { headerName: "Irányítószám", field: "addressPostcode" },
            { headerName: "Vármegye", field: "county", sortable: false},
            { headerName: "1. fogy. hely. szolgáltató", field: "consumptionPlace1Provider"},
            { headerName: "1. fogy. hely. azonosító", field: "consumptionPlace1Id" },
            { headerName: "1. fogy. hely. vármegye", field: "consumptionPlace1County", sortable: false},
            { headerName: "1. fogy. hely. jogcím", field: "consumptionPlace1Type"},
            { headerName: "2. fogy. hely. szolgáltató", field: "consumptionPlace2Provider"},
            { headerName: "2. fogy. hely. azonosító", field: "consumptionPlace2Id"},
            { headerName: "2. fogy. hely. vármegye", field: "consumptionPlace2County", sortable: false},
            { headerName: "2. fogy. hely. jogcím", field: "consumptionPlace2Type"},
            {
                headerName: "Jogalap",
                field: "legalBasis",
                cellRenderer: "legalBasisCellRenderer",
            },
            { headerName: "Jogosultság kezdete", field: "permissionFrom" },
            { headerName: "Jogosultság vége", field: "permissionTo", cellRenderer: "permissionToCellRenderer" },
            {
                headerName: "Adóstátusz",
                field: "taxStatus",
                cellRenderer: "taxStatusCellRenderer",
            },
            { headerName: "Finanszírozó", field: "financier" },
            {
                headerName: "Nyugdíjas törzsszám",
                field: "pensionerRegistrationNumber",
            },
            {
                headerName: "Nyugdíjas tsz. állapota",
                field: "isWrongPensionerRegistrationNumber",
            },
            { headerName: "Kapcsolattartó", field: "contact.name" },
            { headerName: "Jogosultság", field: "isActive", cellRenderer: "isActiveCellRenderer" },
            { headerName: "Utolsó munkáltató", field: "lastEmployerName", sortable: false},
        ],
        loading: tableLoading,
        rowData: data,
        suppressColumnVirtualisation: true,
        frameworkComponents: {
            infoBtnCellRenderer: InfoBtnCellRenderer,
            mavirIdCellRenderer: MavirIdCellRenderer,
            taxStatusCellRenderer: TaxStatusCellRenderer,
            legalBasisCellRenderer: LegalBasisCellRenderer,
            isActiveCellRenderer: IsActiveCellRenderer,
            permissionToCellRenderer: PermissionToCellRenderer,
        },
        autoSize: "true",
        exportcontext: {
            exportInitiate: props.exportInitiate,
            exportCheck: props.exportCheck,
            exportDownload: props.exportDownload,
            filter: filter,
            info: info,
            token: token,
        },
        filterData: filter,
        searchfunc: props.search,
        info: info,
        setInfo: props.setInfo,
        isPaginated: true,
    };

    const FINANCIER_OPTIONS = filterData?.financiers.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    const PROVIDER_OPTIONS1 = filterData?.dataConspServ1.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    const PROVIDER_OPTIONS2 = filterData?.dataConspServ2.map((d) => {
        return {
            value: d.id,
            label: d.name,
        };
    });

    const employerOptions = employers?.map((employer) => {
        return {
            value: `${employer.id}_e`,
            label: employer.name,
            type_en: "employer",
            type_hu: "Munkáltatók",
        };
    });

    const providerOptions = providers?.map((provider) => {
        return {
            value: `${provider.id}_p`,
            label: provider.name,
            type_en: "serviceProvider",
            type_hu: "Szolgáltatók",
        };
    });

    const providerOptionsBasic = providers?.map((provider) => {
        return {
            value: provider.id,
            label: provider.name,
        };
    });

    const optionsArray = employerOptions?.concat(providerOptions);

    return (
        <Grid item container xs={12} spacing={2}>
            <Filter
                onSubmit={() => {
                    setInfo(props, "filter", filter);
                    filterStorageSave(filterStorageKey, filter);
                }}
                defaultFormAction={() => {
                    filterStorageSave(filterStorageKey, {});
                    props.defaultForm();
                    setInfo(props, "filter", {}, "sort", { lastName: "asc" });
                }}
                form={filter}
                name="detailedFilter"
                changeform={props.changeForm}
            >
                <TextField label="MAVIR ID" name="mavirId" value={filter.mavirId || ""} format={{ xs: 12, md: 3 }} />
                <TextField label="Előnév" name="title" value={filter.title || ""} format={{ xs: 12, md: 3 }} />
                <TextField
                    label="Vezetéknév"
                    name="lastName"
                    value={filter.lastName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Keresztnév"
                    name="firstName"
                    value={filter.firstName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Születési hely"
                    name="birthPlace"
                    value={filter.birthPlace || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Születési idő (-tól)"
                    name="birthDateFrom"
                    value={filter.birthDateFrom || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Születési idő (-ig)"
                    name="birthDateTo"
                    value={filter.birthDateTo || ""}
                    type="date"
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    label="Anyja vezetékneve"
                    name="motherLastName"
                    value={filter.motherLastName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Anyja keresztneve"
                    name="motherFirstName"
                    value={filter.motherFirstName || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Város"
                    name="addressCity"
                    value={filter.addressCity || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Cím"
                    name="addressStreet"
                    value={filter.addressStreet || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <TextField
                    label="Irányítószám"
                    name="addressPostcode"
                    value={filter.addressPostcode || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="1. fogy. hely szolgáltató"
                    name="consumptionPlace1Provider"
                    id="consumptionPlace1Provider"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace1Provider || []}
                    options={PROVIDER_OPTIONS1}
                />
                <TextField
                    label="1. fogy. hely azonosító"
                    name="consumptionPlace1Id"
                    value={filter.consumptionPlace1Id || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="1. fogy. hely jogcím"
                    name="consumptionPlace1Type"
                    id="consumptionPlace1Type"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace1Type || []}
                    options={consumptionPlaceTypeOptions}
                />
                <Autocomplete
                    label="2. fogy. hely szolgáltató"
                    name="consumptionPlace2Provider"
                    id="consumptionPlace2Provider"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace2Provider || []}
                    options={PROVIDER_OPTIONS2}
                />
                <TextField
                    label="2. fogy. hely azonosító"
                    name="consumptionPlace2Id"
                    value={filter.consumptionPlace2Id || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="2. fogy. hely jogcím"
                    name="consumptionPlace2Type"
                    id="consumptionPlace2Type"
                    format={{ xs: 12, md: 3 }}
                    value={filter.consumptionPlace2Type || []}
                    options={consumptionPlaceTypeOptions}
                />
                <Autocomplete
                    label="Jogalap"
                    name="legalBasis"
                    id="legalBasis"
                    format={{ xs: 12, md: 3 }}
                    value={filter.legalBasis || []}
                    options={LEGAL_BASIS_OPTIONS_CLIENTS}
                />
                <TextField
                    label="Jogosultság kezdete (-tól)"
                    name="permissionFromStart"
                    value={filter.permissionFromStart || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Jogosultság kezdete (-ig)"
                    name="permissionFromEnd"
                    value={filter.permissionFromEnd || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Jogosultság vége (-tól)"
                    name="permissionToStart"
                    value={filter.permissionToStart || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <TextField
                    label="Jogosultság vége (-ig)"
                    name="permissionToEnd"
                    value={filter.permissionToEnd || ""}
                    format={{ xs: 12, md: 3 }}
                    InputLabelProps={{ shrink: true }}
                    type="date"
                />
                <Autocomplete
                    label="Finanszírozó"
                    name="financier"
                    id="financier"
                    value={filter.financier || []}
                    format={{ xs: 12, md: 3 }}
                    options={FINANCIER_OPTIONS}
                />
                <Autocomplete
                    label="Adóstátusz"
                    name="taxStatus"
                    id="taxStatus"
                    value={filter.taxStatus || []}
                    format={{ xs: 12, md: 3 }}
                    options={taxStatusOptions}
                />
                <TextField
                    label="Nyugdíjas törzsszám"
                    name="pensionerRegistrationNumber"
                    value={filter.pensionerRegistrationNumber || ""}
                    format={{ xs: 12, md: 3 }}
                />
                <Autocomplete
                    label="Nyugdíjas tsz. állapota"
                    name="isWrongPensionerRegistrationNumber"
                    id="isWrongPensionerRegistrationNumber"
                    format={{ xs: 12, md: 3 }}
                    value={filter.isWrongPensionerRegistrationNumber || []}
                    options={IS_WRONG_PENSIONER_REGISTRATION_NUMBER_OPTIONS}
                />
                <Autocomplete
                    value={filter.groupedContact || []}
                    options={optionsArray || []}
                    groupBy={(option) => option.type_hu}
                    id="groupedContact"
                    name="groupedContact"
                    format={{ xs: 12, md: 3 }}
                    label="Kapcsolattartó"
                />
                <Autocomplete
                    label="Jogosultság"
                    name="isActive"
                    id="isActive"
                    format={{ xs: 12, md: 3 }}
                    value={filter.isActive}
                    options={IS_ACTIVE_OPTIONS}
                />
                <Autocomplete
                    label="Szolgáltató"
                    name="provider"
                    options={providerOptionsBasic}
                    format={{ xs: 12, md: 3 }}
                    value={filter.provider || []}
                />
            </Filter>
            {showTable && (
                <Grid item xs={12}>
                    <Table {...table} />
                </Grid>
            )}
        </Grid>
    );
}
