import Particles from "./Particles";
import HelmetTemplate from "./HelmetTemplate";
import { Container, Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Logo from "../../logo.svg";

const useStyles = makeStyles((theme) => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexWrap: "nowrap",
    },
    avatar: {
        margin: theme.spacing(3),
        position: "relative",
        width: "80%",
        height: "auto",
    },
    wrapper: {
        position: "relative",
        padding: "unset",
    },
    base: {
        padding: "10px",
    },
    containerMobile: {
        position: "relative",
        display: "block",
        verticalAlign: "middle",
    },
    title: {
        fontWeight: "bold",
        zIndex: 1,
        marginBottom: theme.spacing(4),
    },
}));

export default function OffLayoutTemplate(props) {
    const { title } = props;
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const matchesMedium = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <>
            <Particles matches={matchesMedium} />
            <Container component="main" maxWidth="xs" className={matches ? "" : classes.containerMobile}>
                <HelmetTemplate title={title} />
                <div className={matches ? classes.base : ""}>
                    <Grid container spacing={3} className={classes.paper}>
                        <img className={classes.avatar} src={Logo} alt={`Logo`} />
                        <Typography component="h1" variant="h5" align="center" className={classes.title}>
                            Alkalmazotti Tarifa Rendszer
                        </Typography>
                        {props.children}
                    </Grid>
                </div>
            </Container>
        </>
    );
}
