export const types = {
    DEFAULT_FORM: "@@monthly-consumption-data/DEFAULT_FORM",
    CHANGE_FORM: "@@monthly-consumption-data/CHANGE_FORM",

    SET_LOADING: "@@monthly-consumption-data/SET_LOADING",

    FIRST_FETCH_REQUEST: "@@monthly-consumption-data/FIRST_FETCH_REQUEST",
    FIRST_FETCH_SUCCESS: "@@monthly-consumption-data/FIRST_FETCH_SUCCESS",
    FIRST_FETCH_FAILURE: "@@monthly-consumption-data/FIRST_FETCH_FAILURE",

    FETCH_REQUEST: "@@monthly-consumption-data/FETCH_REQUEST",
    FETCH_SUCCESS: "@@monthly-consumption-data/FETCH_SUCCESS",
    FETCH_FAILURE: "@@monthly-consumption-data/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@monthly-consumption-data/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@monthly-consumption-data/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@monthly-consumption-data/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@monthly-consumption-data/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@monthly-consumption-data/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@monthly-consumption-data/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@monthly-consumption-data/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@monthly-consumption-data/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@monthly-consumption-data/EXPORT_DOWNLOAD_FAILURE",

    FETCH_SET_INFO: "@@monthly-consumption-data/FETCH_SET_INFO",
};
