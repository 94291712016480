export const types = {
    DEFAULT_FORM: "@@consumption-data-by-permission/DEFAULT_FORM",
    CHANGE_FORM: "@@consumption-data-by-permission/CHANGE_FORM",

    FETCH_REQUEST: "@@consumption-data-by-permission/FETCH_REQUEST",
    FETCH_SUCCESS: "@@consumption-data-by-permission/FETCH_SUCCESS",
    FETCH_FAILURE: "@@consumption-data-by-permission/FETCH_FAILURE",

    EXPORT_INITIATE_REQUEST: "@@consumption-data-by-permission/EXPORT_INITIATE_REQUEST",
    EXPORT_INITIATE_SUCCESS: "@@consumption-data-by-permission/EXPORT_INITIATE_SUCCESS",
    EXPORT_INITIATE_FAILURE: "@@consumption-data-by-permission/EXPORT_INITIATE_FAILURE",

    EXPORT_CHECK_REQUEST: "@@consumption-data-by-permission/EXPORT_CHECK_REQUEST",
    EXPORT_CHECK_SUCCESS: "@@consumption-data-by-permission/EXPORT_CHECK_SUCCESS",
    EXPORT_CHECK_FAILURE: "@@consumption-data-by-permission/EXPORT_CHECK_FAILURE",

    EXPORT_DOWNLOAD_REQUEST: "@@consumption-data-by-permission/EXPORT_DOWNLOAD_REQUEST",
    EXPORT_DOWNLOAD_SUCCESS: "@@consumption-data-by-permission/EXPORT_DOWNLOAD_SUCCESS",
    EXPORT_DOWNLOAD_FAILURE: "@@consumption-data-by-permission/EXPORT_DOWNLOAD_FAILURE",

    CLEAR_FORM: "@@consumption-data-by-permission/CLEAR_FORM",

    SET_LOADING: "@@consumption-data-by-permission/SET_LOADING",
};
