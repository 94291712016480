import React from "react";
import CardTable from "../../../Common/CardTable";
import EditableCard from "../../../Common/EditableCard";

export default function AddConsumptionMeters(props) {
    const { addNew } = props;

    const table = {
        columnDefs: [
            { headerName: "", field: "id", maxWidth: 60 },
            { headerName: "Szám", field: "number", editable: true },
            {
                headerName: "Jelenlegi állás (kWh)",
                field: "currentPosition",
                editable: true,
            },
        ],
        rowData: [
            {
                id: "1.",
                number: "",
                currentPosition: "",
            },
            {
                id: "2.",
                number: "",
                currentPosition: "",
            },
            {
                id: "3.",
                number: "",
                currentPosition: "",
            },
        ],
    };

    return (
        <EditableCard title="Fogyasztásmérő órák" addNew={addNew}>
            <CardTable singleClickEdit={true} showPagination={false} noExportButton {...table}></CardTable>
        </EditableCard>
    );
}
