import { types } from "./types";

const initialState = {
    providerData: [],
    contactData: [],
    providerFilter: {},
    contactFilter: {},
    loading: false,
    token: "",
    status: null,
    file: null,
};

export function admissedApplications(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form,
            };
        case types.DEFAULT_FORM_PROVIDER:
            return {
                ...state,
                providerFilter: initialState.providerFilter,
            };
        case types.DEFAULT_FORM_CONTACT:
            return {
                ...state,
                contactFilter: initialState.contactFilter,
            };
        case types.FETCH_REQUEST_PROVIDER:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS_PROVIDER:
            return {
                ...state,
                loading: false,
                providerData: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE_PROVIDER:
            return {
                ...state,
                loading: false,
                providerData: [],
                error: action.error,
            };
        case types.FETCH_REQUEST_CONTACT:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SUCCESS_CONTACT:
            return {
                ...state,
                loading: false,
                contactData: action?.data?.data?.data,
            };
        case types.FETCH_FAILURE_CONTACT:
            return {
                ...state,
                loading: false,
                contactData: [],
                error: action.error,
            };
        case types.DOWNLOAD_REQUEST:
            return {
                ...state,
            };
        case types.DOWNLOAD_SUCCESS:
            return {
                ...state,
            };
        case types.DOWNLOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_PROVIDER:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_INITIATE_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_INITIATE_SUCCESS_CONTACT:
            return {
                ...state,
                exportId: action.data.data.id,
            };
        case types.EXPORT_INITIATE_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_PROVIDER:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_CHECK_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_CHECK_SUCCESS_CONTACT:
            return {
                ...state,
                status: action.data.data.status,
            };
        case types.EXPORT_CHECK_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_PROVIDER:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_PROVIDER:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_PROVIDER:
            return {
                ...state,
                error: action.error,
            };
        case types.EXPORT_DOWNLOAD_REQUEST_CONTACT:
            return {
                ...state,
            };
        case types.EXPORT_DOWNLOAD_SUCCESS_CONTACT:
            return {
                ...state,
                file: action.data.data,
            };
        case types.EXPORT_DOWNLOAD_FAILURE_CONTACT:
            return {
                ...state,
                error: action.error,
            };
        case types.CHANGE_STATUS_REQUEST:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_SUCCESS:
            return {
                ...state,
            };
        case types.CHANGE_STATUS_FAILURE:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}
