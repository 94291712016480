import { AppBar, makeStyles, Tab } from "@material-ui/core";
import React, { Children } from "react";
import TabPanel from "./TabPanel";
import Tabs from "./Tabs";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

/**
 *
 * @param tabContents - objektumok tömbje, benne az egyes tabok megnevezésével (label) és
 * tartalmazott komponensével (value).
 */

export default function TabsCompleted(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.currentTab ? props.currentTab : 0);

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Tabs value={value} onChange={(e, v) => setValue(v)}>
                    {Children.toArray(props.tabContents.map((e) => <Tab label={e.label} />))}
                </Tabs>
            </AppBar>
            {Children.toArray(
                props.tabContents.map((e, i) => (
                    <TabPanel value={value} index={i}>
                        {e.value}
                    </TabPanel>
                ))
            )}
        </div>
    );
}
