import {
    templateChangeForm,
    templateDefaultForm,
    templateCreate,
    templateExportCheck,
    templateExportDownload,
    templateExportInitiate,
    templateFilter,
    templateClearForm,
    templateFirstFetch,
} from "..";
import employmentTerminationsService from "../../services/employmentTerminations";
import { types } from "./types";

function checkCustomer(mavirId) {
    return (dispatch) => {
        dispatch({ type: types.CHECK_CUSTOMER_REQUEST, mavirId });
        return employmentTerminationsService.checkCustomer(mavirId).then(
            (data) => {
                dispatch({ type: types.CHECK_CUSTOMER_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.CHECK_CUSTOMER_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function setInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_INFO, key, value });
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, employmentTerminationsService),
    firstFetch: templateFirstFetch(types, employmentTerminationsService),
    exportInitiate: templateExportInitiate(types, employmentTerminationsService),
    exportCheck: templateExportCheck(types, employmentTerminationsService),
    exportDownload: templateExportDownload(types, employmentTerminationsService),
    create: templateCreate(types, employmentTerminationsService),
    clearForm: templateClearForm(types, employmentTerminationsService),
    checkCustomer,
    setInfo
};

export default actions;
